import React from "react";
import fourSvg from "./four.svg";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

function StepFour() {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div className="main-section-container">
      <div className="container-fluid container-width">
        <div className="row justify-content-between justify-content-around-under-768">
          {isMobile && (
            <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 align-self-center text-center">
              <img alt="..." className="width-100 big-section-img" src={fourSvg} />
            </div>
          )}
          <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 post-450-start-text-align align-self-center">
            <h2 className="sub-page-section-header mb-3">
              {t("Package consolidation")}
            </h2>
            <p className="text-grey">{t("Package consolidation why choose us page description")}</p>
          </div>
          {!isMobile && (
            <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 align-self-center text-center">
              <img alt="..." className="width-100 big-section-img" src={fourSvg} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StepFour;
