import React from "react";
import One from "./one";
import Two from "./two";
import Three from "./three";
import Four from "./four";
import Five from "./five";

function WhyChooseUs() {
	return (
		<div className="global-section-container flex-col">
			<One />
			<Two />
			<Three />
			<Four />
			<Five />
		</div>
	);
}

export default WhyChooseUs;
