import BackgroundTapeWrapper from "./wrapper";
import Title from "./title";
import Content from "./content";
import styles from "./styles.module.scss";

const SeamlessTech = () => {
    return (
        <div className={styles["seamless-tech"]}>
            <BackgroundTapeWrapper>
                <div className="d-flex justify-content-between flex-wrap">
                    <Title />
                    <Content />
                </div>
            </BackgroundTapeWrapper>
        </div>
    );
};

export default SeamlessTech;
