import React, { useEffect, useState } from "react"
import basicIcon from "./basicIcon.svg"
import premiumIcon from "./premiumIcon.svg"
import businessIcon from "./businessIcon.svg"
import { useTranslation } from "react-i18next"
import checkIcon from "./checkIcon.svg"
import { Button, Col, Form, Row } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import oneYearFreeEN from "./en-1yearfree.svg"
import oneYearFreeAR from "./ar-1yearfree.svg"
import { MEMBERSHIP_CONSTANTS, NUM_DAYS_BEFORE_EXPIRY, PROMOCODES } from "../../constants/constants";
import * as Scroll from "react-scroll";

import "./style.scss";
import { Link } from "react-router-dom"
import { ACCOUNT_PAYMENT, REGISTRATION_FORM } from "../../constants/navigationConstants"
import { getUserDetails } from "../../services/userInfoService"
import { calculateDifferenceInDays, checkForLifeTimeSubscribtion } from "../../utils/string"
import { useMediaQuery } from "react-responsive"
import useAnalytics from "../../analytics/useAnalytics";
import { screenEvent } from "../../analytics/constants";

export default () => {
    const { t, i18n } = useTranslation()
    const {
        loggedIn,
        membershipPlanID,
        setDefaultPromoCode,
        isSubYearly,
        setIsSubYearly,
        isRenewalYearly,
        setIsRenewalYearly,
        authToken,
        setMembershipPlanID
    } = useAuth()
    const { PREMIUM_COST_MONTH, PREMIUM_COST_YEAR, BUSINESS_COST_MONTH, BUSINESS_COST_YEAR, BUSINESS, PREMIUM, BASIC } = MEMBERSHIP_CONSTANTS
    let ScrollLink = Scroll.Link;
    const [membershipDetails, setMembershipDetails] = useState(null)
    const [premPrice, setPremPrice] = useState(PREMIUM_COST_MONTH)
    const [busPrice, setBusPrice] = useState(BUSINESS_COST_MONTH)
    const [planRenew, setPlanRenew] = useState(null)
    const isMobile = useMediaQuery({ query: "(max-width: 991px)" });
    const analytics = useAnalytics()

    const handleSignUpClick = async (price = 0) => {
        switch (price) {
            // case PREMIUM_COST_MONTH:
            //     setPremPrice(PREMIUM_COST_MONTH)
            //     await setDefaultPromoCode(process.env.REACT_APP_FREE_SUB_OFFER_CODE_PREMIUM_NORMAL)
            //     if (loggedIn) {
            //         await setIsRenewalYearly(0)
            //     } else {
            //         await setIsSubYearly(0)
            //     }
            //     if (!loggedIn) {
            //         setMembershipPlanID(PREMIUM)
            //     }
            //     break;
            case PREMIUM_COST_MONTH:
            case PREMIUM_COST_YEAR:
                setPremPrice(PREMIUM_COST_YEAR)
                await setDefaultPromoCode(process.env.REACT_APP_FREE_SUB_OFFER_CODE_PREMIUM_NORMAL)
                if (loggedIn) {
                    await setIsRenewalYearly(1)
                } else {
                    await setIsSubYearly(1)
                }
                if (!loggedIn) {
                    setMembershipPlanID(PREMIUM)
                }
                break;
            case BUSINESS_COST_MONTH:
                setBusPrice(BUSINESS_COST_MONTH)
                await setDefaultPromoCode(process.env.REACT_APP_FREE_SUB_OFFER_CODE_BUSINESS_NORMAL)
                if (loggedIn) {
                    await setIsRenewalYearly(0)
                } else {
                    await setIsSubYearly(0)
                }
                if (!loggedIn) {
                    setMembershipPlanID(BUSINESS)
                }
                break;
            case BUSINESS_COST_YEAR:
                setBusPrice(BUSINESS_COST_YEAR)
                await setDefaultPromoCode(process.env.REACT_APP_FREE_SUB_OFFER_CODE_BUSINESS_NORMAL)
                if (loggedIn) {
                    await setIsRenewalYearly(1)
                } else {
                    await setIsSubYearly(1)
                }
                if (!loggedIn) {
                    setMembershipPlanID(BUSINESS)
                }
                break;
            default:
                if (price === 0) {
                    if (localStorage?.getItem("isSubYearly")) {
                        localStorage?.removeItem("isSubYearly")
                    }
                    if (localStorage?.getItem("isRenewalYearly")) {
                        localStorage?.removeItem("isRenewalYearly")
                    }
                    if (localStorage?.getItem("defaultPromoCode")) {
                        localStorage?.removeItem("defaultPromoCode")
                    }
                    setMembershipPlanID(BASIC)
                }
                break;
        }
    }

    const retrieveUserDetails = async () => {
        try {
            const res = await getUserDetails(authToken);
            setMembershipDetails({
                ...membershipDetails,
                type: res?.membership?.name,
                cycle: "",
                date: res?.plan_data?.expiry_at,
                fee: res?.membership?.price,
                promo_code: res?.plan_data?.promo_code === PROMOCODES.ARAMCO ? res?.plan_data?.promo_code : null,
                for_life_time: checkForLifeTimeSubscribtion(res.plan_data?.expiry_at),
                num_of_days: calculateDifferenceInDays(res?.plan_data?.expiry_at),
                is_yearly: res?.plan_type,
                plan_id: Number(res?.membership?.id)
            });
            setIsSubYearly(res?.plan_type)
            setMembershipPlanID(res?.membership?.id)
            switch (Number(res?.membership?.id)) {
                case PREMIUM:
                    if (res?.plan_type) {
                        setPremPrice(PREMIUM_COST_YEAR)
                    } else {
                        setPremPrice(PREMIUM_COST_MONTH)
                    }
                    break;

                case BUSINESS:
                    if (res?.plan_type) {
                        setPremPrice(BUSINESS_COST_YEAR)
                    } else {
                        setPremPrice(BUSINESS_COST_MONTH)
                    }
                    break;

                default:
                    break;
            }
        } catch (e) {
            console.log(e);
        }
    }

    const features = [
        {
            // boldText: "US & UK address",
            boldText: "Free Addresses",
            regularText: "US, Turkey & Dubai"
        },
        {
            boldText: "Act W|True|W",
            regularText: "Pay per",
            reversed: true
        },
        {
            boldText: "Item",
            diff: {
                b: "1",
                p: "3",
                w: "3"
            },
            regularText: "photos"
        },
        {
            boldText: "US tax free",
            regularText: "shopping"
        },
        {
            boldText: "Multiple shipping",
            regularText: "options"
        },
        {
            boldText: "Buy it for me",
            regularText: "Service-plans"
        },
        {
            boldText: "Insurance"
        },
        {
            boldText: "days",
            diff: {
                p: "30",
                w: "45"
            },
            regularText: "storage"
        },
        {
            boldText: "Item & Package",
            regularText: "Consolidation-plans"
        },
        {
            boldText: "Repackaging",
            regularText: "Package-plans"
        },
        {
            boldText: "% discount",
            diff: {
                p: "10",
                w: "20"
            },
            regularText: "on all Shipping Fees"
        },
        {
            boldText: "Special Discounts",
            regularText: "on heavy Shipments"
        }
    ]

    useEffect(async () => {
        if (!loggedIn) {
            setMembershipPlanID(BASIC?.toString())
        } else {
            await retrieveUserDetails()
        }
    }, [loggedIn, membershipPlanID])

    useEffect(() => {
        if (isMobile) {
            document.getElementById("premium-plan-features")?.click()
        }
    }, [isMobile])

    useEffect(async () => {
        if (localStorage.getItem("defaultPromoCode")) {
            localStorage.removeItem("defaultPromoCode")
        }
        await setDefaultPromoCode(null)
    }, [])

    useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.MEMBERSHIP });
    }, []);

    const handlePickPlanEvent = (targetPlanId) => {
        const isYearly = busPrice === BUSINESS_COST_YEAR;
        const analyticsAttributes = {
            action: loggedIn
                ? targetPlanId > parseInt(membershipPlanID)
                    ? "Upgrade"
                    : "Renew"
                : "Registration",
            membershipType: MEMBERSHIP_CONSTANTS[`${targetPlanId}`],
            planType:
                targetPlanId === 2 ? "Yearly" : isYearly ? "Yearly" : "Monthly",
            amount:
                targetPlanId === 2
                    ? MEMBERSHIP_CONSTANTS["PREMIUM_COST_YEAR"]
                    : targetPlanId === 3
                    ? isYearly
                        ? MEMBERSHIP_CONSTANTS["BUSINESS_COST_YEAR"]
                        : MEMBERSHIP_CONSTANTS["BUSINESS_COST_MONTH"]
                    : 0,
        };
        analytics.events.membershipSelected(analyticsAttributes);
    };

    return (
        <div className="main-section-container mb-2 full-height-page-small-nav w-100 d-flex flex-column">
            <div className="container-fluid container-width">
                <Row className="justify-content-around mt-2 mt-mobile-0">
                    <Col lg={4} xs={12} className="mt-4 pt-2 px-2">
                        <div className="white-background rounded pt-3 pb-3 mr-2 ml-2 border-blue-top px-3">
                            <h2 className={`text-start mt-1 header_icns_txt font-weight-500`}>
                                <img className="mx-2" src={basicIcon} alt="basic_icon" />
                                {t("Basic")}
                            </h2>
                            <h2 className={`text-start mx-3`}>
                                {t("Free")}
                            </h2>
                            <div className="mt-3 mx-3 mb-3">
                                {[...features?.slice(0, 7)]?.map((feature, index) =>
                                    <h6 className={`${index === [...features?.slice(0, 7)]?.length - 1 ? "" : "border-bottom"} pb-2 text-start font-size-screen-size`}>
                                        <img src={checkIcon} alt="Check Icon" />
                                        {([3, 4, 5]?.includes(index) && i18n?.language === "ar") || [1].includes(index)?
                                            <>
                                                {feature?.regularText && <span className={index === 0 ? "soon" : ""}>{
                                                    feature?.regularText === "photos" 
                                                    ? t("photo") 
                                                    : t(feature?.regularText)
                                                }</span>}
                                                &nbsp;
                                                <strong>{
                                                    feature?.diff?.b ?
                                                        t(`${feature?.diff?.b} ${feature?.boldText}`)
                                                        : feature?.boldText.includes("True|W") ?
                                                        <>
                                                            {t(feature?.boldText.split("|")[0])}
                                                            &nbsp;-&nbsp;
                                                            <strong className="blue-color d-inline">
                                                                {t(feature?.boldText.split("|")[1])}
                                                            </strong>
                                                            {i18n?.language === "ar" ? " " : ""}
                                                            <strong className="color-green d-inline">
                                                                {t(feature?.boldText.split("|")[2])}
                                                            </strong>
                                                        </>
                                                        : t(feature?.boldText)
                                                }</strong>
                                            </>
                                            :
                                            <>
                                                <strong>{
                                                    feature?.diff?.b ?
                                                        t(`${feature?.diff?.b} ${feature?.boldText}`)
                                                        :
                                                        t(feature?.boldText)
                                                }</strong>
                                                &nbsp;
                                                {feature?.regularText && <span className={index === 0 ? "soon" : ""}>{
                                                    feature?.regularText === "photos" ? t("photo") : t(feature?.regularText)
                                                }</span>}
                                            </>
                                        }
                                    </h6>
                                )}
                            </div>
                            {(membershipDetails?.plan_id || Number(membershipPlanID)) === BASIC && <Link
                                to={REGISTRATION_FORM}
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: "smooth",
                                    });
                                }}
                            >
                                <Button
                                    className={`text-capitalize ${loggedIn && (membershipDetails?.plan_id || Number(membershipPlanID)) === BASIC ? "current-plan" : "color-inverse-default-button"} w-92`}
                                    id=""
                                    disabled={loggedIn && (membershipDetails?.plan_id || Number(membershipPlanID)) === BASIC}
                                    onClick={() => {
                                        setPremPrice(0)
                                        setBusPrice(0)
                                        handleSignUpClick()
                                        handlePickPlanEvent(1)
                                    }}
                                >
                                    {loggedIn && (membershipDetails?.plan_id || Number(membershipPlanID)) === BASIC ? t("Current plan") : t("Sign up")}
                                </Button>
                            </Link>}
                        </div>
                    </Col>
                    <Col lg={4} xs={12} className={"mt-mobile-3"} id="premium-plan-container">
                        <div className="p-relative pt-3">
                            {!loggedIn || !membershipPlanID || Number(membershipPlanID) === BASIC ?
                                i18n.language === "ar" ?
                                    <img className="en-one-free-month img-fluid" src={oneYearFreeAR} />
                                    :
                                    <img className="ar-one-free-month img-fluid" src={oneYearFreeEN} />
                                : null
                            }
                            <div className="py-2 most-popular btn-bg-color rounded-top text-uppercase text-center text-white">
                                {t("Most Popular")}
                            </div>
                            <div className="white-background rounded-bottom pt-3 pb-3 px-3 blue-border">
                                <h2 className={`text-start mt-1 header_icns_txt font-weight-500`}>
                                    <img className="mx-2" src={premiumIcon} alt="premium_icon" />
                                    {t("Premium")}
                                </h2>
                                <div className="mt-3 mb-3 mx-3">
                                    {[...features?.slice(0, 11)]?.map((feature, index) =>
                                        <h6 className={`${index === [...features?.slice(0, 11)]?.length - 1 ? "" : "border-bottom"} pb-2 text-start font-size-screen-size`}>
                                            <img src={checkIcon} alt="Check Icon" />
                                            {[3, 4, 5, 8]?.includes(index) && i18n?.language === "ar" || [1,8].includes(index) ?
                                                <>
                                                    {feature?.regularText && <span className={index === 0 ? "soon" : ""}>{t(feature?.regularText)}</span>}
                                                    &nbsp;
                                                    <strong className={feature?.boldText?.includes("% dicount") ? "color-red" : ""}>{
                                                        feature?.diff?.p ?
                                                            t(`${feature?.diff?.p}${feature?.boldText?.includes("% discount") ? "" : " "}${feature?.boldText}`)
                                                            : feature?.boldText.includes("True|W") ?
                                                            <>
                                                                {t(feature?.boldText.split("|")[0])}
                                                                &nbsp;-&nbsp;
                                                                <strong className="blue-color d-inline">
                                                                    {t(feature?.boldText.split("|")[1])}
                                                                </strong>
                                                                {i18n?.language === "ar" ? " " : ""}
                                                                <strong className="color-green d-inline">
                                                                    {t(feature?.boldText.split("|")[2])}
                                                                </strong>
                                                            </>
                                                            : t(feature?.boldText)
                                                    }</strong>
                                                </>
                                                :
                                                <>
                                                    <strong className={feature?.boldText?.includes("% discount") ? "color-red" : ""}>{
                                                        feature?.diff?.p ? 
                                                            t(`${feature?.diff?.p}${feature?.boldText?.includes("% discount") ? "" : " "}${feature?.boldText}`)
                                                            :
                                                            t(feature?.boldText)
                                                    }</strong>
                                                    &nbsp;
                                                    {feature?.regularText && <span className={index === 0 ? "soon" : ""}>{t(feature?.regularText)}</span>}
                                                </>
                                            }
                                        </h6>
                                    )}
                                </div>
                                {/* {((membershipDetails?.plan_id || Number(membershipPlanID)) < PREMIUM ||
                                    ((membershipDetails?.plan_id || Number(membershipPlanID)) === PREMIUM && membershipDetails?.num_of_days < NUM_DAYS_BEFORE_EXPIRY)) && <Form>
                                        <Row className="d-flex align-items-center justify-content-between border-top mx-3">
                                            <Col
                                                lg={5}
                                                xs={5}
                                                className="px-0 tr-cursor d-flex align-items-center justify-content-start prem-month"
                                                onClick={() => {
                                                    setPremPrice(PREMIUM_COST_MONTH)
                                                    if ((membershipDetails?.plan_id || Number(membershipPlanID)) === PREMIUM && membershipDetails?.num_of_days < NUM_DAYS_BEFORE_EXPIRY) {
                                                        setPlanRenew("premMonthly")
                                                    }
                                                }}
                                            >
                                                <Form.Check
                                                    type="radio"
                                                    className={i18n?.language === "ar" ? "ml-1" : "mr-1"}
                                                    name={"monthlySub"}
                                                    id={""}
                                                    checked={premPrice && premPrice === PREMIUM_COST_MONTH}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setPremPrice(PREMIUM_COST_MONTH)
                                                            if ((membershipDetails?.plan_id || Number(membershipPlanID)) === PREMIUM && membershipDetails?.num_of_days < NUM_DAYS_BEFORE_EXPIRY) {
                                                                setPlanRenew("premMonthly")
                                                            }
                                                        } else {
                                                            setPremPrice(0)
                                                            setPlanRenew(null)
                                                        }
                                                    }}
                                                />
                                                <span className="font-weight-500 blue-color prices">${PREMIUM_COST_MONTH}</span><span className="select">/{t("Month")}</span>

                                            </Col>
                                            <span className="or-text font-weight-300 text-w-opacity">{t("or")}</span>
                                            <Col
                                                lg={6}
                                                xs={6}
                                                className="px-0 tr-cursor d-flex align-items-center justify-content-end prem-year"
                                                onClick={() => {
                                                    setPremPrice(PREMIUM_COST_YEAR)
                                                    if (loggedIn && membershipDetails?.num_of_days < NUM_DAYS_BEFORE_EXPIRY
                                                        && ((membershipDetails?.plan_id || Number(membershipPlanID)) === PREMIUM)
                                                    ) {
                                                        setPlanRenew("premYearly")
                                                    }
                                                }}
                                            >
                                                <Form.Check
                                                    type="radio"
                                                    name={"yearlySub"}
                                                    className={i18n?.language === "ar" ? "ml-1" : "mr-1"}
                                                    id={""}
                                                    checked={premPrice && premPrice === PREMIUM_COST_YEAR}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setPremPrice(PREMIUM_COST_YEAR)
                                                            if (loggedIn && membershipDetails?.num_of_days < NUM_DAYS_BEFORE_EXPIRY
                                                                && ((membershipDetails?.plan_id || Number(membershipPlanID)) === PREMIUM)
                                                            ) {
                                                                setPlanRenew("premYearly")
                                                            }
                                                        } else {
                                                            setPremPrice(0)
                                                            setPlanRenew(null)
                                                        }
                                                    }}
                                                />
                                                <span className="font-weight-500 color-red position-relative prices old-price-yr">$96</span>
                                                &nbsp;
                                                <span className="font-weight-500 blue-color prices">${PREMIUM_COST_YEAR}</span><span className="select">/{t("Year")}</span>
                                            </Col>
                                        </Row>
                                    </Form>} */}
                                {(membershipDetails?.plan_id || Number(membershipPlanID)) <= PREMIUM && <Link
                                    to={{
                                        pathname: loggedIn ? ACCOUNT_PAYMENT : REGISTRATION_FORM,
                                        state: {
                                            membership_plan_id: PREMIUM,
                                            renewal: {
                                                status: loggedIn && (membershipDetails?.plan_id || Number(membershipPlanID)) === PREMIUM && membershipDetails?.num_of_days < NUM_DAYS_BEFORE_EXPIRY,
                                                type: planRenew
                                            },
                                            analytics_is_yearly: isSubYearly ? true:false,
                                            analytics_source:   loggedIn
                                            ? 2 > parseInt(membershipPlanID)
                                                ? "Upgrade"
                                                : "Renew"
                                            : "Registration"
                                        },
                                    }}
                                    onClick={() => {
                                        window.scrollTo({
                                            top: 0,
                                            left: 0,
                                            behavior: "smooth",
                                        });
                                    }}
                                >
                                    <Button
                                        className={`text-capitalize ${(membershipDetails?.plan_id || Number(membershipPlanID)) === PREMIUM && membershipDetails?.num_of_days >= NUM_DAYS_BEFORE_EXPIRY && !planRenew ? "current-plan" : loggedIn ? "color-inverse-default-button" : "bg-blue"} w-92`}
                                        id=""
                                        disabled={[0, BUSINESS_COST_MONTH, BUSINESS_COST_YEAR].includes(premPrice) || (membershipDetails?.plan_id || Number(membershipPlanID)) === PREMIUM && membershipDetails?.num_of_days >= NUM_DAYS_BEFORE_EXPIRY && !planRenew}
                                        onClick={() => {
                                            handleSignUpClick(premPrice)
                                            handlePickPlanEvent(2)
                                        }}
                                    >
                                        { (!loggedIn || Number(membershipPlanID) === BASIC)? 
                                            t("Start free trial")
                                            :
                                            (membershipDetails?.plan_id || Number(membershipPlanID)) === PREMIUM ?
                                                membershipDetails?.num_of_days >= NUM_DAYS_BEFORE_EXPIRY 
                                                && (!planRenew || !["premYearly", "premMonthly"].includes(planRenew)) ? 
                                                t("Current plan") : t("Renew plan") 
                                            : t("Upgrade plan") 
                                        }
                                    </Button>
                                </Link>}
                                { !loggedIn && <p className="color-red my-3 text-center">{t("No credit card needed to register")}</p> }
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} xs={12} className="mt-4 pt-2 px-2">
                        <div className="white-background rounded pt-3 pb-3 mr-2 ml-2 border-blue-top px-3">
                            <h2 className={`text-start mt-1 header_icns_txt font-weight-500`}>
                                <img className="mx-2" src={businessIcon} alt="business_icon" />
                                {t("Business")}
                            </h2>
                            <div className="mb-3 mt-0 mx-3">
                                {[...features]?.map((feature, index) =>
                                    <h6 className={`${index === [...features]?.length - 1 ? "" : "border-bottom"} pb-2 text-start font-size-screen-size`}>
                                        <img src={checkIcon} alt="Check Icon" />
                                        {[3, 4, 5, 8]?.includes(index) && i18n?.language === "ar" || [1,8].includes(index) ?
                                            <>
                                                {feature?.regularText && <span className={index === 0 ? "soon" : ""}>{t(feature?.regularText)}</span>}
                                                &nbsp;
                                                <strong className={feature?.boldText?.includes("% discount") ? "color-red" : ""}>{
                                                    feature?.diff?.w ?
                                                        t(`${feature?.diff?.w}${feature?.boldText?.includes("% discount") ? "" : " "}${feature?.boldText}`)
                                                        : feature?.boldText.includes("True|W") ?
                                                        <>
                                                            {t(feature?.boldText.split("|")[0])}
                                                            &nbsp;-&nbsp;
                                                            <strong className="blue-color d-inline">
                                                                {t(feature?.boldText.split("|")[1])}
                                                            </strong>
                                                            {i18n?.language === "ar" ? " " : ""}
                                                            <strong className="color-green d-inline">
                                                                {t(feature?.boldText.split("|")[2])}
                                                            </strong>
                                                        </>
                                                        : t(feature?.boldText)
                                                }</strong>
                                            </>
                                            :
                                            <>
                                                <strong className={feature?.boldText?.includes("% discount") ? "color-red" : ""}>{
                                                    feature?.diff?.w ?
                                                        t(`${feature?.diff?.w}${feature?.boldText?.includes("% discount") ? "" : " "}${feature?.boldText}`)
                                                        :
                                                        t(feature?.boldText)
                                                }</strong>
                                                &nbsp;
                                                {feature?.regularText && <span className={index === 0 ? "soon" : ""}>{t(feature?.regularText)}</span>}
                                            </>
                                        }
                                    </h6>
                                )}
                            </div>
                            {((membershipDetails?.plan_id || Number(membershipPlanID)) < BUSINESS ||
                                ((membershipDetails?.plan_id || Number(membershipPlanID)) === BUSINESS && membershipDetails?.num_of_days < NUM_DAYS_BEFORE_EXPIRY)) && <Form>
                                    <Row className="d-flex align-items-center justify-content-between border-top mx-3">
                                        <Col
                                            md={5}
                                            xs={5}
                                            className="px-0 tr-cursor d-flex align-items-center justify-content-start bus-month"
                                            onClick={() => {
                                                setBusPrice(BUSINESS_COST_MONTH)
                                                if ((membershipDetails?.plan_id || Number(membershipPlanID)) === BUSINESS && membershipDetails?.num_of_days < NUM_DAYS_BEFORE_EXPIRY) {
                                                    setPlanRenew("businessMonthly")
                                                }
                                            }}
                                        >
                                            <Form.Check
                                                type="radio"
                                                className={`${i18n?.language === "ar" ? "ml-1" : "mr-1"}`}
                                                name={"monthlySub"}
                                                id={""}
                                                checked={busPrice && busPrice === BUSINESS_COST_MONTH}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setBusPrice(BUSINESS_COST_MONTH)
                                                        if ((membershipDetails?.plan_id || Number(membershipPlanID)) === BUSINESS && membershipDetails?.num_of_days < NUM_DAYS_BEFORE_EXPIRY) {
                                                            setPlanRenew("businessMonthly")
                                                        }
                                                    } else {
                                                        setBusPrice(0)
                                                        setPlanRenew(null)
                                                    }
                                                }}
                                            />
                                            <span className="font-weight-500 blue-color prices">${BUSINESS_COST_MONTH}</span><span className="select">/{t("Month")}</span>

                                        </Col>
                                        <span className="or-text font-weight-300 text-w-opacity">{t("or")}</span>
                                        <Col
                                            md={5} xs={5}
                                            className="px-0 tr-cursor d-flex align-items-center justify-content-end bus-year"
                                            onClick={(e) => {
                                                setBusPrice(BUSINESS_COST_YEAR)
                                                if (membershipDetails?.num_of_days < NUM_DAYS_BEFORE_EXPIRY
                                                    && ((membershipDetails?.plan_id || Number(membershipPlanID)) === BUSINESS)
                                                ) {
                                                    setPlanRenew("businessYearly")
                                                }
                                            }}
                                        >
                                            <Form.Check
                                                type="radio"
                                                name={"yearlySub"}
                                                className={i18n?.language === "ar" ? "ml-1" : "mr-1"}
                                                id={""}
                                                checked={busPrice && busPrice === BUSINESS_COST_YEAR}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setBusPrice(BUSINESS_COST_YEAR)
                                                        if (loggedIn && membershipDetails?.num_of_days < NUM_DAYS_BEFORE_EXPIRY
                                                            && ((membershipDetails?.plan_id || Number(membershipPlanID)) === PREMIUM)
                                                        ) {
                                                            setPlanRenew("businessYearly")
                                                        }
                                                    } else {
                                                        setBusPrice(0)
                                                        setPlanRenew(null)
                                                    }
                                                }}
                                            />
                                            <span className="font-weight-500 blue-color prices">${BUSINESS_COST_YEAR}</span><span className="select">/{t("Year")}</span>
                                        </Col>
                                    </Row>
                                </Form>}
                            <Link
                                to={{
                                    pathname: loggedIn ? ACCOUNT_PAYMENT : REGISTRATION_FORM,
                                    state: {
                                        membership_plan_id: BUSINESS,
                                        renewal: {
                                            status: loggedIn && (membershipDetails?.plan_id || Number(membershipPlanID)) === BUSINESS && membershipDetails?.num_of_days < NUM_DAYS_BEFORE_EXPIRY,
                                            type: planRenew
                                        },
                                        analytics_is_yearly: busPrice === BUSINESS_COST_YEAR ? true : false,
                                        analytics_source:   loggedIn
                                        ? 3> parseInt(membershipPlanID)
                                            ? "Upgrade"
                                            : "Renew"
                                        : "Registration"
                                    },
                                }}
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: "smooth",
                                    });
                                }}
                            >
                                <Button
                                    className={`text-capitalize ${(membershipDetails?.plan_id || Number(membershipPlanID)) === BUSINESS && membershipDetails?.num_of_days >= NUM_DAYS_BEFORE_EXPIRY && !planRenew ? "current-plan" : "color-inverse-default-button"} w-92`}
                                    id=""
                                    disabled={[0, PREMIUM_COST_MONTH, PREMIUM_COST_YEAR].includes(busPrice) || (membershipDetails?.plan_id || Number(membershipPlanID)) === BUSINESS && membershipDetails?.num_of_days >= NUM_DAYS_BEFORE_EXPIRY && !planRenew}
                                    onClick={() => {
                                        handleSignUpClick(busPrice)
                                        handlePickPlanEvent(3);
                                    }}
                                >
                                    {loggedIn ?
                                        (membershipDetails?.plan_id || Number(membershipPlanID)) === BUSINESS ?
                                            membershipDetails?.num_of_days >= NUM_DAYS_BEFORE_EXPIRY
                                                && (!planRenew || !["businessYearly", "businessMonthly"].includes(planRenew)) ?
                                                t("Current plan") : t("Renew plan")
                                            : t("Upgrade plan")
                                        : t("Sign up")}
                                </Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>
            <p className="text-center mt-4 w-screen-size">
                {t("You may")}&nbsp;<strong>{t("cancel your subscription or change plan at any time")}</strong>&nbsp;
                {t("your plan will stay active until the end of your billing cycle")}
            </p>
            <ScrollLink
                to="premium-plan-container"
                spy={true}
                smooth={true}
                duration={300}
                id="premium-plan-features"
                className="d-none"
            />
        </div>
    )
}

