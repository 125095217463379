export const BuyItForMeTabElements = [
    {
        title: "buy_it_for_me_element_1_title",
        description: "buy_it_for_me_element_1_description",
        image: require("./add-url.svg")?.default,
        index: 0,
    },
    {
        title: "buy_it_for_me_element_2_title",
        description: "buy_it_for_me_element_2_description",
        image: require("./confirn-n-pay.svg")?.default,
        index: 1,
    },
    {
        title: "buy_it_for_me_element_3_title",
        description: "buy_it_for_me_element_3_description",
        image: require("./get-delivered.svg")?.default,
        index: 2,
    },
];

export const HowItWorksTabElements = [
    {
        title: "how_it_works_element_1_title",
        description: "how_it_works_element_1_description",
        image: require("./reg-n-get.svg")?.default,
        index: 0,
    },
    {
        title: "how_it_works_element_2_title",
        description: "how_it_works_element_2_description",
        image: require("./shop-n-enjoy.svg")?.default,
        index: 1,
    },
    {
        title: "how_it_works_element_3_title",
        description: "how_it_works_element_3_description",
        image: require("./get-delivered.svg")?.default,
        index: 2,
    },
];
