import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Row,
	Col,
	Button
} from "react-bootstrap";
import RequestWidget from "./requestWidget";
import Banner from "./banner/banner";
import { useAuth } from "../../contexts/AuthContext";
import "./style.scss";
import Modal from "./requests/modal/modal";
import { REQUESTS_CONSTANTS } from "../../constants/constants";
import useAnalytics from "../../analytics/useAnalytics";
import { screenEvent } from "../../analytics/constants";

export default (props) => {
	const [requestData, setRequestData] = useState({})
	const [show, setShow] = useState(false);
	const [reload, setReload] = useState(false);
	const analytics = useAnalytics();

	const [counts, setCounts] = useState({
		[REQUESTS_CONSTANTS.ALL]: 0,
		[REQUESTS_CONSTANTS.COMPLETED_VAL]: 0,
		[REQUESTS_CONSTANTS.PENDING_VAL]: 0,
	});

	const [allRequestData, setAllRequestData] = useState()
	const [pendingRequestData, setPendingRequestData] = useState()
	const [completedRequestData, setCompletedRequestData] = useState()

	let search = window.location.search;
	let params = new URLSearchParams(search);
	let status = params.get("status") || "all";
	let modalOpen = params.get("modal") || "";
	const viewModalClose = (update = false) => {
		setShow(false);
		if (update) {
			setReload(!reload)
		}
		setRequestData({});
	}

	const onRequest = (data, type) => {
		if (type === 'edit') {
			setRequestData(data)
		} else {
			setRequestData({})
		}
		setShow(true);
	};

	useEffect(() => {
		if(modalOpen === 1 || modalOpen === "1" )
			onRequest()
	}, [modalOpen])

	useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.BIFM });
    }, []);

	return (
		<section className="purchase-container-wrapper">
			<Row>
				<Col>
					<Banner />
				</Col>
			</Row>
			<div className="purchase-container">
				<Row className="row dashboard-row">
					<Col xxl={9} xl={9} md={12} sm={12} className="m-auto">
						<RequestWidget
							onRequestClick={onRequest}
							counts={counts}
							setCounts={setCounts}
							params={params}
							status={status}
							reload={reload}
							setReload={setReload}
							allRequestData={allRequestData}
							setAllRequestData={setAllRequestData}
							pendingRequestData={pendingRequestData}
							setPendingRequestData={setPendingRequestData}
							completedRequestData={completedRequestData}
							setCompletedRequestData={setCompletedRequestData}
						/>
					</Col>
				</Row>
			</div>
			<a data-request-data={requestData} data-toggle="modal" data-target="#purchaseModal" id="openPurchaseModel" />
			<Modal
				show={show}
				setShow={setShow}
				category={status}
				viewModalClose={viewModalClose}
				modalRow={requestData}
				counts={counts}
				setCounts={setCounts}
				allRequestData={allRequestData}
				setAllRequestData={setAllRequestData}
				pendingRequestData={pendingRequestData}
				setPendingRequestData={setPendingRequestData}
				reload={reload}
				setReload={setReload}
			/>

		</section>
	)
};