import React, { useState } from "react"
import { Button, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import * as navigationConstants from "../../constants/navigationConstants";
import walletIcon from "./walletIcon.svg";
import cartIcon from "./cartIcon.svg";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import useAnalytics from "../../analytics/useAnalytics";

const Header = ({ changeLanguage, lang, isMobile }) => {
    const { t, i18n } = useTranslation();
    const { loggedIn } = useAuth();
    const analytics = useAnalytics();

    return (
        <div className={isMobile ? "d-none" : "d-flex justify-content-end align-items-center p-2"}>
            {loggedIn && <Row className="w-25">
                <Link
                    className="white-space-nowrap greenish-button d-flex flex-row justify-content-center text-decoration-none pr-3 pl-3 mr-2 ml-2"
                    to={navigationConstants.PURCHASE_REQUEST}
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                        });
                        analytics.events.bifmButton()
                    }}
                >
                    <img src={cartIcon} className={`${i18n.language === "ar" ? "ml-2" : "mr-2"} py-2`} /> {t("Buying_Assistant")}
                </Link>
                <Link
                    className="d-flex flex-row justify-content-center text-decoration-none white-space-nowrap wallet-btn mx-3 p-2"
                    to={navigationConstants.WALLET}
                    onClick={analytics.events.walletButton}
                >
                    <img src={walletIcon} className={i18n.language === "ar" ? "ml-2" : "mr-2"} />
                    <strong>{t("Wallet")}</strong>
                    {/* {walletBalance} {t("usd")} */}
                </Link>
            </Row>
            }
            <div
                id="rate-calculator-header"
                className="tr-cursor px-2 text-end"
                onClick={() =>
                    document?.getElementById("rate-calculator-nav")?.click()
                }
            >
                <i className="mx-2 fas fa-calculator blue-color" />
                {t("Rate Calculator")}
            </div>
            <div id="language-button" className="tr-cursor px-4 text-end" onClick={() => changeLanguage(lang)}>
                <i className="mx-2 fas fa-globe-americas blue-color" />
                {t("Language")}
            </div>

        </div>
    )
}

export default Header