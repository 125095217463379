const usStores = [
    {
        titleEn: "6pm",
        url: "https://www.6pm.com/",
        image: require("./logos/us/logo-us-6pm.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Kids Fashion",
            "Men's Fashion",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "abercombie and fitch",
        url: "https://www.abercrombie.com/shop/wd",
        image: require("./logos/us/logo-us-Abercrombie.svg")?.default,
        categories: [
            "Activewear",
            "Kids Fashion",
            "Men's Fashion",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "adidas",
        url: "https://www.adidas.com/us",
        image: require("./logos/us/logo-us-adidas.svg")?.default,
        categories: [
            "Activewear",
            "Kids Fashion",
            "Men's Fashion",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "alo yoga",
        url: "https://www.aloyoga.com/",
        image: require("./logos/us/logo-us-alo.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Beauty",
            "Men's Fashion",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "amazon",
        url: "https://www.amazon.com/",
        image: require("./logos/us/logo-us-amazon.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Beauty",
            "Department store",
            "Health and wellness",
            "Home",
            "Kids Fashion",
            "Men's Fashion",
            "Technology",
            "Toys",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "American girl ",
        url: "https://www.americangirl.com/",
        image: require("./logos/us/logo-us-American-girl.svg")?.default,
        categories: ["Kids Fashion", "Toys"],
    },
    {
        titleEn: "Anthropologie ",
        url: "https://www.anthropologie.com/",
        image: require("./logos/us/logo-us-Anthropologie.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Beauty",
            "Home",
            "Kids Fashion",
            "Men's Fashion",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "Apple",
        url: "https://www.apple.com/",
        image: require("./logos/us/logo-us-apple.svg")?.default,
        categories: ["Technology"],
    },
    {
        titleEn: "athleta",
        url: "https://www.athleta.com/",
        image: require("./logos/us/logo-us-athleta.svg")?.default,
        categories: [
            "Activewear",
            "Kids Fashion",
            "Men's Fashion",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "best buy",
        url: "https://www.bestbuy.com/",
        image: require("./logos/us/logo-us-bestbuy.svg")?.default,
        categories: ["Beauty", "Home", "Technology", "Toys"],
    },
    {
        titleEn: "Bloomingdale's",
        url: "https://bloomingdales.com",
        image: require("./logos/us/logo-us-bloomingdales.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Beauty",
            "Department store",
            "Home",
            "Kids Fashion",
            "Men's Fashion",
            "Toys",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "Bluemercury",
        url: "https://bluemercury.com/",
        image: require("./logos/us/logo-us-bluemercury.svg")?.default,
        categories: ["Beauty"],
    },
    {
        titleEn: "Brandy Melville",
        url: "https://us.brandymelville.com",
        image: require("./logos/us/logo-us-brandy-melvilles.svg")?.default,
        categories: ["Accessories", "Activewear", "Women's Fashion"],
    },
    {
        titleEn: "carter's ",
        url: "https://www.carters.com/",
        image: require("./logos/us/logo-us-carters.svg")?.default,
        categories: ["Accessories", "Kids Fashion", "Toys"],
    },
    {
        titleEn: "CK",
        url: "https://www.calvinklein.us/en",
        image: require("./logos/us/logo-us-calvinklein.svg")?.default,
        categories: [
            "Activewear",
            "Kids Fashion",
            "Men's Fashion",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "Claire's",
        url: "https://www.claires.com/",
        image: require("./logos/us/logo-us-claires.svg")?.default,
        categories: ["Accessories", "Kids Fashion", "Toys"],
    },
    {
        titleEn: "coach outlet",
        url: "https://www.coachoutlet.com/",
        image: require("./logos/us/logo-us-coach.svg")?.default,
        categories: ["Men's Fashion", "Women's Fashion"],
    },
    {
        titleEn: "Converse ",
        url: "https://www.converse.com",
        image: require("./logos/us/logo-us-converse.svg")?.default,
        categories: [
            "Activewear",
            "Kids Fashion",
            "Men's Fashion",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "Costco",
        url: "https://www.costco.com/",
        image: require("./logos/us/logo-us-costco.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Beauty",
            "Department store",
            "Home",
            "Kids Fashion",
            "Men's Fashion",
            "Technology",
            "Toys",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "Crate and Barrel ",
        url: "https://www.crateandbarrel.com/",
        image: require("./logos/us/logo-us-crate-barrel.svg")?.default,
        categories: ["Home"],
    },
    {
        titleEn: "DKNY",
        url: "https://www.dkny.com/",
        image: require("./logos/us/logo-us-dkny.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Home",
            "Kids Fashion",
            "Men's Fashion",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "ebay",
        url: "https://www.ebay.com/",
        image: require("./logos/us/logo-us-ebay.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Beauty",
            "Department store",
            "Health and wellness",
            "Home",
            "Kids Fashion",
            "Men's Fashion",
            "Technology",
            "Toys",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "Fabkids",
        url: "https://www.fabkids.com",
        image: require("./logos/us/logo-us-fabkids.svg")?.default,
        categories: ["Accessories", "Kids Fashion"],
    },

    {
        titleEn: "Fabletics",
        url: "https://www.fabletics.com/",
        image: require("./logos/us/logo-us-fabletics.svg")?.default,
        categories: ["Activewear", "Men's Fashion", "Women's Fashion"],
    },
    {
        titleEn: "Fao Schwarz",
        url: "https://faoschwarz.com/",
        image: require("./logos/us/logo-us-fao-schwartz.svg")?.default,
        categories: ["Toys"],
    },
    {
        titleEn: "Foot Locker",
        url: "https://www.footlocker.com/",
        image: require("./logos/us/logo-us-footlocker.svg")?.default,
        categories: [
            "Women's Fashion",
            "Men's Fashion",
            "Kids Fashion",
            "Activewear",
        ],
    },
    {
        titleEn: "Free people",
        url: "https://www.freepeople.com/",
        image: require("./logos/us/logo-us-freepeople.svg")?.default,
        categories: [
            "Accessories",
            "Beauty",
            "Kids Fashion",
            "Men's Fashion",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "Gap",
        url: "https://www.gap.com/",
        image: require("./logos/us/logo-us-gap.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Kids Fashion",
            "Men's Fashion",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "Guess",
        url: "https://www.guess.com/us/en/home/",
        image: require("./logos/us/logo-us-guess.svg")?.default,
        categories: ["Women's Fashion", "Accessories"],
    },
    {
        titleEn: "iHerb",
        url: "https://www.iherb.com/",
        image: require("./logos/us/logo-us-iherb.svg")?.default,
        categories: ["Beauty", "Health and wellness"],
    },
    {
        titleEn: "Janie and Jack",
        url: "https://www.janieandjack.com/",
        image: require("./logos/us/logo-us-Janie-Jack.svg")?.default,
        categories: ["Accessories", "Kids Fashion", "Toys"],
    },
    {
        titleEn: "Journey's",
        url: "https://www.journeys.com/",
        image: require("./logos/us/logo-us-journeys.svg")?.default,
        categories: ["Women's Fashion", "Men's Fashion", "Kids Fashion"],
    },
    {
        titleEn: "Kohls",
        url: "https://www.kohls.com/",
        image: require("./logos/us/logo-us-kohls.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Beauty",
            "Department store",
            "Health and wellness",
            "Home",
            "Kids Fashion",
            "Men's Fashion",
            "Technology",
            "Toys",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "Kylie ",
        url: "https://kyliecosmetics.com/en-eu",
        image: require("./logos/us/logo-us-kylie.svg")?.default,
        categories: ["Beauty"],
    },
    {
        titleEn: "Lacoste ",
        url: "https://www.lacoste.com/",
        image: require("./logos/us/logo-us-lacoste.svg")?.default,
        categories: ["Women's Fashion", "Men's Fashion", "Kids Fashion"],
    },
    {
        titleEn: "lulu lemon",
        url: "https://www.lululemon.com/",
        image: require("./logos/us/logo-us-lulu-lemon.svg")?.default,
        categories: [
            "Women's Fashion",
            "Men's Fashion",
            "Kids Fashion",
            "Activewear",
        ],
    },
    {
        titleEn: "Mac",
        url: "https://www.maccosmetics.com/",
        image: require("./logos/us/logo-us-mac.svg")?.default,
        categories: ["Beauty"],
    },
    {
        titleEn: "Macys",
        url: "https://www.macys.com/?lid=glbtopnav_macys_icon-us",
        image: require("./logos/us/logo-us-macy.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Beauty",
            "Department store",
            "Home",
            "Kids Fashion",
            "Men's Fashion",
            "Toys",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "Michael Kors",
        url: "https://www.michaelkors.com/",
        image: require("./logos/us/logo-us-michaelkors.svg")?.default,
        categories: [
            "Accessories",
            "Kids Fashion",
            "Men's Fashion",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "Newegg",
        url: "https://www.newegg.com/",
        image: require("./logos/us/logo-us-newegg.svg")?.default,
        categories: ["Technology"],
    },
    {
        titleEn: "Nike",
        url: "https://www.nike.com/",
        image: require("./logos/us/logo-us-nike.svg")?.default,
        categories: [
            "Women's Fashion",
            "Men's Fashion",
            "Kids Fashion",
            "Activewear",
        ],
    },
    {
        titleEn: "Nordstrom",
        url: "https://www.nordstrom.com/?origin=tab-logo",
        image: require("./logos/us/logo-us-nordstorm.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Beauty",
            "Department store",
            "Home",
            "Kids Fashion",
            "Men's Fashion",
            "Toys",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "Old navy",
        url: "https://oldnavy.gap.com/",
        image: require("./logos/us/logo-us-oldnavy.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Kids Fashion",
            "Men's Fashion",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "pink",
        url: "https://www.victoriassecret.com/pink",
        image: require("./logos/us/logo-us-pink.svg")?.default,
        categories: ["Accessories", "Activewear", "Beauty", "Women's Fashion"],
    },
    {
        titleEn: "Pottery Barn",
        url: "https://www.potterybarn.com/",
        image: require("./logos/us/logo-us-pottery.svg")?.default,
        categories: ["Home"],
    },
    {
        titleEn: "Pottery Barn Kids",
        url: "https://www.potterybarnkids.com",
        image: require("./logos/us/logo-us-pottery-barn-kids.svg")?.default,
        categories: ["Home", "Kids Fashion", "Toys"],
    },
    {
        titleEn: "Puma",
        url: "https://us.puma.com/us/en",
        image: require("./logos/us/logo-us-puma.svg")?.default,
        categories: [
            "Activewear",
            "Kids Fashion",
            "Men's Fashion",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "Ralph Lauren",
        url: "https://www.ralphlauren.com",
        image: require("./logos/us/logo-us-ralph-lauren.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Beauty",
            "Home",
            "Kids Fashion",
            "Men's Fashion",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "Sephora",
        url: "https://www.sephora.com/",
        image: require("./logos/us/logo-us-sephora.svg")?.default,
        categories: ["Beauty"],
    },
    {
        titleEn: "Shop Disney ",
        url: "https://www.shopdisney.com/",
        image: require("./logos/us/logo-us-shopdisney.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Beauty",
            "Home",
            "Kids Fashion",
            "Men's Fashion",
            "Toys",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "Skechers",
        url: "https://www.skechers.com/",
        image: require("./logos/us/logo-us-skechers.svg")?.default,
        categories: [
            "Activewear",
            "Kids Fashion",
            "Men's Fashion",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "target",
        url: "https://www.target.com/",
        image: require("./logos/us/logo-us-target.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Beauty",
            "Department store",
            "Health and wellness",
            "Home",
            "Kids Fashion",
            "Men's Fashion",
            "Technology",
            "Toys",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "Tory Burch",
        url: "https://www.toryburch.com/en-us/",
        image: require("./logos/us/logo-us-torry.svg")?.default,
        categories: ["Accessories", "Home", "Women's Fashion"],
    },
    {
        titleEn: "Under Armour",
        url: "https://www.underarmour.com/",
        image: require("./logos/us/logo-us-underarmour.svg")?.default,
        categories: [
            "Activewear",
            "Kids Fashion",
            "Men's Fashion",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "Victoria's Secret",
        url: "https://www.victoriassecret.com/us/",
        image: require("./logos/us/logo-us-victoria.svg")?.default,
        categories: ["Accessories", "Activewear", "Beauty", "Women's Fashion"],
    },
    {
        titleEn: "Walmart",
        url: "https://www.walmart.com/",
        image: require("./logos/us/logo-us-walmart.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Beauty",
            "Department store",
            "Health and wellness",
            "Home",
            "Kids Fashion",
            "Men's Fashion",
            "Technology",
            "Toys",
            "Women's Fashion",
        ],
    },
    {
        titleEn: "WayFair",
        url: "https://www.wayfair.com/",
        image: require("./logos/us/logo-us-wayfair.svg")?.default,
        categories: ["Kids Fashion", "Toys"],
    },
    {
        titleEn: "west elm",
        url: "https://www.westelm.com/",
        image: require("./logos/us/logo-us-westelm.svg")?.default,
        categories: ["Home"],
    },
    {
        titleEn: "william sonoma",
        url: "https://www.williams-sonoma.com/",
        image: require("./logos/us/logo-us-Williams.svg")?.default,
        categories: ["Home"],
    },
    {
        titleEn: "zappos",
        url: "https://www.zappos.com/",
        image: require("./logos/us/logo-us-zappos.svg")?.default,
        categories: [
            "Accessories",
            "Activewear",
            "Home",
            "Kids Fashion",
            "Men's Fashion",
            "Toys",
            "Women's Fashion",
        ],
    },
];

export default usStores;
