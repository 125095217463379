import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	ACCOUNT_AMAZON_PAY,
	ACCOUNT_PAY_PAL,
	ACCOUNT_SETTINGS,
} from "../../constants/navigationConstants";
import { Link } from "react-router-dom";
import BackButtonSvg from "../../assets/img/icons/backButton.svg";
import { useAuth } from "../../contexts/AuthContext";
import { getData } from "../../utils/api";
import { parcelTableDateFormat } from "../../utils/moment";
import { Loader } from "../common";
import { postDeleteUserCard, postMakeCardDefault } from "../../services/userInfoService";
import { CustomSwitch } from "../common/CustomSwitch";
import { amountsProximating } from "../../utils/string";
import amazonPay from "../AmazonPay/amazon-pay.svg"
import visa from "../AmazonPay/visa.svg"
import paypal from "../AmazonPay/PayPal.svg"
import useAnalytics from "../../analytics/useAnalytics";
import { screenEvent } from "../../analytics/constants";


const AccountBackButton = () => {
	const { t, i18n } = useTranslation();
	return (
		<Link to={ACCOUNT_SETTINGS} className="row align-items-center mb-2">
			{i18n.language === "ar" && (
				<img src={BackButtonSvg} className="ml-3 mr-3 rotate-180" alt="" />
			)}
			{i18n.language !== "ar" && (
				<img src={BackButtonSvg} className="ml-3 mr-3" alt="" />
			)}
			<p className="mb-0 text-start">{t("Account")}</p>
		</Link>
	);
};
const TransactionsTable = (props) => {
	const { t } = useTranslation();
	const { transactionsList } = props;
	return (
		<>
			{transactionsList ? (
				<table className="table">
					<thead>
						<tr>
							<th>{t("Invoice Number")}</th>
							<th>{t("Date")}</th>
							<th>
								<span className="mb-hide">{t("Payment Method")}</span>
							</th>
							<th>
								<span className="mb-hide">{t("Card Details")}</span>
							</th>
							<th>
								<span className="mb-hide">{t("Amount")}</span>
							</th>
						</tr>
					</thead>
					<tbody>
						{transactionsList.map((transaction) => (
							<tr key={transaction.id}>
								<td>{transaction.invoice_number}</td>
								<td>
									{parcelTableDateFormat(new Date(transaction.created_at))}
								</td>
								<td className="mb-hide">
									{transaction.transaction_method.toUpperCase() ===
										t("Stripe").toUpperCase()
										? t("Card")
										: t("Paypal")}
								</td>
								<td className="mb-hide">
									{transaction.SavedCard?.name}
									<br />
									************{transaction.SavedCard?.card_no}
								</td>
								<td className="mb-hide">{amountsProximating(transaction?.amount) || 0}</td>
							</tr>
						))}
					</tbody>
				</table>
			) : null}
		</>
	);
};

const VisaCard = (props) => {
	const { t, i18n } = useTranslation();
	const {
		card,
		retrieveCards,
		authToken,
		cardsList,
		setCardsList
	} = props;

	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

	async function handleDelete(id) {
		setCardsList(
			cardsList?.filter(card => card.id !== id)
		)
		try {
			await postDeleteUserCard(authToken, id);
		} catch (e) {
			console.log(e);
		}
		setShowDeleteConfirm(false)
	}

	async function handleSetDefault(e, id) {
		if (e.target.checked) {
			setCardsList(
				cardsList?.map(card => (
					card.id !== id
						? { ...card, is_default: 0 }
						: { ...card, is_default: 1 }
				))
			)
		}
		try {
			await postMakeCardDefault(authToken, card.id);
		} catch (e) {
			console.log(e);
		}
	}

	return (
		showDeleteConfirm ?

			<div className="grey-background d-flex justify-content-center align-items-center flex-column w-100 border border-radius-10 p-4 h-100">
				<p>{t("Are you sure you want to delete this card?")}</p>
				<div className="d-flex justify-content-center align-items-center mt-2">
					<a onClick={() => setShowDeleteConfirm(false)} className="btn-lg px-5 mx-2 bg-white">No</a>
					<a onClick={() => handleDelete(card.id)} className="btn-lg px-5 mx-2 btn-primary">Yes</a>
				</div>
			</div>
			:

				<div className="d-flex justify-content-between align-items-center w-100 border border-radius-10 p-4 h-100">
					<div className="">
						<strong>
							<p>{card.name}</p>
						</strong>
						{card?.card_type !== "AMAZON_PAY" && card?.card_type !== "PAYPAL" && <p>************{card.card_no}</p>}
						{card?.expiry && <p>
							{t("Expires")}
							{": "}
							{card?.expiry}
						</p>}

						<div
							className={`text-muted ${i18n.language === "ar" ? "align-self-end" : "align-self-start"}`}
						>
							{t("Default")}
							<CustomSwitch checked={card.is_default} disabled={card.is_default} onChange={(e) => handleSetDefault(e, card.id)} name="DefaultCardSwitch" />
						</div>

						<div className={`${i18n.language === "ar" ? "align-self-start" : "align-self-end"}`}>
							{!card.is_default && (
								<a
									className="font-18 text-black mr-1 ml-1"
									onClick={() => setShowDeleteConfirm(true)}
									href="javascript:void(0)"
								>
									<i className="bx icstr bx-trash-alt font-size-22" />
								</a>
							)}
						</div>
					</div>
					<img src={card?.card_type !== "AMAZON_PAY" ? card?.card_type === "PAYPAL"? paypal : visa : amazonPay} />
				</div>
				
	);
};

export default () => {
	const { t } = useTranslation();
	const { authToken } = useAuth();
	const analytics = useAnalytics();
	const [cardsList, setCardsList] = useState([]);
	const [transactionsList, setTransactionsList] = useState([]);
	const [cardLoading, setCardLoading] = useState(true);
	const [transactionsListLoading, setTransactionsListLoading] = useState(true);

	async function retrieveCards() {
		try {
			const res = await getData("paymentService", "/cards", authToken);
			setCardsList(res?.payload);
			setCardLoading(false);
		} catch (e) {
			//
		}
	}

	async function retrieveTransactions() {
		try {
			const res = await getData("paymentService", "/transactions", authToken);
			setTransactionsList(res?.payload);
			setTransactionsListLoading(false);
		} catch (e) {
			//
		}
	}

	useEffect(() => {
		retrieveCards();
		retrieveTransactions();
	}, []);

	useEffect(() => {
		analytics.events.screenVisited({ screen: screenEvent.PAYMENTS });
	}, []);

	return (
		<div className="global-section-container flex-col grey-background pt-5 pb-5 align-items-center full-height-page">
			<div className="container white-background text-start row pt-3 pb-3">
				<div className="col-12 col-sm-12 col-md-12">
					<AccountBackButton />
					<h1 className="sub-page-section-header">{t("Payment-options")}</h1>
					<div className="nav nav-tabs" id="nav-tab" role="tablist">
						<a
							className="nav-item nav-link active"
							id="nav-home-tab"
							data-toggle="tab"
							href="#nav-home"
							role="tab"
							aria-controls="nav-home"
							aria-selected="true"
						>
							<span className="font-weight-500 font-size-16 color-555">
								<i className="bx bx-credit-card" /> {t("Credit Cards")}
							</span>
						</a>
						<a
							className="nav-item nav-link ml-3 mr-3"
							id="nav-active-parcels-tab"
							data-toggle="tab"
							href="#nav-active-parcels"
							role="tab"
							aria-controls="nav-active-parcels"
							aria-selected="false"
							onClick={()=>analytics.events.screenVisited({ screen: screenEvent.PAYMENT_HISTORY })}
						>
							<span className="font-weight-500 font-size-16 color-555">
								<i className="bx bx-detail" /> {t("Payment History")}
							</span>
						</a>
					</div>
					<div className="tab-content" id="nav-tabContent">
						<div
							className="tab-pane fade bg-white active show mt-3"
							id="nav-home"
							role="tabpanel"
							aria-labelledby="nav-home-tab"
						>
							<p>
								{t(
									"Update your payment method or add a new payment method below"
								)}
								<br />
								{t(
									"To view all payment transactions, please go to your history"
								)}
							</p>

							{cardLoading ? (
								<Loader />
							) : (
								<>
								<div className="row">
									{cardsList?.map((card) => (
										<div
											className="col-12 col-sm-12 col-md-6 w-100 mb-2 w-100"
											key={card.id}
										>
											<VisaCard
												card={card}
												retrieveCards={retrieveCards}
												authToken={authToken}
												is_default={card.is_default}
												cardsList={cardsList}
												setCardsList={setCardsList}
											/>
										</div>
									))}
								</div>
								<div className="row">
									<div className="col-sm-12 col-md-6 w-100 mb-2 w-100">
										<Link
											to={ACCOUNT_AMAZON_PAY}
											className="font-18 text-decoration-none"
											id="new-payment-card"
											onClick={() => {
												window.scrollTo({
													top: 0,
													left: 0,
													behavior: "smooth",
												});
											}}
										>
											<div className="w-100 h-100 d-flex align-items-center justify-content-center border grey-background border-radius-10 p-4">
												<i className="bx bx-plus" />
												{t("Add amazon pay account")}
											</div>
										</Link>
									</div>
									<div className="col-sm-12 col-md-6 w-100 mb-2 w-100">
										<Link
											to={ACCOUNT_PAY_PAL}
											className="font-18 text-decoration-none"
											id="new-payment-card"
											onClick={() => {
												window.scrollTo({
													top: 0,
													left: 0,
													behavior: "smooth",
												});
											}}
										>
											<div className="w-100 h-100 d-flex align-items-center justify-content-center border grey-background border-radius-10 p-4">
												<i className="bx bx-plus" />
												{t("Add Paypal account")}
											</div>
										</Link>
									</div>
								</div>
								</>
							)}
						</div>
						<div
							className="tab-pane fade bg-white mt-3"
							id="nav-active-parcels"
							role="tabpanel"
							aria-labelledby="nav-active-parcels-tab"
						>
							<p>{t("Below you can view your completed payment records")}</p>
							{transactionsListLoading ? (
								<Loader />
							) : (
								<TransactionsTable transactionsList={transactionsList} />
							)}
						</div>
					</div>
				</div>
				<div className="col-12 col-sm-12 col-md-4" />
			</div>
		</div>
	);
};
