import styled from "styled-components";

export const ParcelDrawer = styled.div`

    .loading {
        height: 100%;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
    }
    height: 100%;
    width: 450px !important;
    background-color: #fff;
    padding: 1rem 1rem;

    font-family: var(--default-font-family);

    @media (max-width: 575.98px) {
        width: 100% !important;
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
        width: 380px !important;
    }
`;

export const RouteProgressBlock = styled.div`
    padding-top: 15px;
    margin-bottom: 1rem;
    padding-top: 0px;
`;