import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { PROHIBITED_MATERIALS } from "../../../constants/navigationConstants";

const ProhibitedMaterials = () => {
    const { t, i18n } = useTranslation();
    const alignment = i18n.language === "en" ? "" : "rtl";

    return (
        <div className="neg-margin">
            <div className="prohibited-materials-wrapper mx-auto">
                <div className="row content">
                    <div className="col-12 text-center pm-font-size d-flex justify-content-center flex-column">
                        <div className="row">
                            <div className="col-12 font-weight-bold text-center">
                                <span className="d-inline-block content-max-width">
                                    {t("Prohibited Materials")}:
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-5 mb-sm-2 text-center">
                                <span className="d-inline-block content-max-width">
                                    {`${t("Prohibited Materials Description1")} ${t(
                                        "Prohibited Materials Description2"
                                    )}`}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 font-weight-bold text-center">
                                <span className="d-inline-block content-max-width">
                                    <Link
                                        className="blue-color"
                                        to={PROHIBITED_MATERIALS}
                                    >
                                        {t("Click here")}
                                    </Link>
                                    {t("Prohibited Click here Description")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProhibitedMaterials;