import React from "react";
import img21Svg from "./section21.svg";
import img22Svg from "./section22.svg";
import img23Svg from "./section23.svg";
import img24Svg from "./section24.svg";

import { useTranslation } from "react-i18next";

function One() {
  const { t } = useTranslation();
  return (
    <div className="main-section-container d-flex flex-column grey-background w-100">
      <div className="container-fluid container-width">
        <h2 className="sub-page-section-header">
          {t("Services provider prohibited material list")}
        </h2>
        <p>
          {t("Services provider prohibited material list description")}
        </p>
      </div>
      <div className="container row justify-content-between mt-4 pb-4">
        <div className="col-12 col-sm-12 col-md-3 d-flex flex-column jusify-content-between">
          <a
            className="font-16-500 footer-link text-decoration-none"
            href="https://www.dhl.ae/en/country_profile/import_guidelines_express.html"
            target="_blank"
          >
            <img alt="..." className="w-100 rounded" src={img21Svg}></img>
            <p className="w-100 white-background pt-1 pb-1 mb-0">
              {t("Dhl import restrictions")}
            </p>
          </a>
        </div>
        <div className="col-12 col-sm-12 col-md-3 d-flex flex-column jusify-content-between">
          <a
            className="font-16-500 footer-link text-decoration-none"
            href="https://crossborder.fedex.com/us/assets/prohibited-restricted/united-arab-emirates/index.shtml"
            target="_blank"
          >
            <img alt="..." className="w-100 rounded" src={img22Svg}></img>
            <p className="w-100 white-background pt-1 pb-1 mb-0">
              {t("Fedex import restrictions")}
            </p>
          </a>
        </div>
        <div className="col-12 col-sm-12 col-md-3 d-flex flex-column jusify-content-between">
          <a
            className="font-16-500 footer-link text-decoration-none"
            href="https://www.ups.com/assets/resources/media/en_US/UAE_CountryGuide.pdf"
            target="_blank"
          >
            <img alt="..." className="w-100 rounded" src={img23Svg}></img>
            <p className="w-100 white-background pt-1 pb-1 mb-0">
              {t("Ups import restrictions")}
            </p>
          </a>
        </div>
        <div className="col-12 col-sm-12 col-md-3 d-flex flex-column jusify-content-between">
          <a
            className="font-16-500 footer-link text-decoration-none"
            href="https://www.aramex.com/content/uploads/109/197/45680/Prohibited-Items-InternationalExpress.pdf"
            target="_blank"
          >
            <img alt="..." className="w-100 rounded" src={img24Svg}></img>
            <p className="w-100 white-background pt-1 pb-1 mb-0">
              {t("Aramex - Prohibited Items")}
            </p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default One;
