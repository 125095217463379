import React from "react";
import privacyImg from "./privacyImg.jpg";
import {SHOPINIWORLD_LINK} from "../../constants/constants"
import { useTranslation } from "react-i18next"

function PrivacyPolicy() {
	const {t, i18n} = useTranslation();
	return (
		<div className="global-section-container flex-col">
			<div className={`${i18n.language === "ar" ? "rtl-direction":"ltr-direction"} text-start`}>
				<img alt="..." src={privacyImg} className="w-100 rounded"></img>
				<div className="container mb-4 text-start">
					<h1 className="sub-page-header mt-4">{t("Privacy policy")}</h1>
					<p>
            {t("Protecting your private information is our priority")}
						<a className="mx-1" href={`https://${SHOPINIWORLD_LINK}`}>{SHOPINIWORLD_LINK}</a>
           {t("Shopini Logistics LLC and governs data collection and usage")}
						<a className="mx-1" href={`https://${SHOPINIWORLD_LINK}`}>{SHOPINIWORLD_LINK}</a>
           {t("Company The Company website is an eCommerce site")}
					</p>
					<p className="description">{t("Collection of your Personal Information")}</p>
					<p>
           {t("In order to")}
					</p>
					<ul>
						<li>{t("First and Last Name")}</li>
						<li>{t("Mailing Address")}</li>
						<li>{t("E-mail Address")}</li>
						<li>{t("Phone Number")}</li>
						<li>{t("Forwarding address in the home country")}</li>
					</ul>
					<p>
            {t("If you purchase")}
					</p>
					<p>
            {t("We do not collect any personal information")}
					</p>

					<p className="description">{t("Use of your Personal Information")}</p>
					<p>
            {t("The company collects and uses your personal information to")}
					</p>

					<p className="description">{t("Sharing Information with Third Parties")}</p>
					<p>
            {t("The company does not sell, rent, or lease its customer lists to third parties")}
						<br />
						<br />
            {t("The company may")} <br />
						<br />
            {t("Company may disclose your personal information")}
					</p>

					<h2 className="sub-page-section-header mt-4">{t("Data Collection")}</h2>
					<p>
            {t("What data do we collect from our site visitors or members")}
						<br />
            {t("Data We collect")}
					</p>
					<ol>
						<li className="description">{t("Personal information")}</li>
						<p>
              {t("This will include the Customer's name")}
							<br /> <br />
              {t("We also collect personal data")}
						</p>
						<li className="description">{t("Non-Personal Information")}</li>
						<p>
              {t("Non-personal data would include browsing")} <br />
							<br />
              {t("It may also include information")}
							<strong>
								<em> {t("session cookies")} </em>
							</strong>
              {t("which are automatically deleted when you close your browser")}
							<strong>
								<em> {t("persistent cookies")} </em>
							</strong>
              {t("which will remain on your personal computer or any device you use")}
						</p>
					</ol>

					<h2 className="sub-page-section-header mt-4">{t("Customer Consent")}</h2>
					<p>
            {t("By visiting")}
						<a className="mx-1" href={`https://${SHOPINIWORLD_LINK}`}>{SHOPINIWORLD_LINK}</a>
            {t("agree and consent to this Privacy Policy")}
						<a className="mx-1" href={`https://${SHOPINIWORLD_LINK}`}>{SHOPINIWORLD_LINK}</a>
            {t("notify and circulate any change")}
					</p>
					<h2 className="sub-page-section-header mt-4">{t("Data Usage")}</h2>
					<p className="description">
            {t("How does Shopiniworld use")}
						<br />
            {t("We will process and use the data we collect as per below")}
					</p>
					<ul>
						<li>
            {t("We might share data with affiliated")}
							<a className="mx-1" href={`https://${SHOPINIWORLD_LINK}`}>{SHOPINIWORLD_LINK}</a>
						</li>
						<li>{t("To manage the business relationship with you")}</li>
						<li>
            {t("To deliver your packages")}
						</li>
						<li>
            {t("We will use the data to improve your customer experience on the site")}
						</li>
						<li>
              {t("We might use your data when")}
						</li>
						<li>
              {t("We use your data to process")}
						</li>
						<li>
              {t("We might use your data to protect")}
						</li>
						<li>
              {t("We may use data to make sure")}
						</li>
						<li>
              {t("We might use your data to analyse")}
						</li>
						<li>
              {t("We will use your data to fulfil")}
						</li>
						<li>
              {t("We will not at all use your data")}
						</li>
						<li>
              {t("We might use your data to conduct")}
						</li>
						<li>
              {t("We will be using your data for any other purpose with your consent")}
						</li>
						<li>
			  {t("All credit/debit cards")}
						</li>
						<li>
				{t("If you make a payment for our products or services on our website")}
						</li>
						<li>
				{t("Merchant will not pass any debit/credit card details to third parties")}
						</li>
						<li>
				{t("The cardholder must retain a copy")}
						</li>
						<li>
				{t("We accept payments online using Visa")}
						</li>
						<li>
				{ t("Merchant takes appropriate steps to ensure data privacy and security including through various hardware and software methodologies However")}
				<a className="mx-1" href={`https://${SHOPINIWORLD_LINK}`}>{SHOPINIWORLD_LINK}</a>
				{t("cannot guarantee the security of any information that is disclosed online")}
						</li>
						<li>
				{t("The merchant is not responsible for the privacy policies of websites to which it links")}
						</li>
					</ul>
					<h2 className="sub-page-section-header mt-4">
            {t("Personal Data Retention")}
					</h2>
					<p>
            {t("We would like to inform all our members")}
					</p>
					<p className="description">{t("Tracking User Behavior")}</p>
					<p>
						{t("Company may keep track of the websites")}
            <br />
            {t("AI and Google analytics")}
					</p>
					<p className="description">{t("Automatically Collected Information")}</p>
					<p>
            {t("Information about your computer")}
					</p>
					<p className="description">{t("Use of Cookies")}</p>
					<p>
            {t("The Company website may use")}
						<br />
            {t("One of the primary purposes of cookies is to provide")}
						<br />
            {t("You have the ability to accept or decline cookies")}
					</p>
					<p className="description">{t("Links")}</p>
					<p>
            {t("This website contains links to other sites")}
					</p>
					<p className="description">{t("Advertisement")}</p>
					<p>
            {t("Some of the advertisements you see on the Site are selected")}
					</p>
					<p className="description">{t("Security of your Personal Information")}</p>
					<p>
            {t("Company secures your personal information")} <strong> {t("SSL Protocol")} </strong>
            {t("for this purpose")}
						<br />
						<br /> {t("When personal information")}
						<br />
						<br /> {t("We strive to take appropriate security measures")}
					</p>
					<p className="description">{t("Right to Deletion")}</p>
					<p>
					{t("Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will")}
					</p>
					<ul>
						<li>{t("Delete your personal information from our records")}</li>
						<li>
						{t("Direct any service providers to delete your personal information from their records")}
						</li>
					</ul>
					<p>
            {t("Please note that we may not be able to")}
					</p>
					<ul>
						<li>
            {t("Complete the transaction for which")}
						</li>
						<li>
            {t("Detect security incidents")}
						</li>
						<li>
              {t("Debug to identify and repair errors that impair existing intended functionality")}
						</li>
						<li>
              {t("Exercise free speech")}
						</li>
						<li>
              {t("Comply with the UAE Electronic Communications Privacy Act")}
						</li>
						<li>
              {t("Engage in public")}
						</li>
						<li>
              {t("Enable solely internal")}
						</li>
						<li>{t("Comply with an existing legal obligation")}</li>
						<li>
              {t("Otherwise use your personal information")}
						</li>
					</ul>
					<p className="description">{t("Children Under Eighteen")}</p>
					<p>
            {t("The company does not knowingly")}
					</p>
					<p className="description">{t("E-mail Communications")}</p>
					<p>
            {t("From time to time")}
						<br />
            {t("If you would like to stop")}
					</p>
					<p className="description">{t("External Data Storage Sites")}</p>
					<p>
            {t("We may store your data on servers")}
					</p>
					<p className="description">{t("Changes to this Statement")}</p>
					<p>
            {t("The company reserves the right to change this Privacy Policy")}
					</p>
					<p>
						{t("The Website Privacy Policy may be changed or updated")}
					</p>
					<p className="description">{t("Contact Information")}</p>
					<p>
            {t("The company welcomes your questions or comments")}
					</p>
					<p className="description">
            {t("Shopini Logistics LLC")}
						<br />
			{t("Dubai, United Arab Emirates")}
						<br />
			{t("Al Qouz 3, GDP5")}
						<br />
			{t("PO Box 390104")}
						<br/>
			{t("Phone +971 42 502 508")}
						<br/>
			{t("Email address")}: support@shopiniworld.com
						</p>
				</div>
			</div>
		</div>
	);
}

export default PrivacyPolicy;
