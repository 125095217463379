import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Loader } from "../common";
import SummaryDetails from "./SummaryDetails";
import TenPercent from "./10percent.svg"
import { useAuth } from "../../contexts/AuthContext";

export default ({
  parcels,
  totalChargableWeight = 0,
  paymentSummary,
  setPaymentSummary,
  submit,
  setPromoCode,
  promoCode,
  loading,
  paymentLoading,
  totalParcelValue,
  payables
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="widget widget_packages pb-0">
        <div className="widget__inner24 summary-right w-100">
          <h4
            className={`font18-gry1 pb-2 font-weight-600 ${i18n.language === "ar" ? "pr-4 pt-2" : "pl-4 pt-3"
              }`}
          >
            {" "}
            {t("Shipping summary")}
          </h4>
          {loading ? (
            <div className="py-5">
              <Loader />
            </div>
          ) : (
            <>
              <SummaryDetails
                paymentSummary={paymentSummary}
                totalParcelValue={totalParcelValue}
                setPromoCode={setPromoCode}
                parcels={parcels}
                totalChargableWeight={totalChargableWeight}
                promoCode={promoCode}
                payables={payables}
              />

              <Row>
                <Col md={12} sm={12}>
                  <div className="mobile-pay-now paypal-ipad px-4 pb-3 pt-2">
                    <Row className="mobile-show-subtotal font-weight-600 mobile-subtotal-padding px-4 pb-3">
                      <div
                        className={`mobile-width-50-percent float-left text-left float-right-ar text-end-ar ${i18n.language === "ar" ? "text-right" : "text-left"
                          }`}
                      >
                        {paymentSummary.promo_discount ||
                          paymentSummary.promo_discount === 0
                          ? t("Total")
                          : t("Subtotal")}
                      </div>
                      <div className="text-end mobile-width-50-percent float-right text-end float-left-ar text-left-ar">
                        <span className="shipping_total_price">
                          $
                          {paymentSummary.total?.toFixed(2) || 0}
                        </span>
                      </div>
                    </Row>
                    {submit ? (
                      <Button
                        id="shipmentOptionBtn"
                        disabled={paymentLoading}
                        className="rounded u-userLauncherColor credit_button form-control border-radius-10 pt-2 font-weight-500"
                        onClick={submit}
                      >
                        {paymentSummary.total === 0 ? t("Continue") : t("Pay Now")}
                      </Button>
                    ) : null}
                  </div>
                </Col>
              </Row>
              {paymentSummary?.applied_discount_type === "membership" 
              && paymentSummary?.membership_discount?.discounted_amount > 0
              && <>
                  <div className="mb-2 rounded px-1 mx-4 py-3 bg-light-green text-center">
                    <img src={TenPercent} className="img-fluid" />
                    &nbsp;
                    <strong>
                      {t(`${paymentSummary?.membership_discount?.discount_percent}% discount`)}
                    </strong>
                    &nbsp;
                    {t("on Shipping Fees Applied")}
                  </div>

                  <div className="d-flex mx-4 font-15">
                    <p>**</p>
                    &nbsp;&nbsp;
                    <p>{t("If the promo code is more than the Discount")}</p>
                  </div>
                </>
              }
            </>
          )}
        </div>
      </div>
    </>
  );
};
