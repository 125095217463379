import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import _, { head } from "lodash";

import { AppliedPromoCode } from "./AppliedPromoCode";
import { Loader } from "../common/loader/style";
import { useAuth } from "../../contexts/AuthContext";
import { MEMBERSHIP_CONSTANTS, SERVICES_NO_PROMOCODE, SERVICES_WITH_VAT } from "../../constants/constants";
import TenPercent from "../parcelPaymentSummary/10percent.svg"
import ShipmentPromoCode from "../promoCode/ShipmentPromoCode";
import { useMediaQuery } from "react-responsive";
import TrueWeight from "./TrueWeight";

export default ({
    paymentSummary,
    submit,
    loading,
    paymentRequestData,
    selectedShippingRate,
    setSelectedShippingRate,
    promoCode,
    setPromoCode
}) => {
    const { t, i18n } = useTranslation();
    const [openedParcelSummary, setOpenedParcelSummary] = useState("");
    const { membershipPlanID } = useAuth()
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    useEffect(() => {
        if (paymentSummary.parcel_data?.length === 1) {
            setOpenedParcelSummary(head(paymentSummary?.parcel_data)?.uuid);
        }
    }, [paymentSummary?.parcel_data?.length]);
    return loading ?
        <div className="py-5">
            <Loader />
        </div>
        :
        <>
            <div className="widget widget_packages pb-0 font-16">
                <div className="widget__inner24 summary-right summary-width pt-4">
                    <h4
                        className={`font18-gry1 pb-2 font-weight-600 ${i18n.language === "ar" ? "pr-4" : "pl-4"
                            }`}
                    >
                        {" "}
                        {t("Shipping summary")}
                    </h4>
                    {paymentSummary.parcel_data?.map((parcel, index, array) => {
                        return (
                            <>
                                {paymentSummary.parcel_data?.length === 1 &&
                                    openedParcelSummary !== "" ? null : (
                                    <>
                                        <div
                                            onClick={() =>
                                                setOpenedParcelSummary(
                                                    openedParcelSummary === parcel.uuid
                                                        ? ""
                                                        : parcel.uuid
                                                )
                                            }
                                            aria-controls={parcel.uuid}
                                            aria-expanded={openedParcelSummary === parcel.uuid}
                                            className="mx-4 d-flex justify-content-between my-3"
                                        >
                                            <span>{parcel.uuid}</span>
                                            <span>
                                                {openedParcelSummary === parcel.uuid ? (
                                                    <i class="fas fa-chevron-up"></i>
                                                ) : (
                                                    <i class="fas fa-chevron-down"></i>
                                                )}
                                            </span>
                                        </div>
                                    </>
                                )}
                                <div
                                    className={`${index === array.length - 1 ? 'mb-3' : ''} collapse ${openedParcelSummary === parcel.uuid && "show"
                                        }`}
                                    id={parcel.uuid}
                                >
                                    <div className="parcel-details px-4">
                                        {[parcel, ...(parcel?.secondary_parcels || [])]?.map(box =>
                                            <Col md={12} className="services-blk">

                                                <Row className="font-size-16">
                                                    <Col
                                                        md={3}
                                                        sm={3}
                                                        className="font12-gry3 mobile-width-40-percent p-0"
                                                    >
                                                        {t("Parcel ID")}
                                                    </Col>
                                                    <Col
                                                        md={9}
                                                        sm={9}
                                                        className="text-end mobile-width-60-percent p-0"
                                                    >
                                                        <span className="font14-gry1">{box?.package_data?.uuid || box?.uuid}</span>
                                                    </Col>
                                                    <Col
                                                        md={3}
                                                        sm={3}
                                                        className="font12-gry3 mobile-width-40-percent p-0"
                                                    >
                                                        {t("Volume W")}
                                                    </Col>
                                                    <Col
                                                        md={9}
                                                        sm={9}
                                                        className="text-end mobile-width-60-percent p-0"
                                                    >
                                                        <span className="font14-gry1">
                                                            {box?.package_data?.volumetric_weight || box?.volumetric_weight}
                                                            &nbsp;
                                                            {box?.package_data?.weight_unit || box?.weight_unit}
                                                        </span>
                                                    </Col>
                                                    <Col
                                                        md={3}
                                                        sm={3}
                                                        className="font12-gry3 mobile-width-40-percent p-0"
                                                    >
                                                        {t("Actual W")}
                                                    </Col>
                                                    <Col
                                                        md={9}
                                                        sm={9}
                                                        className="text-end mobile-width-60-percent p-0"
                                                    >
                                                        <span className="font14-gry1">
                                                            {box?.package_data?.actual_weight || box?.actual_weight}
                                                            &nbsp;
                                                            {box?.package_data?.weight_unit || box?.weight_unit}
                                                        </span>
                                                    </Col>
                                                    <Col
                                                        md={6}
                                                        sm={6}
                                                        className="font12-gry3 mobile-width-50-percent p-0"
                                                    >
                                                        {t("Chargeable W")}
                                                    </Col>
                                                    <Col
                                                        md={6}
                                                        sm={6}
                                                        className="text-end font14-gry1 mobile-width-50-percent text-left-ar direction-ltr p-0"
                                                    >
                                                        {box?.package_data?.chargeable_weight || box?.chargeable_weight}
                                                        &nbsp;
                                                        {box?.package_data?.weight_unit || box?.weight_unit}
                                                    </Col>
                                                </Row>

                                            </Col>
                                        )}
                                    </div>
                                </div>
                                <Row className="mb-3">
                                    <Col>
                                        <div className="px-4">
                                            <TrueWeight />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="line-height-40 font-weight-light chargeable-weight px-4">
                                    <Col md={8} sm={6} className="mobile-width-65-percent">
                                        {t("Total Volumetric Weight")}
                                    </Col>
                                    <Col
                                        md={4}
                                        sm={6}
                                        className="mobile-width-35-percent text-end  direction-ltr"
                                    >
                                        <span>
                                            {paymentSummary?.total_volumetric_weight}
                                            &nbsp;
                                            {parcel?.chargeable_weight_unit}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="line-height-40 font-weight-light chargeable-weight px-4">
                                    <Col md={8} sm={6} className="mobile-width-65-percent">
                                        {t("Total Actual Weight")}
                                    </Col>
                                    <Col
                                        md={4}
                                        sm={6}
                                        className="mobile-width-35-percent text-end  direction-ltr"
                                    >
                                        <span>
                                            {paymentSummary?.total_actual_weight}
                                            &nbsp;
                                            {parcel.chargeable_weight_unit}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="line-height-40 font-weight-light chargeable-weight px-4">
                                    <Col md={8} sm={6} className="mobile-width-65-percent color-green">
                                        {t("Total Chargeable Weight")}
                                    </Col>
                                    <Col
                                        md={4}
                                        sm={6}
                                        className="mobile-width-35-percent text-end direction-ltr color-green"
                                    >
                                        <span>
                                            {paymentSummary?.total_chargeable_weight}
                                            &nbsp;
                                            {parcel?.chargeable_weight_unit}
                                        </span>
                                    </Col>
                                </Row>
                                {!! parcel?.saved_chargeable_weight 
                                ?<Row className="line-height-40 font-weight-light chargeable-weight px-4">
                                    <Col md={7} sm={6} className="mobile-width-65-percent step-color-orange">
                                        {t("YOU SAVE")}
                                    </Col>
                                    <Col
                                        md={5}
                                        sm={6}
                                        className="mobile-width-35-percent text-end direction-ltr step-color-orange"
                                    >
                                        <span>
                                            {(parcel?.saved_chargeable_weight ? parcel?.saved_chargeable_weight : 0)?.toFixed(2)}
                                            &nbsp;
                                            {parcel?.chargeable_weight_unit}
                                        </span>
                                    </Col>
                                </Row>
                                : null
                                }
                                {/* {paymentSummary?.rates?.find(sc => sc.shipping_company_id === parcel?.package_data?.shipping_company_id) && */}
                                <Row className="line-height-40 font-weight-light chargeable-weight px-0 my-2 grey-background rounded mx-4">
                                    <Col md={7} sm={6} className="mobile-width-65-percent">
                                        {t("Shipping Company")}
                                        <br />
                                        {isMobile ?
                                            <span className="d-flex align-items-center justify-content-between w-50 mt-2">
                                                <img className="w-50" src={selectedShippingRate?.company_logo} />
                                                <small className="white-space-nowrap">{selectedShippingRate?.provider}</small>
                                            </span>
                                            :
                                            <a id="change-shipping-company" data-toggle="modal" className={`text-start font-weight-500 change-link`}
                                                data-target="#shipping-service-modal">
                                                <u>{t("Change")}</u>
                                            </a>}
                                    </Col>
                                    <Col
                                        md={5}
                                        sm={6}
                                        className="mobile-width-35-percent text-end direction-ltr"
                                    >
                                        {isMobile ?
                                            <div className="my-2">
                                                <button
                                                    data-toggle="modal"
                                                    data-target="#shipping-service-modal"
                                                    id="shipping-service-change"
                                                    className="color-inverse-default-button rounded px-4 py-0"
                                                >
                                                    {t("Change")}
                                                </button>
                                                <small className="text-muted d-block">{selectedShippingRate?.eta_days?.split(" ")[0]}&nbsp;{t(selectedShippingRate?.eta_days?.split(" ")[1])}</small>
                                            </div>
                                            :
                                            <span className="d-flex align-items-center justify-content-end">
                                                <small className="white-space-nowrap">{selectedShippingRate?.provider}</small>
                                                &nbsp;
                                                <img className="w-50" src={selectedShippingRate?.company_logo} />
                                            </span>
                                        }
                                    </Col>
                                </Row>
                                {/* } */}
                            </>
                        );
                    })}
                    <Row className="line-height-40 font-weight-light chargeable-weight px-4">
                        <Col md={7} sm={6} className="mobile-width-65-percent">
                            {t("Shipping Fees")}
                        </Col>
                        <Col
                            md={5}
                            sm={6}
                            className="mobile-width-35-percent text-end direction-ltr"
                        >
                            <span>
                                $&nbsp;{selectedShippingRate?.shipping_charges?.toFixed(2) || 0}
                            </span>
                        </Col>
                    </Row>
                    {paymentSummary?.rates
                        && selectedShippingRate?.applied_discount_type === "membership"
                        && Number(membershipPlanID) > 1
                        && selectedShippingRate?.membership_discount?.discounted_amount > 0
                        && <Row className="font-weight-light line-height-40 mobile-hide-subtotal px-4">
                            <Col md={7} sm={7} className="mobile-width-50-percent text-start">
                                <span>{t("Membership Discount")}</span>
                            </Col>
                            <Col
                                md={5}
                                sm={5}
                                className="text-end mobile-width-50-percent text-end"
                            >
                                <span className="color-green">
                                    {selectedShippingRate?.membership_discount?.discount_percent}%
                                </span>
                                (-${selectedShippingRate?.membership_discount?.discounted_amount?.toFixed(2)})
                            </Col>
                        </Row>}
                    {selectedShippingRate?.applied_discount_type !== "membership" && selectedShippingRate?.promo_code_discount?.discounted_amount > 0 ? (
                        <>
                            <Row className="line-height-40 px-4">
                                <Col
                                    md={7}
                                    sm={6}
                                    className="mobile-width-50-percent"
                                >
                                    {t("Coupon Discount")}
                                </Col>
                                <Col
                                    md={5}
                                    sm={6}
                                    className="text-end float-left mobile-width-50-percent text-left-ar color-green"
                                >
                                    <span id="shipping_custom" className="color-green">
                                        {selectedShippingRate?.promo_code_discount?.discount_type === "percent"
                                            ? `${selectedShippingRate?.promo_code_discount?.discount_value}%`
                                            : `$ ${selectedShippingRate?.promo_code_discount?.discount_value?.toFixed(2)}`}
                                    </span>
                                    {selectedShippingRate?.promo_code_discount?.discount_type === "percent" &&
                                        `(-$${selectedShippingRate?.promo_code_discount?.discounted_amount?.toFixed(2)})`}
                                </Col>
                            </Row>
                        </>
                    ) : null}
                    {selectedShippingRate?.applied_discount_type !== "membership" && selectedShippingRate?.promo_code_discount?.discounted_amount > 0 && (
                        <Row className="px-4 pb-2 pt-2">
                            <Col xs={12}>
                                <AppliedPromoCode
                                    promoCodeName={selectedShippingRate?.promo_code_discount?.discount_code}
                                />
                            </Col>
                        </Row>
                    )}
                    {SERVICES_NO_PROMOCODE?.includes(paymentSummary?.shipping_company_id)
                        || (selectedShippingRate?.applied_discount_type !== "membership")
                        || selectedShippingRate?.user_discount_applied ?
                        null
                        :
                        <Row className="px-4 pb-2 pt-2">
                            <Col xs={12}>
                                <ShipmentPromoCode
                                    setPromoCode={setPromoCode}
                                    promoCode={promoCode || selectedShippingRate?.promo_code_discount?.discount_code}
                                    paymentSummary={selectedShippingRate}
                                />
                            </Col>
                        </Row>
                    }
                    <hr className="hrbrdr mx-4" />
                    <Row className="line-height-40 px-4">
                        <Col md={7} sm={6} className="mobile-width-50-percent">
                            {t("Sub Total")}
                        </Col>
                        <Col
                            md={5}
                            sm={6}
                            className="text-end float-left mobile-width-50-percent text-left-ar"
                        >
                            <strong id="shipping_price">
                                $ {selectedShippingRate?.sub_total?.toFixed(2) || 0}
                            </strong>
                        </Col>
                    </Row>
                    <Row className="line-height-40 px-4">
                        <Col md={7} sm={6} className="mobile-width-50-percent font-weight-500">
                            {t("Consolidation Fee")}
                        </Col>
                        <Col
                            md={5}
                            sm={6}
                            className="text-end float-left mobile-width-50-percent text-left-ar"
                        >
                            <span id="consolidation_fees">
                                $ {selectedShippingRate?.consolidation_charges || 0}
                            </span>
                        </Col>
                    </Row>
                    <Row className="line-height-40 px-4">
                        <Col md={7} sm={6} className="mobile-width-50-percent font-weight-500">
                            {SERVICES_WITH_VAT?.includes(selectedShippingRate?.shipping_company_id) ? t("Customs") : t("Duties and Taxes")}
                        </Col>
                        <Col
                            md={5}
                            sm={6}
                            className="text-end float-left mobile-width-50-percent  text-left-ar"
                        >
                            <span id="shipping_custom">
                                $ {(parseFloat(selectedShippingRate?.custom_duties || 0)).toFixed(2)}
                            </span>
                        </Col>
                    </Row>
                    {SERVICES_WITH_VAT?.includes(selectedShippingRate?.shipping_company_id) &&
                        <Row className="line-height-40 px-4">
                            <Col md={7} sm={6} className="mobile-width-50-percent font-weight-500">
                                {t("Taxes")}
                            </Col>
                            <Col
                                md={5}
                                sm={6}
                                className="text-end float-left mobile-width-50-percent  text-left-ar"
                            >
                                <span id="shipping_custom">
                                    $ {(parseFloat(selectedShippingRate?.parcel_taxes || 0)).toFixed(2)}
                                </span>
                            </Col>
                        </Row>}
                    <div id="services" className="margin-bottom-10-negative">
                        {paymentSummary?.rates && selectedShippingRate?.add_on_services &&
                            selectedShippingRate?.add_on_services.map(
                                (addOn) =>
                                    addOn?.amount > 0 && (
                                        <Row className="line-height-40 font16-gry1 consolidate-block padding-bottom-8 px-4">
                                            <Col
                                                md={6}
                                                sm={6}
                                                className="text-capitalize font-weight-500 mobile-width-50-percent line-height-24 pt-2"
                                            >
                                                {i18n?.language === "ar" ? addOn?.service_arabic : addOn?.service}
                                                <br />
                                                {addOn?.parcel_value && <span className="font-size-13 gray-3">
                                                    {t("Parcel Value")}&nbsp;$&nbsp;{addOn?.parcel_value}
                                                </span>}
                                            </Col>
                                            <Col
                                                md={6}
                                                sm={6}
                                                className="text-end mobile-width-50-percent text-left-ar"
                                            >
                                                ${addOn?.amount?.toFixed(2)}
                                            </Col>
                                        </Row>
                                    )
                            )}
                    </div>
                    <hr className="hrbrdr mx-4" />

                    {paymentRequestData.wallet_amount || paymentRequestData.wallet_amount > 0 ? (
                        <Row className="line-height-40 mobile-hide-subtotal px-4">
                            <Col md={6} sm={6} className="mobile-width-50-percent">
                                {t("Paid from wallet")}
                            </Col>
                            <Col
                                md={6}
                                sm={6}
                                className="text-end mobile-width-50-percent text-left-ar"
                            >
                                <span>
                                    -${paymentRequestData.wallet_amount?.toFixed(2)}
                                </span>
                            </Col>
                        </Row>
                    ) : null}
                    {paymentRequestData?.amount > 0 ? (
                        <Row className="font18-gry1 line-height-40 px-4">
                            <Col md={6} sm={6} className="font-weight-light mobile-width-50-percent">
                                {t("Total")}
                            </Col>
                            <Col
                                md={6}
                                sm={6}
                                className="text-end mobile-width-50-percent text-left-ar"
                            >
                                <strong className="shipping_total_price">
                                    ${paymentRequestData?.amount?.toFixed(2)}
                                </strong>
                            </Col>
                        </Row>
                    ) : null}
                    {/* <Row>
                        <Col md={12} sm={12}>
                            <div className="mobile-pay-now paypal-ipad px-3 pb-3 pt-2">
                                <Row className="mobile-show-subtotal font-weight-600 mobile-subtotal-padding px-4 pb-3">
                                    <div
                                        className={`mobile-width-50-percent float-left text-left float-right-ar text-end-ar ${i18n.language === "ar"
                                            ? "text-right"
                                            : "text-left"
                                            }`}
                                    >
                                        {selectedShippingRate?.applied_discount_type !== "" ? t("Total") : t("Sub Total")}
                                    </div>
                                    <div className="text-end mobile-width-50-percent float-right text-end float-left-ar text-left-ar">
                                        <span className="shipping_total_price">
                                            $
                                            {(selectedShippingRate?.total &&
                                                selectedShippingRate?.total?.toFixed(2)) ||
                                                0}
                                        </span>
                                    </div>
                                </Row>
                            </div>
                        </Col>
                    </Row> */}
                    {submit ? <div className="px-4 pb-3">
                        <Button
                            id="shipmentOptionBtn"
                            disabled={submit ? false : true}
                            className="rounded u-userLauncherColor credit_button form-control border-radius-10 pt-2 font-weight-500"
                            onClick={(e) => submit(
                                e,
                                // (parseFloat(paymentSummary?.parcel_data[0]?.parcel_total) === 0),
                                false,
                                paymentSummary?.parcel_user?.is_credit_user
                            )}
                        >
                            {paymentSummary?.parcel_user?.is_credit_user ? t("Continue") : t("Pay Now")}
                        </Button>
                    </div> : null}
                    {paymentSummary?.rates && paymentSummary?.rates && selectedShippingRate?.applied_discount_type
                        && selectedShippingRate?.membership_discount?.discounted_amount > 0 &&
                        <>
                            <div className="mb-2 rounded px-1 mx-4 py-3 bg-light-green text-center">
                                <img src={TenPercent} className="img-fluid" />
                                &nbsp;
                                <strong>
                                    {t(`${selectedShippingRate?.membership_discount?.discount_percent}% discount`)}
                                </strong>
                                &nbsp;
                                {t("on Shipping Fees Applied")}
                            </div>

                            <div className="d-flex mx-4 font-15">
                                <p>**</p>
                                &nbsp;&nbsp;
                                <p>{t("If the promo code is more than the Discount")}</p>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
};