import React, { useEffect, useState } from "react";
import { Button, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import * as navigationConstants from "../../constants/navigationConstants";
import ShopiniFooterLink from "../shopiniFooter/shopiniFooterLink.json";
import AngleDownGreen from "./angleDownGreen.svg";
import AngleDownBlue from "./blueArrow.svg";
import arrowRight from "./angle-right-solid.svg";
import arrowLeft from "./angle-left-solid.svg";
import Xicon from "./x-icon.svg";
import LoginForm from "../loginForm/loginForm";
import { useAuth } from "../../contexts/AuthContext";
import { MEMBERSHIP_CONSTANTS, MENU_STATES, PROMOTION_PAGES } from "../../constants/constants";
import AmchamSvg from "../../pages/promotionPages/amcham/AmCham-Primary-Logo-300 w.png";
import "../../pages/promotionPages/amcham/style.scss";
import _ from "lodash";
import * as Scroll from "react-scroll";
import Header from "../header";
import logoSideIcon from "./logo-side-icon.svg";
import useAnalytics from "../../analytics/useAnalytics";
import { screenEvent } from "../../analytics/constants";

const HeaderLinks = ({ t, ScrollLink, floater, menuItemAlignment, toggleMenuState, location, isMobile }) => {
	const [show, setShow] = useState(null)
	const [,i18n] = useTranslation()
	const analytics = useAnalytics()

	const handleAnalyticsServiceEvent = (link, isPackage) => {
        switch (link) {
			// help
            case navigationConstants.FAQ: {
                analytics.events.helpFaqButton();
                break;
            }
            case navigationConstants.PROHIBITED_MATERIALS: {
                analytics.events.helpProhibitedMaterialsButton();
                break;
            }
            case navigationConstants.DIRECTORY: {
                analytics.events.helpShoppingDirectoryButton();
                break;
            }
            case navigationConstants.TRUE_WEIGHT: {
                analytics.events.helpTrueWeightButton();
                break;
            }

			// services
            case navigationConstants.CONSOLIDATION: {
                if (isPackage) {
                    analytics.events.servicesPackageConsolidationButton();
                } else {
                    analytics.events.servicesItemConsolidationButton();
                }
                break;
            }
            case navigationConstants.OUR_SERVICES: {
                analytics.events.servicesOtherServicesButton();
                break;
            }
            case navigationConstants.BUY_IT_FOR_ME: {
                analytics.events.servicesBifmButton();
                break;
            }
            default:
                return;
        }
    };

	return <>
		<Link
			to={{ pathname: navigationConstants.TRACK_SHIPMENTS }}
			className="px-4 font-weight-500 text-uppercase d-flex flex-shrink-0 flex-row justify-content-center align-items-center text-decoration-none text-black"
			onClick={analytics.events.trackShipmentButton}
		>
			{t("Track Shipment")}
		</Link>
		<Link
			to={{ pathname: navigationConstants.HOW_IT_WORKS }}
			className="px-4 font-weight-500 text-uppercase d-flex flex-shrink-0 flex-row justify-content-center align-items-center text-decoration-none text-black"
		>
			{t("How to shop and ship")}
		</Link>
		<Link
			to={{ pathname: navigationConstants.DIRECTORY }}
			className="px-4 font-weight-500 text-uppercase d-flex flex-shrink-0 flex-row justify-content-center align-items-center text-decoration-none text-black"
			onClick={analytics.events.trackShipmentButton}
		>
			{t("Shopping Directory")}
		</Link>

		{ShopiniFooterLink.filter(header => ["Services", "Help"].includes(Object.keys(header)[0]))?.map((header) => (
			<div className="row align-items-center" key={Object.keys(header)[0] + "-desktop"}>
				<NavDropdown
					show={show === Object.keys(header)[0]}
					onMouseEnter={() => setShow(Object.keys(header)[0])}
					onMouseLeave={() => setShow("")}
					onClick={() => {
						if (isMobile) {
							if (show === Object.keys(header)[0]) {
								setShow("")
							} else {
								setShow(Object.keys(header)[0])
							}
						}
					}}
					rootClose
					title={
						<span
							className={`main-menu-dropdown d-flex justify-content-between align-items-center ${Object.keys(header)[0] !== "How to shop and ship" ? "mx-4" : "mx-5"}`}
							onClick={() => setShow(Object.keys(header)[0])}
						>
							<span className="text-uppercase header-main-menu-text">
								{t(Object.keys(header))}
							</span>
							{Object.keys(header)[0] !== "How to shop and ship" && <img
								className={`mx-3 accordion-arrow menu-dropdown-arrow ${floater}`}
								src={AngleDownBlue}
								alt=""
							/>}
						</span>
					}
					className="dropdown menu-dropdown collasible-nav-dropdown"
					id={`collasible-nav-dropdown ${t(Object.keys(header))}`}
				>
					{Object.values(header)?.map((link_obj) =>
						link_obj.map((link) => ["Shopping Directory"].includes(link.linkName) 
						? null 
						: (
							<Link className={`dropdown-item ${i18n.language==='ar' ? 'text-right' : ''}`} key={link.linkName}
								onClick={() => { toggleMenuState(); setShow(false); handleAnalyticsServiceEvent(link.href,`${link.linkName}`.toLowerCase().includes('package')) }} to={link.href}>
								{link.linkName==='TrueWeigh'
									? <p className="d-inline-block px-1 yellow-badge font-weight-bold"><span className="text-blue-color font-weight-bold">True</span><span className="color-green">Weight</span></p>
									: <p className={`inner-dropdown ${menuItemAlignment}`}>{t(link.linkName)}</p>
								}
							</Link>
						))
					)}
				</NavDropdown>
			</div>
		))}
	</>
}

function NavigationBar(props) {
	const { t, i18n } = useTranslation();
	const [lang, setLang] = useState(localStorage.getItem("i18nextLng"));
	const [floater, setFloater] = useState(props.language === "ar" ? "float-left" : "float-right");
	const imgSrc = `/locales/${props.language || lang || i18n.language}/logoc.gif`;
	const { loggedIn, username, logout, membershipPlanID, walletBalance, showIncomingOrders } = useAuth();
	const [showMenu, setShowMenu] = useState(false)
	const [shown, setShown] = useState("")
	const isMobile = useMediaQuery({ query: "(max-width: 991px)" });
	const location = useLocation();
	const ScrollLink = Scroll.Link

	const analytics = useAnalytics();

	useEffect(() => {
		if (location && location?.state?.from === "authedRoute") {
			document.querySelector("#sign-in-button").click()
		}
	}, [location?.state])

	const toggleMenuState = (state) => {
		const collapsedNav = document.getElementById("responsive-navbar-nav");
		const togglerBtn = document.getElementById("navbar-toggler-btn");
		const body = document.body;

		switch (state) {

			case MENU_STATES.OPEN_MENU: {
				collapsedNav.classList.add("show");
				togglerBtn.classList.remove("collapsed");
				body.classList.add("displayed-menu");
				setShowMenu(true);
				break;
			}

			case MENU_STATES.CLOSE_MENU: {
				collapsedNav.classList.remove("show");
				togglerBtn.classList.add("collapsed");
				body.classList.remove("displayed-menu");
				setShowMenu(false);
				break;
			}

			default: {
				if (showMenu && !collapsedNav.classList.contains("show") && togglerBtn.classList.contains("collapsed")) {
					collapsedNav.classList.add("show");
					togglerBtn.classList.remove("collapsed");
					body.classList.add("displayed-menu");
				} else {
					collapsedNav.classList.remove("show");
					togglerBtn.classList.add("collapsed");
					body.classList.remove("displayed-menu");
				}
				setShowMenu(!showMenu);
				break;
			}
		}
	}

	const toggleGlobalBodyStyle = () => {
		const collapsedNav = document.getElementById("responsive-navbar-nav");
		const togglerBtn = document.getElementById("navbar-toggler-btn");
		const body = document.body;
		if (!showMenu && !collapsedNav.classList.contains("show") && togglerBtn.classList.contains("collapsed")) {
			body.classList.add("displayed-menu");
		} else {
			body.classList.remove("displayed-menu");
		}
	}

	const changeLanguage = (language, revers = false) => {
		if (!revers) {
			if (language === "ar") {
				i18n.changeLanguage("en");
				localStorage.setItem("i18nextLng", "en");
				_.head(document.getElementsByTagName("html")).setAttribute("lang", "en");
				_.head(document.getElementsByTagName("html")).setAttribute("dir", "ltr");
				setLang("en");
				setFloater("float-right");

			} else {
				i18n.changeLanguage("ar");
				localStorage.setItem("i18nextLng", "ar");
				_.head(document.getElementsByTagName("html")).setAttribute("lang", "ar");
				_.head(document.getElementsByTagName("html")).setAttribute("dir", "rtl");
				setLang("ar");
				setFloater("float-left");
			}
			isMobile && toggleMenuState();
		} else {
			i18n.changeLanguage(language);
			localStorage.setItem("i18nextLng", language);
			_.head(document.getElementsByTagName("html")).setAttribute("lang", language);
			const direction = language === "ar" ? "rtl" : "ltr"
			_.head(document.getElementsByTagName("html")).setAttribute("dir", direction);
			setLang(lang);
			const floater = language === "ar" ? "float-right" : "float-left"
			setFloater(floater);
		}
	};
	const alignment = i18n.language === "en" ? "ltr" : "rtl";
	const menuItemAlignment = i18n.language === "en" ? "absolute-ltr" : "absolute-rtl";

	const handleLogout = () => {
		logout();
		window.location.replace("/home");
	};

	const smallNavs = navigationConstants?.SMALL_NAV_ROUTES?.includes(location?.pathname?.replace(".", ""))

	const handleAnalyticsServiceEvent = (link, isPackage) => {
        switch (link) {
            case navigationConstants.CONSOLIDATION: {
                if (isPackage) {
                    analytics.events.servicesPackageConsolidationButton();
                } else {
                    analytics.events.servicesItemConsolidationButton();
                }
                break;
            }
            case navigationConstants.OUR_SERVICES: {
                analytics.events.servicesOtherServicesButton();
                break;
            }
            case navigationConstants.PURCHASE_REQUEST: {
                analytics.events.servicesBifmButton();
                break;
            }
            default:
                return;
        }
    };

	return (
		PROMOTION_PAGES.includes(location.pathname) ?
			<Navbar collapseOnSelect expand="md" className="nav-container">
				<Navbar.Brand className="pl-0 pr-0">
					<Link to={navigationConstants.HOMEPAGE}>
						<img
							className={"shopini-icon " + alignment}
							src={process?.env?.PUBLIC_URL + imgSrc}
							alt=""
						/>
					</Link>
					<div className="ml-auto text-right">
						<img alt="..." className="amcham-logo-width" src={AmchamSvg} />
					</div>
				</Navbar.Brand>

			</Navbar>
			:
			<>
				{!smallNavs &&
					<Header changeLanguage={changeLanguage} lang={lang} isMobile={isMobile} />
				}
				<Navbar collapseOnSelect expand="lg" className={`nav-container ${!loggedIn && smallNavs ? "py-4" : ""}`}>
					<Navbar.Brand className="pl-0 pr-0">
						<Link id="shopini-logo" to={navigationConstants.HOMEPAGE} onClick={toggleMenuState}>
							<img
								className={(
									(isMobile || smallNavs) ?
										"" : loggedIn ?
											"margin-60-neg " : "margin-45-neg ") + "shopini-icon " + alignment}
								src={process?.env?.PUBLIC_URL + imgSrc}
								alt="Shopini Logistics Inc."
							/>
						</Link>
					</Navbar.Brand>
					<button
						className="navbar-toggler pr-0 pl-0 collapsed"
						type="button"
						data-toggle="collapse"
						data-target="#responsive-navbar-nav"
						aria-controls="responsive-navbar-nav"
						aria-expanded="false"
						aria-label="Toggle navigation"
						id="navbar-toggler-btn"
						onClick={toggleGlobalBodyStyle}
					>
						<span className="navbar-toggler-icon" />
						<div className="close close-icon">
							<img src={Xicon} alt="close menu icon" />
						</div>
					</button>
					<Navbar.Collapse id="responsive-navbar-nav">
						{!isMobile && !loggedIn ?
							<Nav className="mr-nav-auto nav-right-buttons">
								{!smallNavs ? (
									<>
										<div className="d-none">
											{[navigationConstants.HOMEPAGE, "/"]?.includes(location?.pathname.replace(".", "")) ?
												<ScrollLink
													className="font-weight-500 text-uppercase px-0 mx-2 d-flex flex-row justify-content-center align-items-center text-decoration-none text-black"
													to={"Calculate shipping fees"}
													id="rate-calculator-nav"
													smooth={true}
													duration={300}
													onClick={analytics.events.rateCalculatorButton}

												>
													<span id="scroll">{t("Rate Calculator")}</span>
												</ScrollLink>
												:
												<Link
													id="rate-calculator-nav"
													to={{ pathname: navigationConstants.HOMEPAGE, state: { calculatorLink: true } }}
													className="font-weight-500 text-uppercase px-0 mx-2 d-flex flex-row justify-content-center align-items-center text-decoration-none text-black"
													onClick={analytics.events.rateCalculatorButton}
												>
													{t("Rate Calculator")}
												</Link>
											}
										</div>
										<HeaderLinks
											t={t}
											ScrollLink={ScrollLink}
											floater={floater}
											menuItemAlignment={menuItemAlignment}
											toggleMenuState={toggleMenuState}
											location={location}
											isMobile={isMobile}
										/>
										<a
											id="sign-in-button"
											data-toggle="modal"

											className="font-weight-500 text-uppercase d-flex flex-row flex-shrink-0 justify-content-center align-items-center text-decoration-none text-black"
											data-target="#navigation-bar-login-form"
											onClick={()=>{
												analytics.events.signinButton();
												analytics.events.screenVisited({ screen: screenEvent.LOGIN });
											}}
										>
											{t("Sign in")}
										</a>

										<Link
											id=""
											to={navigationConstants.MEMBERSHIP_PLANS}
											className="flex-shrink-0 text-white text-uppercase border-radius-5 btn-bg-color d-flex flex-row justify-content-center text-decoration-none pr-3 pl-3"
											onClick={analytics.events.signupButton}
										>
											{t("Sign up")}
										</Link>
									</>)
									:

									<>
										<a
											id="sign-in-button"
											data-toggle="modal"

											className="py-2 font-weight-500 text-uppercase d-flex flex-row justify-content-center align-items-center text-decoration-none text-black"
											data-target="#navigation-bar-login-form"
											onClick={analytics.events.signinButton}
										>
											{t("Sign in")}
										</a>
										<div id="language-button" className="tr-cursor py-2 px-4 text-end" onClick={() => changeLanguage(lang)}>
											<i className="mx-2 fas fa-globe-americas blue-color" />
											{t("Language")}
										</div>
									</>
								}
							</Nav>

							: null}
						{!isMobile && loggedIn && (
							<Nav className="mr-nav-auto nav-right-buttons">
								{/** TODO: check the balance && the wallet history to decide showing the btn or no */}
								{!smallNavs && <HeaderLinks t={t} ScrollLink={ScrollLink} floater={floater} menuItemAlignment={menuItemAlignment} toggleMenuState={toggleMenuState} location={location} />}

								{parseInt(membershipPlanID) !== MEMBERSHIP_CONSTANTS?.BUSINESS && (
									<Link
										className={`white-space-nowrap greenish-button d-flex flex-row justify-content-center text-decoration-none pr-3 pl-3 ${i18n?.language === "ar" ? "mr-1 ml-3" : "mr-3 ml-1"}`}
										to={navigationConstants.MEMBERSHIP_PLANS}
										onClick={() => {
											window.scrollTo({
												top: 0,
												left: 0,
												behavior: "smooth",
											});
											analytics.events.upgradeNowButton();
										}}
									>
										{t("Upgrade Now")}
									</Link>
								)}
								<div className="d-none">
									{[navigationConstants.HOMEPAGE, "/"].includes(location?.pathname.replace(".", "")) ?
										<ScrollLink
											className="font-weight-500 text-uppercase px-0 mx-2 d-flex flex-row justify-content-center align-items-center text-decoration-none text-black"
											to={"Calculate shipping fees"}
											id="rate-calculator-nav"
											smooth={true}
											duration={300}
											onClick={analytics.events.rateCalculatorButton}

										>
											<span id="scroll">{t("Rate Calculator")}</span>
										</ScrollLink>
										:
										<Link
											id="rate-calculator-nav"
											to={{ pathname: navigationConstants.HOMEPAGE, state: { calculatorLink: true } }}
											className="font-weight-500 text-uppercase px-0 mx-2 d-flex flex-row justify-content-center align-items-center text-decoration-none text-black"
											onClick={analytics.events.rateCalculatorButton}
										>
											{t("Rate Calculator")}
										</Link>
									}
								</div>
								{/* <div className="dropdown-container">
									<a
										href="#"
										id="dropdownUserMenu"
										className=" dropdown-toggle footer-link text-decoration-none d-flex align-items-center mt-1"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
										data-offset="10,20"
									>
										<i className="bx bx-user font-18 mr-1 ml-1" />
										{username}
										<i className="fas fa-chevron-down ml-1 mr-1" />
									</a>
									<div
										id="user-dropdown-menu"
										aria-labelledby="dropdownUserMenu"
										className="dropdown-menu user-dropdown p-2"
										x-placement="bottom-start"
									>
										<Link
											className="dropdown-item font-16-400 footer-link"
											to={navigationConstants.DASHBOARD}
											onClick={toggleMenuState}
										>
											<span className="p-2">{t("Dashboard")}</span>
										</Link>
										<Link
											className="dropdown-item font-16-400 footer-link"
											to={navigationConstants.ACCOUNT_SETTINGS}
											onClick={toggleMenuState}
										>
											<span className="p-2">{t("Account")}</span>
										</Link>
										<a className="dropdown-item footer-link" onClick={handleLogout}>
											<span className="p-2"> {t("Logout")}</span>
										</a>
									</div>
								</div> */}
								<NavDropdown
									rootClose
									id="dropdownUserMenu"
									// show={shown === "dropdownUserMenu"}
									// onMouseEnter={(e) => setShown(e.target.id)}
									// onMouseLeave={() => setShown("")}
									title={
										<span className="main-menu-dropdown d-flex align-items-center">
											<span className="header-main-menu-text">
												{username}
											</span>
											<img
												className={`accordion-arrow menu-dropdown-arrow ${floater}`}
												src={AngleDownBlue}
												alt=""
											/>
										</span>
									}
									className="dropdown menu-dropdown collasible-nav-dropdown"
								>
									<Link
										className="dropdown-item"
										to={navigationConstants.DASHBOARD}
										onClick={() => { toggleMenuState(); setShown("") }}
									>
										<span className="px-2">{t("Dashboard")}</span>
									</Link>
									{showIncomingOrders ? <Link
										className="dropdown-item"
										to={navigationConstants.INCOMING_ORDERS}
										onClick={() => { toggleMenuState(); setShown("") }}
									>
										<span className="px-2">{t("Incoming Orders")}</span>
									</Link> : null}
									<Link
										className="dropdown-item"
										to={navigationConstants.ACCOUNT_SETTINGS}
										onClick={() => { toggleMenuState(); setShown("") }}
									>
										<span className="px-2">{t("Account")}</span>
									</Link>
									<a className="dropdown-item pb-3" onClick={handleLogout}>
										<span className="px-2"> {t("Logout")}</span>
									</a>
								</NavDropdown>
								{smallNavs &&
									<div id="language-button" className="tr-cursor py-2 px-2 text-end" onClick={() => changeLanguage(lang)}>
										<i className="mx-2 fas fa-globe-americas blue-color" />
										{t("Language")}
									</div>
								}
								{/* <Button id="language-button" onClick={() => changeLanguage(lang)}>
									{t("Language")}
								</Button> */}
							</Nav>
						)}
						{isMobile && (
							<Nav className="mr-nav-auto nav-right-buttons">
								{loggedIn && (
									<div>
										<Nav.Link>
											<Link
												className="nav-buttons-font flex-space-between"
												to={navigationConstants.DASHBOARD}
												onClick={toggleMenuState}
											>
												{t("Dashboard")}
												{lang === "en" ? (
													<img alt="..." className="left-right-arrow" src={arrowLeft}></img>
												) : (
													<img alt="..." className="left-right-arrow" src={arrowRight}></img>
												)}
											</Link>
										</Nav.Link>
										<NavDropdown.Divider />
										{showIncomingOrders ?
											<>
												<Nav.Link>
													<Link
														className="nav-buttons-font flex-space-between"
														to={navigationConstants.INCOMING_ORDERS}
														onClick={toggleMenuState}
													>
														{t("Incoming Orders")}
														{lang === "en" ? (
															<img alt="..." className="left-right-arrow" src={arrowLeft}></img>
														) : (
															<img alt="..." className="left-right-arrow" src={arrowRight}></img>
														)}
													</Link>
												</Nav.Link>
												<NavDropdown.Divider />
											</>
											: null}
										<Link
											className="nav-buttons-font flex-space-between pt-2 pb-2"
											to={navigationConstants.ACCOUNT_SETTINGS}
											onClick={toggleMenuState}

										>
											{t("Account")}
											{lang === "en" ? (
												<img alt="..." className="left-right-arrow" src={arrowLeft}></img>
											) : (
												<img alt="..." className="left-right-arrow" src={arrowRight}></img>
											)}
										</Link>
										<NavDropdown.Divider />
										<Link
											className="nav-buttons-font flex-space-between pt-2 pb-2"
											to={navigationConstants.PURCHASE_REQUEST}
											onClick={(e)=>{
												toggleMenuState(e)
												analytics.events.bifmButton()
											}}

										>
											{t("Buying_Assistant")}
											{lang === "en" ? (
												<img alt="..." className="left-right-arrow" src={arrowLeft}></img>
											) : (
												<img alt="..." className="left-right-arrow" src={arrowRight}></img>
											)}
										</Link>
										<NavDropdown.Divider />
										<Link
											className="nav-buttons-font flex-space-between pt-2 pb-2"
											to={navigationConstants.WALLET}
											onClick={(e)=>{
												toggleMenuState(e)
												analytics.events.walletButton()
											}}

										>
											{t("Wallet")}
											{lang === "en" ? (
												<img alt="..." className="left-right-arrow" src={arrowLeft}></img>
											) : (
												<img alt="..." className="left-right-arrow" src={arrowRight}></img>
											)}
										</Link>
										<NavDropdown.Divider />
									</div>
								)}
								{!smallNavs ?
									ShopiniFooterLink.filter(header => !["How it works", "Services", "NOW SHOP IT, SHIP IT ON THE GO"].includes(Object.keys(header)[0])).map((header) => (
										<div key={Object.keys(header)[0]}>
											<NavDropdown
												rootClose
												show={shown === Object.keys(header)[0]}
												onMouseEnter={() => setShown(Object.keys(header)[0])}
												onMouseLeave={() => setShown("")}
												title={
													<span className="main-menu-dropdown">
														<span className="header-main-menu-text text-uppercase">
															{t(Object.keys(header))}
														</span>
														<img
															className={`accordion-arrow menu-dropdown-arrow ${floater}`}
															src={AngleDownGreen}
															alt=""
														/>
													</span>
												}
												className="dropdown menu-dropdown collasible-nav-dropdown"
												id={`collasible-nav-dropdown ${t(Object.keys(header))}`}
											>
												{Object?.values(header)?.map((link_obj) =>
													link_obj.map((link) => (isMobile && ['How it works', 'Shopping Directory'].includes(link.linkName) ? null :
														<Link className={`dropdown-item ${i18n.language==='ar' ? 'text-right' : ''}`} key={link.linkName}
															onClick={(e)=>{toggleMenuState(e); handleAnalyticsServiceEvent(link.href,`${link.linkName}`.toLowerCase().includes('package'))}} to={link.href}>
															{link.linkName==='TrueWeigh'
																?(<p className="d-inline-block px-1 yellow-badge font-weight-bold"><span className="text-blue-color font-weight-bold">True</span><span className="color-green">Weight</span></p>)
																:(<p className={`inner-dropdown ${menuItemAlignment}`}>{t(link.linkName)}</p>)
															}
														</Link>
													))
												)}
											</NavDropdown>
											<NavDropdown.Divider />
										</div>
									))
									: null
								}
								<Nav.Link
									className="d-none nav-buttons-font flex-space-between"
								>
									{[navigationConstants.HOMEPAGE, "/"].includes(location?.pathname.replace(".", "")) ?
										<ScrollLink
											className="font-weight-500 text-uppercase px-0 mx-2 d-flex flex-row justify-content-center align-items-center text-decoration-none text-black"
											to={"Calculate shipping fees"}
											id="rate-calculator-nav"
											smooth={true}
											duration={300}
											onClick={analytics.events.rateCalculatorButton}

										>
											<span id="scroll">{t("Rate Calculator")}</span>
										</ScrollLink>
										:
										<Link
											id="rate-calculator-nav"
											to={{ pathname: navigationConstants.HOMEPAGE, state: { calculatorLink: true } }}
											className="font-weight-500 text-uppercase px-0 mx-2 d-flex flex-row justify-content-center align-items-center text-decoration-none text-black"
											onClick={analytics.events.rateCalculatorButton}
										>
											{t("Rate Calculator")}
										</Link>
									}

									{lang === "en" ? (
										<img alt="..." className="left-right-arrow" src={arrowLeft}></img>
									) : (
										<img alt="..." className="left-right-arrow" src={arrowRight}></img>
									)}
								</Nav.Link>
								{/* <NavDropdown.Divider /> */}
								{isMobile
									?<>
										<Nav.Link
											className="nav-buttons-font flex-space-between"
											href={navigationConstants.HOW_IT_WORKS}
										>
											{t("How it works")}
										</Nav.Link>
										<NavDropdown.Divider />
										<Nav.Link
											className="nav-buttons-font flex-space-between"
											href={navigationConstants.TRACK_SHIPMENTS}
										>
											{t("Track Shipment")}
										</Nav.Link>
										<NavDropdown.Divider />
										<Nav.Link
											className="nav-buttons-font flex-space-between"
											href={navigationConstants.DIRECTORY}
										>
											{t('Shopping Directory')}
										</Nav.Link>
										<NavDropdown.Divider />
									</>
									:null
								}
								{loggedIn && (
									<div>
										<Nav.Link
											className="nav-buttons-font flex-space-between"
											onClick={handleLogout}
										>
											{t("Logout")}
										</Nav.Link>
										<NavDropdown.Divider />
									</div>
								)}
								<Nav.Link
									className="nav-buttons-font flex-space-between"
									onClick={() => changeLanguage(lang)}
								>
									{t("Language")}
								</Nav.Link>

								<NavDropdown.Divider />

								{!loggedIn && (<div className="nav-right-buttons">
									<Button
										id="sign-in-button"
										data-toggle="modal"
										data-target="#navigation-bar-login-form"
										onClick={analytics.events.signinButton}
									>
										{t("Sign in")}
									</Button>
									<Link
										id="sign-up-button"
										to={navigationConstants.MEMBERSHIP_PLANS}
										className="text-white text-uppercase border-radius-5 btn-bg-color d-flex flex-row justify-content-center text-decoration-none pr-3 pl-3 w-100"
										onClick={() => {
											toggleMenuState();
											analytics.events.signupButton()
										}}
									>
										{t("Sign up")}
									</Link>
								</div>
								)}
							</Nav>
						)}
					</Navbar.Collapse>
					<div
						className="modal fade"
						id="navigation-bar-login-form"
						tabIndex="-1"
						aria-labelledby="navigation-bar-login-form"
						aria-hidden="true"
					>
						<div className="modal-dialog modal-dialog-centered login-modal-width">
							<div className="modal-content p-3 w-100">
								<div className="modal-header border-bottom-0">
									<h5 className="modal-title" id="exampleModalLabel">
										{t("Login-to-shopiniworld")}
									</h5>
									<button
										type="button"
										className={`close ml-0 mr-0 ${floater}`}
										data-dismiss="modal"
										aria-label="Close"
									>
										<i className="bx bx-x" />
									</button>
								</div>
								<div className="modal-body w-100">
									<LoginForm modal={true} toggleMenuState={toggleMenuState} />
								</div>
							</div>
						</div>
					</div>
				</Navbar>
				<div id="en-lng" className="d-none" onClick={() => changeLanguage("en", true)} />
				<div id="ar-lng" className="d-none" onClick={() => changeLanguage("ar", true)} />
			</>

	);
}

export default NavigationBar;
