import React, { useEffect, useMemo, useRef, useState } from "react";
import { Redirect } from "react-router-dom"
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import _, { isEmpty } from "lodash";
import { generateCostAndCustomFee } from '../../utils/shipping';
import { consolidateRequest, generateShipmentLabel } from "../../services/parcelApi"
import { useAuth } from "../../contexts/AuthContext";

import * as NAVIGATION_CONSTANTS from "../../constants/navigationConstants";
import PaymentSummary from "../parcelPaymentSummary";
import Payment from "../stripe";
import BeforeConsolidationPaymentSummary from "../beforeConsolidationPaymentSummary";
import { Loader } from "../common";
import { PARCEL_CONSTANTS, PROMOS } from "../../constants/constants";
import { MaskedLoader } from "../common";
import AmazonPay from "../AmazonPay";
import { getData, postData } from "../../utils/api";
import useAnalytics from "../../analytics/useAnalytics";
import { screenEvent } from "../../analytics/constants";

export default (props) => {

	const { t } = useTranslation();
	const { authToken, userPin, warehousesList, warehouseId} = useAuth();

	const parcels = (props.location && props.location.state && props.location.state.parcels) || [];
	const payables = (props.location && props.location.state && props.location.state.payables) || [];
	const paymentDetailsFromProps = (props.location && props.location.state && props.location.state.paymentDetails) || {};
	const addOnServices = props.location && props.location.state && props.location.state.addOnServices;
	const totalChargableWeight = props.location && props.location.state && props.location.state.totalChargableWeight || 0;
	const totalParcelValue = props.location && props.location.state && props.location.state.totalParcelValue || 0;

	const [loading, setLoading] = useState(true);
	const [paymentRequestData, setPaymentRequestData] = useState({});
	const [proceedToNextPage, setProceedToNextPage] = useState(null);
	const [paymentStatus, setPaymentStatus] = useState("in-progress");
	const [creditCard, setCreditCard] = useState({});
	const [isAmazonPay, setIsAmazonPay] = useState(false);
	const [isPaypal, setIsPaypal] = useState(false);
    const [isOthers, setIsOthers] = useState(false);
	const [walletBalance, setWalletBalance] = useState(undefined)
	const warehouse = warehousesList.find(
            (warehouse) => warehouse.id === Number(warehouseId)
        )?.country
	const paymentStartedRef = useRef(false)
	const analytics = useAnalytics()
	const targetOrigin =  useMemo(()=>process?.env?.REACT_APP_MOBILE_POST_MESSAGE_TARGET_ORIGIN,[])
	const analyticsEventAttributes = {
        country: props?.location?.state?.paymentDetails?.address?.country,
        city: props?.location?.state?.paymentDetails?.address?.city,
        shippingCompany: props?.location?.state?.paymentDetails?.service,
        extraServices: (
            props?.location?.state?.paymentDetails?.add_on_services ?? []
        ).map((addonService) => addonService.service),
        promoCode: !!props?.location?.state?.paymentDetails?.promo_code_discount
            ?.is_valid
            ? props?.location?.state?.paymentDetails?.promo_code_discount
                  .discount_code ?? undefined
            : undefined,
        parcelIds: (parcels ?? []).map((parcel) => ({
            "Parcel ID": parcel.uuid,
            "Actual Weight": parcel.package_data?.actual_weight,
            "Volumetric Weight": parcel.package_data?.volumetric_weight,
            "Chargeable Weight": parcel.package_data?.chargeable_weight,
        })),
        totalChargeableWeight: totalChargableWeight,
        shippingFees: props?.location?.state?.paymentDetails?.shipping_charges,
        discountAmount: !!props?.location?.state?.paymentDetails
            ?.promo_code_discount?.is_valid
            ? props?.location?.state?.paymentDetails?.promo_code_discount
                  .discounted_amount
            : undefined,
        subtotal: props?.location?.state?.paymentDetails?.sub_total,
        totalAmount: props?.location?.state?.paymentDetails?.total,
        tax: 0,
        warehouse,
        paymentMethod: paymentRequestData?.total
            ? creditCard?.card_type === "PAYPAL"
                ? "PayPal"
                : creditCard?.card_type === "AMAZON_PAY"
                ? "Amazon"
                : undefined
            : undefined,
        walletBalance,
        usedWalletAmount: paymentRequestData?.wallet_amount
            ? Number(paymentRequestData?.wallet_amount)
            : undefined,
    };
	
	const onPaymentCompletion = async (response) => {
		if (response && response.status) {
			setPaymentStatus("success");
            window?.webkit?.messageHandlers?.backHomePage?.postMessage(
                JSON.stringify({ status: "success" }),
                targetOrigin
            );
		} else {
			setPaymentStatus("fail");
            window?.webkit?.messageHandlers?.backHomePage?.postMessage(
                JSON.stringify({ status: "fail" }),
                targetOrigin
            );
		}
		if (creditCard?.id || response?.skip) {
			setProceedToNextPage("success-return");
		} else {
			setProceedToNextPage("amazon-return");
		}
	};

	useEffect(() => {
		setLoading(false);
		setPaymentRequestData({ ...paymentDetailsFromProps });
	}, [JSON.stringify(paymentDetailsFromProps)]);

	useEffect(() => {
        if (
            !paymentStartedRef.current &&
            analyticsEventAttributes.parcelIds.length
        ) {
            paymentStartedRef.current = true;
            const {
				paymentMethod,
                walletBalance,
                usedWalletAmount,
                ...parcelPaymentStartedAttributes
            } = analyticsEventAttributes;
            analytics.events.shippingNoConsolidationPaymentStarted(
                parcelPaymentStartedAttributes
            );
        }
    }, [JSON.stringify(analyticsEventAttributes)]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		if(loading) return
		setLoading(true)
		let response;
		let data = {
			parcel_ids: _.map(parcels, "id"),
			parcel_uuids: _.map(parcels, "uuid"),
			promo_code:  paymentRequestData?.promo_code_discount?.is_valid ? paymentRequestData?.promo_code_discount?.discount_code : null,
			country_id: paymentRequestData?.address?.country,
			add_on_services: addOnServices,
			user_address_id: paymentRequestData?.address?.id,
			total_amount: paymentRequestData?.total,
			shipping_service: paymentRequestData?.service,
			shipping_company_id: paymentRequestData?.shipping_company_id,
			card_reference: creditCard?.card_reference, 
			card: {
				name: creditCard.name,
				digits: creditCard.number,
			},
			wallet_amount: paymentRequestData?.wallet_amount || 0
		};
		if ( paymentRequestData?.total !== 0 &&
            creditCard?.name !== "" ) {
			if (creditCard?.card_type === "PAYPAL") {
				response = await postData(
					"paymentService",
					"/paypal/agreement?payment_type=parcel&capture=true",
					data,
					authToken
				);
			} else {
				response = await postData(
					"paymentService",
					"/amazon/signature?type=parcel&capture=true",
					data,
					authToken
				);
			}
		} else {
			response = await postData(
				"paymentService",
				`/pay?type=parcel`,
				data,
				authToken
			);
			response.skip = true
		}
		setLoading(false)
            
        onPaymentCompletion(response)
	};

	useEffect(async () => {
        const retriveWalletBalance = async () => {
            try {
                const response = await getData(
                    "paymentRequestService",
                    "/v1/PurchaseRequestController/getTransactionsWallet",
                    authToken
                );
                let tempWalletData =
                    !response.status || response.error
                        ? []
                        : response.payload.data;
                var newBalance =
                    tempWalletData.length > 0
                        ? tempWalletData[0].available_balance.toFixed(2)
                        : 0;
                setWalletBalance(parseFloat(newBalance)??0);
            } catch (e) {}
        };
        await retriveWalletBalance();
    }, [authToken]);

	useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.NO_CONSOLIDATION_PAYMENT });
    }, []);

	return (
		<>
			{(!parcels.length) ? <Redirect to={NAVIGATION_CONSTANTS.DASHBOARD} /> : null}
			{proceedToNextPage === "amazon-return"
				? <Redirect
					to={{
						pathname: NAVIGATION_CONSTANTS.PAYMENT_RETURN,
						search: "",
						state: {...(props.location && props.location.state && props.location.state ? props.location.state : {}), parcelPaymentSavedCardEventsAttributes: analyticsEventAttributes}
					}}
				/>
			: proceedToNextPage === "success-return" ? 
				<Redirect
					to={{
						pathname: NAVIGATION_CONSTANTS.PAYMENT_STATUS + "/" + paymentStatus,
						search: "",
						state: {...(props.location && props.location.state && props.location.state ? props.location.state : {}), parcelPaymentSavedCardEventsAttributes: analyticsEventAttributes}
					}}
				/>
				: null
			}

			<section className="content pb-0 pt-0 shipping-options-container-wrapper">

				<div
					className="container-fluid container-width padding-vertical-25 payment-option-save-card-height shipping-options-container">
					<Row>
						<Col lg={7} md={12} sm={12}>
							<AmazonPay 
								paymentRequestData={paymentRequestData}
								setShipmentLoading={setLoading}
								onPaymentCompletion={onPaymentCompletion}
								paymentType={"shipping"}
								shipmentLoading={loading}
								totalPayment={paymentDetailsFromProps?.total?.toFixed(2) || 0}
								parcels={parcels}
								addOnServices={addOnServices}
								setCreditCard={setCreditCard}
								creditCard={creditCard}
								setIsAmazonPay={setIsAmazonPay}
								setIsPaypal={setIsPaypal}
								setIsOthers={setIsOthers}
								setPaymentRequestData={setPaymentRequestData}
								analyticsEventAttributes={analyticsEventAttributes}
							/>
						</Col>

						<Col md={12} sm={12} lg={5} className="mobile-margin-bottom-90 text-start">
							<PaymentSummary
								parcels={payables?.length > 0 ? [] : parcels}
								payables={payables}
								totalChargableWeight={totalChargableWeight}
								totalParcelValue={totalParcelValue}
								paymentSummary={paymentRequestData}
								loading={props.loading}
								paymentLoading={loading}
								promoCode={paymentRequestData?.promo_code_discount?.is_valid ? paymentRequestData?.promo_code_discount?.discount_code : null}
								submit={(
									creditCard?.id
									|| (paymentRequestData?.total === 0 && (paymentRequestData?.wallet_amount && paymentRequestData?.wallet_amount > 0))
									) && handleSubmit}
							/>
						</Col>
					</Row>
				</div>
			</section>

		</>
	);
}