import styles from "./styles.module.scss";

const e2e = [
    "Dashboard & Reporting",
    "Automated Delivery Routes & Schedules",
    "End to End Order Visibility",
    "Optimize Deliveries based on Cost & lead time",
    "Custom Clearance Expertise",
];

const crossBorder = [
    "Build your own Carrier Network with our Parcel Hub",
    "Cash Collection & Settlements",
    "Order Return Service",
];

const Content = () => {
    return (
        <>
            <div
                className={`row m-0 p-0 ${styles["pb-44px"]}`}
            >
                <div className={`col-12 col-md-6 m-0 p-0 `}>
                    <span
                        className={`d-inline-block ${styles["e2e-solutions"]}`}
                    >
                        Your Customized End-to-End Delivery Solutions
                    </span>
                </div>
                <div className={`col-12 col-md-6 m-0 p-0`}>
                    {e2e.map((element) => (
                        <div className="d-flex align-items-start">
                            <img
                                className="mr-3 pt-1"
                                src={
                                    require("../../images/bullet-true-blue.svg")
                                        .default
                                }
                                alt={""}
                            />
                            <span className={`${styles["list-item"]}`}>
                                {element}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
            <div
                className={`row m-0 p-0 ${styles["pb-44px"]} ${styles["wrapper"]}`}
            >
                <div className={`col-12 col-md-6 m-0 p-0 `}>
                    <span
                        className={`d-inline-block ${styles["cross-border"]}`}
                    >
                        Cross-Border Integrated Platform
                    </span>
                </div>
                <div className={`col-12 col-md-6 m-0 p-0`}>
                    {crossBorder.map((element) => (
                        <div className="d-flex align-items-start">
                            <img
                                className="mr-3 pt-1"
                                src={
                                    require("../../images/bullet-true-green.svg")
                                        .default
                                }
                                alt={""}
                            />
                            <span className={`${styles["list-item"]}`}>
                                {element}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Content;
