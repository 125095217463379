import { FormControl, } from "@material-ui/core";
import React, { useState , cloneElement} from "react";
import { useEffect } from "react";
import {copyTextToClipboard} from '../../utils/string'

import {
    Card,
    Row,
    Col,
    Badge,
    DropdownButton,
    Dropdown,
    Select
} from "react-bootstrap";
import ReactFlagsSelect from "react-flags-select";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { PARCEL_CONSTANTS } from "../../constants/constants";
import {
    HOMEPAGE,
    PROHIBITED_MATERIALS,
    REFERRAL_PAGE,
    BUYITFORME_POLICY
} from "../../constants/navigationConstants";
import { useAuth } from "../../contexts/AuthContext";
import "./style.scss";
import styles from './styles.module.scss'
import { TextCopyOverlay } from "../common";
import { screenEvent } from "../../analytics/constants";
import useAnalytics from "../../analytics/useAnalytics";

const generateUserAddressJsonString = ({
    t,
    fullName,
    country,
    address,
    address2,
    userPin,
    city,
    state,
    zip,
    phoneNumber,
}) => {
    return JSON.stringify({
        [t("Full Name")]: fullName,
        [t("Address line 1")]: address,
        [t("Address line 2")]: address2,
        [country?.toLowerCase()==='gb'? t("City / Town") : t("City")]: city,
        [country?.toLowerCase()==='gb'? t("State / County") : t("State / Province")]: state,
        [t("Zip")]: zip,
        [t("Phone number")]: phoneNumber,
    });
}

const BottomLinks = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <Card className="rounded border-0 only-mb-hide">
            <Card.Body>
                <Link to={REFERRAL_PAGE}>
                    <div className="d-flex flex-row border-bottom fs-16 gray-1 fstyle-w400 line-height-p160">
                        <div className="items">
                            <Badge className="bdg-icn-grn">
                                <i className="fas fa-gift text-white" />
                            </Badge>
                        </div>

                        <Col md={10} className="line-height-30 ">
                            {t("Refer a Friend")}
                            <span className="bg-orange text-white">{t("NEW")}</span>
                        </Col>
                        <Col md={1} className="line-height-30 text-end right-arrow">
                            <i className={`bx ${(i18n.language === "en") ? "bx-chevron-right" : "bx-chevron-left"}`} />
                        </Col>
                        <p>&nbsp;</p>
                    </div>
                </Link>
                <Link to={PROHIBITED_MATERIALS}>
                    <div className="d-flex flex-row border-bottom fs-16 gray-1 fstyle-w400 line-height-p160">
                        <div className="items">
                            <Badge className="bdg-icn-grn">
                                <i className="bx bx-calendar-x fs-16" />
                            </Badge>
                        </div>

                        <Col md={9} className="line-height-30 ">
                            {t("Prohibited Materials")}
                        </Col>
                        <Col md={1} className="line-height-30 text-end right-arrow">
                            <i
                                className={`bx ${i18n.language === "en"
                                        ? "bx-chevron-right"
                                        : "bx-chevron-left"
                                    }`}
                            />
                        </Col>
                        <p>&nbsp;</p>
                    </div>
                </Link>
                <Link
                    to={{ pathname: HOMEPAGE, state: { calculatorLink: true } }}
                >
                    <div className="d-flex flex-row  border-bottom fs-16 gray-1 fstyle-w400 line-height-p160">
                        <div className="items">
                            <span className="badge bdg-icn-grn">
                                <i className="bx bxs-calculator fs-16" />
                            </span>
                        </div>
                        <Col md={9} className="line-height-30">
                            {t("Calculate Shipping Rate")}
                        </Col>
                        <Col md={1} className="right-arrow line-height-30">
                            <i
                                className={`bx ${i18n.language === "en"
                                        ? "bx-chevron-right"
                                        : "bx-chevron-left"
                                    }`}
                            />
                        </Col>
                        <p>&nbsp;</p>
                    </div>
                </Link>
                <Link
                    to={BUYITFORME_POLICY}
                >
                    <div className="d-flex flex-row fs-16 gray-1 fstyle-w400 line-height-p160">
                        <div className="items">
                            <span className="badge bdg-icn-grn">
                                <i className='bx bxs-purchase-tag fs-16' />
                            </span>
                        </div>
                        <Col md={10} className="line-height-30">
                            {t("Buy it for me Payment Terms")}
                        </Col>
                        <Col md={1} className="right-arrow line-height-30">
                            <i className={`bx ${(i18n.language === "en") ? "bx-chevron-right" : "bx-chevron-left"}`} />
                        </Col>
                        <p>&nbsp;</p>
                    </div>
                </Link>
            </Card.Body>
        </Card>
    );
};

const UserAddressBlock = ({ user, onOpen, onClose, accountPage }) => {
    const { t, i18n } = useTranslation();
    const analytics = useAnalytics();
    const warehouse = PARCEL_CONSTANTS.WAREHOUSE;
    const inversefloater =
        i18n.language === "en" ? "float-left" : "float-right";
    const { warehousesList, warehouseId, setWarehouseId } = useAuth();
    const [loading, setLoading] = useState(true)
    const createWarehousesLabels = () => {
        const customLabels = {}

        warehousesList?.forEach(w => {
            return customLabels[w?.country] = {
                primary: w?.name,
                secondary: w?.active ? null : <div className="mx-1 coming-soon orange-bg float-end">{t("Coming soon")}</div> 
            } 
        })

        return customLabels
    }

    const getWarehouseData = (keyName) => {
        const selectedWarehouse = warehousesList?.find(w => w?.id === warehouseId)
        return selectedWarehouse ? selectedWarehouse[keyName] : null
    }

    return (
        <>
            <Col md={12} className="mb-padding-left0 pr-0">
                <span className={inversefloater}>
                    <a
                        className="backto mb-show ipad-hide"
                        href="#"
                        aria-hidden="true"
                        onClick={onClose}
                    >
                        <i
                            className={`bx ${i18n.language === "ar"
                                    ? "bx-right-arrow-alt"
                                    : "bx-left-arrow-alt"
                                }`}
                        />
                    </a>
                </span>
                <h5 className="fs-18 gray-1 text-center ipad-padding-top-24">
                    {" "}
                    {t("Account ID")}
                </h5>
            </Col>
            <Col md={12}>
                <h1 className="fs-32 orange line-height-48 text-center">
                    {user.userPin}
                </h1>
            </Col>
            <hr className="hrbrdr" />
            <ReactFlagsSelect
                countries={warehousesList?.map((warehouse) => warehouse.country)}
                customLabels={createWarehousesLabels()}
                className={`ltr-direction h-full my-3`}
                selected={warehousesList.find(w => w.id === warehouseId)?.country}
                onSelect={(code) => {
                    if (warehousesList?.find(w => w.country === code)?.active) {
                        setWarehouseId(warehousesList?.find(w => w.country === code)?.id)
                    } else {
                        setWarehouseId(1)
                    }
                }}
                onFocus={(e) => {
                    if (e.target.autocomplete) {
                        e.target.autocomplete = "noautocomplete";
                    }
                }}
                showSelectedLabel
                showSecondarySelectedLabel
                placeholder={"Select a warehouse"}
                alignOptionsToRight
                fullWidth
            // onClick={() => {
            //     warehousesList?.forEach((warehouse) => {
            //         if (!warehouse.active) {
            //             console.log("huda",document?.getElementById(`rfs-${warehouse.country?.toUpperCase()}`))
            //             const warehouseItem = document?.getElementById(`rfs-${warehouse.country?.toUpperCase()}`)
            //             warehouseItem?.setAttribute("disabled", true)
            //             // warehouseItem.classList.add("text-muted")
            //         }
            //     })
            // }}
            // disabled={!warehousesList.find(w => w.id === warehouseId)?.active}
            />
            {/* <FormControl fullWidth>
            <select
                value={warehouseId}
                label="Select Warehouse"
                onChange={setWarehouseId}            
            >
                {warehousesList?.map(warehouse => 
                    <option key={`warehouse-${warehouse?.id}`} value={warehouse?.id}>
                        {warehouse?.country}
                    </option>    
                )}
            </select>
            </FormControl> */}
            <div className="d-flex align-items-start">
                <Col md={7} sm={7} className="padding-left0 text-start">
                    <h6 className="fs-18 fstyle-w500 gray-1 text-nowrap">
                        {t(`My ${(getWarehouseData("country") || "")} Address`)}
                    </h6>
                </Col>
                <Col md={5} sm={5} className={`${i18n?.language === "en" ? "text-to-right" : ""}`}>
                    <TextCopyOverlay
                        textToBeCopied={generateUserAddressJsonString({
                            t,
                            fullName: user.name,
                            country: (getWarehouseData("country") || ""),
                            address: (getWarehouseData("country") || "")?.toLowerCase() === 'cn'?`(${user.userPin}) ${getWarehouseData("address")}` : getWarehouseData("address"),
                            address2:
                                (getWarehouseData("country") || "")?.toLowerCase() === "us"
                                    ? user.userPin                                    
                                    : (getWarehouseData("address2") ?? user.userPin),
                            userPin:user.userPin,
                            city:
                                (getWarehouseData("country") || "")?.toLowerCase() === "gb"
                                    ? `${getWarehouseData("city")} (${user.userPin})`
                                    : getWarehouseData("city"),
                            state: getWarehouseData("state"),
                            zip: getWarehouseData("zip_code"),
                            phoneNumber: getWarehouseData("phone"),
                        })}
                    >
                        <div
                                className={`${styles["copy-all"]} ${styles["button-icon"]} d-flex align-items-center`}
                        >
                            <img
                                className={`${styles["copy-all-icon"]}`}
                                src={require("../common/TextCopyOverlay/copy-icon.svg").default}
                                alt=""
                            />
                            <span>{t("Click to Copy")}</span>
                        </div>
                    </TextCopyOverlay>
                </Col>
            </div>
            <div className="d-flex align-items-start flex-row pt-2">
                <Col
                    md={5}
                    sm={5}
                    className="fs-16 fstyle-n line-height-p160 gray-3 padding-left0"
                >
                    {t("Name")}
                </Col>
                <Col
                    md={7}
                    sm={7}
                    className="ltr-direction fs-16 fstyle-n line-height-p160 gray-1 "
                >
                    <TextCopyOverlay textToBeCopied={user.name}>
                        <div>
                            {user.name}
                        </div>
                    </TextCopyOverlay>
                </Col>
            </div>
            <div className="d-flex flex-row align-items-start pt-2">
                <Col
                    md={5}
                    sm={5}
                    className="fs-16 fstyle-n line-height-p160 gray-3 padding-left0"
                >
                    {t("Phone")}
                </Col>
                <Col
                    md={7}
                    sm={7}
                    className="ltr-direction fs-16 fstyle-n line-height-p160 gray-1 phone-dir-ltr"
                >
                     <TextCopyOverlay textToBeCopied={getWarehouseData("phone")}>
                        <div>
                            {getWarehouseData("phone")}
                        </div>
                    </TextCopyOverlay>
                </Col>
            </div>
            <div className="d-flex align-items-start flex-row pt-2">
                <Col
                    md={5}
                    sm={5}
                    className="fs-16 fstyle-n line-height-p160 gray-3 padding-left0"
                >
                    {t("Address line 1")}
                </Col>
                <Col
                    md={7}
                    sm={7}
                    className="ltr-direction fs-16 fstyle-n line-height-p160 gray-1 phone-dir-ltr"
                >
                    <TextCopyOverlay textToBeCopied={((getWarehouseData("country") || "") || "").toLowerCase() === 'cn'?`(${user.userPin}) ${getWarehouseData("address")}` : getWarehouseData("address")}>
                        <div>
                            {((getWarehouseData("country") || "") || "").toLowerCase() === 'cn'
                                ? <><span className={`${styles['text-orange']}`}>{`(${user.userPin}) `}</span><span>{getWarehouseData("address")}</span></>
                                : <span>{getWarehouseData("address")}</span>
                            }
                        </div>
                    </TextCopyOverlay>
                </Col>
            </div>
            <div className="d-flex align-items-start flex-row pt-2">
                <Col
                    md={5}
                    sm={5}
                    className="fs-16 fstyle-n line-height-p160 gray-3 padding-left0"
                >
                    {t("Address line 2")}
                </Col>
                <Col md={7} sm={7} className="ltr-direction text-nowrap text-start">
                    <TextCopyOverlay textToBeCopied={((getWarehouseData("country") || "") || "").toLowerCase() === "us" ? user.userPin : (getWarehouseData("address2") ?? user.userPin)}>
                            <div>{ ((getWarehouseData("country") || "") || "").toLowerCase() === "us"
                                ? <span className={`${styles['text-orange']}`}>{user.userPin}</span>
                                : getWarehouseData("address2") ?? user.userPin
                                }
                                <a
                                    className="gray-2 mx-1"
                                    href="#us_demo_address"
                                    data-toggle="modal"
                                    data-target="#us_demo_address"
                                >
                                    <i className="bx bx-info-circle" />
                                </a>
                            </div>
                    </TextCopyOverlay>
                </Col>
            </div>
            <div className="d-flex align-items-start flex-row pt-2">
                <Col
                    md={5}
                    sm={5}
                    className="fs-16 fstyle-n line-height-p160 gray-3 padding-left0"
                >
                    { (getWarehouseData("country") || "") === "GB" ? 
                        t("City / Town") :  
                        t("City")
                    }
                </Col>
                <Col md={7} sm={7} className="ltr-direction fstyle-n line-height-p160 gray-1">
                    <TextCopyOverlay textToBeCopied={`${getWarehouseData("city")}${(getWarehouseData("country") || "") === "GB"?` (${user.userPin})`:""}`}>
                        <div>
                            {getWarehouseData("city")}
                            &nbsp;
                            {(getWarehouseData("country") || "") === "GB" && <strong className="orange">({user.userPin})</strong>}
                        </div>
                    </TextCopyOverlay>
                </Col>
            </div>
                <div className="d-flex align-items-start flex-row pt-2">
                    <Col
                        md={5}
                        sm={5}
                        className="fs-16 fstyle-n line-height-p160 gray-3 padding-left0"
                    >
                        { (getWarehouseData("country") || "") === "GB" ? 
                            t("State / County") :  
                            (getWarehouseData("country") || "") === "US" ? t("State / Province") : 
                            t("State")
                        }
                    </Col>
                    <Col md={7} sm={7} className="ltr-direction fstyle-n line-height-p160 gray-1">
                        <TextCopyOverlay textToBeCopied={getWarehouseData("state")}>
                            <div>
                                {getWarehouseData("state")}
                            </div>
                        </TextCopyOverlay>
                    </Col>
                </div>
            <div className="d-flex align-items-start flex-row pt-2">
                <Col
                    md={5}
                    sm={5}
                    className="fs-16 fstyle-n line-height-p160 gray-3 padding-left0"
                >
                    {t("Zip Code")}
                </Col>
                <Col md={7} sm={7} className="ltr-direction fstyle-n line-height-p160 gray-1">
                    <TextCopyOverlay textToBeCopied={getWarehouseData("zip_code")}>
                        <div>
                            {getWarehouseData("zip_code")}
                        </div>
                    </TextCopyOverlay>
                </Col>
            </div>
            <hr className="hrbrdr" />
            <Row className="pt-1 ipad-padding-bottom-24 padding-right16">
                <Col md={12} className="col-md-12 text-start">
                    <p className="fs-16 fstyle-n gray-1 line-height-p160 text-start">
                        {t(
                            "For better experience, please make sure to add your Account ID"
                        )}{" "}
                        <span className="fs-16 font-weight-500 line-height-24 orange">
                            {user.userPin}
                        </span>{" "}
                        {t(
                            "To the address line 2 so we can identify you easily and ship your package so fast"
                        )}
                    </p>
                    { accountPage ? null : <a
                        className="fs-16 fstyle-n line-height-p160 text-start"
                        href="#us_demo_address"
                        data-toggle="modal"
                        data-target="#us_demo_address"
                        onClick={()=>{
                            analytics.events.screenVisited({ screen: screenEvent.WAREHOUSE_ADDRESS_EXAMPLE });
                        }}
                    >
                        {t("How to use my address")}
                    </a> }
                </Col>
            </Row>
        </>
    );
};

export default (props) => {
    return (
        <Row className="row-bg-white account-block">
            <Col md={12} className="">
                <Card className="rounded border-0 z-0">
                    <Card.Body className="card-body-mb">
                        <UserAddressBlock {...props} />
                    </Card.Body>
                </Card>
                <Row className="only-mb-hide">
                    <p>&nbsp;</p>
                </Row>
                { props?.accountPage ? null : <BottomLinks {...props} /> }
            </Col>
        </Row>
    );
};
