import React, { useEffect, useState } from "react"
import {
    Row,
    Col,
    Card
} from "react-bootstrap"
import { useTranslation } from "react-i18next"

import {
    MEMBERSHIP_CONSTANTS,
    PARCEL_CONSTANTS,
    SERVICES_WITH_VAT,
    USER_CONSTANTS
} from "../../constants/constants.js"
import { humanize } from "../../utils/string"
import { useAuth } from "../../contexts/AuthContext"
import { head } from 'lodash'
import ShippingBox from "../../assets/img/icons/shipping-box.svg"
import { SERVICES_NO_PROMOCODE } from "../../constants/constants"
export default ({
    parcels,
    rates,
    shippingService,
    membershipServices,
    address,
    addOnServices,
    setAddOnServices,
    setConsolidationType,
    userAddressesState,
    addressList,
}) => {

    const { t, i18n } = useTranslation()
    const floater = i18n.language === "ar" ? "float-left" : "float-right"
    const promotionCurvature = i18n.language === "ar" ? "round-promotion-left" : "round-promotion-right"
    let { membershipPlanID } = useAuth()

    const [itemConsolidate, setItemConsolidate] = useState(false)
    const [boxConsolidate, setBoxConsolidate] = useState(false)
    const toggleExtraService = (event, service, index) => {
        let selectedMembershipServices = addOnServices
        if (event.target.checked) {
            selectedMembershipServices.push(service.id)

        }
        else {
            selectedMembershipServices = selectedMembershipServices.filter(id => id !== service.id)
        }
        setAddOnServices([...selectedMembershipServices])
        const consolidationType = boxConsolidate ? PARCEL_CONSTANTS.BOX_CONSOLIDATION : itemConsolidate ? PARCEL_CONSTANTS.ITEM_CONSOLIDATION : ""
        setConsolidationType(consolidationType)
    }

    const removeConsolidation = (e) => {
        setBoxConsolidate(false)
        setItemConsolidate(false)
        setConsolidationType("")
    }

    useEffect(() => {
        if (boxConsolidate && itemConsolidate) {
            setItemConsolidate(false)
        }
    }, [boxConsolidate])

    useEffect(() => {
        if (boxConsolidate && itemConsolidate) {
            setBoxConsolidate(false)
        }
    }, [itemConsolidate])

    useEffect(() => {
        if (!userAddressesState && addressList?.length === 0) {
            document.querySelector("#change-address").click()
        }
    }, [userAddressesState])


    return (
        <>
            <Col sm={12} className="pr-0 pl-0">
                <div className="widget widget_packages text-start">
                    <div className="paymentoption">
                        <h2 className={`font32-gry1 mb-0 py-3 ${i18n.language === "ar" ? "pr-4" : "pl-4"}`}>{t("Shipment Options")}</h2>
                    </div>
                    <Card
                        className={`promotion-card ${(parcels?.filter(parcel => parcel?.package_data?.can_consolidate)?.length > 1 && Number(membershipPlanID) !== MEMBERSHIP_CONSTANTS?.BASIC && rates?.length > 0) ? "" : "d-none"}`}>
                        <h4 className={`promotion-h4 ${promotionCurvature}`}>
                            <img alt="..." src={ShippingBox} />
                            &nbsp;&nbsp;&nbsp;
                            {t("Save up to 80% on your shipping fees")}
                        </h4>
                        <Card.Body style={{ "padding-bottom": "0px" }}>
                            <Row>
                                <Col md={12}>
                                    <p>
                                        <input type="radio"
                                            className="pkg-service consolidate margin-right-8 margin-left-8-ar margin-right-0-ar"
                                            name="consolidate"
                                            checked={boxConsolidate}
                                            onChange={() => {
                                                setBoxConsolidate(!boxConsolidate)
                                                setConsolidationType(PARCEL_CONSTANTS.BOX_CONSOLIDATION)
                                            }}
                                        />
                                        <span className="mr-1 d-inline">{t("Consolidate Packages")}</span>
                                        <small className="gray-2">
                                            ({t("up to")} {PARCEL_CONSTANTS.CONSOLIDATION_BOX_SAVING_PERCENTAGE}% {t("off")})&nbsp;
                                        </small>

                                        <i aria-expanded="false" data-toggle="tooltip" data-placement="top"
                                            className="bx bx-info-circle"
                                            title={t("We will combine all packages and ship them as one to you so you can save min 60% on shipping charges")} />
                                    </p>

                                </Col>
                            </Row>
                            <Row className="mt-n10">
                                <Col md={12}>
                                    <p>
                                        <input type="radio"
                                            className="pkg-service consolidate margin-right-8 margin-left-8-ar margin-right-0-ar"
                                            name="consolidate"
                                            checked={itemConsolidate}
                                            onChange={(e) => {
                                                setItemConsolidate(!itemConsolidate)
                                                setConsolidationType(PARCEL_CONSTANTS.ITEM_CONSOLIDATION)
                                            }}
                                        />
                                        <span className="mr-1 d-inline">{t("Consolidate Items")}</span>
                                        <small
                                            className="gray-2">({t("up to")} {PARCEL_CONSTANTS.CONSOLIDATION_ITEM_SAVING_PERCENTAGE}% {t("off")})&nbsp;
                                        </small>
                                        <i aria-expanded="false" data-toggle="tooltip" data-placement="top"
                                            className="bx bx-info-circle"
                                            title={t("We can also receive all your packages, open them, and then combine and items in one package You will even achieve more than 80% in reductions on shipping fees")} />
                                    </p>
                                </Col>
                            </Row>

                            <Row className="mt-n10">
                                <Col md={12}>
                                    <p>
                                        <input type="radio"
                                            className="consolidate normal-parcel margin-right-8 margin-left-8-ar"
                                            name="consolidate" onChange={removeConsolidation}
                                            checked={!(boxConsolidate || itemConsolidate)} />
                                        <span className="mr-1 d-inline">{t("No consolidate")}</span>
                                    </p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <div className="widget__inner24 p-4 shipment-ar">
                        <Row>
                            <Col sm={12}>
                                <span className="font18-gry1">
                                    {t("Delivery Address")}
                                </span>
                                <a id="change-address" data-toggle="modal" className={`${floater} font-weight-500 change-link`}
                                    data-target="#address-modal">
                                    {userAddressesState ? t("Change Address") : t("Add Delivery Address")}
                                </a>
                            </Col>
                            <Col md={12} className="pt-2">
                                <span className="font14-gry1">
                                    {(address?.id)
                                        ? <>
                                            <input type="hidden" name="shipment_address_id" id="shipment_address_id"
                                                value={address?.id} />
                                            <span className="parcel-address">
                                                {address?.address + ", " + address?.state + ", " + (address?.postal_code ? address?.postal_code + ", " : "") + address?.country}
                                            </span>
                                        </>
                                        : null
                                    }
                                </span>
                            </Col>

                        </Row>
                        <hr className="hrbrdr" />
                        {rates?.length > 0 &&
                            <>
                                <Row>
                                    <Col sm={12}>
                                        <span className="font18-gry1">
                                            {t("Shipping Company")}
                                        </span>
                                        <a data-toggle="modal"
                                            className={`${floater} font-weight-500 change-link change_shipping_service_modal`}
                                            data-target="#shipping-service-modal">
                                            {t("Change")}
                                        </a>
                                    </Col>
                                </Row>
                                <Row className="mt-1">
                                    <Col sm={12}>
                                        <span>
                                            <img src={shippingService.company_logo} width="50" id="shippingCompanyLogo"
                                                alt="shipping logo" />
                                            <span
                                                className={`font14-gry1 shipment_company_name ${i18n.language === "ar" ? "mr-2" : "ml-2"}`}>
                                                {humanize(shippingService.service) || null}
                                            </span>
                                        </span>
                                        <span className={`${floater} font14-gry1 font-weight-500`}>
                                            {shippingService?.eta_days?.split(" ")[0]}&nbsp;{t(shippingService?.eta_days?.split(" ")[1])}
                                        </span>
                                    </Col>
                                    {SERVICES_NO_PROMOCODE?.includes(shippingService?.shipping_company_id) && <Col className="font-14 mt-2" sm={12}>
                                        {t("Shipping discount promo code cant be applied here")}
                                    </Col>}
                                    {SERVICES_WITH_VAT?.includes(shippingService?.shipping_company_id) && <Col className="font-14 mt-2" sm={12}>
                                        {t("VAT amount will be added to the shipping fees")}
                                    </Col>}
                                </Row>
                                <hr className="hrbrdr" />

                                {membershipServices?.length > 0 &&
                                    <>
                                        <Row>
                                            <Col md={12} className="pb-3">
                                                <span className="font18-gry1">{t("Select / Extra Services")}</span>
                                            </Col>
                                        </Row>
                                        {membershipServices.map((service, index) => {
                                            return <Col md={12} className="services-blk" key={`${service.id}-membership-service`}>
                                                <Row>
                                                    <Col md={12}>
                                                        <input type="checkbox" name="services" id="insurance"
                                                            className="pkg-service services-check"
                                                            onChange={(e) => toggleExtraService(e, service, index)} />
                                                        <span className="font-weight-500"> {t(service?.service)} </span>
                                                        <span className={`${floater} font24-gry1 mr-1 d-inline`}>
                                                            {t(service?.service_value)}
                                                            {service?.service_type === "flat" ? "$" : "%"}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <p className="font12-gry3 pl-4 padding-top-8 padding-right-30-ar">
                                                            {t(service.description)}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        })}
                                    </>}
                            </>}
                    </div>
                </div>
            </Col>
        </>
    )
}