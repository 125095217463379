import React, { useEffect } from "react";
import Services from "./services";
import ShippingBenefitsSectionOne from "./shippingBenefitsSectionOne";
import ShippingBenefitsSectionTwo from "./shippingBenefitsSectionTwo";
import useAnalytics from "../../analytics/useAnalytics";
import { screenEvent } from "../../analytics/constants";

function OurServicesPage(props) {
	const analytics = useAnalytics();

    useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.OUR_SERVICES });
    }, []);

	return (
		<div className="global-section-container flex-col">
			<Services/>
			<ShippingBenefitsSectionOne/>
			<ShippingBenefitsSectionTwo/>
		</div>
	);
}

export default OurServicesPage;
