import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { PROMOS } from "../../constants/constants";
import PromoCode from "../promoCode";
import * as NAVIGATION_CONSTANTS from "../../constants/navigationConstants";
import { Link } from "react-router-dom";

export default ({ paymentSummary, setPaymentSummary, membershipId, defaultPromoCode, setShowSkip, setProceedToPay, submit }) => {
    const { t, i18n } = useTranslation();
    const isMobile = useMediaQuery({ query: "(max-width: 991px)" });
    const isPay = (paymentSummary?.promo_code &&
        paymentSummary?.promo_code !== "" &&
        paymentSummary?.discountedTotal > 0) || (!paymentSummary?.promo_code || paymentSummary?.promo_code === "")
    return (
        <>
            <div className="widget widget_packages pb-0">
                <div className={`widget__inner24 summary-right summary-width pt-3 pb-3 ${isMobile ? "mb-4 w-100" : ""}`}>
                    <h4 className="font18-gry1 pb-2 font-weight-600 px-4"> {t("Summary")}</h4>
                    {paymentSummary.items &&
                        paymentSummary.items.map((item, index) => {
                            return (
                                <Row className="font-weight-500 line-height-40 px-4">
                                    <Col
                                        md={item.title !== "Plan" ? 9 : 6}
                                        sm={item.title !== "Plan" ? 9 : 6}
                                        xs={item.title !== "Plan" ? 9 : 6}
                                        className="mobile-width-50-percent">
                                        {t(`${item.title}`)}
                                    </Col>
                                    <Col
                                        md={item.title !== "Plan" ? 3 : 6}
                                        sm={item.title !== "Plan" ? 3 : 6}
                                        xs={item.title !== "Plan" ? 3 : 6}
                                        className="text-end float-left mobile-width-50-percent  text-left-ar"
                                    >
                                        <span>{t(item.value)}</span>
                                    </Col>
                                </Row>
                            );
                        })}
                    <hr className="hrbrdr mobile-hide-subtotal" />
                    <Row className="font18-gry1 line-height-40 mobile-hide-subtotal px-4">
                        <Col md={6} sm={6} className="mobile-width-50-percent">
                            {t("Sub Total")}
                        </Col>
                        <Col
                            md={6}
                            sm={6}
                            className="text-end mobile-width-50-percent text-left-ar"
                        >
                            <span className="shipping_total_price">
                                ${paymentSummary.grandTotal}
                            </span>
                        </Col>
                    </Row>
                    <Row className="px-4 mt-2">
                        <Col xs={12}>
                            <PromoCode
                                source={PROMOS.COUPON_TYPE_SUBSCRIPTION}
                                paymentSummary={paymentSummary}
                                setPaymentSummary={setPaymentSummary}
                                membershipId={membershipId}
                                defaultPromoCode={defaultPromoCode}
                                setShowSkip={setShowSkip}
                            />
                        </Col>
                    </Row>
                    {paymentSummary.discountedTotal ||
                        paymentSummary.discountedTotal === 0 ? (
                        <Row className="font18-gry1 line-height-40 mobile-hide-subtotal px-4">
                            <Col md={6} sm={6} className="mobile-width-50-percent">
                                {t("Total")}
                            </Col>
                            <Col
                                md={6}
                                sm={6}
                                className="text-end mobile-width-50-percent text-left-ar"
                            >
                                <span className="shipping_total_price">
                                    ${paymentSummary.discountedTotal}
                                </span>
                            </Col>
                        </Row>
                    ) : null}
                    <Row className="px-4">
                        <Col md={12} sm={12} className="pt-3" style={{
                            paddingLeft: "30px",
                            paddingRight: "30px"
                        }}>
                            <div className="mobile-pay-now paypal-ipad">
                                <Row className="mobile-show-subtotal font-weight-600 mobile-subtotal-padding mb-3">
                                    <div
                                        className={`mobile-width-50-percent float-left text-left float-right-ar text-end-ar ${i18n.language === "ar" ? "text-right" : "text=left"}`}>
                                        {!paymentSummary.discountedTotal ||
                                            paymentSummary.discountedTotal === 0
                                            ? t("Total")
                                            : t("Sub Total")}
                                    </div>
                                    <div
                                        className="text-end mobile-width-50-percent float-right text-end float-left-ar text-left-ar">
                                        <span className="shipping_total_price">
                                            $
                                            {paymentSummary.discountedTotal ||
                                                paymentSummary.discountedTotal === 0
                                                ? paymentSummary.discountedTotal
                                                : paymentSummary.grandTotal}
                                        </span>
                                    </div>
                                </Row>
                                {/* {showSkip && submit &&
                                    <Col xs={{ span: isMobile ? 12 : 3, offset: isMobile || i18n.language === "ar" ? 0 : 5 }} className={isMobile ? "btn color-inverse-default-button text-center mb-4 py-2" : " pt-2"}>
                                        <Link onClick={(e) => submit(e, true)} className="font-weight-bold" to={NAVIGATION_CONSTANTS.VERIFY_EMAIL}>{t("Skip for now")}</Link>
                                    </Col>
                                }
                                <Row className={`paddingtop15 ${showSkip && !isMobile ? `padding-3 ${i18n.language === "ar" ? "justify-content-end" : ""}` : ""}`} id="payment_credit">
                                    <Col md={8} />
                                    <Col md={4}>
                                        <Col xs={{ span: isMobile ? 12 : showSkip ? 4 : 12 }} className="mobile-checkout">
                                            <button
                                                className="rounded form-control border-radius-10 padding-vertical-15 font-weight-500 btn btn-primary"
                                                disabled={!submit ? true : false}
                                                id="save_payment"
                                                onClick={submit}
                                            >
                                                <span
                                                    className="credit-spinner spinner-border spinner-border-sm d-none white-color margin-btm-4" />
                                                <span className="credit-text">{t(paymentButtonText)}</span>
                                            </button>
                                        </Col>
                                    </Col>
                                 </Row> */}
                            </div>
                        </Col>
                    </Row>
                    {submit && <Row className="px-4">
                        <Col xs={12}>
                            <Button
                                id="membershipPayment"
                                className={ isPay ?
                                    "color-inverse-default-button w-100"
                                    :
                                    "rounded u-userLauncherColor credit_button form-control border-radius-10 padding-vertical-15 font-weight-500"}
                                onClick={submit}
                                disabled={submit ? false : true}

                            >
                                { isPay
                                    ? t("Pay Now")
                                    : t("Subscribe")}
                            </Button>
                        </Col>
                    </Row>}
                </div>
            </div>
        </>
    );
};
