import React from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { capitalizeFirstChar } from "../../../utils/string";

const ConfirmationModal = ({
    show,
    handleClose,
    message,
    options,
    title,
    img,
    handleOptionClick,
    isMobile
}) => {
    const { t, i18n } = useTranslation();

    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName={i18n.language + ""}
            aria-labelledby="contained-modal-title-vcenter"
            id="conformationModal"
            contentClassName={"gray-bg"}
            centered
        >
            <Modal.Header className="justify-content-center" closeButton />
            <Modal.Body className="py-0 mb40 text-center">
                <img src={img} />
                <Modal.Title className="text-center my-3">{t(title)}</Modal.Title>
                <p className="text-muted text-justify text-center">
                    {t(message)}
                </p>
                <Row className="justify-content-center">
                    {options?.map((option) => (
                        <Button 
                            key={`option-${option}`} 
                            className={`${isMobile ? "my-2" : ""} mx-3 btn btn-${option}`}
                            onClick={() => handleOptionClick(option)}
                        >
                            {t(capitalizeFirstChar(option))}
                        </Button>
                    ))}
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default ConfirmationModal;
