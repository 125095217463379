import React, { useEffect, useMemo, useState } from "react";
import AmazonPay from "../AmazonPay";
import qs from "query-string";
import { HOMEPAGE } from "../../constants/navigationConstants";
import { useAuth } from "../../contexts/AuthContext";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GLOBAL_CONSTANTS } from "../../constants/constants";
import { postData } from "../../utils/api";

const MobilePayment = () => {
    const [paymentRequestData, setPaymentRequestData] = useState({});
    const [addOnServices, setAddOnServices] = useState([]);
    const [creditCard, setCreditCard] = useState({});
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [isAmazonPay, setIsAmazonPay] = useState(false)
    const [isPaypal, setIsPaypal] = useState(false);
    const [isOthers, setIsOthers] = useState(false);
    const queryParam = qs.parse(decodeURIComponent(window?.location?.search));
    const [selectWallet, setSelectWallet] = useState(queryParam.payment_type === "buy-it" && parseFloat(queryParam?.wallet_balance) > 0);
    const [totalPayment, setTotalPayment] = useState(0);
    const { setAuthToken, authToken } = useAuth();
    const { t, i18n } = useTranslation();

    const targetOrigin =  useMemo(()=>process?.env?.REACT_APP_MOBILE_POST_MESSAGE_TARGET_ORIGIN,[])
    
    const afterPayment = async (request = {}, _mobileAnalyticsAttributes) => {
        const mobileAnalyticsAttributes = {..._mobileAnalyticsAttributes}?? {}
		try {
            let walletPaymentResponse
			if (request?.is_payby_wallet === 1 && request?.wallet_amount > 0) {
				walletPaymentResponse = await postData(
					"paymentRequestService",
					"/v2/PurchaseRequestController/paymentPurchaseRequest",
					{
						purchase_request_id: Number(queryParam?.id),
						is_payby_wallet: 1,
						wallet_amount: request?.wallet_amount?.toFixed(2),
					},
					authToken
				)
				request["wallet_transaction_id"] = walletPaymentResponse?.payload?.item?.id
			}
            if (walletPaymentResponse?.status || request?.is_payby_wallet === 0) {
                const updateStatus = await postData(
                    "paymentRequestService",
                    "/v2/PurchaseRequestController/createPurchaseRequestPayment",
                    {
                        ...request
                    },
                    authToken
                )
                if (updateStatus?.status) {
                    mobileAnalyticsAttributes['status'] = 'success'
                    window?.webkit?.messageHandlers?.backHomePage?.postMessage(
                        JSON.stringify(mobileAnalyticsAttributes),
                        targetOrigin
                    );
                } else {
                    mobileAnalyticsAttributes['status'] = 'fail'
			        window?.webkit?.messageHandlers?.backHomePage?.postMessage(
                        JSON.stringify(mobileAnalyticsAttributes),
                        targetOrigin
                    );
                }
            } else {
			    mobileAnalyticsAttributes['status'] = 'fail'
			    window?.webkit?.messageHandlers?.backHomePage?.postMessage(
                    JSON.stringify(mobileAnalyticsAttributes),
                    targetOrigin
                );
            }
		} catch (error) {
			console.log(error)
			mobileAnalyticsAttributes['status'] = 'fail'
			window?.webkit?.messageHandlers?.backHomePage?.postMessage(
                JSON.stringify(mobileAnalyticsAttributes),
                targetOrigin
            );
		}
	}

    const onPaymentCompletion = async (response, _, _mobileAnalyticsAttributes) => {
        setSelectWallet(false)
        const mobileAnalyticsAttributes = {..._mobileAnalyticsAttributes}?? {}
        if (response && response.status) {
            if (queryParam?.payment_type === "buy-it") {
                let afterPaymentRequest = {
                    ...paymentRequestData,
                    transaction_id: response?.payload?.transaction_id || null
                }
                await afterPayment(afterPaymentRequest, mobileAnalyticsAttributes)
            } else {
                mobileAnalyticsAttributes['status'] = 'success'
			    window?.webkit?.messageHandlers?.backHomePage?.postMessage(
                    JSON.stringify(mobileAnalyticsAttributes),
                    targetOrigin
                );
            }
        } else {
            mobileAnalyticsAttributes['status'] = 'fail'
			window?.webkit?.messageHandlers?.backHomePage?.postMessage(
                JSON.stringify(mobileAnalyticsAttributes),
                targetOrigin
            );
        }
    };

    useEffect(async () => {
        if (queryParam?.lng && queryParam?.lng !== "") {
            localStorage.setItem("i18nextLng", queryParam?.lng)
            i18n?.changeLanguage(queryParam?.lng)
        }
        if (!queryParam?.auth_token || queryParam?.auth_token === "") {
            setMessage("401");
        } else {
            await setAuthToken(queryParam?.auth_token);
            const discount = [
                "membership",
                "membership-update",
                "membership-renewal",
            ].includes(queryParam?.payment_type) && queryParam?.discount
                ? JSON.parse(queryParam?.discount)
                : null;
            const address =
                ["shipping", "consolidation"].includes(queryParam?.payment_type) && queryParam?.address
                    ? JSON.parse(queryParam?.address)
                    : null;

            if (queryParam?.add_on_services) {
                setAddOnServices(JSON.parse(queryParam?.add_on_services));
            }
            let tempPaymentRequestData = {}
            switch (queryParam?.payment_type) {
                case "shipping":
                    tempPaymentRequestData = {
                        parcels: JSON.parse(queryParam?.parcels) || [],
                        address: {
                            country: address?.country,
                            id: Number(address?.id),
                        },
                        total: parseFloat(queryParam?.total_amount),
                        service: queryParam?.shipping_service,
                        shipping_company_id: Number(
                            queryParam?.shipping_company_id
                        ),
                        shipping_charges: parseFloat(queryParam?.amount),
                        promo_code: queryParam?.promo_code || null,
                    }
                    setTotalPayment(tempPaymentRequestData?.total)
                    break;
                case "consolidation":
                    tempPaymentRequestData = {
                        parcel_ids: JSON.parse(queryParam?.parcel_ids) || [],
                        parcel_uuids: JSON.parse(queryParam?.parcel_uuids) || [],
                        amount: parseFloat(queryParam?.amount),
                        promo_code: queryParam?.promo_code || null,
                        shipping_service: queryParam?.shipping_service,
                        shipping_company_id: Number(queryParam?.shipping_company_id),
                        user_address: {
                            country: address?.country,
                            id: Number(address?.id),
                        },
                        is_consolidation: 1
                    }
                    setTotalPayment(tempPaymentRequestData?.amount)
                    break;
                case "buy-it":
                    tempPaymentRequestData = {
                        purchase_request_id: Number(queryParam?.id),
                        is_payby_card: parseFloat(queryParam?.total) > parseFloat(queryParam?.wallet_balance) ?
                            1 : !selectWallet ? 1 : 0,
                        card_id: creditCard?.id || null,
                        wallet_amount: selectWallet ? parseFloat(queryParam?.wallet_balance) > parseFloat(queryParam?.total) ? parseFloat(queryParam?.total) : parseFloat(queryParam?.wallet_balance) : 0,
                        card_amount: !selectWallet ? parseFloat(queryParam?.total) : parseFloat(queryParam?.total) > parseFloat(queryParam?.wallet_balance) ?
                            parseFloat(queryParam?.total) - parseFloat(queryParam?.wallet_balance) : 0,
                        total: parseFloat(queryParam?.total),
                        is_payby_wallet: selectWallet ? 1 : 0,
                        total_amount: parseFloat(queryParam?.total)
                    }
                    setTotalPayment(tempPaymentRequestData?.total_amount)
                    break;
                case "membership":
                    tempPaymentRequestData = {
                        discountedTotal: parseFloat(
                            queryParam?.discountedTotal
                        ),
                        grandTotal: parseFloat(queryParam?.grandTotal),
                        amount: parseFloat(queryParam?.amount),
                        total_amount: parseFloat(queryParam?.amount),
                        total: parseFloat(queryParam?.amount),
                        promo_code: queryParam?.promo_code,
                        discount: discount
                            ? {
                                original_amount: parseFloat(
                                    discount?.original_amount
                                ),
                                discounted_amount: parseFloat(
                                    discount?.discounted_amount
                                ),
                                promo_code_value: parseFloat(
                                    discount?.promo_code_value
                                ),
                                promo_code_type: discount?.promo_code_type,
                                promo_code: discount?.promo_code,
                            }
                            : null,
                        is_yearly: Number(queryParam.is_yearly)
                    }
                    setTotalPayment(tempPaymentRequestData?.total_amount)
                    break;
                case "membership-update":
                case "membership-renewal":
                    tempPaymentRequestData = {
                        discountedTotal: parseFloat(
                            queryParam?.discountedTotal
                        ),
                        grandTotal: parseFloat(queryParam?.grandTotal),
                        amount: parseFloat(queryParam?.amount),
                        total_amount: parseFloat(queryParam?.amount),
                        total: parseFloat(queryParam?.amount),
                        promo_code: discount?.promo_code,
                        discount: discount
                            ? {
                                original_amount: parseFloat(
                                    discount?.original_amount
                                ),
                                discounted_amount: parseFloat(
                                    discount?.discounted_amount
                                ),
                                promo_code_value: parseFloat(
                                    discount?.promo_code_value
                                ),
                                promo_code_type: discount?.promo_code_type,
                                promo_code: discount?.promo_code,
                            }
                            : null,
                        membership_plan_id: queryParam?.membership_plan_id,
                        is_yearly: Number(queryParam.is_yearly)
                    }; 
                    setTotalPayment(tempPaymentRequestData?.total_amount)
            }
            tempPaymentRequestData["user_pin"] = queryParam?.user_pin;
            await setPaymentRequestData({ ...tempPaymentRequestData });
        }
    }, [window?.location?.search, selectWallet]);

    useEffect(() => {
        const reloadCount = sessionStorage.getItem('reloadCount');
        if(reloadCount < 1) {
        sessionStorage.setItem('reloadCount', String(reloadCount + 1));
        window.location.reload();
        } else {
        sessionStorage.removeItem('reloadCount');
        }
    }, [])
    

    return (
        <Container className="align-items-center justify-content-center mt-4">
            {/* {(!paymentRequestData?.parcels?.length) ? <Redirect to={NAVIGATION_CONSTANTS.HOMEPAGE} /> : null} */}
            {message ? (
                <Alert variant={"danger"}>{t(message)}</Alert>
            ) : (
                <>
                    {queryParam?.wallet_balance && Math.round(parseFloat(queryParam?.wallet_balance)) > 0 && <Form.Group>
                        <Col sm={12} className={`saved-card-container border rounded mt-4`}>
                            <Form.Check
                                type="checkbox"
                                size="md"
                                label={<div className="saved-card-details">
                                    <span>{t("Use Wallet")}</span>
                                    <span className="name-on-card">{t("Balance")}{" "}{parseFloat(queryParam?.wallet_balance)?.toFixed(2)} {GLOBAL_CONSTANTS.CURRENCY}</span>
                                </div>}
                                checked={selectWallet}
                                // disabled={isAmazonPay || selectedCard?.card_type === "AMAZON_PAY"}
                                onChange={() =>
                                    setSelectWallet(!selectWallet)
                                }
                            />
                        </Col>
                    </Form.Group>
                    }

                    <AmazonPay
                        paymentRequestData={paymentRequestData}
                        paymentDetails={paymentRequestData}
                        setShipmentLoading={setLoading}
                        onPaymentCompletion={onPaymentCompletion}
                        paymentType={queryParam?.payment_type || "shipping"}
                        shipmentLoading={loading}
                        totalPayment={totalPayment}
                        parcels={paymentRequestData?.parcels || []}
                        addOnServices={addOnServices}
                        fee={{
                            charges: paymentRequestData?.parcel_amount || [],
                            customs: paymentRequestData?.parcel_custom || [],
                        }}
                        setCreditCard={setCreditCard}
                        creditCard={creditCard}
                        setIsAmazonPay={setIsAmazonPay}
                        isAmazonPay={isAmazonPay}
                        isPaypal={isPaypal}
                        setIsPaypal={setIsPaypal}
                        mobileRequest={true}
                        userPinFromMobile={paymentRequestData?.user_pin}
                        mobileAuthToken={queryParam?.auth_token}
                        selectWallet={selectWallet}
                        setIsOthers={setIsOthers}
                        setPaymentRequestData={setPaymentRequestData}
                    />

                    {selectWallet &&
                        <Row className="w-100 mx-0 mt-3">
                            <Col offset={6} />
                            <Col md={6} sm={12} className="border rounded">
                                <div className="my-2 d-flex justify-content-between">
                                    <span className="">{t("Paid from Wallet")}</span>
                                    <span className="float-end">{GLOBAL_CONSTANTS.CURRENCY_VAL} {parseFloat(queryParam?.total) > parseFloat(queryParam?.wallet_balance) ? parseFloat(queryParam?.wallet_balance).toFixed(2) : parseFloat(queryParam?.total).toFixed(2)}</span>
                                </div>
                                <div className="my-2 d-flex justify-content-between">
                                    <span className="">{t("To be paid")}</span>
                                    <span className="float-end">{parseFloat(queryParam?.total) <= parseFloat(queryParam?.wallet_balance) && GLOBAL_CONSTANTS.CURRENCY_VAL + " " + 0}
                                        {parseFloat(queryParam?.total) > parseFloat(queryParam?.wallet_balance) && GLOBAL_CONSTANTS.CURRENCY_VAL + " " + (parseFloat(queryParam?.total).toFixed(2) - parseFloat(queryParam?.wallet_balance).toFixed(2)).toFixed(2)}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    }
                </>
            )}
        </Container>
    );
};

export default MobilePayment;
