import React, {useState, useEffect, useMemo} from "react";
import {Link, Redirect} from "react-router-dom";
import {useTranslation} from "react-i18next";
import FailedIcon from "../../assets/img/icons/fail.gif";

import * as NAVIGATION_CONSTANTS from "../../constants/navigationConstants";
import { getData, postData } from "../../utils/api";
import { useAuth } from "../../contexts/AuthContext";
import useAnalytics from "../../analytics/useAnalytics";
export default (props) => {

    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [redirectLink, setRedirectLink] = useState("");
    const [iconSVG, setIconSVG] = useState("");
    const { authToken, userEmail } = useAuth();
    const analytics = useAnalytics()
    const targetOrigin =  useMemo(()=>process?.env?.REACT_APP_MOBILE_POST_MESSAGE_TARGET_ORIGIN,[])

    useEffect(async () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let sourceUrl = params.get("source");
        let transactionId = params.get("transactionId");
        let token = params.get("authToken") || authToken;
        let status = params.get("status");
        let paypalCdStatus = params.get("paypalCdStatus");

        let response = '';
        let data = {};
        if(!token || token === "") {
            window.location.href = NAVIGATION_CONSTANTS.HOMEPAGE
        }
        setLoading(true);

        if(sourceUrl === 'paypal'){
            let paypalToken = params.get("token");
            let baToken = params.get("ba_token");
            data = {
                "token":  paypalToken,
                "ba_token":  baToken,
                "transaction_id":  transactionId,
                "status": status
            };
            response = await postData(
                "paymentService",
                "/paypal/agreement-complete",
                data,
                token
            )
        } else if (params?.get("resultIndicator") && params?.get("resultIndicator") !== "") {
            let sessionVersion = params.get("sessionVersion");
            let resultIndicator = params.get("resultIndicator");
            data = {
                "session_version":  sessionVersion,
                "result_indicator":  resultIndicator,
                "transaction_id":  transactionId,
                "status": status
            };
            response = await postData(
                "paymentService",
                "/adcb/payment-complete",
                data,
                token
            )
        } else {
            let amazonCheckoutSessionId = params.get("amazonCheckoutSessionId");
            data = {
                "session_id":  amazonCheckoutSessionId,
                "transaction_id":  transactionId,
                "status": status
            };
            response = await postData(
                "paymentService",
                "/amazon/payment-complete",
                data,
                token
            )
        }


        if (document.getElementById("adcb-checkout")){
            document.getElementById("adcb-checkout")?.remove()
        }

        const transactionRes = response?.payload?.transaction_id
            ? await getData(
                  "paymentService",
                  `/transactions/${response?.payload?.transaction_id}`,
                  token
              )
            : undefined;
        let mobileAnalyticsAttributes = {};
        let cardDetails;
        if (transactionRes?.payload?.card_details) {
            try {
                cardDetails = JSON.parse(transactionRes.payload.card_details);
            } catch (e) {
                cardDetails = undefined;
            }
        }
        const analyticsAttributes = cardDetails?.request?.analytics_event;
        const paymentMethod = cardDetails?.request?.payment_method ?? "";
        const walletBalance = cardDetails?.request?.wallet_balance || 0;
        const walletAmount = cardDetails?.request?.wallet_amount || 0;
        const requestType = !!cardDetails?.request?.is_consolidation ? "consolidation" : transactionRes?.payload?.request_type;
        mobileAnalyticsAttributes = {
            payment_method: paymentMethod,
            used_wallet_balance: walletAmount,
            wallet_balance: walletBalance,
        };
        
        if (response.status === true || paypalCdStatus === "success") {
            mobileAnalyticsAttributes["status"] = "success";
            window.webkit?.messageHandlers?.backHomePage?.postMessage(
                JSON.stringify(mobileAnalyticsAttributes),
                targetOrigin
            );
            if (analyticsAttributes && requestType && paymentMethod) {
                analyticsAttributes["paymentMethod"] = paymentMethod;
                analytics.events.membershipPaymentCompleted(analyticsAttributes);
            }
            const sendVerificationEmail = async () => {
                setLoading(true)
                let response = await postData(
                    "userService",
                    "/v2/verify-email",
                    {
                        email: userEmail ? userEmail : localStorage.getItem("userEmail"),
                    }
                );
            };
            await sendVerificationEmail()
            setRedirectLink(NAVIGATION_CONSTANTS.ONE_TIME_VERIFY_EMAIL);
        } else {
            setTitle(t("Your payment was not successful"));
            setIconSVG(FailedIcon)
            mobileAnalyticsAttributes["status"] = "fail";
            window.webkit?.messageHandlers?.backHomePage?.postMessage(
                JSON.stringify(mobileAnalyticsAttributes),
                targetOrigin
            );
            if (analyticsAttributes && requestType && paymentMethod) {
                analyticsAttributes["paymentMethod"] = paymentMethod;
                const {
                    paymentMethod,
                    totalAmount,
                    planType,
                    membershipType,
                    source,
                } = analyticsAttributes;
                analytics.events.membershipPaymentFailure({
                    paymentMethod,
                    totalAmount,
                    planType,
                    membershipType,
                    source,
                    reason: response?.message ?? "unknown",
                });
            }
            setRedirectLink(NAVIGATION_CONSTANTS.ACCOUNT_PAYMENT);
        }

        setLoading(false);

    }, []);

    return (
        <div className="d-flex justify-content-center align-items-center global-section-container full-height-page-small-nav grey-background pt-5 pb-5">
            <div className={"container-fluid container-width w-50-mobile-100  white-background info-page rounded " + (loading ? "loading" : null)}>
                { redirectLink && (!iconSVG || iconSVG === "") && redirectLink !== "" && 
                    <Redirect to={{
                        pathname: redirectLink,
                        state: {
                            paymentStatus: "payment-success"
                        }
                    }} /> }
                {loading
                    ?   
                        <div className="text-center">
                            <div className="spinner-border text-danger" role="status"/>
                            <h3 className="my-3">{t("Please wait")}</h3>
                            <p>
                                {t("Your payment is currently being processed")}
                                <br/>
                                {t("Do not refresh or close your browser")}
                            </p>
                        </div>
                    : <div>
                        <img className="w-25" alt="..." src={iconSVG}/>
                        <h2 className="">
                            {t(title)}
                        </h2>
                        <Link to={{
                            pathname: redirectLink,
                            search: "",
                            state: (props.location && props.location.state ? props.location.state : {})
                        }}>
                            <button className="btn btn-primary rounded">
                                {t("Go to Payment")}
                            </button>
                        </Link>
                    </div>
                }
            </div>
        </div>
    );
};
