import React, { useEffect, useState } from 'react'
import { useAuth } from '../contexts/AuthContext';
import { getUserAddress } from '../services/userInfoService';

const useUserAddresses = (isSourceAddresses=false) => {
	const { authToken } = useAuth()
	const [addressList, setAddressList] = useState([]);
	// const [loading, setLoading] = useState(true);
	
	const getUserAddressList = async (isSourceAddresses = false) => {
		const addressFromApi = await getUserAddress(authToken, isSourceAddresses)
		setAddressList(addressFromApi)
	}

	useEffect(() => {
		getUserAddressList(isSourceAddresses)
	}, [isSourceAddresses])

	return {addressList, setAddressList, getUserAddressList}

}

export default useUserAddresses
