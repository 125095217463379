import React from "react";
import LoginForm from "./loginForm";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import { Redirect } from "react-router-dom";
import { DASHBOARD } from "../../constants/navigationConstants";

function LoginFormPage() {
	const { t } = useTranslation();
	const { loggedIn } = useAuth();
	return (loggedIn ? <Redirect to={DASHBOARD} />
		:
		<div className="global-section-container flex-col grey-background pt-5 pb-5">
			<div className="container w-50-mobile-100 white-background login-modal-width">
				<h1 className="sub-page-section-header text-start mb-4">
					{t("Login-to-shopiniworld")}
				</h1>
				<LoginForm modal={false} />
			</div>
		</div>
	);
}

export default LoginFormPage;
