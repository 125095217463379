import React from "react";
import {FaSearch} from "react-icons/fa";
import {LightgalleryItem, LightgalleryProvider, withLightgallery} from "react-lightgallery";
import {useTranslation} from "react-i18next";

export default ({parcel, width, height, hasSearchIcon, photoLinkText}) => {

    const {t, i18n} = useTranslation();

    const SeePhotosLink = withLightgallery(({openGallery, parcel}) => {
        return (
            <div
                className={`${hasSearchIcon ? null : "py-4"} fs-16 font-color-blue font-weight-500 item-imgs-div tr-cursor ${i18n.language === 'ar' ? "text-right" : "text-left"}`}
                onClick={() => {
                    openGallery("any");
                }}
            >
                {photoLinkText && photoLinkText !== "" ? photoLinkText : t("See")} {parcel?.ParcelFiles ? parcel?.ParcelFiles?.length : parcel?.PurchaseAttachments?.length} {t("Photos")}
            </div>
        );
    });

    const SearchIconPhotosLink = withLightgallery(({openGallery}) => {
        return (
            <span
                className="gallery-poster fs-16 font-color-blue font-weight-500 item-imgs-div tr-cursor"
                onClick={() => {
                    openGallery("any");
                }}
            >
                <FaSearch/>
            </span>
        );
    });

    return (
        <>
            <LightgalleryProvider>
                {(parcel?.ParcelFiles ? parcel?.ParcelFiles : parcel?.PurchaseAttachments)?.map((file, index) => {
					const imageLink = file.file ? file.file : [file?.Attachment?.domain, file?.Attachment?.folder_path, file?.Attachment?.attachment_name].join("")
					return <div className={(index !== 0) ? "hide" : ""} data-src={imageLink}>
                        <LightgalleryItem key={index} group={"any"} src={imageLink}>
                            <img alt="..." width={width} height={height} src={imageLink}/>
                        </LightgalleryItem>
                        {hasSearchIcon ? <SearchIconPhotosLink/> : null}
                    </div>;
                })}
                <SeePhotosLink parcel={parcel}/>
            </LightgalleryProvider>
        </>
    );
};