import styles from "./styles.module.scss";

const Contents = () => {
    return (
        <>
            <div className={`${styles["shopini-logistics"]}`}>
                E-COMMERCE SOLUTIONS
            </div>
            <div className={`${styles["title"]}`}>
                Smart tracking on the GO!
            </div>
            <div className={`${styles["sub-title"]}`}>
                Realtime Dashboard to keep your shipments tracking hassle-free
            </div>
            <div className="d-flex justify-content-center d-sm-block">
                <a
                    target="_blank"
                    href="https://apps.apple.com/us/app/shopiniworld/id1572659962"
                    rel="noreferrer"
                >
                    <img
                        src={require("../../images/app-app-store.svg").default}
                        alt="App store"
                        className={`${styles["app-store"]}`}
                    />
                </a>
                <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.shopiniworld.customer"
                    rel="noreferrer"
                >
                    <img
                        src={
                            require("../../images/app-google-play.svg").default
                        }
                        alt="Google play"
                        className={`${styles["google-play"]}`}
                    />
                </a>
            </div>
        </>
    );
};

export default Contents;
