import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useAuth } from "../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { requestTableDateFormat } from "../../../utils/moment";
import SearchForm from "./searchForm";
import TableList from "./tableList";
import {
    GLOBAL_CONSTANTS,
    REQUESTS_CONSTANTS,
} from "../../../constants/constants";
import { Loader } from "../../common";
import { getData, postData } from "../../../utils/api";
import { useLocalStorage } from "../../../contexts/localStorage";
import WalletModal from "./modal/walletModal";
import { confirmAlert } from "react-confirm-alert";
import useAnalytics from "../../../analytics/useAnalytics";
export default (props) => {
    const { t, i18n } = useTranslation();

    const { authToken, walletBalance, setWalletBalance } = useAuth();

    const [show, setShow] = useState(false);
    const [doneSteps, setDoneSteps] = useState("");
    const [loading, setLoading] = useState(true);
    const [allData, setAllData] = useState();
    const [pendingData, setPendingData] = useState();
    const [completedData, setCompletedData] = useState();
    const analytics = useAnalytics()

    // cache all parcels data to use it when clearing the search filter

    const [storedValue, setValue] = useLocalStorage(
        "allRequests",
        props.parcelData
    );

    const handleBIFMPayNowButtonEvent = (row)=>{
        const eventAttributes = {
            productUrl: row.product_url,
            productName: row.product_name,
            unitPrice: row.unit_price,
            quantity: row.quantity,
            shippingFees: row.shipping_fee,
            color: row.color ?? undefined,
            size: row.size ?? undefined,
            attachPictures: !!row.PurchaseAttachments?.length,
            comments: row.comments ?? undefined,
            totalAmount: row.total_amount,
            requestId: row.id,
            serviceFees: row.service_fee ? Number(row.service_fee) : 0
        };
        analytics.events.bifmPurchaseRequestPayButton(eventAttributes);
    }

    const handleClick = (row) => {
        props.onRequest(row, "edit");
    };

    const viewModalClose = () => {
        setShow(false);
        setDoneSteps(["productDetailsStep"]);
        props.setReload(!props.reload);
    };

    const deductBalanceFromWallet = async (row) => {
        let request = {
            purchase_request_id: row.id,
            is_payby_card: 0,
            card_id: "",
            card_amount: 0,
            is_payby_wallet: 1,
            wallet_amount: parseFloat(row?.pay_by_wallet_amount),
            is_payby_promo_code: "",
            is_payby_promo_code: "",
            promo_code_amount: "",
            is_new_card: 0,
            save_card: 0,
            remarks: REQUESTS_CONSTANTS.DEDUCT_REMARKS_ID,
        };

        try {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="custom-alert-ui">
                            <h1>{t("Confirm Deduct")}</h1>

                            <p>{t("Proceed with deduct from wallet")}</p>

                            <button onClick={onClose} className="cancel-button">
                                {t("Cancel")}
                            </button>

                            <button
                                onClick={async () => {
                                    let response = await postData(
                                        "paymentRequestService",
                                        `/v2/PurchaseRequestController/paymentPurchaseRequest`,
                                        request,
                                        authToken
                                    );
                                    setShow(true);
                                    
                                    if (response?.status) {
                                        request["wallet_transaction_id"] = response?.payload?.item?.id
                                        if (
                                            response?.payload?.data?.User
                                                ?.LastWalletTransaction &&
                                            response?.payload?.data?.User
                                                ?.LastWalletTransaction.length >
                                                0
                                        )
                                            setWalletBalance(
                                                parseFloat(
                                                    response?.payload?.data
                                                        ?.User
                                                        ?.LastWalletTransaction[0]
                                                        ?.available_balance
                                                )
                                            );
                                        const updateStatus = await postData(
                                            "paymentRequestService",
                                            "/v2/PurchaseRequestController/createPurchaseRequestPayment",
                                            request,
                                            authToken
                                        )
                                        if (updateStatus?.status) {
                                            setDoneSteps(["successPurchase"])

                                        }
                                    } else {
                                        setDoneSteps(["failedPurchase"]);
                                    }

                                    onClose();
                                }}
                                className="deduct-button"
                            >
                                {t("Deduct")}
                            </button>
                        </div>
                    );
                },
            });
        } catch (error) {
            setDoneSteps(["failedPurchase"]);

            console.log("error - ", error);
        }
    };

    const tableColumns = [
        {
            dataField: "request_id",

            text: t("Request_ID"),

            classes: "w-130-px",
        },

        {
            dataField: "updated_at",

            text: t("Request_Sent"),

            classes: "w-250-px date-format",

            formatter: (cell, row) => {
                return cell ? requestTableDateFormat(cell, i18n.language) : "-";
            },
        },

        {
            // ask for the request detail

            dataField: "product_name",

            text: t("Request_Detail"),
        },

        {
            dataField: "PurchaseStatus.name",

            text: t("Request_Status"),

            classes: "w-330-px",

            formatter: (cell, row) => {
                let colorClass = "";

                let deductedAmount = row?.pay_by_wallet_amount
                    ? parseFloat(row?.pay_by_wallet_amount)
                    : 0;

                if (cell === "Purchased") {
                    colorClass = "status-purchased";
                } else if (cell === "Rejected") {
                    colorClass = "status-reject";
                } else if (cell === "Payment Pending") {
                    colorClass = "status-pending";
                } else if (cell === "Payment Completed") {
                    colorClass = "status-payment-confirmed";
                } else {
                    colorClass = "status-wallet-request";
                }

                return (
                    <div className="status">
                        <div className="d-inline">
                            <span
                                className={`status-icon ${colorClass} `}
                            ></span>

                            <span className={`status-text ${colorClass} mx-2`}>
                                {i18n.language === "en"
                                    ? cell
                                    : row?.PurchaseStatus?.ar_name}
                            </span>
                        </div>

                        {cell === "Payment Pending" &&
                            props.category !== REQUESTS_CONSTANTS.ALL && (
                                <Button
                                    className="pay-now mx-2"
                                    onClick={() => {
                                        handleClick(row);
                                        handleBIFMPayNowButtonEvent(row);
                                    }}
                                >
                                    {t("Pay_Now")}
                                </Button>
                            )}

                        {cell === "Confirmation Request" &&
                            deductedAmount > 0 &&
                            deductedAmount <= walletBalance && (
                                <Button
                                    className="wallet-request mx-2 d-flex"
                                    onClick={() => deductBalanceFromWallet(row)}
                                >
                                    <div>{t("Deduct")}</div>

                                    <div className=" mx-1 amount-dir">
                                        {GLOBAL_CONSTANTS.CURRENCY_VAL}
                                        {deductedAmount}
                                    </div>
                                </Button>
                            )}
                    </div>
                );
            },
        },
    ];

    useEffect(async () => {
        let tempParcelData;
        let tempCategory = props.category;
        let urlData = `/v1/PurchaseRequestController/getUserRequests?status=${tempCategory}`;
        setLoading(true);
        if (tempCategory === "all")
            urlData = `/v1/PurchaseRequestController/getUserRequests`;
        const response = await getData(
            "paymentRequestService",
            urlData,
            props.authToken
        );
        tempParcelData =
            !response.status || response.error ? [] : response.payload.data;
        if (tempCategory === "all") setAllData([...tempParcelData]);
        if (tempCategory === "1") setPendingData([...tempParcelData]);
        if (tempCategory === "2") setCompletedData([...tempParcelData]);
        props.setParcelData([...tempParcelData]);
        setLoading(false);
    }, [props?.reload, props?.category]);

    const renderNewPurchaseButton = () => (
        <div className="create-new-request-button">
            <Button onClick={(e)=>{
                props.onRequest(e)
                analytics.events.bifmNewPurchaseRequestButton()
            }}>
                {t("New_Purchase_Request")}
            </Button>
        </div>
    );

    return loading ? (
        <Loader />
    ) : (
        <Row className="purchase-request-container">
            <Col className="request-button">{renderNewPurchaseButton()}</Col>

            <Col md={"12"}>
                <Row className="margin-top-bottom10">
                    <SearchForm
                        {...props}
                        setParcelData={props.setParcelData}
                        parcelData={props.parcelData}
                        placeholder={t("Type_Request_ID")}
                        allData={allData}
                        pendingData={pendingData}
                        completedData={completedData}
                    />
                </Row>

                <TableList
                    {...props}
                    columns={tableColumns}
                    buttonText={""}
                    exportButton={true}
                />

                <WalletModal
                    show={show}
                    viewModalClose={viewModalClose}
                    doneSteps={doneSteps}
                />
            </Col>
        </Row>
    );
};
