import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Step1 from "./step-1.png";
import Step2 from "./step-2.png";
import Step3 from "./step-3.png";
import ArrowEn from "./arrowEn.png";
import ArrowAr from "./arrowAr.png";
import ConsolEn1 from "./consol-1-en.svg"
import ConsolEn2 from "./consol-2-en.svg"
import ConsolEn3 from "./consol-3-en.svg"
import ConsolAr1 from "./consol-1-ar.svg"
import ConsolAr2 from "./consol-2-ar.svg"
import ConsolAr3 from "./consol-3-ar.svg"
import { useMediaQuery } from "react-responsive"

const Process = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <div className="custom-container">
            <h1 className="text-center my-3"><strong>{t("How does consolidation work")}</strong></h1>
            <Row className="mt-3 d-flex justify-content-center">
                <Col lg={3} sm={12} className="py-3 text-center">
                    <img src={Step1} className="w-85 mb-4" />
                    <p className="mb-3">{t("When you shop many products from your loved US brands")}</p>
                </Col>
                <Col lg={1} sm={12} className="d-flex align-items-center justify-content-center">
                    <img src={ i18n.language === "ar" ? ArrowAr : ArrowEn } className="img-fluid" />
                </Col>
                <Col lg={3} sm={12} className="py-3 text-center">
                    <img src={Step2} className="w-85 mb-4" />
                    <p className="mb-3">{t("We will open each box and remove unnecessary packing")}</p>
                </Col>
                <Col lg={1} sm={12} className="d-flex align-items-center justify-content-center">
                    <img src={ i18n.language === "ar" ? ArrowAr : ArrowEn } className="img-fluid" />
                </Col>
                <Col lg={3} sm={12} className="py-3 text-center">
                    <img src={Step3} className="w-85 mb-4" />
                    <p className="mb-3">{t("Now your package will be ready for shipping")}</p>
                </Col>
            </Row>

            <Row className="mt-3 d-flex align-items-center justify-content-center">
                <Col lg={4} md={3} sm={12} className="text-center">
                    <h5 className="text-blue my-3">{t("SHIPPING 3 SINGLE BOXES")}</h5>
                    <img src={ i18n.language === "ar" ? ConsolAr1 : ConsolEn1 } className="w-85 mb-5" />
                    <h6 className="text-blue">{t("SHIPPING FEE $138")}</h6>
                </Col>
                <Col lg={4} md={3} sm={12} className="text-center">
                    <h5 className="text-blue my-3">{t("BOX CONSOLIDATION")}</h5>
                    <img src={ i18n.language === "ar" ? ConsolAr2 : ConsolEn2 } className="w-85 mb-5" /> 
                    <h6 className="text-blue">{t("SHIPPING FEE $80")}</h6>
                </Col>
                <Col lg={4} md={3} sm={12} className="text-center">
                    <h5 className="text-blue my-3">{t("ITEM CONSOLIDATE INTO 1 BOX")}</h5>
                    <img src={ i18n.language === "ar" ? ConsolAr3 : ConsolEn3 } className="w-85 mb-5" />
                    <h6 className="text-blue">{t("SHIPPING FEE $56")}</h6>
                </Col>
            </Row>
            <p className="text-blue text-start">{t("Shipping fees might vary depending on shipping destination")}</p>
        </div>
    );
};

export default Process;
