import React from 'react';
import { useTranslation } from 'react-i18next';
import PromoDiscountIcon from "../../assets/img/icons/promo-discount.svg";
import "../promoCode/style.scss";

export const AppliedPromoCode = ({promoCodeName}) => {
    const { t, i18n } = useTranslation()
    return (
        <div className="promo-code-container">
            <div className="promo-banner">
                <img src={PromoDiscountIcon} alt={""}/>
                {` ${promoCodeName} ${t("Coupon Applied")}`}
            </div>
        </div>
    )
}
