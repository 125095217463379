import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import SellerTab from "./tabs/seller";
import RetailerTab from "./tabs/retailer";
import styles from "./styles.module.scss";

const Solutions = () => {
    console.log("styles", styles);
    const [tab, setTab] = useState("seller");
    return (
        <div className={styles["solutions"]}>
            <Tab.Container
                defaultActiveKey="seller"
                activeKey={tab}
                onSelect={(tab) => setTab(tab)}
            >
                <div className="d-flex justify-content-center">
                    <Nav className={`${styles["tab-bar"]}`}>
                        <Nav.Link
                            eventKey="seller"
                            className={`${
                                tab === "seller" && styles["active"]
                            }`}
                        >
                            Seller Solutions
                        </Nav.Link>
                        <Nav.Link
                            eventKey="retailer"
                            className={`${
                                tab === "retailer" && styles["active"]
                            }`}
                        >
                            Retailer Solutions
                        </Nav.Link>
                    </Nav>
                </div>
                <Tab.Content>
                    <Tab.Pane
                        eventKey="seller"
                        className={
                            tab === "seller" &&
                            `${styles["tab-content-active"]}`
                        }
                    >
                        <SellerTab />
                    </Tab.Pane>
                    <Tab.Pane
                        eventKey="retailer"
                        className={
                            tab === "retailer" &&
                            `${styles["tab-content-active"]}`
                        }
                    >
                        <RetailerTab />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    );
};

export default Solutions;
