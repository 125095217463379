import styles from "./styles.module.scss";

const Card = ({ children, iconUrl }) => {
    console.log("icon", iconUrl);
    return (
        <div
            className={`d-flex flex-nowrap align-items-start ${styles["card-container"]}`}
        >
            <div
                className={`flex-shrink-0 flex-grow-0 ${styles["card-icon"]}`}
                style={{ backgroundImage: `url(${iconUrl})` }}
            ></div>
            <div className={`${styles["card-content"]}`}>{children}</div>
        </div>
    );
};

export default Card;
