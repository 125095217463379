import { Switch, withStyles } from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";

export const CustomSwitch = withStyles({
    switchBase: {
      color: grey[300],
      '&$checked': {
        color: green[500],
      },
      '&$disabled': {
        backgroundColor: grey[500],
      },
      '&$checked + $track': {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);