import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MEMBERSHIP_PLANS } from "../../../constants/navigationConstants";
import "./styles.scss";

const Header = () => {
    const { t, i18n } = useTranslation();
    const alignment = i18n.language === "en" ? "" : "rtl";

    return (
        <div className="w-100">
            <div
                className={`header-wrapper ${
                    alignment === "rtl" ? "right-to-left" : "left-to-right"
                }`}
            >
                <div className={`content px-5 px-sm-0 ${alignment}`}>
                    <div className="row">
                        <div className="col-12 mt-4 pb-3 shop-latest blue-color">
                            {t("Shop the latest trends")}
                            <br />
                            {t("from USA")}
                        </div>
                    </div>
                    <div className="row pb-3">
                        <div className="col-12 get-delivered text-white">
                            {t("And get it delivered right")}
                            <br />
                            {t("at your doorstep")}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Link
                                role={"button"}
                                to={MEMBERSHIP_PLANS}
                                className="d-inline-block"
                            >
                                <button className="btn btn-primary font-weight-bold">
                                    {t("Sign up - c")}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
