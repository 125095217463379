import { isNull } from "lodash";

export function parcelFormattedDimensions (parcel, t = null) {
	let dimUnit = !isNull(t) ? t(parcel.dimensions_unit) : parcel.dimensions_unit;

	if (parcel.dimension_length) {
		return `${parcel.dimension_length} × ${parcel.dimension_width} × ${parcel.dimension_height} ${dimUnit}`;
	}

	return "In progress";
}

export function parcelFormattedWeight (parcel, actual = true, t = null) {
	if (parcel.weight_value || parcel.total_chargeable_weight || parcel.chargeable_weight) {
		if (actual) {
			return `${parcel.weight_value} ${isNull(t) ? parcel.weight_unit.toString().toUpperCase() : t(parcel.weight_unit)}`;
		} else {
			return `${parcel.total_chargeable_weight || parcel.chargeable_weight} ${isNull(t) ? parcel.weight_unit.toString().toUpperCase() : t(parcel.weight_unit)}`;
		}
	}
	return "In progress";
}