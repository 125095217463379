import React from "react"
import { useTranslation } from "react-i18next";
import ShortUniqueId from "short-unique-id";
import { trackingListDateFormat } from "../../utils/moment";

const TrackingList = ({ trackingList }) => {
    const uid = new ShortUniqueId({ length: 8 });
    const { t, i18n } = useTranslation()
    return <div className="tracking-list bg-white pb-3 px-3">
        <p className="text-muted pt-3">{t("Travel History")}</p>
        {trackingList &&
            trackingList?.length &&
            trackingList?.map((log, index) => {
                return (
                    <div
                        key={uid()}
                    >
                        <div className={`tracking-item ${[trackingList?.length - 1]?.includes(index) ? "border-0" : ""}`}>
                            <div className={`tracking-icon status-intransit`}>
                                <svg
                                    className="svg-inline--fa fa-circle fa-w-16"
                                    aria-hidden="true"
                                    data-prefix="fas"
                                    data-icon="circle"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    data-fa-i2svg=""
                                >
                                    <path
                                        fill="currentColor"
                                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                    />
                                </svg>
                            </div>
                            <div className="tracking-content p-0">
                                <span>{log.title}</span>
                                <span className="date font-12">{log?.date}</span>
                                <span className="date font-12">{`${log.place} - ` || ""}{`${log.company_name}` || ""}</span>
                            </div>
                        </div>
                    </div>
                );
            })
        }
    </div>
};

export default TrackingList