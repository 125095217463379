import { useMemo } from "react";
import useVoid from "../void/useVoid";

const debuggerFn = (name, args) => {
    console.log(
        `%cDebugging event: ${name}`,
        "background-color: red; color: blue;",
        args
    );
};

const useDebugger = () => {
    const voidClient = useVoid();
    return useMemo(
        () => ({
            events: Object.keys(voidClient.events).reduce(
                (newEvents, event) => ({
                    ...newEvents,
                    [event]: (args) => {
                        debuggerFn(event, args);
                    },
                }),
                {}
            ),
            attributes: Object.keys(voidClient.attributes).reduce(
                (newAttributes, attribute) => ({
                    ...newAttributes,
                    [attribute]: (args) => debuggerFn(attribute, args),
                }),
                {}
            ),
        }),
        []
    );
};

export default useDebugger;
