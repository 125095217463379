import React from "react";
import { Container, Card, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FreeUsAddress from "./freeUsAddress.svg";
import PackageConsolidation from "./packageConsolidation.svg";
import FreeStorage from "./freeStorage.svg";
import buyItForMeIcon from "../../ourServicesPage/buyItForMe.svg";
import { BUY_IT_FOR_ME, CONSOLIDATION, OUR_SERVICES } from "../../../constants/navigationConstants";

function ServicesSection() {
  const { t, i18n } = useTranslation();
  return (
    <Container fluid className="services-section-container text-center">
      <Container fluid className={'container-width'}>
        <p className="section-title">{t("Our services")}</p>
        <Row>
            {/* free us address*/}
            <Col className="col-lg-3 col-12">
            <Card>
              <Card.Img
                className="tiny-section-img"
                variant="top"
                src={FreeUsAddress}
              />
              <Card.Body>
                <Card.Title>{t("Free address")}</Card.Title>
                <Card.Text>{t("Free address description")}</Card.Text>
              </Card.Body>
            </Card>
            </Col>
            {/* Package Consolidation*/}
            <Col className="col-lg-3 col-12">
            <Card className="hover-bg-white tr-cursor landing-links" onClick={() => document.getElementById("home-consolidation-page")?.click()}>
              <Card.Img
                className="tiny-section-img"
                variant="top"
                src={PackageConsolidation}
              />
              <Card.Body>
                <Card.Title>{t("Package consolidation")}</Card.Title>
                <Card.Text className="text-black">{t("Package consolidation description")}</Card.Text>
              </Card.Body>
            </Card>
            </Col>
            {/* free storage*/}
            <Col className="col-lg-3 col-12">
            <Card>
              <Card.Img
                className="tiny-section-img"
                variant="top"
                src={FreeStorage}
              />
              <Card.Body>
                <Card.Title>{t("Free storage")}</Card.Title>
                <Card.Text>{t("Free storage description")}</Card.Text>
              </Card.Body>
            </Card>
            </Col>
            {/* fast worldwide delivery card*/}
            <Col className="col-lg-3 col-12">
            <Card className="hover-bg-white tr-cursor landing-links" onClick={() => document.getElementById("home-buyItForMe-page")?.click()}>
              <Card.Img
                className="tiny-section-img"
                variant="top"
                src={buyItForMeIcon}
              />
              <Card.Body>
                <Card.Title>
                    {t("Buy it for me service")
                    ?.replaceAll(`${i18n?.language === "ar" ? "" : "\""}`, "")
                    ?.replaceAll(`${i18n?.language === "ar" ? ":" : "service :"}`, "")
                    }
                </Card.Title>
                <Card.Text className="text-black">{t("buy it for me desc").split(".")[0] + "."}</Card.Text>
              </Card.Body>
            </Card>
            </Col>
        </Row>
        <Link
          to={OUR_SERVICES}
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          <Button id="get-tax-free-address-button">
            {t("See All Services")}
          </Button>
        </Link>
      </Container>
      <Link 
        onClick={() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        }}
        id="home-buyItForMe-page" 
        to={BUY_IT_FOR_ME} 
        className="d-none" 
      />
      <Link 
        onClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
        id="home-consolidation-page" 
        to={CONSOLIDATION} 
        className="d-none" 
      />
    </Container>
  );
}

export default ServicesSection;
