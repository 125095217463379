import React from "react";
import { useTranslation } from "react-i18next";
import TabBody from "./tabBody";

function Tab6() {
	const { t } = useTranslation();
	const questionsAndAnswers = [
		{ containerId: "sixone", collapseId: "collapseSixOne", question: t("Qsixone"), answer: t("Asixone") },
		{ containerId: "sixtwo", collapseId: "collapseSixTwo", question: t("Qsixtwo"), answer: t("Asixtwo") },
		// { containerId: "sixthree", collapseId: "collapseSixThree", question: t("Qsixthree"), answer: t("Asixthree") },
	]
	return (
		<>
			<div className="accordion" id="claimsRefundsQuestions">
				<div className="card align-items-start">
					<p className="sub-page-section-header mb-show-block text-start">{t("Claims and refunds")}</p>
				</div>
				{questionsAndAnswers?.map(questionAndAnswer =>
					<TabBody questionAndAnswer={questionAndAnswer} />
				)}
			</div>
		</>
	);
}

export default Tab6;
