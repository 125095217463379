import React from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
import TabBody from "./tabBody";

function Tab7() {
	const { t } = useTranslation();
	const questionsAndAnswers = [
		{ containerId: "sevenone", collapseId: "collapseSevenOne", question: t("Qsevenone"), answer: t("Asevenone") },
		{ containerId: "seventwo", collapseId: "collapseSevenTwo", question: t("Qseventwo"), answer: t("Aseventwo") },
		{ containerId: "seventhree", collapseId: "collapseSevenThree", question: t("Qseventhree"), answer: t("Aseventhree") },
		{ containerId: "sevenfour", collapseId: "collapseSevenFour", question: t("Qsevenfour"), answer: t("Asevenfour") },
		{ containerId: "sevenfive", collapseId: "collapseSevenFive", question: t("Qsevenfive"), answer: t("Asevenfive") },
		{ containerId: "sevensix", collapseId: "collapseSevenSix", question: t("Qsevensix"), answer: t("Asevensix") },
		{ containerId: "sevenseven", collapseId: "collapseSevenSeven", question: t("Qsevenseven"), answer: t("Asevenseven") },
		{ containerId: "seveneight", collapseId: "collapseSevenEight", question: t("Qseveneight"), answer: t("Aseveneight") },
		{ containerId: "sevennine", collapseId: "collapseSevenNine", question: t("Qsevennine"), answer: t("Asevennine") },
		{ containerId: "seventen", collapseId: "collapseSevenTen", question: t("Qseventen"), answer: t("Aseventen") },

	]
	return (
		<>
			<div className="accordion" id="packagesQuestions">
				<div className="card align-items-start">
					<p className="sub-page-section-header mb-show-block text-start">{t("Package details")}</p>
				</div>
				{questionsAndAnswers?.map(questionAndAnswer =>
					<TabBody questionAndAnswer={questionAndAnswer} />
				)}
			</div>
		</>
	);
}

export default Tab7;
