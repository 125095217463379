import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from 'react-i18next';
import i18n from "./i18n";
import {AuthProvider} from "./contexts/AuthContext";
import {Loader} from "./components/common";
import {BrowserRouter as Router} from "react-router-dom";
import AnalyticsProvider from "./analytics/AnalyticsProvider";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import FirebaseProvider from "./firebase/FirebaseProvider";

ReactDOM.render(
	/*<React.StrictMode>*/
	<Suspense fallback={<div className="center-spinner"><Loader/></div>}>
		<FirebaseProvider>
			<AnalyticsProvider>
				<AuthProvider>
					<I18nextProvider i18n={i18n}>
						<Router>
							<App />
						</Router>
					</I18nextProvider>
				</AuthProvider>
			</AnalyticsProvider>
		</FirebaseProvider>
	</Suspense>,
	/*</React.StrictMode>,*/
	document.getElementById("root")
);

reportWebVitals();

serviceWorkerRegistration.register();