import React from "react";
import Banner from "./banner";
import Process from "./process";
import CalculateShippingFees from "./CalculateShippingFees";

export default () => {
    return (
        <div className="bg-color-light-grey rate-calculator">
            <Banner />
            <CalculateShippingFees />
            {/*<Process />*/}
        </div>
    );
};
