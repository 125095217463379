import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { getData, postData } from "../../utils/api";
import { MaskedLoader } from "../common";
import CreditCard from "./CreditCard";
import AmazonPayButton from "./AmazonPayButton"
import PayPalButton from "../PayPal/PayPalButton"

import { GLOBAL_CONSTANTS, PROMOS } from "../../constants/constants";
import PaymentCardList from "./cardList";
import * as navigationConstants from "../../constants/navigationConstants";
import _, { isEmpty, omit } from "lodash";
import CreditCardIcon from "../../assets/img/icons/creditCard.svg"
const AmazonPay = (props) => {

	const { t, i18n } = useTranslation();

	const [loaderMessage, setLoaderMessage] = useState("");
	const [loading, setLoading] = useState(false);
	// const [showSkip, setShowSkip] = useState(false)
	const isMobile = useMediaQuery({ query: "(max-width: 991px)" });
	const [paymentButtonText, setPaymentButtonText] = useState("")
	const { authToken, userPin } = useAuth();
	const [isValid, setIsValid] = useState(false);
	const [name, setName] = useState("");
	const [saveCard, setSaveCard] = useState(props?.paymentType?.includes('membership'));
	const [selectedCard, setSelectedCard] = useState(null);
	const [savedCardList, setSavedCardList] = useState([]);
	const [paymentError, setPaymentError] = useState("");
	const [creditCardDetails, setCreditCardDetails] = useState({
		name: "",
		number: "",
	});
	const [walletPayment, setWalletPayment] = useState({
		selectWallet: 0,
		walletBalance: 0,
		walletAmount: 0
	});
	const [walletBalance, setWalletBalance] = useState(0)

	useEffect(() => {
		setLoaderMessage(t("Please wait while we load your payment details"));
	}, []);

	const handleSubmit = async (event, skip = false) => {
		event.preventDefault();
		let request = props?.paymentType === 'shipping' ? {
			parcel_uuids: _.map(props?.parcels, "uuid"),
			parcel_ids: _.map(props?.parcels, "id"),
			service: props.paymentRequestData?.service,
			shipping_company_id: props.paymentRequestData?.shipping_company_id,
			country_id: props.paymentRequestData?.address?.country,
			user_address_id: props.paymentRequestData?.address?.id,
			total_amount: props.paymentRequestData?.total,
			name: creditCardDetails?.name,
			card_reference: selectedCard?.card_reference || null,
			card: {
				...selectedCard,
				name: creditCardDetails?.name,
				digits: creditCardDetails?.number,
			},
			promo_code:
				props?.paymentRequestData?.promo_code_discount?.is_valid
					? props?.paymentRequestData?.promo_code_discount?.discount_code
					: props?.paymentRequestData?.promo_code || null,
			user_pin: userPin && userPin !== "" ? userPin : props?.userPinFromMobile,
			wallet_amount: walletPayment?.walletAmount,

		} : props?.paymentType === 'buy-it' ? {
			...props?.paymentRequestData,
			is_payby_card: selectedCard?.id ? 1 : 0,
			card_id: selectedCard?.id || null,
			card_reference: props?.paymentRequestData?.card_amount === 0 ? null : selectedCard?.card_reference,
			card: {
				...selectedCard,
				name: creditCardDetails.name,
				digits: creditCardDetails.number,
			}

		} : props?.paymentType === 'consolidation' ? {
			parcel_ids: props?.paymentRequestData?.parcel_ids,
			parcel_uuids: props?.paymentRequestData?.parcel_uuids,
			promo_code: props?.paymentRequestData?.promo_code || null,
			total_amount: props?.paymentRequestData?.amount,
			card_reference: selectedCard?.card_reference || null,
			wallet_amount: walletPayment?.walletAmount,
			shipping_service: props?.paymentRequestData?.shipping_service,
			shipping_company_id: props?.paymentRequestData?.shipping_company_id,
			user_address_id: props?.paymentRequestData?.user_address?.id ? props?.paymentRequestData?.user_address?.id : null,
            country_id: props?.paymentRequestData?.user_address?.country || null,
			is_consolidation: 1

		} : ['membership-update', 'membership-renewal'].includes(props?.paymentType) ? {
			...props?.paymentDetails,
			card_reference: selectedCard?.card_reference || null,
			name: creditCardDetails?.name,
			card: {
				...selectedCard,
				name: creditCardDetails.name,
				digits: creditCardDetails.number,
			},
			user_pin: userPin && userPin !== "" ? userPin : props?.userPinFromMobile,
			is_update: true,
			membership_plan_id: props?.paymentRequestData?.membership_plan_id,
		} : {
			...props?.paymentDetails,
			name: creditCardDetails?.name,
			user_pin: userPin && userPin !== "" ? userPin : props?.userPinFromMobile,
		};
		// if (!skip) {

		// }
		if(props?.analyticsEventAttributes) {
			request['analytics_event'] = props.analyticsEventAttributes
		}
		const mobileAnalyticsAttributes = props?.mobileRequest
            ? {
                  used_wallet_balance: request?.wallet_amount || 0,
                  wallet_balance: walletBalance,
              }
            : undefined;

		setLoading(true);
		let token = authToken && authToken !== "" ? authToken : props?.mobileAuthToken
		try {
			var response = {};
			let paymentDataType = (props?.paymentType === "shipping" ? "parcel" : props?.paymentType)
			if (token && props?.mobileRequest && !skip && request?.total_amount !== 0 && !props?.byPassPayment) {
				if (
					!["membership", "add-card", "buy-it"]?.includes(props?.paymentType)
					|| (props?.paymentType === "buy-it" && (request?.is_payby_card === 1))) {
					if (selectedCard?.card_type === "PAYPAL") {
						mobileAnalyticsAttributes['payment_method'] = "PayPal"
						response = await postData(
							"paymentService",
							`/paypal/agreement?payment_type=${paymentDataType}&capture=true`,
							request,
							token
						);
					}
					if (selectedCard?.card_type === "AMAZON_PAY") {
						mobileAnalyticsAttributes['payment_method'] = "Amazon"
						response = await postData(
							"paymentService",
							`/amazon/signature?type=${paymentDataType}&capture=true`,
							request,
							token
						);
					}
				}
			} else {
				if (token && (request?.total_amount === 0 || props?.totalPayment === 0) && !props?.byPassPayment) {
					response = await postData(
						"paymentService",
						`/pay?type=${props?.paymentType === "membership" ? "membership-skip" : paymentDataType}`,
						request,
						token
					);
				}
			}
			if (props?.onPaymentCompletion) {
				if ((props?.paymentType === "buy-it" && (request?.is_payby_card === 0 && request?.is_payby_wallet === 1))) {
					response.status = true
				}
				response.skip = skip
				props?.onPaymentCompletion(
                    response,
					false,
                    mobileAnalyticsAttributes
                );
			}
		} catch (error) {
			console.log("error - ", error);
			if (props?.onPaymentCompletion) {
				props?.onPaymentCompletion(
                    response,
					false,
                    mobileAnalyticsAttributes
                );
			}
		}
		setLoading(false);
	};

	const toggleSaveCard = (e) => {
		setSaveCard(e.target.checked);
	};

	const onNameChange = (e) => {
		let value = e.target.value;
		setName(value);
	};

	const getSavedCard = async () => {
		setLoading(true)
		let token = authToken && authToken !== "" ? authToken : props?.mobileAuthToken
		const response = await getData("paymentService", "/cards", token);
		if (response.status && response.payload && response.payload.length) {
			if (props?.mobileRequest && props?.paymentType === "add-card") {
				setSavedCardList([])
			} else {
				setSavedCardList(response.payload);
			}
		}
		setLoading(false);
	};

	useEffect(async () => {
		await getSavedCard();
	}, []);

	useEffect(async () => {
		if (props?.selectWallet && !props?.paymentRequestData?.is_payby_card) {
			setSelectedCard({})
		}
	}, [props?.selectWallet]);

	useEffect(() => {
		if (selectedCard) {
			setName(selectedCard.name);
			setIsValid(true);
		}
	}, [selectedCard]);

	// useEffect(() => {
	// 	setPaymentButtonText(props?.paymentRequestData?.total === 0 ? "Continue" : "Save and Continue")
	// }, [props?.paymentRequestData?.total])

	useEffect(async () => {
		const retriveWalletBalance = async () => {
			try {
				const response = await getData(
					"paymentRequestService",
					"/v1/PurchaseRequestController/getTransactionsWallet",
					authToken
				)
				let tempWalletData = (!response.status || response.error) ? [] : response.payload.data
				var newBalance = tempWalletData.length > 0 ? (tempWalletData[0].available_balance).toFixed(2) : 0
				setWalletPayment({ ...walletPayment, walletBalance: newBalance })
				setLoading(false);
			} catch (e) {
				console.log(e);
			}
		}
		if (["shipping", "parcel", "consolidation"]?.includes(props?.paymentType)) {
			await retriveWalletBalance()
		}
	}, [props?.paymentType])

	useEffect(async () => {
        const retriveWalletBalance = async () => {
            try {
                const response = await getData(
                    "paymentRequestService",
                    "/v1/PurchaseRequestController/getTransactionsWallet",
                    authToken
                );
                let tempWalletData =
                    !response.status || response.error
                        ? []
                        : response.payload.data;
                var newBalance =
                    tempWalletData.length > 0
                        ? tempWalletData[0].available_balance.toFixed(2)
                        : 0;
                setWalletBalance(parseFloat(newBalance)??0);
            } catch (e) {}
        };
        await retriveWalletBalance();
    }, [authToken]);

	const onWalletSelectChange = (checked=false) => {
		let affectedKey = "total"
		if (props?.paymentType === "consolidation") {
			affectedKey = "amount"
		}
		const walletPaymentAmount = parseFloat(walletPayment.walletBalance) >= parseFloat(props?.paymentRequestData[affectedKey]) ?
			parseFloat(props?.paymentRequestData[affectedKey]) : parseFloat(walletPayment.walletBalance)
		if (checked) {
			if (parseFloat(walletPayment.walletBalance) >= parseFloat(props?.paymentRequestData[affectedKey])) {
				props?.setIsAmazonPay(false)
				props?.setIsPaypal(false)
				props?.setIsOthers(false)
			}
			setWalletPayment({
				...walletPayment,
				selectWallet: 1,
				walletAmount: parseFloat(walletPaymentAmount)
			})
			props?.setPaymentRequestData({
				...props?.paymentRequestData,
				wallet_amount: walletPaymentAmount,
				[affectedKey]: props?.paymentRequestData[affectedKey] - walletPaymentAmount
			})
		} else {
			setWalletPayment({
				...walletPayment,
				selectWallet: 0,
				walletAmount: 0
			})
			let tempPaymentRequestData = {
				...props?.paymentRequestData,
				[affectedKey]: props?.paymentRequestData[affectedKey] + props?.paymentRequestData?.wallet_amount
			}
			props?.setPaymentRequestData(omit(tempPaymentRequestData, "wallet_amount"))
		}

	}

	useEffect(async () => {
	  if (props?.paymentRequestData?.shipping_service && props?.paymentType === "consolidation") {
		await onWalletSelectChange(false)
		await onWalletSelectChange(true)
	  }
	}, [props?.paymentRequestData?.shipping_service])
	

	return (
		<Card className="payment-card">
			{props?.shipmentLoading || loading
				? <MaskedLoader message={loaderMessage} />
				: null
			}

			<span id="paymentErrors"></span>
			<Card.Body className={isMobile ? "px-0" : "pb-0"}>
				{props?.selectWallet && !props?.paymentRequestData?.is_payby_card ?
					null
					:
					<>
						<Card.Title className={"mb-4"}>
							{!props?.paymentType.match("buy-it") ?
								<h2 className="font32-gry1 mb-0 pb-2">
									{props?.paymentType.match("membership") ? t("Billing Details") : t("Shipping Payment")}
								</h2>
								:
								<h6 className="font32-gry1 mb-0 pb-2">
									{t("Request_Payment_Option")}
								</h6>
							}
						</Card.Title>

						{["shipping", "parcel", "consolidation"]?.includes(props?.paymentType)
							&& parseFloat(walletPayment.walletBalance) > 0 &&
							!props?.byPassPayment &&
							<Form.Group className="justify-content-around saved-card-container border rounded">
								<Col xs={10}>
									<Form.Check
										type="checkbox"
										size="md"
										label={<div className="saved-card-details">
											<span>{t("Use Wallet")}</span>
											<span className="name-on-card">{t("Balance")}{" "}{parseFloat(walletPayment.walletBalance)?.toFixed(2)} {GLOBAL_CONSTANTS.CURRENCY}</span>
										</div>}
										checked={walletPayment.selectWallet}
										// disabled={isAmazonPay || selectedCard?.card_type === "AMAZON_PAY"}
										onChange={(e) => {
											onWalletSelectChange(e.target.checked)
										}}
									/>
								</Col>
								<Col xs={2} className="text-end">
									<img src={CreditCardIcon} />
								</Col>
							</Form.Group>
						}

						<PaymentCardList
							name={name}
							onNameChange={onNameChange}
							toggleSaveCard={toggleSaveCard}
							savedCardList={savedCardList}
							selectedCard={selectedCard}
							setSelectedCard={setSelectedCard}
							paymentType={props?.paymentType}
							paymentRequestData={props?.paymentRequestData}
							paymentDetails={props?.paymentDetails}
							setCreditCard={props?.setCreditCard}
							creditCardDetails={creditCardDetails}
							setCreditCardDetails={setCreditCardDetails}
							setIsAmazonPay={props?.setIsAmazonPay}
							setIsPaypal={props?.setIsPaypal}
							walletPayment={props?.walletPayment}
							parcels={props?.parcels}
							addOnServices={props?.addOnServices}
							mobileRequest={props?.mobileRequest}
							mobileAuthToken={props?.mobileAuthToken || ""}
							setIsOthers={props?.setIsOthers}
							setLoading={setLoading}
							showSkip={props?.showSkip || false}
							byPassPayment={props?.byPassPayment}
							wallet={walletPayment}
							handleSubmit={props?.paymentType.includes("membership") && props?.handleSubmit}
							setProceedToPay={props?.paymentType.includes("membership") && props?.setProceedToPay}
							setPaymentRequestData={props?.setPaymentRequestData}
							analyticsEventAttributes={props?.analyticsEventAttributes}
							walletBalance={walletBalance}
						/>
					</>
				}
				<Row className={`paddingtop15 ${props?.showSkip && !isMobile ? `padding-3 ${i18n.language === "ar" ? "justify-content-end" : ""}` : ""}`} id="payment_credit">
					{props?.mobileRequest && isMobile &&
						<>
							<Col md={12} className="px-4">
								{props?.paymentRequestData?.consolidation_type && props?.paymentRequestData?.consolidation_type !== ""
									? null
									:
									<div className="mobile-pay-now pb-3 px-2">
										<Row className="font-weight-600 mobile-subtotal-padding ">
											<div className="mobile-width-50-percent float-left float-right-ar">
												{t("Sub Total")}
											</div>
											{props?.totalPayment && <div
												className={`mobile-width-50-percent ${i18n.language === "ar" ? "text-left" : "text-right"}`}>
												{/* {props?.paymentRequestData?.amount < 0 ? 0 : props?.paymentRequestData?.amount?.toFixed(2)}$ */}
												{parseFloat(props?.totalPayment)?.toFixed(2) || 0}
											</div>}
										</Row>
									</div>
								}
							</Col> </>}
					{/* {props?.showSkip &&
						<Col xs={{ span: isMobile ? 12 : 4, offset: isMobile || i18n.language === "ar" ? 0 : 4 }} className={isMobile ? "btn color-inverse-default-button text-center mb-4 py-2" : " pt-2"}>
							<Link onClick={(e) => handleSubmit(e, true)} className="font-weight-bold" to={{
								pathname: props?.paymentType === "membership" ? navigationConstants.ONE_TIME_VERIFY_EMAIL : navigationConstants.DASHBOARD,
								state: {
									paymentSkipped: true
								}
							}}>{t("Skip for now")}</Link>
						</Col>
					} */}
					{(selectedCard?.id && (["buy-it"].includes(props?.paymentType)) ||
						(props?.mobileRequest && (selectedCard?.id || props?.paymentRequestData?.total === 0 ||
							props?.paymentRequestData?.amount === 0 ||
							props?.selectWallet)))
						?
						<Col xs={{ span: isMobile ? 12 : props?.showSkip ? 4 : 12 }} className="mobile-checkout">
							<button
								className={`rounded form-control border-radius-10 padding-vertical-10 font-weight-500 btn btn-primary`}

								id="save_payment"
								onClick={(e) => {
									if (props?.mobileRequest || (!selectedCard?.id && props?.totalPayment === 0)) {
										handleSubmit(e, false)
									} else {
										props?.handleSubmit(e, false)
									}
								}}
							>
								<span
									className="credit-spinner spinner-border spinner-border-sm d-none white-color margin-btm-4" />
								<span className="credit-text">{t("Continue")}</span>
							</button>
						</Col>
						: null
					}
				</Row>
			</Card.Body>
		</Card>
	)
}

export default AmazonPay