import React from "react";
import {Col, Row} from "react-bootstrap";
import {MdClose} from "react-icons/md";
import {useTranslation} from "react-i18next";

import {humanize} from "../../utils/string";

export default ({
                    setShippingService,
                    rates,
                    separatedParcelsRates,
                    shippingService
                }) => {
    const {t, i18n} = useTranslation();
    const availableForServices = rates.map(rate => rate.service);
    const shippingServicesAndPrices = separatedParcelsRates?.length > 0 ? [...separatedParcelsRates.filter(rate => availableForServices.includes(rate.service))] : [...rates]

    return (
        <div className="modal fade shipping-services-modal"
             id="shipping-service-modal" tabIndex="-1"
             aria-labelledby="shipping-service-modal" aria-hidden="true">
            <div
                className="modal-dialog custom-modal-width-750 mobile-margin-0">
                <div
                    className="modal-content padding8 modal-margin-top-200 mobile-modal-width">
                    <div className="modal-body">
                        <button type="button"
                                className={`close ${i18n.language === "ar" ? "float-left" : "float-right"}`}
                                data-dismiss="modal" aria-label="Close"
                                id="dismiss-shipping-service-modal">
                            <MdClose/>
                        </button>
                        <h6 className={`text-color-1 font-size-20 font-weight-600 ${i18n.language === "ar" ? "text-right" : "text-left"}`}
                            id="change_shipping_company_modal">
                            {t("Select Your Shipping Service")}
                        </h6>
                        <br/>
                        <div id="shipping-service-listing-section">
                            <Row>
                                <Col md={12} className="form-group">
                                    {shippingServicesAndPrices?.map((service, index) => {
                                        return <>
                                            <Col md={12} id="active-bg-37"
                                                 className="col-md-12 border-bottom cursor list-block shipping-companies-services shipping-companies-services"
                                                 onClick={() => {
                                                     setShippingService({
                                                         ...(rates?.find(rate => rate.service === service.service)),
                                                         address: shippingService.address,
                                                         creditedUser: shippingService?.creditedUser || 0
                                                     });
                                                 }}
                                                 data-dismiss="modal"
                                                 key={service.shipping_company_id}
                                            >
                                                <div
                                                    className="d-flex flex-row font-size-16 line-height-40">
                                                    <Col md={1}
                                                         className="mobile-width-10-percent">
                                                        <div
                                                            className="demo-radio-button">
                                                            <input
                                                                className="shipping_companies with-gap radio-col-deep-purple"
                                                                type="radio"
                                                                data-service={service.provider}
                                                                name="shipping_company"
                                                                id={service.service}
                                                                checked={shippingService.service === service.service}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <div
                                                        className="shipping-popup-img mobile-width-10-percent">
                                                        <img alt="..."
                                                             className="img-size-32"
                                                             src={service.company_logo}/>
                                                    </div>
                                                    <Col md={6}
                                                         className="text-left text-right-ar mobile-width-38-percent">
                                                        {humanize(service.service)}
                                                    </Col>
                                                    <Col md={3}
                                                         className="text-right mobile-width-34-percent text-left-ar">
                                                        <span className="d-block">${service?.shipping_charges?.toFixed(2)}</span>
                                                        <span className="d-block font-12">{service?.eta_days?.split(" ")[0]}&nbsp;{t(service?.eta_days?.split(" ")[1])}</span>
                                                    </Col>
                                                </div>
                                            </Col>
                                        </>;
                                    })}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
