import Contents from "./contents";
import SmartTrackingImage from "./image";
import styles from "./styles.module.scss";

const SmartTracking = () => {
    return (
        <div className={`${styles["smart-tracking"]}`}>
            <div className="d-flex justify-content-between align-items-start flex-wrap">
                <div className={styles["contents"]}>
                    <Contents />
                </div>
                <div className={styles["image"]}>
                    <SmartTrackingImage />
                </div>
            </div>
        </div>
    );
};

export default SmartTracking;
