import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Box1 = styled.div`
    content: " ";
    height: 0;
    padding-top: 92.65%;
    background-image: url(${require("./box1.svg").default});
    background-size: 100% 100%;
    width: 213px;

    html[dir="rtl"] & {
        background-image: url(${require("./box1-ar.svg").default});
    }

    @media (min-width: 1200px) {
        width: 136px;
    }
`;

const Box2 = styled.div`
    content: " ";
    height: 0;
    padding-top: 133.33%;
    background-image: url(${require("./box2.svg").default});
    background-size: 100% 100%;
    width: 184px;

    html[dir="rtl"] & {
        background-image: url(${require("./box2-ar.svg").default});
    }

    @media (min-width: 1200px) {
        width: 108px;
    }
`;

const Arrow = styled.div`
    content: " ";
    height: 0;
    padding-top: 56.5%;
    background-image: url(${require("./arrow.gif").default});
    background-size: 100% 100%;
    width: 27px;
    transform: rotate(90deg);

    @media (min-width: 1200px) {
        width: 32px;
        transform: rotate(0);
        html[dir="rtl"] & {
            transform: scaleX(-100%);
        }
    }
`;

const Calculate = styled.div`
    content: " ";
    height: 0;
    padding-top: 53.95%;
    background-image: url(${require("./calculate.svg").default});
    background-size: 100% 100%;
    width: 213px;

    html[dir="rtl"] & {
        background-image: url(${require("./calculate-ar.svg").default});
    }

    @media (min-width: 1200px) {
        width: 158px;
    }
`;

const Compare = styled.div`
    content: " ";
    height: 0;
    padding-top: 53.95%;
    background-image: url(${require("./compare.svg").default});
    background-size: 100% 100%;
    width: 213px;

    html[dir="rtl"] & {
        background-image: url(${require("./compare-ar.svg").default});
    }

    @media (min-width: 1200px) {
        width: 158px;
    }
`;

const OthersWrapper = styled.div`
    position: relative;
    overflow: visible;
    padding: 12px 0;
    margin-bottom: 30px;
    border: 2px solid rgba(236, 201, 75, 1);
    border-radius: 11px;
    transform: translateY(30px);

    @media (min-width: 1200px) {
        padding-right: 6.11%;
        padding-left: 6.11%;
    }
`;

const SIcon = styled.img`
    position: absolute;
    top: 1px;
    right: calc(50% - 6px);
    transform: translate(50%, -100%);
`;

const Others = () => {
    const [t] = useTranslation();

    return (
        <OthersWrapper>
            <div className="text-center fw-500 fs-18">
                {t("TRUE_WEIGHT_with-others")}
            </div>
            <div className="d-flex justify-content-xl-center align-items-center flex-column flex-xl-row">
                <div className="mb-3 mb-xl-0 mx-xl-4">
                    <Box1 />
                </div>
                <div className="mb-3 mb-xl-0">
                    <Arrow />
                </div>
                <div className="mb-4 mb-xl-0 mx-xl-3">
                    <Calculate />
                </div>
                <div className="mb-4 mb-xl-0 mx-xl-3">
                    <Compare />
                </div>
                <div className="mb-3 mb-xl-0">
                    <Arrow />
                </div>
                <div className="align-self-center mx-xl-4">
                    <Box2 />
                </div>
            </div>
            <SIcon
                src={require("./s.svg").default}
                height={25}
                width={23}
                alt=""
            />
        </OthersWrapper>
    );
};

export default Others;
