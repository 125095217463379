import React, { useEffect } from "react";
import MainSection from "./mainSection";
import ups from "../../../assets/img/shippingCompanies/ups.svg";
import aramex from "../../../assets/img/shippingCompanies/aramex.svg";
import dhl from "../../../assets/img/shippingCompanies/dhl.svg";
import fedex from "../../../assets/img/shippingCompanies/fedex.svg";
import usps from "../../../assets/img/shippingCompanies/usps.svg";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/AuthContext";
import { DASHBOARD } from "../../../constants/navigationConstants"
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper/core";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

import "./style.scss";

SwiperCore.use([Pagination]);

const ShippingCompanies = () => {
	const { t } = useTranslation();
	return (
		<div className="main-section-container grey-background text-center align-items-center d-flex flex-column">
			<h2 className="sub-page-section-header mb-5">{t("Ship with your Favorite Shipping Company")}</h2>
			<div className="container-fluid container-width row justify-content-around align-items-center mr-0 ml-0 pr-0 pl-0 mb-4">
				<img alt="..." src={ups} className="ml-3 mr-3 mt-2" />
				<img alt="..." src={aramex} className="ml-3 mr-3 mt-2" />
				<img alt="..." src={dhl} className="ml-3 mr-3 mt-2" />
				<img alt="..." src={fedex} className="ml-3 mr-3 mt-2" />
				<img alt="..." src={usps} className="ml-3 mr-3 mt-2" />
			</div>
		</div>
	)
}

const Brands = () => {
	const { t } = useTranslation();
	const reqBrands = require.context('./', true, /.jpg$/);
	const loadImage = imageFile => (reqBrands(`${imageFile}`).default);
	const links = {
		"0": "https://www.ebay.com/",
		"1": "https://www.costco.com/",
		"2": "https://www.walmart.com/",
		"3": "https://www.target.com/",
		"4": "https://www.amazon.com/",
		"5": "https://www.apple.com/",
		"6": "https://www.nike.com/",
		"7": "https://www.ralphlauren.com/sporting-life?utm_source=PaidSearch&pkwid=NonBrand_Google_RLE_NB_US_X_X_DSA-Olympics_X_DYNAMIC+SEARCH+ADS&gclid=Cj0KCQjwqp-LBhDQARIsAO0a6aJ5rBQ6H78rrahsNO773sNgEMfe9ahXrykPGK8RaBT5G7M80fNdQEwaAjTlEALw_wcB&gclsrc=aw.ds",
		"8": "https://www.bestbuy.com/?intl=nosplash",
		"9": "https://www.ae.com/us/en"
	}
	return (
		<div className="ain-section-container text-center align-items-center d-flex flex-column my-6">
			<h2 className="sub-page-section-header px-2 mb-5">{t("Here are suggested stores to get you started")}</h2>
			<div className="row container-fluid container-width row justify-content-around align-items-center mr-0 ml-0 pr-0 pl-0 mb-4">
				{reqBrands?.keys()?.map((image, index) =>
					<div key={image} className="d-none d-md-block col-lg-2 col-md-2 mx-1">
						<a target="_blank" href={links[index.toString()]}><img alt="..." src={loadImage(image)} className="img-fluid ml-3 mr-3 mt-2" /></a>
					</div>
				)}
			</div>
			<div className="d-block d-md-none">
				<div className="col-12">
					<Swiper
						spaceBetween={1}
						slidesPerView={1}
						pagination={{
							"dynamicBullets": true
						}}
					>
						{reqBrands?.keys()?.map((image, index) => (
							<SwiperSlide className="mx-auto my-auto w-100" key={`-${image}`}>
								<a target="_blank" href={links[index.toString()]} ><img alt="..." src={loadImage(image)} className="mt-2 mb-5"  /></a>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</div>
	)
}

export const PromotionPage = ({ history }) => {
	const { i18n } = useTranslation();
	const { authToken } = useAuth();
	useEffect(() => {
		if (authToken) {
			history.push(DASHBOARD)
		}
		i18n.changeLanguage("en")
		localStorage.setItem("i18nextLng", "en")
	}, [i18n.language])
	return (
		<div>
			<MainSection />
			{/* <ShippingCompanies /> */}
			<Brands />
		</div>
	);
}