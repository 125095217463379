import * as ApiHandler from "./apiRequestHandlers";

export async function postLogin(data = {}) {
    if (!data) {
        console.log("unable to login, fill out the login form");
        return null;
    }

    const dataRetrieved = await ApiHandler.postApi("/v2/login", null, data);
    return dataRetrieved;
}

/**
 *
 * @param loginCredentials
 * @returns {Promise<null|*|boolean>}
 */
export async function getUserLogin(loginCredentials = {}) {
    try {
        if (!loginCredentials) {
            throw new Error("verify login credentials");
        }
        return await postLogin(loginCredentials);
    } catch (e) {
        return false;
    }
}

export async function postRegister(data = {}) {
    if (!data) {
        console.log("unable to register, fill out the registration forms");
        return null;
    }

    const dataRetrieved = await ApiHandler.postApi("/v2/user-register", null, data);
    return dataRetrieved;
}
