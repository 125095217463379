export function validateUrl (url) {
	var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
}

export function checkCharacterCount (url, count) {
	return url.length > count;
}

var invalidChars = ["-", "e", "+", "E"];
export function checkInvalidNumber (e) {
	if((invalidChars.includes(e.key) || !imposeMinMax(e)) && e.keyCode !== 8){
        e.preventDefault();
    }
   return true
}

export function imposeMinMax(el) {
    if(el.target.value != ""){
      if(parseInt(el.target.value) < parseInt(el.target.min)){
        el.target.value = el.target.min;
      }
      if(parseInt(el.target.value) > parseInt(el.target.max)){
        return false;
      }
    }
    return true;
  }
