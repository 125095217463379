import React from "react";
import threeSvg from "./three.svg";
import { useTranslation } from "react-i18next";

function StepThree() {
  const { t, i18n } = useTranslation();
  return (
    <div className="main-section-container grey-background">
      <div className="container-fluid container-width">
        <div className="row justify-content-between justify-content-around-under-768">
          <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 align-self-center text-center">
            <img alt="..." className="width-100 big-section-img" src={threeSvg} />
          </div>
          <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 post-450-start-text-align align-self-center">
            <h2 className="sub-page-section-header mb-3">
              {t("Package repacking")}
            </h2>
            <p className="text-grey">{t("Package repacking why choose us page description")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepThree;
