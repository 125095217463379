import { useTranslation, Trans } from "react-i18next";
import styles from "./styles.module.scss";
import styled from "styled-components";

const NowShip = () => {
    const [, i18n] = useTranslation();
    const isAr = i18n.language === 'ar'

    return (
        <div className="d-inline-block fs-31px" style={isAr? {lineHeight: '40px'} : {}}>
            <Trans i18nKey="TRUE_WEIGHT_now-ship-more">
                <br />
                <br />
                <span className="text-blue-color font-weight-bold" />
                <span className="color-green font-weight-bold" />
            </Trans>
        </div>
    );
};

const PayAsActualWeight = () => {
    const [t, i18n] = useTranslation();
    const isAr = i18n.language === 'ar'
    
    return (
        <div className={`d-inline-block ${isAr ? 'fs-28px' : 'fs-34px'}`}>
            <span>{t("TRUE_WEIGHT_pay-as-actual-weight")}</span>
            <br />
            <span>{t("TRUE_WEIGHT_volume-weight-not-matter")}</span>
        </div>
    );
};

const ShipFromTo = () => {
    const [t, i18n] = useTranslation();

    return (
        <div className="d-inline-block fs-13px">
            <span className="d-inline-block px-1 mb-1 red-badge">
                {t("TRUE_WEIGHT_ship-from")} {t("TRUE_WEIGHT_america")}
            </span>
            <br />
            <span className="d-inline-block px-1 orange-badge">
                {t("TRUE_WEIGHT_ship-to")}
            </span>
            <br />
            <span>{t("Saudi Arabia").toUpperCase()}</span>
            <br />
            <span>{t("United Arab Emirates").toUpperCase()}</span>
            <br />
            <span>{t("Kuwait").toUpperCase()}</span>
            <br />
            <span>{t("Bahrain").toUpperCase()}</span>
            <br />
            <span>{t("Qatar").toUpperCase()}</span>
            <br />
            <span>{t("Oman").toUpperCase()}</span>
            <br />
            <span>{t("Iraq").toUpperCase()}</span>
            <br />
            <span>{t("TRUE_WEIGHT_rest-of-the-world")}</span>
        </div>
    );
};

const BoxImg = styled.div`
    height: 0px;
    padding-top: 85.57%;
    margin: 0 auto;
    background-image: url(${require("./box.png").default});
    background-size: 100% 100%;
`;

const Header = () => {
    const [,i18n] = useTranslation()
    const isAr = i18n.language === 'ar'

    return (
        <div className={`row ${styles.header} text-white`}>
            <div
                className={`col-xl ${styles["box"]} mx-auto mb-4 m-xl-auto order-4 order-xl-1 text-center ${isAr ? 'text-xl-right' : 'text-xl-left'}`}
            >
                <BoxImg />
            </div>
            <div
                className={`col-12 col-xl ${styles["now-ship"]} mx-auto mb-4 m-xl-auto order-2 order-xl-3 text-center ${isAr ? 'text-xl-right font-weight-bolder' : 'text-xl-left'}`}
            >
                <NowShip />
            </div>
            <div
                className={`col-12 col-xl ${styles["ship-from-to"]} mx-auto mb-4 m-xl-auto order-3 order-xl-2 text-center ${isAr ? 'text-xl-right fw-500' : 'text-xl-left font-weight-bold'}`}
            >
                <ShipFromTo />
            </div>
            <div
                className={`col-12 col-xl ${styles["pay-as-actual-weight"]} mx-auto mb-4 m-xl-auto order-1 order-xl-1 text-center ${isAr ? 'text-xl-right font-weight-bolder' : 'text-xl-left'}`}
            >
                <PayAsActualWeight />
            </div>
        </div>
    );
};

export default Header;
