import React, { useEffect, useState } from 'react'
import { useAuth } from '../contexts/AuthContext';
import { getData } from './api';

const useSavedCardsList = () => {
	const { authToken } = useAuth()
	const [cardList, setCardList] = useState([]);
	const [loading, setLoading] = useState(true);
	
	const getSavedCard = async () => {
		const response = await getData("paymentService", "/cards", authToken);
		if (response.status && response.payload && response.payload.length) {
			setCardList(response.payload);
		}
		setLoading(false);
	};

	useEffect(() => {
		getSavedCard()
	}, [])

	return {loading, cardList, setCardList, getSavedCard}

}

export default useSavedCardsList
