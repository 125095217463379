import { head, last } from "lodash"
import React, { useState } from "react"
import { Alert, Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { MEMBERSHIP_CONSTANTS, SERVICES_NO_PROMOCODE, SERVICES_WITH_VAT } from "../../constants/constants"
import { useAuth } from "../../contexts/AuthContext"
import ShipmentPromoCode from "../promoCode/ShipmentPromoCode"
import TrueWeight from './TrueWeight'

const SummaryDetails = ({
  paymentSummary,
  totalParcelValue,
  setPromoCode,
  promoCode,
  parcels = [],
  totalChargableWeight,
  payables = [],
  showConsolidatedParcelsText = false,
  consolidationType = ""
}) => {
  const { t, i18n } = useTranslation()
  const { membershipPlanID } = useAuth()
  return (
    <>
      <div className="parcel-details px-4 font-16">
        {[...parcels, ...payables]?.map((parcel, index, array) => {
          return (
            <>
              {index === 0 && showConsolidatedParcelsText && (
                <p className="my-2">{t("Parcels Consolidation")}</p>
              )}
              {showConsolidatedParcelsText && payables?.includes(parcel) && head(payables) === parcel && (
                <p className="mt-4 mb-2">{t("Separate Shipping Parcel")}</p>
              )}
              {parcel && (
                <Col
                  md={12}
                  className={`${array.length - 1 === index ? "mb-3" : ''} ${payables?.includes(parcel)
                    ? `light-red-box ${head(payables) === parcel && "rounded-top"
                    } ${last(payables) === parcel && "rounded-bottom"}`
                    : "services-blk"
                    }`}
                >
                  <Row className="font-size-16">
                    <Col
                      md={4}
                      sm={4}
                      className={`font12-gry3 mobile-width-40-percent ${!payables?.includes(parcel) ? "p-0" : "px-2"
                        }`}
                    >
                      {t("Parcel ID")}
                    </Col>
                    <Col
                      md={8}
                      sm={8}
                      className={`text-end mobile-width-60-percent ${!payables?.includes(parcel) ? "p-0" : "px-2"
                        }`}
                    >
                      <span className="font14-gry1">{parcel.uuid}</span>
                    </Col>
                  </Row>
                  <Row className="font-size-16">
                    <Col
                      md={8}
                      sm={8}
                      className={`font12-gry3 mobile-width-40-percent ${!payables?.includes(parcel) ? "p-0" : "px-2"
                        }`}
                    >
                      {t("Volume W")}
                    </Col>
                    <Col
                      md={4}
                      sm={4}
                      className={`text-end mobile-width-60-percent ${!payables?.includes(parcel) ? "p-0" : "px-2"
                        }`}
                    >
                      <span className="font14-gry1">
                        {parcel?.package_data?.volumetric_weight}
                        &nbsp;
                        {parcel?.package_data?.weight_unit}
                      </span>
                    </Col>
                  </Row>
                  <Row className="font-size-16">
                    <Col
                      md={8}
                      sm={8}
                      className={`font12-gry3 mobile-width-40-percent ${!payables?.includes(parcel) ? "p-0" : "px-2"
                        }`}
                    >
                      {t("Actual W")}
                    </Col>
                    <Col
                      md={4}
                      sm={4}
                      className={`text-end mobile-width-60-percent ${!payables?.includes(parcel) ? "p-0" : "px-2"
                        }`}
                    >
                      <span className="font14-gry1">
                        {parcel?.package_data?.actual_weight}
                        &nbsp;
                        {parcel?.package_data?.weight_unit}
                      </span>
                    </Col>
                  </Row>
                  <Row className="font-size-16">
                    <Col
                      md={8}
                      sm={8}
                      className={`font12-gry3 mobile-width-40-percent ${!payables?.includes(parcel) ? "p-0" : "px-2"
                        }`}
                    >
                      {t("Chargeable W")}
                    </Col>
                    <Col
                      md={4}
                      sm={4}
                      className={`text-end mobile-width-60-percent ${!payables?.includes(parcel) ? "p-0" : "px-2"
                        }`}
                    >
                      <span className="font14-gry1">
                        {parcel.chargeable_weight}
                        &nbsp;
                        {parcel.chargeable_weight_unit}
                      </span>
                    </Col>
                  </Row>
                </Col>
              )}
            </>
          )
        })}
        <Row className="mb-3">
          <Col>
            <TrueWeight />
          </Col>
        </Row>
        <Row className="line-height-40 margin-top-20 chargeable-weight { isset($summary['consolidate']) ?  : 'd-none'}">
          <Col md={7} sm={6} className="mobile-width-65-percent">
            {t("Total Chargeable Weight")}
          </Col>
          <Col
            md={5}
            sm={6}
            className="mobile-width-35-percent text-end  direction-ltr"
          >
            <span>
              {totalChargableWeight +
                " " +
                (head(payables && payables.length > 0 ? payables : parcels) &&
                  head(payables && payables.length > 0 ? payables : parcels)
                    .chargeable_weight_unit)}
            </span>
          </Col>
        </Row>
      </div>
      {consolidationType === "" &&
        <Row className="line-height-40 px-4 font-16">
          <Col md={7} sm={6} className="mobile-width-50-percent">
            {t("Shipping Fees")}
          </Col>
          <Col
            md={5}
            sm={6}
            className="text-end float-left mobile-width-50-percent  text-left-ar"
          >
            <span id="shipping_price">
              $ {paymentSummary?.shipping_charges?.toFixed(2) || 0}
            </span>
          </Col>
        </Row>
      }
      {paymentSummary?.applied_discount_type !== "membership"
        && !SERVICES_NO_PROMOCODE?.includes(paymentSummary?.shipping_company_id)
        && !paymentSummary?.user_discount_applied
        ? (
          <>
            <Row className="line-height-40 px-4 font-weight-500">
              <Col
                md={7}
                sm={6}
                className="mobile-width-50-percent"
              >
                {t("Coupon Discount")}
              </Col>
              <Col
                md={5}
                sm={6}
                className="text-end float-left mobile-width-50-percent text-left-ar"
              >
                <span id="shipping_custom" className="color-green">
                  {paymentSummary.promo_code_discount?.discount_type === "percent"
                    ? `${paymentSummary.promo_code_discount?.discount_value}%`
                    : `$ ${paymentSummary.promo_code_discount?.discount_value?.toFixed(2)}`}
                </span>
                {paymentSummary.promo_code_discount?.discount_type === "percent" && consolidationType === "" &&
                  `(-$${paymentSummary.promo_code_discount?.discounted_amount?.toFixed(2)})`}
              </Col>
            </Row>
          </>
        ) : null}
      {SERVICES_NO_PROMOCODE?.includes(paymentSummary?.shipping_company_id) || paymentSummary?.user_discount_applied ?
        null
        :
        <Row className="px-4 pb-2 pt-2">
          <Col xs={12}>
            <ShipmentPromoCode
              setPromoCode={setPromoCode}
              promoCode={promoCode || paymentSummary?.promo_code_discount?.discount_code}
              paymentSummary={paymentSummary}
            />
          </Col>
        </Row>
      }

      {paymentSummary?.applied_discount_type === "membership"
        && Number(membershipPlanID) > 1
        && paymentSummary?.membership_discount?.discounted_amount > 0
        && <Row className="line-height-40 mobile-hide-subtotal px-4">
          <Col md={8} sm={8} className="mobile-width-50-percent text-start">
            <span>{t("Membership Discount")}</span>
          </Col>
          <Col
            md={4}
            sm={4}
            className="text-end mobile-width-50-percent text-end"
          >
            <span className="color-green">
              {paymentSummary?.membership_discount?.discount_percent}%
            </span>
            {consolidationType === "" && `(-$${paymentSummary?.membership_discount?.discounted_amount?.toFixed(2)})`}
          </Col>
        </Row>}
      <hr className="hrbrdr mobile-hide-subtotal mx-4" />

      {consolidationType === "" &&
        <>
          <Row className="line-height-40 mobile-hide-subtotal px-4">
            <Col md={6} sm={6} className="mobile-width-50-percent">
              {t("Sub Total")}
            </Col>
            <Col
              md={6}
              sm={6}
              className="text-end mobile-width-50-percent text-left-ar"
            >
              <strong className="shipping_total_price">
                ${paymentSummary.sub_total?.toFixed(2) || 0}
              </strong>
            </Col>
          </Row>
          <Row className="line-height-40 px-4">
            <Col md={7} sm={6} className="mobile-width-50-percent font-weight-500">
              {SERVICES_WITH_VAT?.includes(paymentSummary?.shipping_company_id) ? t("Customs") : t("Duties and Taxes")}
            </Col>
            <Col
              md={5}
              sm={6}
              className="text-end float-left mobile-width-50-percent  text-left-ar"
            >
              <span id="shipping_custom">
                $ {(parseFloat(paymentSummary?.custom_duties || 0)).toFixed(2)}
              </span>
            </Col>
          </Row>
          {SERVICES_WITH_VAT?.includes(paymentSummary?.shipping_company_id) &&
            <Row className="line-height-40 px-4">
              <Col md={7} sm={6} className="mobile-width-50-percent font-weight-500">
                {t("Taxes")}
              </Col>
              <Col
                md={5}
                sm={6}
                className="text-end float-left mobile-width-50-percent  text-left-ar"
              >
                <span id="shipping_custom">
                  $ {(parseFloat(paymentSummary?.parcel_taxes || 0)).toFixed(2)}
                </span>
              </Col>
            </Row>}
          <div id="services" className="margin-bottom-10-negative">
            {paymentSummary.add_on_services &&
              paymentSummary.add_on_services.map(
                (addOn) =>
                  addOn?.amount > 0 && (
                    <Row className="line-height-40 font16-gry1 consolidate-block padding-bottom-8 px-4">
                      <Col
                        md={6}
                        sm={6}
                        className="text-capitalize mobile-width-50-percent font-weight-500 line-height-24 pt-2"
                      >
                        {i18n?.language === "ar" ? addOn?.service_arabic : addOn?.service}
                        <br />
                        {addOn?.parcel_value && <span className="font-size-13 gray-3">
                          {t("Parcel Value")}&nbsp;$&nbsp;{addOn?.parcel_value}
                        </span>}
                      </Col>
                      <Col
                        md={6}
                        sm={6}
                        className="text-end mobile-width-50-percent text-left-ar"
                      >
                        ${addOn?.amount?.toFixed(2)}
                      </Col>
                    </Row>
                  )
              )}
          </div>
          <hr className="hrbrdr mobile-hide-subtotal mx-4" />
          {paymentSummary.wallet_amount || paymentSummary.wallet_amount > 0 ? (
            <Row className="line-height-40 mobile-hide-subtotal px-4">
              <Col md={6} sm={6} className="mobile-width-50-percent">
                {t("Paid from wallet")}
              </Col>
              <Col
                md={6}
                sm={6}
                className="text-end mobile-width-50-percent text-left-ar"
              >
                <span>
                  -${paymentSummary.wallet_amount?.toFixed(2)}
                </span>
              </Col>
            </Row>
          ) : null}
          {paymentSummary.total || paymentSummary.total > 0 ? (
            <Row className="line-height-40 mobile-hide-subtotal px-4">
              <Col md={6} sm={6} className="mobile-width-50-percent">
                {t("Total")}
              </Col>
              <Col
                md={6}
                sm={6}
                className="text-end mobile-width-50-percent text-left-ar"
              >
                <strong className="shipping_total_price">
                  ${paymentSummary.total?.toFixed(2)}
                </strong>
              </Col>
            </Row>
          ) : null}
        </>
      }
    </>
  )
}

export default SummaryDetails