export default {
	appName: process?.env?.REACT_APP_NAME,
	apiUrl: "/api",
	domain: process?.env?.REACT_APP_USER_SERVICE,
	userService: {
		apiUrl: "/api",
		domain: process?.env?.REACT_APP_USER_SERVICE
	},
	parcelService: {
		apiUrl: "/api",
		domain: process?.env?.REACT_APP_PARCEL_SERVICES
	},
	shippingService: {
		apiUrl: "/api",
		domain: process?.env?.REACT_APP_SHIPPING_SERVICES
	},
	paymentService: {
		apiUrl: "/api",
		domain: process?.env?.REACT_APP_PAYMENT_SERVICES
	},
	notificationService: {
		apiUrl: "/api",
		domain: process?.env?.REACT_APP_NOTIFICATION_SERVICES
	},
	paymentRequestService: {
		apiUrl: "/api",
		domain: process?.env?.REACT_APP_REQUEST_SERVICES
	},
	pricingService: {
		apiUrl: "/api",
		domain: process?.env?.REACT_APP_PRICING_SERVICES
	}
};
