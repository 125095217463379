import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Plans from "./plans";

// import promotionHome from "./promotionHome.svg";

import promotionHome from "./newPromotionBanner.svg";
import FreeUsAddress from "./freeAddress.svg";
import ItemsConsolidation from "./itemsConsolidation.svg";
import FreeStorage from "./freeStorage.svg";
import multishippingOptions from "./multishippingOptions.svg";
import { PROMOTION_PAGES } from "../../../constants/constants";
import { Markup } from "interweave";
import { useMediaQuery } from "react-responsive";
import * as navigationConstants from "../../../constants/navigationConstants";
import { Link } from "react-router-dom";


function MainSection() {
	const { t, i18n } = useTranslation();


	const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

	return (
		<Container fluid>
			<div className="light-orange new-banner-bg">
				{/* <Container fluid className={'container-width'}>
					<Row>
						<Col xs={12} md={6} lg={6}>
							<div>
								<span className="main-title px-3">
									{t("Shop us brands")}
								</span>
								<br />
								<span className="main-title pl-3 pr-5">
									{t("Tax free")}
								</span>
								<br />
								<span className="title green-bg px-3">
									{t("We ship to you")}
								</span>
							</div>

							{isMobile && <img alt="Promotion Home" id="promotionHome" className={`w-100 py-5`} src={promotionHome} />}
							<div className={`${isMobile ? "mx-auto" : "my-2 line-height-2"}`}>
								<p className={`${isMobile ? "w-80" : "amcham-desc"}`}>
									<Markup content={t("promotion page home description")} />
								</p>
							</div>
						</Col>
						{!isMobile && <Col md={6} lg={6} className={`pb-5 pt-3 d-flex align-items-left justify-content-left`}>
							<img alt="Promotion Home" id="promotionHome" src={promotionHome} />
						</Col>}
					</Row>
				</Container> */}
			</div>

			<Plans promotionPage={PROMOTION_PAGES} />
			<div className="px-2">
				<h2 className="sub-page-section-header text-center">{t("Our Benefits")}</h2>
				<Card>
					<Card.Body className="services-cards-container bg-white w-100 justify-content-around">
						{/* free us address*/}
						<Card className="card-shadow ml-4 mr-4 mt-2 mb-2 w-100">
							<Card.Img
								className="tiny-section-img"
								variant="top"
								src={FreeUsAddress}
							/>
							<Card.Body>
								<Card.Title>{t("Free address")}</Card.Title>
								<ul className="text-start">
									<li>{t("Tax Free")}</li>
									<li>{t("No Hidden Fees")}</li>
								</ul>
							</Card.Body>
						</Card>
						{/* Package Consolidation*/}
						<Card 
							onClick={() => {
								window.scrollTo({
									top: 0,
									left: 0,
									behavior: "smooth",
								});
								document.getElementById("amcham-consol-link").click()
							}} 
							className="tr-cursor card-shadow ml-4 mr-4 mt-2 mb-2 w-100"
						>
							<Card.Img
								className="tiny-section-img"
								variant="top"
								src={ItemsConsolidation}
							/>
							<Card.Body>
								<Card.Title>{t("Items Consolidation")}</Card.Title>
								<ul className="text-start">
									<li>{t("Save Up to 80% on shipping")}</li>
								</ul>
							</Card.Body>
						</Card>
						{/* fast worldwide delivery card*/}
						<Card className="card-shadow ml-4 mr-4 mt-2 mb-2 w-100">
							<Card.Img
								className="tiny-section-img"
								variant="top"
								src={multishippingOptions}
							/>
							<Card.Body>
								<Card.Title>{t("Multi Shipping Options")}</Card.Title>
							</Card.Body>
						</Card>
						{/* free storage*/}
						<Card className="card-shadow ml-4 mr-4 mt-2 mb-2 w-100">
							<Card.Img
								className="tiny-section-img"
								variant="top"
								src={FreeStorage}
							/>
							<Card.Body>
								<Card.Title>{t("Free storage")}</Card.Title>
								<ul className="text-start">
									<li>{t("Free Items Photos")}</li>
								</ul>
							</Card.Body>
						</Card>
					</Card.Body>
				</Card>
			</div>
			<Link id="amcham-consol-link" className="d-none" to={navigationConstants?.CONSOLIDATION} />

		</Container>
	);
}

export default MainSection;
