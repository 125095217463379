import { createContext, useContext, useEffect, useState } from "react"
import { exportToLocalStorageKey, importFromLocalStorageKey } from "../utils/localStorageApi"
import { postIsPlanPaid, postIsVerifiedUser, postPlanSkipped } from "../services/userInfoService"
import { SPAIN_CUSTOMERS } from "../constants/constants"
import useAnalytics from "../analytics/useAnalytics";

export const AuthContext = createContext({})

const AuthProvider = (props) => {
	let authTokenFromLocalStorage
	let usernameFromLocalStorage
	let userPinFromLocalStorage
	let userEmailFromLocalStorage
	let membershipPlanIDFromLocalStorage
	let defaultPromoCodeFromLocalStorage
	let countryFromLocalStorage
	let phoneFromLocalStorage
	let postalCodeFromLocalStorage
	let warehousesListFromLocalStorage
	let warehouseIdFromLocalStorage
	let walletBalanceFromLocalStorage
	let isSubYearlyFromLocalStorage
	let isRenewalYearlyFromLocalStorage
	let showIncomingOrdersFromLocalStorage
	let uploadFilesLocalStorage
	
	try {
		authTokenFromLocalStorage = importFromLocalStorageKey("accessToken")
		usernameFromLocalStorage = importFromLocalStorageKey("username")
		userPinFromLocalStorage = importFromLocalStorageKey("userPin")
		userEmailFromLocalStorage = importFromLocalStorageKey("userEmail")
		membershipPlanIDFromLocalStorage = importFromLocalStorageKey("membershipPlanID")
		defaultPromoCodeFromLocalStorage = importFromLocalStorageKey("defaultPromoCode")
		countryFromLocalStorage = importFromLocalStorageKey("country")
		phoneFromLocalStorage = importFromLocalStorageKey("phone")
		postalCodeFromLocalStorage = importFromLocalStorageKey("postalCode")
		warehousesListFromLocalStorage = importFromLocalStorageKey("warehousesList")
		warehouseIdFromLocalStorage = importFromLocalStorageKey("warehouseId")
		isSubYearlyFromLocalStorage = importFromLocalStorageKey("isSubYearly")
		isRenewalYearlyFromLocalStorage = importFromLocalStorageKey("isRenewalYearly")
		walletBalanceFromLocalStorage = importFromLocalStorageKey("walletBalance")
		showIncomingOrdersFromLocalStorage = importFromLocalStorageKey("showIncomingOrders")
		uploadFilesLocalStorage = importFromLocalStorageKey("uploadFiles")
	} catch (e) {
		console.log(e)
	}

	const [loggedIn, setLoggedIn] = useState(false)
	const [authToken, setAuthToken] = useState(authTokenFromLocalStorage || "")
	const [username, setUsername] = useState(usernameFromLocalStorage || "")
	const [userPin, setUserPin] = useState(userPinFromLocalStorage || "")
	const [userEmail, setUserEmail] = useState(userEmailFromLocalStorage || "")
	const [membershipPlanID, setMembershipPlanID] = useState(membershipPlanIDFromLocalStorage || "")
	const [defaultPromoCode, setDefaultPromoCode] = useState(defaultPromoCodeFromLocalStorage || "")
	const [country, setCountry] = useState(countryFromLocalStorage || "")
	const [phone, setPhone] = useState(phoneFromLocalStorage || "")
	const [postalCode, setPostalCode] = useState(postalCodeFromLocalStorage || "")
	const [warehousesList, setWarehousesList] = useState(JSON.parse(warehousesListFromLocalStorage || "[]"))
	const [warehouseId, setWarehouseId] = useState(SPAIN_CUSTOMERS.includes(userPinFromLocalStorage) ? 6 : Number(warehouseIdFromLocalStorage) || 1)
	const [walletBalance, setWalletBalance] = useState(walletBalanceFromLocalStorage || "")
	const [isSubYearly, setIsSubYearly] = useState(Number(isSubYearlyFromLocalStorage) || 0)
	const [isRenewalYearly, setIsRenewalYearly] = useState(Number(isRenewalYearlyFromLocalStorage) || 0)
	const [showIncomingOrders, setShowIncomingOrders] = useState(Number(showIncomingOrdersFromLocalStorage) || 0)
	const [uploadFiles, setUploadFiles] = useState(Number(uploadFilesLocalStorage) || 0)
	
	const analytics = useAnalytics();

	useEffect(() => {
		if (authToken && username) {
			login()
		}
	}, [])

	useEffect(() => {
		if (!authToken) return
		localStorage.setItem("accessToken", authToken)
	}, [authToken])

	useEffect(() => {
		if (!username) return
		localStorage.setItem("username", username)
	}, [username])

	useEffect(() => {
		if (!userPin) return
		localStorage.setItem("userPin", userPin)
	}, [userPin])

	useEffect(() => {
		if (!userEmail) return
		localStorage.setItem("userEmail", userEmail)
	}, [userEmail])


	useEffect(() => {
		if (!membershipPlanID) return
		localStorage.setItem("membershipPlanID", membershipPlanID?.toString())
	}, [membershipPlanID])

	useEffect(() => {
		localStorage.setItem("defaultPromoCode", defaultPromoCode)
	}, [defaultPromoCode])

	useEffect(() => {
		// if (!country) return
		if (country && country !== "") {
			localStorage.setItem("country", country)
		}
	}, [country])

	useEffect(() => {
		if (phone && phone !== "") {
			localStorage.setItem("phone", phone)
		}
	}, [phone])

	useEffect(() => {
		// if (!isYearly) return
		localStorage.setItem("isSubYearly", isSubYearly?.toString())
	}, [isSubYearly])

	useEffect(() => {
		// if (!isYearly) return
		localStorage.setItem("isRenewalYearly", isRenewalYearly?.toString())
	}, [isRenewalYearly])
	
	useEffect(() => {
		if (!postalCode) return
		localStorage.setItem("postalCode", postalCode)
	}, [postalCode])

	useEffect(() => {
		if (warehousesList?.length === 0) return
		localStorage.setItem("warehousesList", JSON.stringify(warehousesList))
	}, [warehousesList?.length])

	useEffect(() => {
		if (!warehouseId) return
		localStorage.setItem("warehouseId", warehouseId)
	}, [warehouseId])
	
	useEffect(() => {
		if (!walletBalance) return
		localStorage.setItem("walletBalance", walletBalance)
	}, [walletBalance])

	useEffect(() => {
		// if (!showIncomingOrders) return
		localStorage.setItem("showIncomingOrders", showIncomingOrders)
	}, [showIncomingOrders])

	useEffect(() => {
		// if (!showIncomingOrders) return
		localStorage.setItem("uploadFiles", uploadFiles)
	}, [uploadFiles])

	const login = () => {
		setLoggedIn(true)
	}

	const logout = () => {
		let language = importFromLocalStorageKey("i18nextLng")
		const defaultPromoCodeBackup = importFromLocalStorageKey("defaultPromoCode") || "";
		// const warehousesListBkup = warehousesList || [];
		const warehouseBkup = Number(importFromLocalStorageKey("warehouseId")) || 1;
		//clear the user data
		setLoggedIn(false)
		setAuthToken(null)
		setUsername(null)
		setUserPin(null)
		setMembershipPlanID(null)
		localStorage.clear()
		exportToLocalStorageKey("defaultPromoCode", defaultPromoCodeBackup);
		setCountry("AE")
		setPostalCode("00971")
		setWarehouseId(warehouseBkup)
		exportToLocalStorageKey("i18nextLng", language)
		analytics.events.userLogout()
	}

	const isPlanPaid = async () => {
		return await postIsPlanPaid(authToken, { email: userEmail ? userEmail : localStorage.getItem('userEmail') })
	}

	const isEmailVerified = async () => {
		return await postIsVerifiedUser(authToken, { email: userEmail ? userEmail : localStorage.getItem('userEmail') })
	}

	const isPlanSkipped = async () => {
		return await postPlanSkipped(authToken, { email: userEmail ? userEmail : localStorage.getItem('userEmail') })
	}

	const authContextValue = {
		login,
		logout,
		loggedIn,
		authToken,
		setAuthToken,
		username,
		userPin,
		setUsername,
		setUserPin,
		userEmail,
		setUserEmail,
		isEmailVerified,
		isPlanSkipped,
		membershipPlanID,
		setMembershipPlanID,
		isPlanPaid,
		defaultPromoCode,
		setDefaultPromoCode,
		country,
		setCountry,
		phone, 
		setPhone,
		postalCode, 
		setPostalCode,
		warehousesList,
		setWarehousesList,
		warehouseId,
		setWarehouseId,
		walletBalance,
		setWalletBalance,
		isSubYearly,
		setIsSubYearly,
		isRenewalYearly,
		setIsRenewalYearly,
		showIncomingOrders,
		setShowIncomingOrders,
		uploadFiles,
		setUploadFiles

	}

	return <AuthContext.Provider value={authContextValue} {...props} />
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
