import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SuccessIcon from "../../assets/img/icons/success.gif";
import FailedIcon from "../../assets/img/icons/fail.gif";

import * as NAVIGATION_CONSTANTS from "../../constants/navigationConstants";
import { getData, postData } from "../../utils/api";
import { useAuth } from "../../contexts/AuthContext";
import useAnalytics from '../../analytics/useAnalytics';
export default (props) => {

    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [redirectLink, setRedirectLink] = useState("");
    const [iconSVG, setIconSVG] = useState("");
    const { authToken, setWalletBalance } = useAuth();
    const analytics = useAnalytics()
    const targetOrigin =  useMemo(()=>process?.env?.REACT_APP_MOBILE_POST_MESSAGE_TARGET_ORIGIN,[])

    const handleAnalyticsEvent = (requestType, attributes, reason) => {
        if (!!reason) {
            switch (requestType) {
                case "buy-it": {
                    const { totalAmount, requestId, paymentMethod } =
                        attributes;
                    analytics.events.bifmPurchaseRequestPaymentFailure({
                        totalAmount,
                        requestId,
                        paymentMethod,
                        reason,
                    });
                    break;
                }
                case "consolidation": {
                    const { paymentMethod, totalAmount, warehouse, parcelId } =
                        attributes;
                    analytics.events.shippingConsolidationPaymentFailure({
                        paymentMethod,
                        totalAmount,
                        warehouse,
                        parcelId,
                        reason,
                    });
                    break;
                }
                case "shipping":
                case "parcel": {
                    const { paymentMethod, totalAmount, warehouse, parcelIds } =
                        attributes;
                    analytics.events.shippingNoConsolidationPaymentFailure({
                        paymentMethod,
                        totalAmount,
                        warehouse,
                        parcelIds,
                        reason,
                    });
                    break;
                }
                case "membership":
                case "membership-update":
                case "membership-renewal": {
                    const {
                        paymentMethod,
                        totalAmount,
                        planType,
                        membershipType,
                        source,
                    } = attributes;
                    analytics.events.membershipPaymentFailure({
                        paymentMethod,
                        totalAmount,
                        planType,
                        membershipType,
                        source,
                    });
                    break;
                }
                default:
                    break;
            }
        } else {
            switch (requestType) {
                case "buy-it": {
                    analytics.events.bifmPurchaseRequestPaymentCompleted(
                        attributes
                    );
                    break;
                }
                case "consolidation": {
                    analytics.events.shippingConsolidationPaymentCompleted(
                        attributes
                    );
                    break;
                }
                case "shipping":
                case "parcel": {
                    analytics.events.shippingNoConsolidationPaymentCompleted(
                        attributes
                    );
                    break;
                }
                case "membership":
                case "membership-update":
                case "membership-renewal": {
                    analytics.events.membershipPaymentCompleted(attributes);
                    break;
                }
                default:
                    break;
            }
        }
    };

    useEffect(async () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let sourceUrl = params.get("source");
        let transactionId = params.get("transactionId");
        let token = params.get("authToken") || authToken;
        let status = params.get("status");
        let paypalByCardStatus = params.get("paypalStatus");
        let response = '';
        let data = {};
        if (!token || token === "") {
            window.location.href = NAVIGATION_CONSTANTS.HOMEPAGE
        }
        setLoading(true);

        if (sourceUrl === 'paypal') {
            let paypalToken = params.get("token");
            let baToken = params.get("ba_token");
            data = {
                "token": paypalToken,
                "ba_token": baToken,
                "transaction_id": transactionId,
                "status": status
            };
            response = await postData(
                "paymentService",
                "/paypal/agreement-complete",
                data,
                token
            )
        } else if (params?.get("resultIndicator") && params?.get("resultIndicator") !== "") {
            let sessionVersion = params.get("sessionVersion");
            let resultIndicator = params.get("resultIndicator");
            data = {
                "session_version": sessionVersion,
                "result_indicator": resultIndicator,
                "transaction_id": transactionId,
                "status": status
            };
            response = await postData(
                "paymentService",
                "/adcb/payment-complete",
                data,
                token
            )
        } else {
            let amazonCheckoutSessionId = params.get("amazonCheckoutSessionId");
            data = {
                "session_id": amazonCheckoutSessionId,
                "transaction_id": transactionId,
                "status": status
            };
            response = await postData(
                "paymentService",
                "/amazon/payment-complete",
                data,
                token
            )
        }

        if (document.getElementById("adcb-checkout")) {
            document.getElementById("adcb-checkout")?.remove()
        }

        let analyticsAttributes
        let mobileAnalyticsAttributes = {}
        let requestType
        let paymentMethod
        if (response.status === true) {
            let paymentStatus = true
            let buyItSuccess = false
            let transactionRes = {}
            if (response?.payload?.transaction_id) {
                transactionRes = await getData("paymentService", `/transactions/${response?.payload?.transaction_id}`, token)
            let cardDetails;
            if (transactionRes?.payload?.card_details) {
                try {
                    cardDetails = JSON.parse(transactionRes.payload.card_details);
                } catch (e) {
                    cardDetails = undefined;
                }
            }
            analyticsAttributes = cardDetails?.request?.analytics_event;
            paymentMethod = cardDetails?.request?.payment_method ?? "";
            const walletBalance = cardDetails?.request?.wallet_balance || 0;
            const walletAmount = cardDetails?.request?.wallet_amount || 0;
            requestType = cardDetails?.request?.is_consolidation ? "consolidation" : transactionRes?.payload?.request_type;
            mobileAnalyticsAttributes = {
                payment_method: paymentMethod,
                used_wallet_balance: walletAmount,
                wallet_balance: walletBalance,
            };
                if (transactionRes?.status && transactionRes?.payload?.request_type === "buy-it") {
                    const paymentRequest = JSON.parse(transactionRes?.payload?.card_details)?.request
                    paymentRequest["transaction_id"] = transactionRes?.payload?.id
                    let walletPaymentResponse
                    if (paymentRequest?.is_payby_wallet) {
                        walletPaymentResponse = await postData(
                            "paymentRequestService",
                            "/v2/PurchaseRequestController/paymentPurchaseRequest",
                            {
                                purchase_request_id: paymentRequest.purchase_request_id,
                                is_payby_wallet: 1,
                                wallet_amount: paymentRequest?.wallet_amount?.toFixed(2),
                            },
                            authToken
                        )
                        paymentRequest["wallet_transaction_id"] = walletPaymentResponse?.payload?.item?.id
                    }
                    if (walletPaymentResponse?.status || paymentRequest?.is_payby_wallet === 0) {
                        const updateStatus = await postData(
                            "paymentRequestService",
                            "/v2/PurchaseRequestController/createPurchaseRequestPayment",
                            {
                                ...paymentRequest
                            },
                            authToken
                        )
                        buyItSuccess = updateStatus?.status
                    }
                }
            }

            if ((response.status === true && transactionRes?.payload?.request_type !== "buy-it") || buyItSuccess) {
                setTitle(t("Your request was successful"));
                // setMessage(t("Thank you for your payment, you can track your shipment in the active parcels tab in the dashboard"));
                setIconSVG(SuccessIcon);
                if (response?.payload?.data?.User?.LastWalletTransaction) {
                    setWalletBalance(response?.payload?.data?.User?.LastWalletTransaction[0]?.available_balance)
                }
                mobileAnalyticsAttributes['status'] = 'success'
                window.webkit?.messageHandlers?.backHomePage?.postMessage(
                    JSON.stringify(mobileAnalyticsAttributes),
                    targetOrigin
                );
                if(analyticsAttributes && requestType && paymentMethod) {
                    analyticsAttributes['paymentMethod'] = paymentMethod
                    handleAnalyticsEvent(requestType, analyticsAttributes)
                }
            } else {
                setTitle(t("Payment Failed"));
                setMessage(t("Unfortunately , we couldnt collect payment Please add a valid credit card details"));
                setIconSVG(FailedIcon)
                mobileAnalyticsAttributes['status'] = 'fail'
                window.webkit?.messageHandlers?.backHomePage?.postMessage(
                    JSON.stringify(mobileAnalyticsAttributes),
                    targetOrigin
                );
                if(analyticsAttributes && requestType && paymentMethod) {
                    analyticsAttributes['paymentMethod'] = paymentMethod
                    handleAnalyticsEvent(requestType, analyticsAttributes, transactionRes?.message ?? "Unknown")
                }
            }
        } else {
            setTitle(t("Payment Failed"));
            setMessage(t("Unfortunately , we couldnt collect payment Please add a valid credit card details"));
            setIconSVG(FailedIcon)
            mobileAnalyticsAttributes['status'] = 'fail'
                window.webkit?.messageHandlers?.backHomePage?.postMessage(
                    JSON.stringify(mobileAnalyticsAttributes),
                    targetOrigin
                );
        }
        setRedirectLink(NAVIGATION_CONSTANTS.DASHBOARD);
        setLoading(false);

    }, []);

    return (
        <div className="d-flex justify-content-center align-items-center global-section-container grey-background full-height-page-small-nav pt-5 pb-5">
            <div className={"container-fluid container-width w-50-mobile-100 white-background info-page " + (loading ? "loading" : null)}>

                {loading
                    ? <div className="spinner-border" role="status" />
                    : <div>
                        <img alt="..." src={iconSVG} className="w-25" />
                        <h2 className="">
                            {t(title)}
                        </h2>

                        { message && message !== "" && <p>
                            {t(message)}
                        </p> }

                        <Link to={{
                            pathname: redirectLink,
                            search: "",
                            state: (props.location && props.location.state ? props.location.state : {})
                        }}>
                            <button className="btn btn-primary rounded">
                                {t("Return to Dashboard")}
                            </button>
                        </Link>
                    </div>
                }
            </div>
        </div>
    );
};
