import { useTranslation } from "react-i18next";
import Header from "./header";
import HowItWorks from "./howItWorks";
import ServicesSection from "./servicesSection/servicesSection";
import Stores from "./stores";

import "./styles.scss";
import { useEffect } from "react";
import useAnalytics from "../../analytics/useAnalytics";
import { screenEvent } from "../../analytics/constants";

const Directory = () => {
    const { t, i18n } = useTranslation();
    const analytics = useAnalytics();

    useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.SHOPPING_DIRECTORY });
    }, []);

    return (
        <div
            className={`directory-page ${
                i18n.language === "ar" ? "right-to-left" : ""
            }`}
        >
            <Header />
            <HowItWorks />
            <Stores />
            <ServicesSection />
            <div className="directory-footer-container">
                <p className="text-blue p-0 m-0 text-center font-weight">
                    {t("Get 20% off on shipping using SHOPINI20")}
                </p>
            </div>
        </div>
    );
};

export default Directory;
