import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ResetPasswordForm from "../../components/resetPasswordForm";
import { postForgetPassword } from "../../services/userInfoService";
import { DASHBOARD, HOMEPAGE } from "../../constants/navigationConstants";
import { Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import useAnalytics from "../../analytics/useAnalytics";
import { screenEvent } from "../../analytics/constants";

export default () => {
	const { t } = useTranslation();
	const [emailToAppend, setEmailToAppend] = useState({
		email: "",
	});
	const [error, setError] = useState(null);
	const [showSucess, setShowSucess] = useState(false);
	const { resetToken } = useParams();
	const [renderResetPassword, setRenderResetPassword] = useState(false);
	const [redirectToHomepage, setRedirectToHomepage] = useState(false);
	const { loggedIn } = useAuth();
	const analytics = useAnalytics()
	useEffect(() => {
		if (!resetToken) {
			setRenderResetPassword(false);
		} else {
			setRenderResetPassword(true);
		}
	}, []);

	const handleRedirectToHomepage = () => {
		setRedirectToHomepage(true);
	};

	const submitForgetPassword = async (e) => {
		e.preventDefault();
		try {
			const res = await postForgetPassword(emailToAppend);
			if (res?.error) {
				throw new Error(res.error);
			}
			setError(null);
			setShowSucess(true);
		} catch (error) {
			setError(error.message);
			setShowSucess(false);
		}
		analytics.events.sendResetLinkButton({email:emailToAppend.email})
	};

	let name, value;
	const handleInputs = (event) => {
		name = event.target.name;
		value = event.target.value;
		setEmailToAppend({ ...emailToAppend, [name]: value });
	};

	useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.FORGOT_PASSWORD });
    }, []);

	return (loggedIn ? <Redirect to={DASHBOARD} />
		:
		<div className="global-section-container flex-col grey-background pt-5 pb-5">
			<div className="container w-25-mobile-100 white-background forget-password-width text-start">
				<h2 className="mb-4">{t("Forgot password?")}</h2>

				<p>{t("Forgot password description")}</p>
				{!renderResetPassword ? (
					<form onSubmit={submitForgetPassword}>
						<div className="form-group">
							<label htmlFor="forgetPasswordEmail">{t("Email address")}</label>
							<input
								type="email"
								className="form-control"
								id="forgetPasswordEmail"
								name="email"
								value={emailToAppend.email}
								onChange={handleInputs}
								placeholder="example@domain.com"
								required
							/>
							{!!error && (
								<Alert variant={"danger"}>
									{error}
								</Alert>
							)}
							{showSucess && (
								<Alert variant={"success"} className="mt-3">
									{t("Reset password email was sent successfully")}
								</Alert>
							)}
						</div>
						<div className="row justify-content-end mr-0 ml-0 mt-2">
							<button type="submit" className="btn btn-primary w-100">
								{t("Reset Password")}
							</button>
						</div>
					</form>
				) : (
					<>
						<ResetPasswordForm
							resetToken={resetToken}
							callback={handleRedirectToHomepage}
						/>
						{redirectToHomepage ? <Redirect to={HOMEPAGE} /> : null}
					</>
				)}
			</div>
		</div>
	);
};
