import React from "react";
import One from "./one";
import Two from "./two";
import Three from "./three";
import { useTranslation } from "react-i18next";

function ProhibitedMaterialPage() {
	const { t, i18n } = useTranslation();
	return (
		<div className="global-section-container flex-col align-items-center">
			<div className="container-fluid grey-background pt-5 pb-5">
				<h1 className="sub-page-header">
					{t("Prohibited and unacceptable materials")}
				</h1>
			</div>
			<One />
			<Two />
			{/* <Three /> */}
		</div>
	);
}

export default ProhibitedMaterialPage;
