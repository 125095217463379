import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

const USContent = () => {
    const { t, i18n } = useTranslation();
    const alignment = i18n.language === "en" ? "ltr" : "rtl";

    return (
        <div
            className={`us-content-wrapper ${
                alignment === "rtl" ? "text-right rtl" : "text-left"
            }`}
        >
            <div className="content container-fluid">
                <div className="row pb-2">
                    <div className="col-12 text-center custom-fs-2 font-weight-bold blue-color">
                        {t("USA is just a click away")}
                    </div>
                </div>
                <div className="row pb-4">
                    <div className="col-12 text-center custom-fs-3 font-weight-bold">
                        {t("How it works")}
                    </div>
                </div>
                <div className="row steps-width mx-auto">
                    <div className="col-12 custom-fs-3">
                        <div className="row pb-4">
                            <div
                                className={`col-3  ${alignment === "rtl" ? "" : "text-nowrap"} blue-color font-weight-bold ${
                                    alignment === "rtl"
                                        ? "text-left"
                                        : "text-right"
                                }`}
                            >{`${t("Step 1")}`}</div>
                            <div
                                className={`col-9 ${
                                    alignment === "rtl"
                                        ? "text-right"
                                        : "text-left"
                                }`}
                            >
                                {t("Step 1 Description")}
                            </div>
                        </div>
                        <div className="row pb-4">
                            <div
                                className={`col-3  ${alignment === "rtl" ? "" : "text-nowrap"} blue-color font-weight-bold ${
                                    alignment === "rtl"
                                        ? "text-left"
                                        : "text-right"
                                }`}
                            >{`${t("Step 2")}`}</div>
                            <div
                                className={`col-9 ${
                                    alignment === "rtl"
                                        ? "text-right"
                                        : "text-left"
                                }`}
                            >
                                {t("Step 2 Description")}
                            </div>
                        </div>
                        <div className="row pb-4">
                            <div
                                className={`col-3  ${alignment === "rtl" ? "" : "text-nowrap"} blue-color font-weight-bold ${
                                    alignment === "rtl"
                                        ? "text-left"
                                        : "text-right"
                                }`}
                            >{`${t("Step 3")}`}</div>
                            <div
                                className={`col-9 ${
                                    alignment === "rtl"
                                        ? "text-right"
                                        : "text-left"
                                }`}
                            >
                                {t("Step 3 Description")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default USContent;
