import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import New from "./new.svg";
import NewAr from "./new-ar.svg";

const CardTitle = styled.div`
    font-weight: 400;
    margin-bottom: 7px;
`;

const CardContent = styled.div`
    font-weight: 300;
    color: #0000ff;
    padding: ${({ isAr }) => (isAr ? `0 0 0 16px` : `0 16px 0 0`)};
`;

const CardContainer = styled.div`
    position: relative;
    margin: 0 auto;
    padding: 9px 16px;
    background-color: #f4f6f9;
    border-radius: 6px;
    max-width: 224px;
    color: #0000ff;
    font-size: 12px;
`;

const StyledNew = styled.img`
    position: absolute;
    z-index: 1;
    top: 20%;
    left: ${(props) => (props.isAr ? 0 : "unset")};
    right: ${(props) => (props.isAr ? "unset" : 0)};
    transform: translateX(${(props) => (props.isAr ? "-30%" : "50%")});
`;

const OurTrueWeight = () => {
    const { t, i18n } = useTranslation();

    const isAr = i18n.language === "ar";

    return (
        <CardContainer
            className={`${isAr ? "text-right" : "text-left"}`}
            isAr={isAr}
        >
            <StyledNew isAr={isAr} alt="" src={isAr ? NewAr : New} />
            <CardTitle>
                <Trans i18nKey="styled_true_weight">
                    <span className="font-weight-bolder"></span>
                    <span className="color-green"></span>
                    <span className="color-green font-weight-bolder"></span>
                </Trans>
            </CardTitle>
            <CardContent isAr={isAr}>
                <p className="mb-0">
                    {t("Now you can pay as per Actual Weight only")}{" "}
                    {t(
                        "Your package Volumetric weight is no more considered when shipping"
                    )}
                </p>
                <br />
                <Trans i18nKey="This means, now you can  Ship more & Pay less">
                    <span className="color-green font-weight-bold"></span><br/>
                </Trans>
            </CardContent>
        </CardContainer>
    );
};

export default OurTrueWeight;
