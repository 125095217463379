import React from "react"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { BUYITFORME_POLICY, FAQ } from "../../constants/navigationConstants"
import FaqsIcon from "./faqs.png"
import PaymentTermsIcon from "./payment-terms.png"

const BottomLinks = () => {
  const { t, i18n } = useTranslation()
  return (
    <>
        <Row className="py-5 green-bg d-flex justify-content-center align-items-center bottom-links">
            <Col className="tr-cursor text-center my-3" lg={3} sm={12} onClick={() => {
                document.getElementById("faqs")?.click()
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
            }}>
                <img src={FaqsIcon}/>
                <span className="mt-2 text-blue d-block">{t("FAQS")}</span>
            </Col>
            <Col className="tr-cursor text-center my-3" lg={3} sm={12} onClick={() => {
                document.getElementById("payment-terms")?.click()
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
            }}>
                <img src={PaymentTermsIcon}/>
                <span className="mt-2 text-blue d-block">{t("Payment Terms")}</span>
            </Col>
            <Link to={BUYITFORME_POLICY} className="d-none" id="payment-terms" />
            <Link to={{ pathname: FAQ, state: "buy-it-for-me-tab"}} className="d-none" id="faqs" />
        </Row>
    </>
  )
}

export default BottomLinks