import React from "react";
import USContent from "./USContent";
import Header from "./Header";
import ProhibitedMaterials from "./ProhibitedMaterials";
import Address from "./Address";

const USWarehouse = () => (
    <div className="us-warehouse">
        <Header />
        <USContent />
        <ProhibitedMaterials />
        <Address />
    </div>
);

export default USWarehouse;
