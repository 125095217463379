import React, { useEffect, useState } from "react";
import ReactFlagsSelect from 'react-flags-select'
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AddressCard from "./addressCard";
import { postData } from "../../utils/api";
import useCountries from "../../utils/useCountries";
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {THANK_YOU_FOR_CONTACTING_US} from '../../constants/navigationConstants'
import useAnalytics from "../../analytics/useAnalytics";


function ContactUsForm() {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const { countries } = useCountries();
	const numberOfCharsInTextArea = 200;
	const classNameForPhoneInput = i18n.language === "ar" ? "ltr-direction w-100" : "w-100";
	const [messageTextAreaLength, setMessageTextAreaLength] = useState(
		numberOfCharsInTextArea
	);
	const [submitResult, setSubmitResult] = useState({})
	//this is the object that will be sent onSubmit of the form, check api
	const [contactUserInfo, setContactUserInfo] = useState({
		name: "",
		email: "",
		phone: "",
		subject: "",
		content: "",
		country: "AE",
		code: "",
		companyName: "",
	});
	const [officeLocation, setOfficeLocation] = useState({})
	const [loading, setLoading] = useState(false)
	const analytics = useAnalytics()
	
	const countChars = (e) => {
		let textAreaValue = e.target.value;
		setMessageTextAreaLength(numberOfCharsInTextArea - textAreaValue.length);
	};

	let name, value;
	const handleInputs = (event) => {
		name = event.target.name;
		value = event.target.value;
		setContactUserInfo({ ...contactUserInfo, [name]: value });
		if (name === "content") {
			countChars(event);
		}
	};

	const handlePhoneInput = (phoneValue) => {
		setContactUserInfo({ ...contactUserInfo, phone: phoneValue })
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (submitResult?.message && submitResult?.message !== "") {
			setSubmitResult({})
		}
		setLoading(true);
		const {companyName, ...payload} = contactUserInfo
		payload['content'] = `${payload.content}${ companyName && companyName !== "" ? ` 
Company name: ${companyName}` : ""}`
		const res = await postData("notificationService", "/contact-form/send", payload);
		setLoading(false);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		if (res.status) {
			history.push(THANK_YOU_FOR_CONTACTING_US);
			const analyticsAttributes = {
                name: contactUserInfo.name,
                email: contactUserInfo.email,
                companyName: contactUserInfo.companyName,
                shipTo: contactUserInfo.country,
                phoneNumber: contactUserInfo.phone,
                Subject: contactUserInfo.subject,
                message: contactUserInfo.content,
            };
            analytics.events.contactUsSubmitCompleted(analyticsAttributes);
		} else {
			setSubmitResult({ message: "Something went wrong", severity: "danger" })
		}
		setContactUserInfo({
			name: "",
			email: "",
			phone: "",
			subject: "",
			content: "",
			country: "AE",
			code: "",
			companyName: '',
		})
	}

	useEffect(() => {
		setOfficeLocation(countries?.find(country => country.iso2_code === contactUserInfo.country)?.OfficeLocation)
	}, [countries?.length])

	return (
		<div className="main-section-container grey-background">
			<div className="container mb-4">
				{submitResult.message && submitResult.message !== "" && <Alert variant={submitResult.severity}>{t(submitResult.message)}</Alert>}
				<h1 className="sub-page-header d-none d-md-block">{t("Want to talk to us?")}</h1>
				<div className="col-12 col-sm-12 col-md-9 mx-auto d-none d-md-block">
					<p className="mb-5">{t("Want to talk to us? description")}</p>
				</div>
				<div className="row justify-content-center">
					<div className="col-sm-12 col-md-8">
						<form
							className="white-background shadow-2 contact-us-form w-100"
							onSubmit={handleSubmit}
						>
							<div className="form-row mb-1">
								<h2 className="sub-page-section-header text-justify pl-1 pr-1 mb-4">
									{t("Contact us now")}
								</h2>
							</div>
							<div className="form-row mb-1">
								<div className="col -12 col-md-6 mb-3">
									<label htmlFor="contactUsNameInput" className="required">{t("Full Name")}</label>
									<input
										autoComplete="off"
										type="text"
										className="form-control"
										id="contactUsNameInput"
										placeholder={t("Full Name")}
										onChange={handleInputs}
										name="name"
										value={contactUserInfo.name}
										required
									/>
								</div>
								<div className="col -12 col-md-6 mb-3">
									<label htmlFor="contactUsEmailInput" className="required">{t("Email")}</label>
									<input
										autoComplete="off"
										type="email"
										className="form-control"
										id="contactUsEmailInput"
										placeholder="name@example.com"
										onChange={handleInputs}
										name="email"
										value={contactUserInfo.email}
										required
									/>
								</div>
							</div>
							<div className="form-row mb-2">
								<div className="col -12 col-md-12 mb-3 w-100">
									<label htmlFor="contactUsCompanyNameInput">{t("Company Name")}</label>
									<input
										autoComplete="off"
										type="text"
										className="form-control"
										id="contactUsCompanyNameInput"
										placeholder={t("Company Name")}
										onChange={handleInputs}
										name="companyName"
										value={contactUserInfo.companyName}
									/>
								</div>
							</div>
							<div className="form-row mb-1">
								<div className={`col-12 col-md-6 mb-3 w-100 ${i18n.language === "ar" ? "text-right" : "text-left"}`}>
									<label htmlFor="registerShipto" className="required">
										{t("Ship-to")}{" "}
									</label>
									<ReactFlagsSelect
										countries={countries?.map(country => country.iso2_code)}
										className={`${i18n.language === "ar" && "rtl-direction"}`}
										selected={contactUserInfo.country}
										onSelect={(code) => {
											setContactUserInfo({ ...contactUserInfo, country: code, code: code })
											setOfficeLocation(countries?.find(country => country.iso2_code === code)?.OfficeLocation)
										}}
										onFocus={(e) => {
											if (e.target.autocomplete) {
												e.target.autocomplete = "noautocomplete";
											}
										}}
										showSelectedLabel
										placeholder={"Select a country"}
										alignOptionsToRight
										fullWidth
									/>
									{/* <select
										autocomplete="off"
										className="form-control"
										id="registerShipto"
										name="country"
										onChange={handleInputs}
										value={contactUserInfo.country}
										required
									>
										<option value="">{t("Select a country")}</option>
										{countries?.map((country) => (
											<option key={country.id} name="code" value={country.iso2_code}>
												{country.name}
											</option>
										))}
									</select> */}
								</div>
								<div className="col -12 col-md-6 mb-3">
									<label htmlFor="contactUsMobileInput">{t("Phone")}</label>
									<PhoneInput
										country={contactUserInfo.country && contactUserInfo.country.toLocaleLowerCase()}
										inputProps={{
											name: "phone",
											autoComplete: "off"
										}}
										id="contactUsMobileInput"
										onChange={handlePhoneInput}
										inputClass="form-control w-100"
										value={contactUserInfo.phone}
										containerClass={classNameForPhoneInput}
									/>
								</div>
							</div>
							<div className="form-row mb-2">
								<div className="col -12 col-md-12 mb-3 w-100">
									<label htmlFor="contactUsSubjectInput" className="required">{t("Subject")}</label>
									<input
										autoComplete="off"
										type="text"
										className="form-control"
										id="contactUsSubjectInput"
										placeholder={t("Subject")}
										onChange={handleInputs}
										name="subject"
										value={contactUserInfo.subject}
										required
									/>
								</div>
							</div>
							<div className="form-row mb-2">
								<div className="col -12 col-md-12 mb-3 w-100">
									<label htmlFor="contactUsMessageInput" className="required">{t("Message")}</label>
									<textarea
										className="form-control"
										maxLength={numberOfCharsInTextArea}
										id="contactUsMessageInput"
										placeholder={t("Message")}
										onChange={handleInputs}
										name="content"
										value={contactUserInfo.content}
										rows="3"
										required
									/>
									<div id="count">
										<p id="current_count" className={`mt-2 ${messageTextAreaLength === 0 && "text-danger"}`}>
											{messageTextAreaLength} {t("Chars left")}
										</p>
									</div>
								</div>
							</div>
							<div className="d-flex justify-content-end">
								<button disabled={loading} id="contact-us-submit" className="btn btn-primary " type="submit">
									{loading ? 
									<div class="spinner-border text-light" role="status">
									</div>
									:
									t("Submit")
									}
								</button>
							</div>
						</form>
					</div>
					<div className="col-md-4 d-none d-md-block">
						{officeLocation && officeLocation.id &&
							delete officeLocation.address &&
							<div className={`address-card p-3 ltr-direction ${i18n.language === "ar" ? "text-right" : "text-left"}`}>
								<AddressCard location={officeLocation} />
							</div>
						}
					</div>

				</div>
			</div>
		</div>
	);
}

export default ContactUsForm;
