import React, { useState, useEffect } from "react";
import {
	Row,
	Col,
	Form
} from "react-bootstrap";

import {
	useStripe,
	useElements,
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement
} from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";

export default ({name, onNameChange, onCardElementsValueChange, toggleSaveCard}) => {
	const { t, i18n } = useTranslation();

	const CARD_OPTIONS = {
		iconStyle: "solid",
		style: {
			base: {
				iconColor: "#c4f0ff",
				fontWeight: 500,
				fontSize: "16px",
				fontSmoothing: "antialiased",
				":-webkit-autofill": {
					color: "#8798A9",
				},
				"::placeholder": {
					color: "#8798A9",
				}
			},
			invalid: {
				iconColor: "#8798A9",
				color: "#8798A9",
			}
		}
	};

	return <>
		<Row style={{"padding-top": "20px !important"}}>
			<Col md={12}>
				<div className="form-group">
					<label htmlFor="card" className="account_lbl">
						{ t("Name on Card")}
						<sup>*</sup>
					</label>
					<input className='key-up1 form-control card_fields'
						id="name" type='text' name="name"
						defaultValue={name}
						placeholder={ t("Name of card holder")}
						onChange={onNameChange}
					/>
					<span className="help-block form-error display-none card_name_error">
						{ t("Please enter the name of card")}
					</span>
				</div>
			</Col>
		</Row>
		<Row>
			<Col md={12}>
				<div className="form-group">
                    
					<label htmlFor="card_number" className="account_lbl w-100">
						{ t("Card Number")}
						<sup>*</sup>

						<sup className="float-right text-right line-height-30 padding-left-8 padding-left-0-ar">
							<img alt="..." src={"/images/payment/american.svg"} />
						</sup>
						<sup className="float-right text-right line-height-30 padding-left-8">
							<img alt="..." src={"/images/payment/master.svg"}  />
						</sup>
						<sup className="float-right text-right line-height-30 padding-left-8">
							<img alt="..." src={"/images/payment/visa.svg"}  />
						</sup>
					</label>

					<CardNumberElement
						options={CARD_OPTIONS}
						className="key-up1 form-control card_fields"
						onReady={() => {
							console.log("CardNumberElement [ready]");
						}}
						onChange={(e) => onCardElementsValueChange(e, "number")}
						onBlur={() => {
							console.log("CardNumberElement [blur]");
						}}
						onFocus={() => {
							console.log("CardNumberElement [focus]");
						}}
					/>
					<span id="card-number-error" className="form-error has-error"/>
				</div>
			</Col>
		</Row>
		<Row>
			<Col md={6} xs={12}>
				<div className="form-group">
					<label htmlFor="card_expiry" className="account_lbl w-100">
						{ t("Expiration Year/Month")}
					</label>

					<CardExpiryElement
						options={CARD_OPTIONS}
						className="key-up1 form-control card_fields"
						onReady={() => {
							console.log("CardNumberElement [ready]");
						}}
						onChange={(e) => onCardElementsValueChange(e, "expiry")}
						onBlur={() => {
							console.log("CardNumberElement [blur]");
						}}
						onFocus={() => {
							console.log("CardNumberElement [focus]");
						}}
					/>
					<span id="card-expiry-error" className="form-error has-error" />
				</div>
			</Col>
			<Col md={6} xs={12}>
				<div className="form-group">
					<label htmlFor="card_cvc" className="account_lbl w-100">
						{ t("CVC")}
					</label>

					<CardCvcElement
						options={CARD_OPTIONS}
						className="key-up1 form-control card_fields"
						onReady={() => {
							console.log("CardNumberElement [ready]");
						}}
						onChange={(e) => onCardElementsValueChange(e, "cvc")}
						onBlur={() => {
							console.log("CardNumberElement [blur]");
						}}
						onFocus={() => {
							console.log("CardNumberElement [focus]");
						}}
					/>
					<span id="card-cvc-error" className="form-error has-error" />
				</div>
			</Col>
		</Row>
		{toggleSaveCard 
			? <Row className='form-row'>
				<Col md={6} className='col-md-6 form-group'>
					<Form.Check type="checkbox" name="save_card" id="save_card" value="1" label={t("Save Card")} onChange={toggleSaveCard} />
				</Col>
			</Row>
			: null
		}
	</>;
};