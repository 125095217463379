import React from "react";
// import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Dropzone from 'react-dropzone-uploader'
import UploadIcon from "./upload.svg";
import "./style.scss";
import { Form } from "react-bootstrap";

export default ({
    multiple = false,
    handleChangeStatus,
    maxFiles = 1,
    formId,
    accept,
    onSubmit
}) => {
    const { t, i18n } = useTranslation();

    return (
        <Form id={formId}>
            <Dropzone
                multiple={multiple}
                inputContent={<div className="upload-files my-3">
                    <img src={UploadIcon} className="my-3" />
                    <span className="light-text">{t("Drag & Drap your file here")}</span>
                    <span className="light-text lighter-text text-uppercase">{t("or")}</span>
                    <button
                        className="text-white text-capitalize border-radius-5 btn-bg-color d-flex flex-row justify-content-center text-decoration-none px-4 py-2 my-2"
                        onClick={(e) => {
                            e.preventDefault()
                            document?.getElementsByClassName("dzu-input")[0] && document?.getElementsByClassName("dzu-input")[0]?.click()
                        }}
                    >
                        {t("Browse file")}
                    </button>
                </div>}
                accept={accept}
                maxFiles={maxFiles}
                onChangeStatus={handleChangeStatus}
                canRemove={true}
            />
        </Form>
    );
};