import React, { useEffect, useState } from "react";
import { Button, Row, Col, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SearchForm from "./searchForm";
import ParcelTable from "./table";
import EmptyParcel from "./empty";
import { PARCEL_CONSTANTS, TOGGLES, MEMBERSHIP_CONSTANTS } from "../../../constants/constants";
import * as NAVIGATION_CONSTANTS from "../../../constants/navigationConstants";
import { Loader } from "../../common";
import { getData, postData } from "../../../utils/api";
import { useLocalStorage } from "../../../contexts/localStorage";
import BackButtonSvg from "../../../assets/img/icons/backButton.svg";
import { DASHBOARD } from "../../../constants/navigationConstants";
import { checkToggleByName } from "../../../services/parcelApi";
import { useAuth } from "../../../contexts/AuthContext";
import ConfirmationModal from "./confirmationModal";
import modalIcon from "../../beforeConsolidationPaymentSummary/consolidatedItemsSvg.svg";
import ItemsModal from "./itemsModal";
import ResultsModal from "../../resultsModal";
import exportIcon from "./exportIcon.svg";
import axios from "axios";
import useAnalytics from "../../../analytics/useAnalytics";

const Parcel = (props) => {
    const { t, i18n } = useTranslation()
    const { warehouseId, membershipPlanID, warehousesList} = useAuth()
    const [allParcelData, setAllParcelData] = useState([])
    const [readyParcelData, setReadyParcelData] = useState([])
    const [delParcelData, setDelParcelData] = useState([])
    const [shipments, setShipments] = useState([])
    const [readyPending, setReadyPending] = useState([])
    const [exceptions, setExceptions] = useState([])
    const [selectedParcels, setSelectedParcels] = useState([])
    const [stateCounter, setStateCounter] = useState(selectedParcels.length)
    const [loading, setLoading] = useState(true)
    const [exportLoading, setExportLoading] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [allParcelsLoaded, setAllParcelsLoaded] = useState(false)
    const [showItemsModal, setShowItemsModal] = useState(false)
    const [parcel, setParcel] = useState(null)
    const [reload, setReload] = useState(false)
    const [showResultsModal, setShowResultsModal] = useState({
        show: 0,
        status: false
    })
    const [allParcelsCount, setAllParcelsCount] = useState(0)
    const [pageNumber, setPageNumber] = useState(1);
    const [pages, setPages] = useState({
        [PARCEL_CONSTANTS.ALL]: 1,
        [PARCEL_CONSTANTS.READY_TO_SHIP]: 1,
        [PARCEL_CONSTANTS.ACTIVE]: 1,
        [PARCEL_CONSTANTS.DELIVERED]: 1,
        [PARCEL_CONSTANTS.EXCEPTION]: 1,
        [PARCEL_CONSTANTS.PENDING_PAYMENT]: 1,
    });
    const [count, setCount] = useState(0)
    const [showBackBtn, setShowBackBtn] = useState(false)
    const analytics = useAnalytics()
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let uuid = params.get("uuid");
    let from = params.get("from");
    let to = params.get("to");
    let activeTab = params.get("category");
    // cache all parcels data to use it when clearing the search filter
    const [storedValue, setValue] = useLocalStorage("allParcels", (props.category === PARCEL_CONSTANTS.ACTIVE ?
        shipments : props.category === PARCEL_CONSTANTS.DELIVERED ?
            delParcelData :
            allParcelData) || []);
    const tableColumns = {
        [PARCEL_CONSTANTS.ALL]: [
            {
                key: "id",
                title: t("Parcel ID"),
                action: false,
            },
            {
                key: "supplier",
                title: t("Supplier"),
                action: false,
            },
            {
                key: "receivedAt",
                title: t("Received At"),
                action: false,
            },
            {
                key: "dimensions",
                title: t("Dimensions"),
                action: false,
            },
            {
                key: "weight",
                title: t("Weight"),
                action: false,
            },
            {
                key: "status",
                title: t("Parcel Status"),
                action: false,
            },
            {
                key: "accordion",
                action: true,
                type: "icon",
            },
        ],
        [PARCEL_CONSTANTS.READY_TO_SHIP]: [
            {
                key: "checkbox",
                title: "",
                action: true,
            },
            {
                key: "id",
                title: t("Parcel ID"),
                action: false,
            },
            {
                key: "supplier",
                title: t("Supplier"),
                action: false,
            },
            {
                key: "updatedAt",
                title: t("Last Update"),
                action: false,
            },
            {
                key: "dimensions",
                title: t("Dimensions"),
                action: false,
            },
            {
                key: "weight",
                title: t("Weight"),
                action: false,
            },
            {
                key: "accordion",
                action: true,
                type: "icon",
            },
        ],
        [[PARCEL_CONSTANTS.ACTIVE, PARCEL_CONSTANTS.READY_PENDING].includes(props.category) ? props.category : undefined]: [
            {
                key: "id",
                title: t("Parcel ID"),
                action: true,
            },
            // {
            //     key: "dimensions",
            //     title: t("Dimensions"),
            //     action: false,
            // },
            {
                key: "weight",
                title: t("Weight"),
                action: false,
            },
            {
                key: "dispatchedAt",
                title: t("Dispatched At"),
                action: false,
            },
            {
                key: "status",
                title: t("Parcel Status"),
                action: false,
            },
            {
                key: "view",
                action: true,
                type: "button",
            },
        ],
        [PARCEL_CONSTANTS.DELIVERED]: [
            {
                key: "id",
                title: t("Parcel ID"),
                action: true,
            },
            // {
            //     key: "dimensions",
            //     title: t("Dimensions"),
            //     action: false,
            // },
            {
                key: "weight",
                title: t("Actual Weight"),
                action: false,
            },
            {
                key: "deliveredAt",
                title: t("Delivered At"),
                action: false,
            },
            {
                key: "status",
                title: t("Parcel Status"),
                action: false,
            },
            {
                key: "view",
                action: true,
                type: "button",
            },
        ],
        [PARCEL_CONSTANTS.EXCEPTION]: [
            {
                key: "id",
                title: t("Parcel ID"),
                action: true,
            },
            {
                key: "dimensions",
                title: t("Dimensions"),
                action: false,
            },
            {
                key: "weight",
                title: t("Weight"),
                action: false,
            },
            {
                key: "updatedAt",
                title: t("Last Update"),
                action: false,
            },
            {
                key: "status",
                title: t("Parcel Status"),
                action: false,
            },
        ],
    };

    const tableTitle = {
        [PARCEL_CONSTANTS.ALL]: "",
        [PARCEL_CONSTANTS.READY_TO_SHIP]: t(
            "Select parcels to consolidate & ship"
        ),
        [PARCEL_CONSTANTS.ACTIVE]: t("All the parcels on shipment"),
        [PARCEL_CONSTANTS.READY_PENDING]: t("All the shipments on payment pending"),
        [PARCEL_CONSTANTS.DELIVERED]: t("All delivered parcels"),
        [PARCEL_CONSTANTS.EXCEPTION]: t("All exceptions parcels"),
    };

    const tableTitleInfo = {
        [PARCEL_CONSTANTS.ALL]: "",
        [PARCEL_CONSTANTS.READY_TO_SHIP]: t(
            "You can select couple of parcels to consolidate"
        ),
        [PARCEL_CONSTANTS.ACTIVE]: "",
        [PARCEL_CONSTANTS.READY_PENDING]: "",
        [PARCEL_CONSTANTS.DELIVERED]: "",
        [PARCEL_CONSTANTS.EXCEPTION]: "",
    };

    const setLocalStorageValue = (key, value) => {
        try {
            // Allow value to be a function so we have same API as useState
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };

    const getLocalStorageValue = (key) => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : null;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
        }
    }

    useEffect(async () => {
        let tempParcelData = [];
        let tempParcelItems = [];
        let tempParcelFiles = [];
        let tempCategory = props.category;

        let query = ""
        // if (tempCategory !== PARCEL_CONSTANTS.READY_TO_SHIP) {
            query = pageNumber && pageNumber > 0 ? `&page=${pageNumber}` : query
            query = uuid && uuid !== "" ? `${query}&keyword=${uuid}` : query
            query = from && from !== "" ? `${query}&start_date=${from}` : query
            query = to && to !== "" ? `${query}&end_date=${to}` : query

            if ((uuid && uuid !== "") || (from && from !== "") || (to && to !== "")) {
                setShowBackBtn(true)
            } else {
                setShowBackBtn(false)
            }
        // }

        let requestUrl = `/v2/count/parcel?category=${tempCategory}&warehouse_id=${warehouseId}${query}`;

        setLoading(true);
        const response = await getData(
            "parcelService",
            requestUrl,
            props.authToken
        );

        setCount(response?.payload?.count)

        let parcelsrequestUrl = `/v5/parcels?key=${tempCategory}&warehouse_id=${warehouseId}${query}`
        const parcelsResponse = await getData(
            "parcelService",
            parcelsrequestUrl,
            props.authToken
        );

        tempParcelData =
            !parcelsResponse.status || parcelsResponse.error || !parcelsResponse.payload.parcels ? [] : parcelsResponse.payload.parcels;
        tempParcelItems =
            !parcelsResponse.status || parcelsResponse.error || !parcelsResponse.payload.items ? [] : parcelsResponse.payload.items;
        tempParcelFiles =
            !parcelsResponse.status || parcelsResponse.error || !parcelsResponse.payload.files ? [] : parcelsResponse.payload.files;
        let tempSortedParcels = []
        switch (tempCategory) {
            case PARCEL_CONSTANTS.READY_TO_SHIP:
                tempParcelData = tempParcelData.map(p => {
                    return {
                        ...p,
                        ParcelItems: tempParcelItems[`${p.id}`],
                        ParcelFiles: tempParcelFiles[`${p.id}`]
                    }
                })
                setReadyParcelData([...tempParcelData])
                break;
            case PARCEL_CONSTANTS.ACTIVE:
                tempSortedParcels = [...(tempParcelData?.sort((a, b) => {
                    return a?.created_at - b?.created_at
                }))]
                setShipments([...tempSortedParcels])
                setLocalStorageValue("shipments", [...tempSortedParcels]);
                break;
            case PARCEL_CONSTANTS.READY_PENDING:
                tempSortedParcels = [...(tempParcelData?.sort((a, b) => {
                    return a?.created_at - b?.created_at
                }))]
                setReadyPending([...tempSortedParcels])
                setLocalStorageValue("pendings", [...tempSortedParcels]);
                break;
            case PARCEL_CONSTANTS.DELIVERED:
                tempSortedParcels = [...(tempParcelData?.sort((a, b) => {
                    if (a?.delivery_date && b?.delivery_date) {
                        return a?.delivery_date - b?.delivery_date
                    } else {
                        return a?.ParcelLogs?.find(pl => pl?.parcel_status_id === PARCEL_CONSTANTS.DELIVERED_PARCEL_STATUS_ID)?.created_at - b?.ParcelLogs?.find(pl => pl?.parcel_status_id === PARCEL_CONSTANTS.DELIVERED_PARCEL_STATUS_ID)?.created_at
                    }
                }))]
                setDelParcelData([...tempSortedParcels])
                setLocalStorageValue("delParcels", [...tempSortedParcels]);
                break;
            case PARCEL_CONSTANTS.EXCEPTION:
                setExceptions([...tempParcelData])
                break;
            default:
                // setAllParcelsCount(response?.payload?.count || response.payload.parcels?.length)
                tempSortedParcels = [...(tempParcelData?.sort((a, b) => {
                    return a?.created_at - b?.created_at
                }))]
                tempSortedParcels = tempSortedParcels.map(p => {
                    return {
                        ...p,
                        ParcelItems: tempParcelItems[`${p.id}`],
                        ParcelFiles: tempParcelFiles[`${p.id}`]
                    }
                })
                setAllParcelData([...tempSortedParcels])
                setLocalStorageValue("allParcels", tempSortedParcels);
                break;
            }
            setLoading(false);
            setAllParcelsLoaded(false);
        }, [warehouseId, reload]);

    const renderInfoText = () => (
        <p
            className={`${props.isEnglish ? "text-left" : "text-right"
                } font16-gry1 padtb-15`}
        >
            {tableTitle[props.category]}{" "}
            {tableTitleInfo[props.category] &&
                tableTitleInfo[props.category] != "" ? (
                <i
                    aria-expanded="false"
                    data-toggle="tooltip"
                    data-placement="top"
                    className="bx bx-info-circle"
                    title={tableTitleInfo[props.category]}
                />
            ) : null}
        </p>
    );

    const renderNumberOfParcels = () => (
        <p
            className={`font16-gry3 padtb-15 pdngspnrght ${!props.isEnglish && props.isMobile ? "pr-0 text-start" : "pl-0"
                }`}
        >
            {`${selectedParcels.length} ${t("Parcel Selected")}`}
        </p>
    );

    const handleShipSelectedEvent = () => {
        const warehouse = warehousesList.find(
            (warehouse) => warehouse.id === Number(warehouseId)
        )?.country;
        if (selectedParcels.length && warehouse) {
            analytics.events.shippingShipSelectedParcelsButton({
                numberOfParcels: selectedParcels.length,
                warehouse,
            });
        }
    };

    const renderShipParcelsButton = () => (
        <Link
            to={{
                pathname: NAVIGATION_CONSTANTS.SHIPMENT_OPTIONS,
                search: "",
                state: { selectedParcels },
            }}
        >
            <Button onClick={handleShipSelectedEvent}>{t("Ship Selected")}</Button>
        </Link>
    );

    // const loadMore = async () => {
    //     try {
    //         setLoading(true)
    //         const tempCategory = props.category === PARCEL_CONSTANTS.ACTIVE ? PARCEL_CONSTANTS.READY_PENDING : props.category
    //         let requestUrl = `/v5/parcels?key=${tempCategory}&warehouse_id=${warehouseId}`
    //         const response = await getData(
    //             "parcelService",
    //             requestUrl,
    //             props.authToken
    //         );
    //         let tempParcelData =
    //             !response.status || response.error ? [] : response.payload.parcels;
    //         let tempSortedParcels = []
    //         setCount(response?.payload?.count)
    //         switch (tempCategory) {
    //             case PARCEL_CONSTANTS.READY_TO_SHIP:
    //                 setReadyParcelData([...tempParcelData])
    //                 break;
    //             case PARCEL_CONSTANTS.ACTIVE:
    //             case PARCEL_CONSTANTS.READY_PENDING:
    //                 tempSortedParcels = [...(tempParcelData?.sort((a, b) => {
    //                     return a?.created_at - b?.created_at
    //                 }))]
    //                 setShipments([...tempSortedParcels])
    //                 setLocalStorageValue("shipments", [...tempSortedParcels]);
    //                 break;
    //             case PARCEL_CONSTANTS.DELIVERED:
    //                 tempSortedParcels = [...(tempParcelData?.sort((a, b) => {
    //                     if (a?.delivery_date && b?.delivery_date) {
    //                         return a?.delivery_date - b?.delivery_date
    //                     } else {
    //                         return a?.ParcelLogs?.find(pl => pl?.parcel_status_id === PARCEL_CONSTANTS.DELIVERED_PARCEL_STATUS_ID)?.created_at - b?.ParcelLogs?.find(pl => pl?.parcel_status_id === PARCEL_CONSTANTS.DELIVERED_PARCEL_STATUS_ID)?.created_at
    //                     }
    //                 }))]
    //                 setDelParcelData([...tempSortedParcels])
    //                 setLocalStorageValue("delParcels", [...tempSortedParcels]);
    //                 break;
    //             case PARCEL_CONSTANTS.EXCEPTION:
    //                 setExceptions([...tempParcelData])
    //                 break;
    //             default:
    //                 // setAllParcelsCount(response?.payload?.count || response.payload.parcels?.length)
    //                 tempSortedParcels = [...(tempParcelData?.sort((a, b) => {
    //                     return a?.created_at - b?.created_at
    //                 }))]
    //                 setAllParcelData([...tempSortedParcels])
    //                 setLocalStorageValue("allParcels", tempSortedParcels);
    //                 break;
    //         }
    //         setShowConfirmation(false)
    //         setAllParcelsLoaded(true)
    //         setLoading(false)
    //     } catch (error) {
    //         return false
    //     }
    // }

    // const handleOptionClick = (option) => {
    //     switch (option) {
    //         case "yes":
    //             loadMore()
    //             break;
    //         default:
    //             setShowConfirmation(false)
    //             break;
    //     }
    // }

    const handleSubmit = async (data) => {
        let requestUrl = `/update/parcel-item/${parcel?.id}`
        const response = await postData(
            "parcelService",
            requestUrl,
            data,
            props.authToken
        );
        setParcel(null)
        setShowItemsModal(false)
        setShowResultsModal({
            show: 1,
            status: response?.status
        })
    }

    const handleExport = async () => {
        try {
            setExportLoading(true)
            const tempCategory = props.category
            //=== PARCEL_CONSTANTS.ACTIVE ? PARCEL_CONSTANTS.READY_PENDING : props.category
            let query = `${tempCategory && tempCategory !== "" ? `category=${tempCategory}&` : ""}`
            query = `${query}${warehouseId ? `warehouse_id=${warehouseId}&` : ""}`
            query = `${query}${uuid && uuid !== "" ? `keyword=${uuid}&` : ""}`
            query = `${query}${from && from !== "" ? `start_date=${from}&` : ""}`
            query = `${query}${to && to !== "" ? `end_date=${to}` : ""}`
            const defaultOptions = {
                headers: {
                    "Authorization": props?.authToken ? `Bearer ${props?.authToken}` : "",
                },
            };
            let { data } = await axios.get(
                `${process.env.REACT_APP_PARCEL_SERVICES}/api/export-parcels?${query}`,
                defaultOptions,
            )
            if (data && data !== "") {
                const outputFilename = `${Date.now()}.csv`;
                const aElement = document.createElement('a');
                aElement.setAttribute('download', outputFilename);
                const href = URL.createObjectURL(new Blob([data]));
                aElement.href = href;
                aElement.setAttribute('target', '_blank');
                aElement.click();
                URL.revokeObjectURL(href);
                // setShowResultsModal({ status: true, show: 1 })
            } else {
                setShowResultsModal({ status: false, show: 1 })
            }
            setExportLoading(false)
        } catch (error) {
            setExportLoading(false)
            setShowResultsModal({ status: false, show: 1 })
            console.log(error)
        }
    }

    return loading ? (
        <Loader />
    ) : (
        <>
            <Row>
                <Col md={"12"}>
                    {[PARCEL_CONSTANTS.ALL, PARCEL_CONSTANTS.DELIVERED, PARCEL_CONSTANTS.ACTIVE, PARCEL_CONSTANTS.READY_PENDING].includes(props.category) ? (
                        <Row className="margin-top-bottom10">
                            <SearchForm
                                {...props}
                                setParcelData={
                                    props.category === PARCEL_CONSTANTS.ACTIVE
                                        ? setShipments :
                                        props.category === PARCEL_CONSTANTS.DELIVERED
                                            ? setDelParcelData :
                                            props.category === PARCEL_CONSTANTS.READY_PENDING
                                                ? setReadyPending :
                                                setAllParcelData}
                                parcelData={props.category === PARCEL_CONSTANTS.ACTIVE ?
                                    shipments : props.category === PARCEL_CONSTANTS.DELIVERED ?
                                        delParcelData : props.category === PARCEL_CONSTANTS.READY_PENDING ?
                                            readyPending : allParcelData}
                                // setAllParcelsCount={setAllParcelsCount}
                                setLoading={setLoading}
                                setReload={setReload}
                                reload={reload}
                                allParcels={getLocalStorageValue("allParcels")}
                            />
                            {props.category !== PARCEL_CONSTANTS.READY_PENDING ?
                                <Col sm={12} md={2} className="px-1">
                                    <a
                                        className={`d-flex align-items-center justify-content-center nowrap-space btn text-blue rounded light-blue-bg add-edit-items-hover min-w-0`}
                                        onClick={async () => {
                                            await handleExport()
                                        }}
                                    >
                                        <img src={exportIcon} />
                                        &nbsp;
                                        {exportLoading ?
                                            <div class="spinner-border text-light" role="status">
                                            </div>
                                            :
                                            t("Export")
                                        }
                                    </a>
                                </Col> : null}
                        </Row>
                    ) : (
                        <>
                            {(props.category === PARCEL_CONSTANTS.ACTIVE ?
                                shipments : props.category === PARCEL_CONSTANTS.DELIVERED ?
                                    delParcelData :
                                    props.category === PARCEL_CONSTANTS.READY_TO_SHIP ?
                                        readyParcelData :
                                        props.category === PARCEL_CONSTANTS.EXCEPTION ?
                                            exceptions :
                                            props.category === PARCEL_CONSTANTS.READY_PENDING ?
                                                readyPending :
                                                allParcelData)?.length ? (
                                <div
                                    className={
                                        "flex-space-between padding-top-8 margin-top-bottom10"
                                    }
                                >
                                    {props.isMobile ? (
                                        <div className={`row`}>
                                            <div className={"col-sm-12"}>
                                                {renderInfoText()}
                                            </div>

                                            {selectedParcels.length ? (
                                                <div className="flex-row-space-between-center-align">
                                                    <div className="col-sm-6">
                                                        {renderNumberOfParcels()}
                                                    </div>
                                                    <div className="col-sm-6">
                                                        {renderShipParcelsButton()}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : (
                                        <>
                                            {renderInfoText()}
                                            {selectedParcels.length ? (
                                                <div className="flex-row-space-between-center-align">
                                                    {renderNumberOfParcels()}
                                                    {renderShipParcelsButton()}
                                                </div>
                                            ) : null}
                                        </>
                                    )}
                                </div>
                            ) : null}
                        </>
                    )}

                    {(props.category === PARCEL_CONSTANTS.ACTIVE ?
                        shipments : props.category === PARCEL_CONSTANTS.DELIVERED ?
                            delParcelData :
                            props.category === PARCEL_CONSTANTS.READY_TO_SHIP ?
                                readyParcelData :
                                props.category === PARCEL_CONSTANTS.EXCEPTION ?
                                    exceptions :
                                    props.category === PARCEL_CONSTANTS.READY_PENDING ?
                                        readyPending :
                                        allParcelData)?.length !== 0 ? (
                        <Row className={"justify-content-end"}
                        >
                            <ParcelTable
                                {...props}
                                parcelData={(props.category === PARCEL_CONSTANTS.ACTIVE ?
                                    shipments : props.category === PARCEL_CONSTANTS.DELIVERED ?
                                        delParcelData :
                                        props.category === PARCEL_CONSTANTS.READY_TO_SHIP ?
                                            readyParcelData :
                                            props.category === PARCEL_CONSTANTS.EXCEPTION ?
                                                exceptions :
                                                props.category === PARCEL_CONSTANTS.READY_PENDING ?
                                                    readyPending :
                                                    allParcelData)}
                                setParcelData={(props.category === PARCEL_CONSTANTS.ACTIVE ?
                                    setShipments : props.category === PARCEL_CONSTANTS.DELIVERED ?
                                        setDelParcelData :
                                        props.category === PARCEL_CONSTANTS.READY_TO_SHIP ?
                                            setReadyParcelData :
                                            props.category === PARCEL_CONSTANTS.EXCEPTION ?
                                                setExceptions :
                                                props.category === PARCEL_CONSTANTS.READY_PENDING ?
                                                    setReadyPending :
                                                    setAllParcelData)}
                                tableColumns={tableColumns[props.category]}
                                setSelectedParcels={setSelectedParcels}
                                setStateCounter={setStateCounter}
                                stateCounter={stateCounter}
                                loading={loading}
                                parcel={parcel}
                                setParcel={setParcel}
                                showItemsModal={showItemsModal}
                                setShowItemsModal={setShowItemsModal}
                                allParcelsCount={allParcelsCount}
                                count={count}
                                pageNumber={pageNumber}
                                setPageNumber={setPageNumber}
                                reload={reload}
                                setReload={setReload}
                                pages={pages}
                                setPages={setPages}
                                showBackBtn={showBackBtn}
                            />
                            {/* {[
                                PARCEL_CONSTANTS.ALL,
                                PARCEL_CONSTANTS.ACTIVE,
                                PARCEL_CONSTANTS.READY_PENDING,
                            ].includes(props?.category) && !allParcelsLoaded && ((props.category === PARCEL_CONSTANTS.ACTIVE ?
                                shipments :
                                allParcelData) || []).filter(pd => !pd?.is_pending_payment)?.length === 10 && (
                                    <Button
                                        variant="primary"
                                        onClick={() => setShowConfirmation(true)}
                                    >
                                        {t("Load More")}
                                    </Button>
                                )} */}
                        </Row>
                    ) : (
                        <EmptyParcel
                            category={props.category}
                            isEnglish={props.isEnglish}
                            setCounts={props.setCounts}
                            counts={props.counts}
                            count={count}
                        />
                    )}

                    {[PARCEL_CONSTANTS.ALL, PARCEL_CONSTANTS.DELIVERED, PARCEL_CONSTANTS.ACTIVE, PARCEL_CONSTANTS.READY_PENDING].includes(props.category) &&
                        // ((props.category === PARCEL_CONSTANTS.ACTIVE &&
                        //     shipments?.length !== count)
                        //     || (props.category === PARCEL_CONSTANTS.DELIVERED &&
                        //         delParcelData?.length !== count) ||
                        //     (props.category === PARCEL_CONSTANTS.ALL && allParcelData?.length !== count)) && 
                        showBackBtn && (
                            <a
                                // to={DASHBOARD}
                                id="dashboard-search-back"
                                className="row align-items-center mb-2"
                                onClick={() => {
                                    // switch (props?.category) {
                                    //     case PARCEL_CONSTANTS.ACTIVE:
                                    //     case PARCEL_CONSTANTS.READY_PENDING:
                                    //         setShipments(getLocalStorageValue("shipments"));
                                    //         break;
                                    //     case PARCEL_CONSTANTS.DELIVERED:
                                    //         setDelParcelData(getLocalStorageValue("delParcels"));
                                    //         break;
                                    //     default:
                                    //         setAllParcelData(getLocalStorageValue("allParcels"));
                                    //         break;
                                    // }
                                    setShowBackBtn(false)
                                    setReload(!reload)
                                    props?.removeQueryParam("to")
                                    props?.removeQueryParam("from")
                                    props?.removeQueryParam("uuid")
                                }}
                            >
                                {i18n.language === "ar" && (
                                    <img
                                        alt="..."
                                        src={BackButtonSvg}
                                        className="ml-3 mr-3 rotate-180"
                                    />
                                )}
                                {i18n.language !== "ar" && (
                                    <img
                                        alt="..."
                                        src={BackButtonSvg}
                                        className="ml-3 mr-3"
                                    />
                                )}
                                <p className="mb-0 text-start">{t("Back")}</p>
                            </a>
                        )}
                </Col>
            </Row>
            {/* <ConfirmationModal
                show={showConfirmation}
                handleClose={() => setShowConfirmation(false)}
                title={"Confirmation"}
                img={modalIcon}
                options={["no", "yes"]}
                message={
                    "This process might take some time, while we retrieve all parcel details, Are you sure you want to continue"
                }
                handleOptionClick={handleOptionClick}
                isMobile={props.isMobile}
            /> */}
            <ItemsModal
                show={showItemsModal}
                handleClose={() => {
                    setParcel(null)
                    setShowItemsModal(false)
                }}
                parcel={parcel}
                isMobile={props?.isMobile}
                suggestions={props?.suggestions}
                suppliers={props?.suppliers}
                handleSubmit={handleSubmit}
            />
            <ResultsModal
                showModal={showResultsModal}
                setShowModal={setShowResultsModal}
                onSuccess={() => setReload((reload) => !reload)}
            />
        </>
    );
};

export default Parcel