import { Alert } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import { postUpdatePassword } from "../../services/userInfoService";

export default (props) => {
	const { showCancel = true, callback } = props;
	const { t, i18n } = useTranslation();
	const [passwordForm, setPasswordFrom] = useState({
		oldPassword: "",
		newPassword: "",
	});
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	const [error, setError] = useState(null);
	const { userEmail } = useAuth();

	let name, value;
	const handleInputs = (event) => {
		name = event.target.name;
		value = event.target.value;
		setPasswordFrom({ ...passwordForm, [name]: value });
	};

	const handleConfirmPass = (e) => {
		setConfirmNewPassword(e.target.value);
	};

	useEffect(() => {
		if (confirmNewPassword !== passwordForm.newPassword) {
			setError("Passwords don't match");
		} else {
			setError(null);
		}
	}, [confirmNewPassword]);

	async function handlePasswordUpdate(e) {
		e.preventDefault();
		const passObject = { email: userEmail, ...passwordForm };
		try {
			const res = await postUpdatePassword(passObject);
			if (res?.error) {
				throw new Error(res.error);
			}
			setError(null);
			props.setSuccessInfo(t("Successfuly updated the Password"));
			callback();
		} catch (e) {
			setError(e.message);
		}
	}

	return (
		<div className="mt-2">
			<form onSubmit={handlePasswordUpdate}>
				<div className="form-group">
					<label htmlFor="resetPasswordOld">{t("Current-password")}</label>
					<input
						type="password"
						className="form-control"
						id="resetPasswordOld"
						name="oldPassword"
						value={passwordForm.password}
						onChange={handleInputs}
						required
					/>
				</div>
				<div className="form-group">
					<label htmlFor="resetPasswordNew">{t("New-password")}</label>
					<input
						type="password"
						className="form-control"
						id="resetPasswordNew"
						name="newPassword"
						value={passwordForm.newPassword}
						onChange={handleInputs}
						required
					/>
				</div>
				<div className="form-group">
					<label htmlFor="resetPasswordConfirm">{t("Confirm-password")}</label>
					<input
						type="password"
						className="form-control"
						name="newPassword"
						value={confirmNewPassword}
						onChange={handleConfirmPass}
						required
					/>
					<small id="emailHelp" className="form-text text-muted">
						{t("Password-description")}
					</small>
					{!!error && (
						<Alert variant={"danger"}>
							{error}
						</Alert>
					)}
					{/* Password must contain at least 8 characters, including UPPER/Lowercase, special character and a number */}
				</div>
				<div className="row justify-content-end mr-0 ml-0 mt-2">
					<button
						className={
							"btn btn-light mr-2 ml-2 " + (showCancel ? "" : "d-none")
						}
						onClick={callback}
					>
						{t("Cancel")}
					</button>
					<button type="submit" className="btn btn-primary">
						{t("Save")}
					</button>
				</div>
			</form>
		</div>
	);
};
