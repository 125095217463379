import React, { useState, useEffect, useMemo } from "react";

import {
	Form,
	InputGroup,
	Button,
	Row,
	Col
} from "react-bootstrap";

import { useTranslation } from "react-i18next";

import {
	PARCEL_CONSTANTS
} from "../../../constants/constants.js";

export default (props) => {
	const { t } = useTranslation();

	const [title, setTitle] = useState(null);
	const [imgURL, setImgURL] = useState("");
	const [message, setMessage] = useState(null);
	const [linkComponent, setLinkComponent] = useState(null);

	useEffect(() => {
		setParcelValues();
	}, [props.isEnglish]);

	useEffect(() => {
		props.setCounts({ ...props.counts, [props?.category]: props?.count });

	}, []);

	const setParcelValues = () => {
		switch(props.category) {
		case PARCEL_CONSTANTS.ACTIVE:
			setTitle(t("Currently you don’t have any active parcels"));
			setImgURL("/images/general/active-p-img.svg");
			setMessage(<>
				<h1 className="gray-2">
					{t("No Parcel Yet!")}
				</h1>
				<p className="gray-2 empty-table-p">
					{t("There are currently no parcels that require")}
				</p>
				<p className="gray-2 empty-table-p">
					{t("Action on your part")}
				</p>
			</>);
			setLinkComponent(null);
			break;
		case PARCEL_CONSTANTS.DELIVERED:
			setTitle(t("Currently you don’t have any delivered parcels"));
			setImgURL("/images/general/delivered-p-img.svg");
			setMessage(<>
				<h1 className="gray-2">
					{t("No Parcel Yet!")}
				</h1>
				<p className="gray-2 empty-table-p">
					{t("There are currently no parcels that require")}
				</p>
				<p className="gray-2 empty-table-p">
					{t("Action on your part")}
				</p>
			</>);
			setLinkComponent(
				<a className="rounded btn btn_type_sml" href="#us_demo_address" data-toggle="modal" data-target="#us_demo_address" style={{"min-width": "312px", "text-transform": "none"}}>
					{t("How to use my account ID")}
				</a>
			);
			break;
		case PARCEL_CONSTANTS.EXCEPTION:
			setTitle(t("Currently you don’t have any exception parcels"));
			setImgURL("/images/general/active-p-img.svg");
			setMessage(<>
				<h1 className="gray-2">
					{t("No Parcel Yet!")}
				</h1>
				<p className="gray-2 empty-table-p">
					{t("There are currently no parcels that require")}
				</p>
				<p className="gray-2 empty-table-p">
					{t("Action on your part")}
				</p>
			</>);
			setLinkComponent(
				<a className="rounded btn btn_type_sml" href="#us_demo_address" data-toggle="modal" data-target="#us_demo_address" style={{"min-width": "312px", "text-transform": "none"}}>
					{t("How to use my account ID")}
				</a>
			);
			break;
		case PARCEL_CONSTANTS.READY_TO_SHIP:
		case PARCEL_CONSTANTS.ALL:
		default:
			setTitle(t("Currently you don’t have any packages to ship"));
			setImgURL("/images/general/ready-to-ship-img.svg");
			setMessage(<p className="gray-2 empty-table-p">
				{t("Enjoy shopping from US top brands, don’t")}
				<br />
				{t("Forget to use your account ID")}
			</p>);
			setLinkComponent(
				<a className="rounded btn btn_type_sml" href="#us_demo_address" data-toggle="modal" data-target="#us_demo_address" style={{"min-width": "312px", "text-transform": "none"}}>
					{t("How to use my account ID")}
				</a>
			);
			break;
		}
	};

	return (<>
		<Row className="padding-left-right-15">
			<Col md={"12"} className="no-parcel-title">
				<p className={`${props.isEnglish ? "text-left" : "text-right"} fs-16 gray-1 fstyle-n line-height-p160 mb-0`}>
					{title}
				</p>
			</Col>
		</Row>
		<Row>
			<Col md={"12"} className="text-center empty-parcel-div">
				<img alt="..." src={imgURL} />
				{message}
				{linkComponent}
			</Col>
		</Row>
	</>);
};