import React from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
import TabBody from "./tabBody";
function Tab1() {
	const { t } = useTranslation();
	const questionsAndAnswers = [
		{ containerId: "oneone", collapseId: "collapseOneOne", question: t("Qoneone"), answer: t("Aoneone") },
		{ containerId: "onetwo", collapseId: "collapseOneTwo", question: t("Qonetwo"), answer: t("Aonetwo") },
		{ containerId: "onethree", collapseId: "collapseOneThree", question: t("Qonethree"), answer: t("Aonethree") },
		{ containerId: "onefour", collapseId: "collapseOneFour", question: t("Qonefour"), answer: t("Aonefour") },
		{ containerId: "onefive", collapseId: "collapseOneFive", question: t("Qonefive"), answer: t("Aonefive") },
		{ containerId: "onesix", collapseId: "collapseOneSix", question: t("Qonesix"), answer: t("Aonesix") },
		{ containerId: "oneseven", collapseId: "collapseOneSeven", question: t("Qoneseven"), answer: t("Aoneseven") },
		{ containerId: "oneeight", collapseId: "collapseOneEight", question: t("Qoneeight"), answer: t("Aoneeight") },
		{ containerId: "onenine", collapseId: "collapseOneNine", question: t("Qonenine"), answer: t("Aonenine") },
		{ containerId: "oneten", collapseId: "collapseOneTen", question: t("Qoneten"), answer: t("Aoneten") },
		{ containerId: "oneeleven", collapseId: "collapseOneEleven", question: t("Qoneeleven"), answer: t("Aoneeleven") },
		{ containerId: "onetwelve", collapseId: "collapseOneTwelve", question: t("Qonetwelve"), answer: t("Aonetwelve") },
		{ containerId: "onethirteen", collapseId: "collapseOneThirteen", question: t("Qonethirteen"), answer: t("Aonethirteen") },
		{ containerId: "onefourteen", collapseId: "collapseOneFourteen", question: t("Qonefourteen"), answer: t("Aonefourteen") },
		{ containerId: "onefifteen", collapseId: "collapseOneFifteen", question: t("Qonefifteen"), answer: t("Aonefifteen") },
		{ containerId: "onesixteen", collapseId: "collapseOneSixteen", question: t("Qonesixteen"), answer: t("Aonesixteen") },
	]
	return (
		<>
			<div className="accordion" id="newCustomerQuestions">
				<div className="card align-items-start">
					<p className="sub-page-section-header mb-show-block text-start">{t("How to get started")}</p>
				</div>
				{questionsAndAnswers?.map(questionAndAnswer =>
					<TabBody questionAndAnswer={questionAndAnswer} />
				)}
			</div>
		</>
	);
}

export default Tab1;
