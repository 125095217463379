import React, { useEffect, useState } from "react";
import {
	Card
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import {
	STRIPE_PRIVATE_KEY
} from "../../constants/constants.js";
import CheckoutForm from "./checkout";
import { MaskedLoader } from "../common";

import "./style.scss";

const stripeTestPromise = loadStripe(process?.env?.REACT_APP_STRIPE_KEY);

export default (props) => {
	const { t, i18n } = useTranslation();

	const [loading, setLoading] = useState(true);
	const [loaderMessage, setLoaderMessage] = useState("");

	useEffect(() => {
		setLoaderMessage(t("Please wait while we load your payment details"));
	}, []);

	return (
		<Card className="payment-card">
			{props.shipmentLoading || loading 
				? <MaskedLoader message={loaderMessage} /> 
				: null
			}
			<Card.Body>
				<Card.Title className={'mb-4'}>
					<h2 className="font32-gry1 mb-0 pb-2">
						{props.paymentType.includes("membership") ? t("Billing Details") : t("Shipping Payment")}
					</h2>
				</Card.Title>
				{/*{props.description &&
					<Card.Text>
						{props.description}
					</Card.Text>
				}*/}
				<Elements stripe={stripeTestPromise}>
					<CheckoutForm {...props} setLoading={setLoading} setLoaderMessage={setLoaderMessage} />
				</Elements>
			</Card.Body>
		</Card>
	);
};