import _ from "lodash";

import {generateCostAndCustomFee} from "../utils/shipping";
import {postData, getData} from "../utils/api";

export const checkToggleByName = async (urlInternalName) => {
    const response = await getData("userService", `/check/toggle?name=${urlInternalName}`, null);
        if (!response.status) {
            return 0;
        }
	return response?.payload;
};

export const consolidateRequest = async (paymentDetails, authToken) => {
    //const fee = generateCostAndCustomFee(parcels, paymentDetails);
    let response = await postData("parcelService", "/consolidation-request", {
        "parcel_ids": paymentDetails?.parcel_ids,
        "shipping_company_id": paymentDetails?.shipping_company_id,
        "shipping_service": paymentDetails?.service,
        "user_address_id": paymentDetails?.user_address_id,
        "consolidation_type": paymentDetails?.consolidation_type,
		"add_on_services" : paymentDetails?.add_on_services,
    	"promo_code": paymentDetails?.promo_code
    }, authToken);
    if (!response.status) {
        console.log("error");
    }
    return response;
};

// export const generateConsolidatedRates = async (parcelDetails, authToken) => {
//     let response = await postData("shippingService", "/rates", {
//         "country_id": parcelDetails.address.country_id,
//         "weight": parcelDetails.totalWeight,
//         "weight_unit": parcelDetails.weightUnit,
//         "height": 1,
//         "width": 1,
//         "length": 1,
//         "dimension_unit": "in"
//     }, authToken);
//     if (!response.status) {
//         console.log("error - ", response);
//     }
//     return response.payload;
// };

// export const generateParcelRates = async (selectedParcels, authToken) => {
//     const parcelIDs = _.map(selectedParcels, "id");
//     let response = await postData("shippingService", "/parcel/rates", {
//         "parcel_ids": parcelIDs
//     }, authToken);
//     if (!response.status) {
//         console.log("error response - ", response);
//         return;
//     }
//     let parcelsRatesTemp = response.payload;
//     if (!parcelsRatesTemp.length) return;

//     return parcelsRatesTemp;
// };

export const generateShipmentLabel = async (parcels, paymentDetails, authToken, fee) => {
    //const fee = generateCostAndCustomFee(parcels, paymentDetails);
    let response = await postData("shippingService", "/shipment-label/generate", {
        "parcel_ids": _.map(parcels, "id"),
        "charges": fee.charges,
        "custom": fee.customs,
        "shipping_company_id": paymentDetails.shipping_company_id,
        "shipping_service": paymentDetails.service,
        "user_address_id": paymentDetails.address.id
    }, authToken);
    if (response.status) {
        // navigate to Payment success page
    }
};