import React from "react";
import { useTranslation } from "react-i18next";
import TabBody from "./tabBody";

function Tab2() {
	const { t } = useTranslation();
	const questionsAndAnswers = [
		{ containerId: "twoone", collapseId: "collapseTwoOne", question: t("Qtwoone"), answer: t("Atwoone") },
		{ containerId: "twotwo", collapseId: "collapseTwoTwo", question: t("Qtwotwo"), answer: t("Atwotwo") },
		{ containerId: "twothree", collapseId: "collapseTwoThree", question: t("Qtwothree"), answer: t("Atwothree") },
		{ containerId: "twofour", collapseId: "collapseTwoFour", question: t("Qtwofour"), answer: t("Atwofour") },
		{ containerId: "twofive", collapseId: "collapseTwoFive", question: t("Qtwofive"), answer: t("Atwofive") },
		{ containerId: "twosix", collapseId: "collapseTwoSix", question: t("Qtwosix"), answer: t("Atwosix") },
		{ containerId: "twoseven", collapseId: "collapseTwoSeven", question: t("Qtwoseven"), answer: t("Atwoseven") },
	]
	return (
		<>
			<div className="accordion" id="paymentsQuestions">
				<div className="card align-items-start">
					<p className="sub-page-section-header mb-show-block text-start">{t("Prohibited Items")}</p>
				</div>
				{questionsAndAnswers?.map(questionAndAnswer =>
					<TabBody questionAndAnswer={questionAndAnswer} />
				)}
			</div>
		</>
	);
}

export default Tab2;
