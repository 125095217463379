import React from 'react'
import Banner from './banner'
import BottomLinks from './bottomLinks'
import Steps from './steps'

export default () => {
  return (
    <>
        <Banner/>
        <Steps />
        <BottomLinks />
    </>
  )
}