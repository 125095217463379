import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TableList from "./tableList";

export default ({ vas }) => {
  const { t, i18n } = useTranslation();

  const tableColumns = [
    {
      dataField: "Warehouse",
      text: t("Warehouse"),
      formatter: (cell, row) => {
        return <>
          <img
            className="d-inline flags rounded"
            src={window.location.origin + `/images/flags/1x1/${cell?.country?.toLowerCase()}.svg`}
          />
          <span className="mx-2">{cell?.country}</span>
        </>
      }
    },
    {
      dataField: "service",
      text: t("Service"),
      formatter: (cell, row) => {
        return <span>{cell}</span>
      }
    },
    // {
    //   dataField: "min_value",
    //   text: t("Default"),
    //   formatter: (cell, row) => {
    //     return cell ? `${cell} $` : 0
    //   }
    // },

    {
      dataField: "service_value",
      text: t("Your Rate"),
      classes: "text-center",
      formatter: (cell, row) => {
        return <strong>{cell}&nbsp;{row?.service_type !== "flat" ? "%" : "$"}</strong>
      }
    }
  ];
  return (
    <Row className="myrates-request-container">
      <Col lg={"12"}>
        {vas?.length > 0 ?
          <>
            <p className="w-100 text-start my-3">{t("Value Added Services")}</p>
            <TableList
              data={vas}
              columns={tableColumns}
              buttonText={""}
              exportButton={true}
            />
          </>
          : null
        }
      </Col>
    </Row>
  )
}