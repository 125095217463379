import config from "../config";

const AppUrl = `${config.app.domain}${config.app.apiUrl}`;

/**
 *
 * @param internalUrl
 * @param authToken
 * @param data
 * @returns {Promise<*>}
 */
export async function getApi(internalUrl, authToken, data) {
	const url = AppUrl + internalUrl;
	let dataFromResponse;
	if (authToken) {
		if (data) {
			await fetch(url, {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${authToken}`,
				},
				body: JSON.stringify(data),
			})
				.then((response) => response.json())
				.then((data) => (dataFromResponse = data))
				.catch((error) => {
					console.error("Error: ", error);
				});
		} else {
			await fetch(url, {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${authToken}`,
				},
			})
				.then((response) => response.json())
				.then((data) => (dataFromResponse = data))
				.catch((error) => {
					console.error("Error: ", error);
				});
		}
	} else {
		if (data) {
			await fetch(url, {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			})
				.then((response) => response.json())
				.then((data) => (dataFromResponse = data))
				.catch((error) => {
					console.error("Error: ", error);
				});
		} else {
			await fetch(url, {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			})
				.then((response) => response.json())
				.then((data) => (dataFromResponse = data))
				.catch((error) => {
					console.error("Error: ", error);
				});
		}
	}
	return dataFromResponse;
}

/**
 *
 * @param internalUrl
 * @param authToken
 * @param data
 * @param put
 * @returns {Promise<*>}
 */
export async function postApi(internalUrl, authToken, data, put = false) {
    const url = AppUrl + internalUrl;

    let dataFromResponse;
    if (authToken) {
        if (data) {
            await fetch(url, {
                method: put ? "PUT" : "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => (dataFromResponse = data))
                .catch((error) => {
                    console.error("Error: ", error);
                });
        } else {
            await fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
            })
                .then((response) => response.json())
                .then((data) => (dataFromResponse = data))
                .catch((error) => {
                    console.error("Error: ", error);
                });
        }
    } else {
        if (data) {
            await fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => (dataFromResponse = data))
                .catch((error) => {
                    console.error("Error: ", error);
                });
        } else {
            await fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((data) => (dataFromResponse = data))
                .catch((error) => {
                    console.error("Error: ", error);
                });
        }
    }

    return dataFromResponse;
}

/**
 *
 * @param response
 * @returns {*}
 */
// function handleErrors(response) {
//     return response.json();
// }
