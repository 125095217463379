import styles from "./styles.module.scss";

const Title = () => {
    return (
        <>
            <div className={`${styles["shopini-logistics"]}`}>
                E-COMMERCE SOLUTIONS
            </div>
            <div className={`${styles["title"]}`}>
                Customized International Shipping Solutions:
            </div>
            <div className={`${styles["sub-title"]}`}>
                Optimize your Line-haul & Last mile carriers preferences
            </div>
        </>
    );
};

export default Title;
