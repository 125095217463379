import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import RequestImageView from "../../dashboard/parcel/images";
import { Row, Col } from "react-bootstrap";
import filterFactory from "react-bootstrap-table2-filter";
import { parcelTableDateFormat } from "../../../utils/moment";
import DownloadExcelIcon from "./downloadexcel.svg";
import EditIcon from "../../dashboard/parcel/editIcon.svg";
import { useAuth } from "../../../contexts/AuthContext";
import UploadOrders from "./uploadOrders";
import ResultsModal from "../../resultsModal";
import { useMediaQuery } from "react-responsive";
import { postData } from "../../../utils/api";
import InBoundTrackingModal from "./inBoundTrackingModal";
import DownloadIcon from "./downloadIcon.svg";
import { INCOMING_ORDERS_CONSTANTS } from "../../../constants/constants";
import OrderDetails from "./OrderDetails";
import SplitOrders from "./splitOrders";
import SplitIcon from "./split.svg"

export const SearchBar = (props) => {
	let input;
	const searchClick = () => {
		props?.onSearch(input?.value);
	};
	return (
		<div className="input-group float-right">
			<input
				className="form-control"
				ref={(n) => (input = n)}
				type="text"
				onKeyUp={searchClick}
				placeholder={"Search"}
			/>
			{/* <div className="input-group-btn px-2" onClick={searchClick}>
                <Search color="#2D3748" size={10} className="mx-2 my-2 arrow-icon" />
            </div> */}
		</div>
	);
};


function TableList(props) {

	const { t, i18n } = useTranslation();
	const { authToken, warehouseId, warehousesList } = useAuth();
	const [reqId, setReqId] = useState(null)
	const [subTableOptions, setSubTableOptions] = useState({
		custom: true,
		totalSize: 0,
		page: 1,
		sizePerPage: 20,
		hideSizePerPage: true,
		hidePageListOnlyOnePage: true,
		showTotal: false,
		prePageText: t("Previous"),
		nextPageText: t("Next"),
	})
	const [orderData, setOrderData] = useState({})
	const [showModal, setShowModal] = useState("")
	const [parcel, setParcel] = useState({})
	const [showResultsModal, setShowResultsModal] = useState({
		show: 0,
		status: false
	})
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" })
	const { options = {}, setOptions = null, category = "", count = 0 } = props

	const customTotal = (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
			{from}&nbsp;-&nbsp;{to}&nbsp;{t("of")}&nbsp;{size}&nbsp;{t("Items")}
		</span>
	);


	useEffect(() => {
		props?.setCounts({ ...props?.counts, [category]: count || 0 })
		setOptions({
			...options,
			totalSize: {
				...options?.totalSize,
				[category === INCOMING_ORDERS_CONSTANTS.INCOMING_ORDERS_VAL ? "lists" : "parcels"]: count
			},
		})
	}, [count])


	const expandRow = {
		renderer: row => {
			const columns = [
				{
					dataField: "purchase_order_number",
					text: t("Order Number"),
				},
				{
					dataField: "uuid",
					text: t("Parcel ID"),
				},
				{
					dataField: "recipient_name",
					text: t("Recipient Name"),
					classes: "w-130-px",
					formatter: (cell, row) => {
						return cell && cell !== "" ?
							<span>
								{cell}
								{/* <br />
							<small className="text-muted d-block">{cell?.address}</small>
							<small className="text-muted d-block">{cell?.address2}</small> */}
							</span> : "-"
					}
				},
				// {
				// 	dataField: "ParcelItems",
				// 	text: t("Item Description"),
				// 	classes: "w-200-px",
				// 	formatter: (cell, row) => {
				// 		return cell?.length > 0 ? `${cell[0]?.item_name?.substring(0, 99)}...` : "-"
				// 	}
				// },
				{
					dataField: "quantity",
					text: t("SH Qty"),
					classes: "text-center",
					formatter: (cell, row) => {
						return cell || 0
					}
				},
				{
					dataField: "received_qty",
					hidden: Number(row?.upload_type) !== 4,
					text: t("Received Qty"),
					classes: "text-center",
					formatter: (cell, row) => {
						return cell || 0
					}
				},
				{
					dataField: "order_qty",
					hidden: Number(row?.upload_type) !== 4,
					text: t("Order Qty"),
					classes: "text-center",
					formatter: (cell, row) => {
						return cell || 0
					}
				},
				{
					// dataField: "ParcelHasStatuses",
					text: t("Status"),
					classes: "font-12",
					formatter: (cell, row) => {
						let colorClass = "";
						if ([20, 23, 39].includes(row?.parcel_status_id)) {
							colorClass = "status-reject";
						} else if ([22, 14, 9, 34, 38].includes(row?.parcel_status_id)) {
							colorClass = "status-completed";
						} else if ([1, 3, 4].includes(row?.parcel_status_id)) {
							colorClass = "status-ready";
						} else {
							colorClass = "status-in-progress"
						}

						return (
							<div className="status">
								<div className="d-inline">
									<span
										className={`status-icon ${colorClass} `}
									></span>

									<span className={`status-text-expand ${colorClass}`}>
										{t(`${row?.reason}`)}
									</span>
								</div>
							</div>
						);
					},
				},
				{
					dataField: "parcel_received_at",
					text: t("Recieved Date"),
					classes: "date-format",
					formatter: (cell, row) => {
						// const warehouseCountry = warehousesList?.find(w => w?.id === (row.warehouse_id || 1))?.country || "AE"
						let dateAndTime, date, time
                		if (cell && cell !== "") {
							dateAndTime = parcelTableDateFormat(cell, true, "US")?.split(",")
							date = dateAndTime[0].trim()
							time = dateAndTime[1].trim()
						}
						return <>
							<span className="d-block">{date}</span>
							<small className="text-muted">{time}</small>
						</>
					}
				},
				{
					dataField: "new_awb_number",
					text: t("Shopini AWB"),
					formatter: (cell, row) => {
						return cell ? <>
							{cell}
							{ ( row?.awb_link && row?.awb_link !== "" ) ? <img 
								onClick={() => window.open(`${row?.awb_link}`, "_blank")}
								className={`tr-cursor ${isMobile ? "mx-1" : "mx-2"}`} src={DownloadIcon}
							/> : null }
						</> : "-"
					}
				},
				{
					dataField: "purchase_tracking_number",
					text: t("Inbound AWB"),
					formatter: (cell, row) => {
						return <span>
							{cell || <small className="text-w-opacity">{t("Add Tracking number")}</small>}
							<img
								onClick={() => {
									setOrderData({
										parcelId: row?.parcel_id,
										newInbound: cell || "-",
										orderId: row?.purchase_order_number
									})
									setShowModal("updateInboundAWB")
								}}
								className={`tr-cursor ${isMobile ? "mx-1" : "mx-2"}`} src={EditIcon}
							/>
						</span>
					}
				},
				{
					dataField: "",
					text: "",
					hidden: row?.upload_type && Number(row?.upload_type) !== 4,
					formatter: (cell, row) => {
						return <a className="order-details-link" onClick={() => {
							setParcel({...row})
							setShowModal("orderDetails")
						}}>
							{t("View Details")}
						</a>
					}
				},
				{
					dataField: "",
					text: t("Actions"),
					formatter: (cell, row) => {
						return Number(row?.quantity) <= 1 ? "-" : <a
						className={`nowrap-space p-2 rounded light-blue-bg add-edit-items-hover`}
						onClick={() => {
							setParcel({ ...row })
							setShowModal("splitOrder")
						}}
					>
						<img src={SplitIcon} />
						&nbsp;
						{t("Split")}
					</a>
					}
				}
			]
			return (
				<div className="grey-background py-3 px-3" key={`${row?.list_id}-incoming-order`}>
					<div className="d-flex align-items-center justify-content-end mb-3">
						<button
							className="text-white text-capitalize border-radius-5 btn-bg-color d-flex flex-row justify-content-center text-decoration-none px-4 py-2"
							onClick={() => window.open(`${process?.env?.REACT_APP_PARCEL_SERVICES}/api/download/awb-number?warehouse_id=${warehouseId}&list_upload_id=${row?.list_id}`, "_blank")}
						>
							<img className="d-inline" src={DownloadExcelIcon} />
							&nbsp;&nbsp;{t("Download Excel")}
						</button>
					</div>
					<PaginationProvider
						pagination={paginationFactory({ ...subTableOptions, totalSize: row?.parcels?.length, paginationTotalRenderer: customTotal })}
					>
						{
							({
								paginationProps,
								paginationTableProps
							}) => (
								<>
									<BootstrapTable
										keyField="parcel_id"
										data={row?.parcels}
										columns={columns}
										wrapperClasses="table-responsive spacing-table"
										headerClasses="tbl-hd-bg font-12"
										rowClasses="row-class bg-white border-right border-left font-12"
										noDataIndication={t("No_Record")}
										{...paginationTableProps}
										onTableChange={(type, { page, sizePerPage, totalSize }) => setSubTableOptions({ ...subTableOptions, page, sizePerPage, totalSize })}
									/>
									<div className="w-100 d-flex align-items-center justify-content-end my-3">
										<PaginationTotalStandalone
											{...paginationProps}

										/>
										<PaginationListStandalone
											{...paginationProps}
										/>
									</div>
								</>)}
					</PaginationProvider>
				</div>
			)
		},
		showExpandColumn: category === INCOMING_ORDERS_CONSTANTS.INCOMING_ORDERS_VAL,
		expandColumnPosition: "right",
		expandByColumnOnly: true,
		expandHeaderColumnRenderer: ({ isAnyExpands }) => {
			return "";
		},
		expandColumnRenderer: ({ expanded }) => {
			if (expanded) {
				return (
					<i className="bx bx-chevron-up font-size-20"></i>
				);
			}
			return (
				<i className="bx bx-chevron-down font-size-20"></i>
			);
		}
	}

	const handleSubmit = async (requestData) => {
		try {
			let res = await postData("parcelService", "/parcels-lists/split-redundant-order", requestData, authToken)
			setShowResultsModal({
				show: 1,
				status: res?.status,
				message: res?.message
			})
		} catch (error) {
			setShowResultsModal({
                show: 1,
                status: false,
                message: "Something went worng"
            })
		}
	}

	return (

		<div className="section-container">
			<ToolkitProvider
				keyField="uuid"
				data={props?.parcelData || []}
				columns={props?.columns}
				search
			>
				{
					props => {
						return (
							<div className="table-container my-2">
								<Row className={"table-data"}>
									<Col>
										{/* <SearchBar {...props.searchProps} /> */}
										<PaginationProvider
											pagination={paginationFactory({ 
												...options, 
												page: options.page[category === INCOMING_ORDERS_CONSTANTS.INCOMING_ORDERS_VAL ? "lists" : "parcels"],
												totalSize : count,
												paginationTotalRenderer: customTotal 
											})}
										>
											{
												({
													paginationProps,
													paginationTableProps
												}) => (
													<div>
														<BootstrapTable
															{...props?.baseProps}
															remote={true}
															keyField={"list_id"}
															onTableChange={(type, { page, sizePerPage, totalSize }) => {
																setOptions({ 
																	...options, 
																	page : {
																		...options?.page,
																		[category === INCOMING_ORDERS_CONSTANTS.INCOMING_ORDERS_VAL ? "lists" : "parcels"]: page,
																	},
																	sizePerPage, 
																	totalSize : {
																		...options?.totalSize,
																		[category === INCOMING_ORDERS_CONSTANTS.INCOMING_ORDERS_VAL ? "lists" : "parcels"]: totalSize,
																	},
																})
															}}
															wrapperClasses="table-responsive spacing-table"
															headerClasses="tbl-hd-bg"
															bordered={false}
															rowClasses="row-class"
															filter={filterFactory()}
															expandRow={expandRow}
															{...paginationTableProps}
														/>
														<div className="w-100 d-flex align-items-center justify-content-end my-3">
															<PaginationTotalStandalone
																{...paginationProps}
															/>
															<PaginationListStandalone
																{...paginationProps}
															/>
														</div>
													</div>
												)
											}
										</PaginationProvider>
									</Col>
								</Row>
							</div>
						)
					}
				}
			</ToolkitProvider>
			<InBoundTrackingModal
				show={showModal === "updateInboundAWB"}
				handleClose={() => {
					setOrderData({})
					setShowModal("")
				}}
				setShowResultsModal={setShowResultsModal}
				orderData={orderData}
			/>
			<ResultsModal
				showModal={showResultsModal}
				setShowModal={setShowResultsModal}
				onSuccess={() => props?.setReload(!props?.reload)}
			/>
			<OrderDetails 
				parcel={parcel}
				show={showModal === "orderDetails"}
				handleClose={() => {
					setShowModal("")
					setParcel({})
				}}
				isMobile={isMobile}
			/>
			<SplitOrders 
				parcelId={parcel.uuid}
				show={showModal === "splitOrder"}
				handleClose={() => {
					setShowModal("")
					setParcel({})
				}}
				handleSubmit={handleSubmit}
				setShowResultsModal={setShowResultsModal}
			/>
		</div>
	);
}


export default TableList;