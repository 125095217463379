import React from "react";

import {
	Spinner
} from "react-bootstrap";

import {
	MaskedLoader
} from "./style";


export default ({message}) => {
	return (
		<MaskedLoader>
			{message 
				? <h5>
					{message}
				</h5>
				: null
			}
			<Spinner animation="border" role="status">
				<span className="sr-only">Loading...</span>
			</Spinner>
		</MaskedLoader>
	);
};