import React, { useState, useEffect } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import { useAuth } from "../../contexts/AuthContext";
import Account from "../account";

export default ({isDrawerOpen, setIsDrawerOpen}) => {

	const { username, userPin } = useAuth();

	return (
		<>
			<SwipeableDrawer
				anchor={"right"}
				open={isDrawerOpen}
				onClose={() => setIsDrawerOpen(false)}
				onOpen={() => setIsDrawerOpen(true)}
				variant={"temporary"}
			>
				<Account
					user={{
						name: username,
						userPin, userPin
					}}
					onClose={() => setIsDrawerOpen(false)}
					onOpen={() => setIsDrawerOpen(true)}
				/>
			</SwipeableDrawer>
		</>
	);
};