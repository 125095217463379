import React from "react";
import stepFourSvg from "./step4.svg";
import { useTranslation } from "react-i18next";

function StepFour() {
  const { t, i18n } = useTranslation();
  return (
    <div className="main-section-container">
      <div className="container-fluid container-width">
        <div className="row justify-content-between justify-content-around-under-768">
          <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 post-450-start-text-align">
            <p className="description step-color-orange">{t("Step four")}</p>
            <h2 className="sub-page-section-header">
              {t("Ship with favorite company")}
            </h2>
            <p className="text-grey">
              {t("Ship with favorite company description 1")}
            </p>
            <p className="text-grey">
              {t("Ship with favorite company description 2")}
            </p>
            <p className="text-grey">
              {t("Ship with favorite company description 3")}
            </p>
            <ul className="text-grey">
              <li>{t("Ship with favorite company li 1")}</li>
              <li>{t("Ship with favorite company li 2")}</li>
              <li>{t("Ship with favorite company li 3")}</li>
            </ul>
          </div>
          <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 align-self-center text-center">
            <img alt="..." className="width-100 big-section-img" src={stepFourSvg} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepFour;
