import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import AddressFormat1 from "./AddressFormat1";
import AddressFormat2 from "./AddressFormat2";

const Address = () => {
    const { t, i18n } = useTranslation();

    const address = {
        fullName: "",
        addressLine1: "183 Edgemoor Road",
        addressLine2: "SW0000",
        city: "Wilmington",
        state: "Delaware",
        country: "United States of America",
        postCode: "19809",
        tel: "+1 8442746733",
    };

    return (
        <div className="address-container mx-auto">
            <div className="row mb-3">
                <div className="col-12 px-3 fs-2-custom blue-color text-center font-weight-bold">
                    {t("How to add your US address")}
                </div>
            </div>
            <div className="row mb-5">
                <div
                    className="col-12 px-3 fs-3-custom text-center mx-auto always-ensure"
                >
                    {t("How to add your US address Description")}
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-xl-6 mb-3">
                    <AddressFormat1 {...address}></AddressFormat1>
                </div>

                <div className="col-12 col-xl-6 mb-3">
                    <AddressFormat2 {...address}></AddressFormat2>
                </div>
            </div>
        </div>
    );
};

export default Address;
