import styles from "./styles.module.scss";

const HeaderContent = () => {
    return (
        <div>
            <div className={`${styles["shopini-logistics"]}`}>
                E-COMMERCE SOLUTIONS
            </div>
            <div className={`${styles["are-you-retailer"]}`}>
                Are you an online retailer? Selling on Amazon or Noon?
            </div>
            <div className={`${styles["upscale-business"]}`}>
                Upscale your business with us Now !
            </div>
        </div>
    );
};

export default HeaderContent;
