import React, { useState, cloneElement } from "react";
import styles from "./modal.styles.module.scss";
import { useTranslation } from "react-i18next";
import { TextCopyOverlay } from "../../common";

const generateAddressJsonExample1 = ({
    t,
    fullName,
    country,
    address,
    address2,
    userPin,
    city,
    state,
    zip,
    phoneNumber,
}) => {
    return JSON.stringify({
        [t("Full Name")]: fullName,
        [`${t("dashboard_modal_Address")} 1`]: `${address}`,
        [`${t("dashboard_modal_Address")} 2`]: `${address2}`,
        [country?.toLowerCase() === "gb" ? t("City / Town") : t("City")]: city,
        [country?.toLowerCase() === "gb"
            ? t("State / County")
            : t("State / Province")]: state,
        [t("Zip")]: zip,
        [t("Phone number")]: phoneNumber,
    });
};

const generateAddressJsonExample2 = ({
    t,
    fullName,
    address,
    userPin,
    city,
    state,
    zip,
    phoneNumber,
    country,
}) => {
    return JSON.stringify({
        [t("Full Name")]: fullName,
        [`${t("dashboard_modal_Address")}`]: `${address}`,
        [country?.toLowerCase() === "gb" ? t("City / Town") : t("City")]: city,
        [country?.toLowerCase() === "gb"
            ? t("State / County")
            : t("State / Province")]: state,
        [t("Zip")]: zip,
        [t("Phone number")]: phoneNumber,
    });
};

const Example1Card = ({
    t,
    country,
    fullName,
    address,
    address2,
    userPin,
    city,
    state,
    zip,
    phoneNumber,
}) => {
    return (
        <div className={`mb-2 ${styles["card"]}`}>
            <div className="row">
                <div className="col-12">
                    <div
                        className={`mb-3 d-flex justify-content-between align-items-center ${styles["country-title"]}`}
                    >
                        <div className="d-flex flex-row">
                            <span
                                className={`d-inline-block ${styles["flag"]}`}
                            >
                                <img
                                    alt=""
                                    width="100%"
                                    src={`/images/flags/1x1/${country?.toLowerCase()}.svg`}
                                />
                            </span>
                            <span>
                                {t("dashboard_modal_Example", {
                                    country: t(country?.toUpperCase()),
                                })}
                            </span>
                            &nbsp;1
                        </div>
                        <TextCopyOverlay
                            textToBeCopied={generateAddressJsonExample1({
                                t,
                                fullName,
                                country,
                                address:
                                    country?.toLowerCase() === "cn"
                                        ? `(${userPin}) ${address}`
                                        : `${address}`,
                                address2:
                                    country?.toLowerCase() === "us"
                                        ? userPin
                                        : address2 ?? userPin,
                                userPin,
                                city:
                                    country?.toLowerCase() === "gb"
                                        ? `${city} (${userPin})`
                                        : city,
                                state,
                                zip,
                                phoneNumber,
                            })}
                        >
                            <div
                                className={`${styles["copy-all"]} ${styles["button-icon"]} d-flex align-items-center`}
                            >
                                <img
                                    className={`${styles["copy-all-icon"]}`}
                                    src={require("../../common/TextCopyOverlay/copy-icon.svg").default}
                                    alt=""
                                />
                                <span>{t("Click to Copy")}</span>
                            </div>
                        </TextCopyOverlay>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="mb-3 col-12">
                    <span>{t("Example case for address line 1 and 2")}</span>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-4">
                    <div className="h-100 d-flex align-items-center">
                        {t("Full Name")}
                    </div>
                </div>
                <TextCopyOverlay textToBeCopied={fullName}>
                    <div className="col-8">
                        <div className={`h-100 ${styles["form-field-value"]}`}>
                            {fullName}
                        </div>
                    </div>
                </TextCopyOverlay>
            </div>
            <div className="row mb-3">
                <div className="col-4">
                    <div className="h-100 d-flex align-items-center">
                        {t("dashboard_modal_Address")} 1
                    </div>
                </div>
                <TextCopyOverlay
                    textToBeCopied={
                        country?.toLowerCase() === "cn"
                            ? `(${userPin}) ${address}`
                            : address
                    }
                >
                    <div className="col-8">
                        <div className={`h-100 ${styles["form-field-value"]}`}>
                            {country?.toLowerCase() === "cn" ? (
                                <div dir="ltr">
                                    <span
                                        className={`${styles["text-orange"]}`}
                                    >{`(${userPin}) `}</span>
                                    <span>{address}</span>
                                </div>
                            ) : (
                                <span>{address}</span>
                            )}
                        </div>
                    </div>
                </TextCopyOverlay>
            </div>
            <div className="row mb-3">
                <div className="col-4">
                    <div className="h-100 d-flex align-items-center">
                        {t("dashboard_modal_Address")} 2
                    </div>
                </div>
                <TextCopyOverlay
                    textToBeCopied={
                        country?.toLowerCase() === "us"
                            ? userPin
                            : address2 ?? userPin
                    }
                >
                    <div className="col-8">
                        <div className={`h-100 ${styles["form-field-value"]}`}>
                            {country?.toLowerCase() === "us" ? (
                                <span className={` ${styles["text-orange"]}`}>
                                    {userPin}
                                </span>
                            ) : (
                                <span>{address2 ?? userPin}</span>
                            )}
                        </div>
                    </div>
                </TextCopyOverlay>
            </div>
            <div className="row mb-3">
                <div className="col-4">
                    <div className="h-100 d-flex align-items-center">
                        {country?.toLowerCase() === "gb"
                            ? t("City / Town")
                            : t("City")}
                    </div>
                </div>
                <TextCopyOverlay
                    textToBeCopied={
                        country?.toLowerCase() === "gb"
                            ? `${city} (${userPin})`
                            : city
                    }
                >
                    <div className="col-8">
                        <div className={`h-100 ${styles["form-field-value"]}`}>
                            {country?.toLowerCase() === "gb" ? (
                                <>
                                    <span>{city}</span>&nbsp;
                                    <span
                                        className={`${styles["text-orange"]}`}
                                    >
                                        {`(${userPin})`}
                                    </span>
                                </>
                            ) : (
                                <span> {city}</span>
                            )}
                        </div>
                    </div>
                </TextCopyOverlay>
            </div>
            <div className="row mb-3">
                <div className="col-4">
                    <div className="h-100 d-flex align-items-center">
                        {country?.toLowerCase() === "gb"
                            ? t("State / County")
                            : t("State / Province")}
                    </div>
                </div>
                <TextCopyOverlay textToBeCopied={state}>
                    <div className="col-8">
                        <div className={`h-100 ${styles["form-field-value"]}`}>
                            {state}
                        </div>
                    </div>
                </TextCopyOverlay>
            </div>
            <div className="row mb-3">
                <div className="col-4">
                    <div className="h-100 d-flex align-items-center">
                        {t("Zip")}
                    </div>
                </div>
                <TextCopyOverlay textToBeCopied={zip}>
                    <div className="col-8">
                        <div className={`h-100 ${styles["form-field-value"]}`}>
                            {zip}
                        </div>
                    </div>
                </TextCopyOverlay>
            </div>
            <div className="row mb-3">
                <div className="col-4">
                    <div className="h-100 d-flex align-items-center">
                        {t("Phone number")}
                    </div>
                </div>
                <TextCopyOverlay textToBeCopied={phoneNumber}>
                    <div className="col-8">
                        <div
                            className={`h-100 ${styles["form-field-value"]}`}
                            dir="ltr"
                        >
                            {phoneNumber}
                        </div>
                    </div>
                </TextCopyOverlay>
            </div>
        </div>
    );
};

const Example2Card = ({
    t,
    country,
    fullName,
    address,
    address2,
    userPin,
    city,
    state,
    zip,
    phoneNumber,
}) => {
    return (
        <div className={`mb-2 ${styles["card"]}`}>
            <div className="row">
                <div className="col-12">
                    <div
                        className={`mb-3 d-flex justify-content-between align-items-center ${styles["country-title"]}`}
                    >
                        <div className="d-flex flex-row">
                            <span
                                className={`d-inline-block ${styles["flag"]}`}
                            >
                                <img
                                    alt=""
                                    width="100%"
                                    src={`/images/flags/1x1/${country?.toLowerCase()}.svg`}
                                />
                            </span>
                            <span>
                                {t("dashboard_modal_Example", {
                                    country: t(country?.toUpperCase()),
                                })}
                            </span>
                            &nbsp;2
                        </div>
                        <TextCopyOverlay
                            textToBeCopied={generateAddressJsonExample2({
                                t,
                                fullName,
                                address:
                                    country?.toLowerCase() === "us"
                                        ? `${address}, C/O ${userPin}`
                                        : `${address}, ${address2}`,
                                userPin,
                                city:
                                    country?.toLowerCase() === "gb"
                                        ? `${city} (${userPin})`
                                        : city,
                                state,
                                zip,
                                phoneNumber,
                                country,
                            })}
                        >
                            <div
                                className={`${styles["copy-all"]} ${styles["button-icon"]} d-flex align-items-center`}
                            >
                                <img
                                    className={`${styles["copy-all-icon"]}`}
                                    src={require("../../common/TextCopyOverlay/copy-icon.svg").default}
                                    alt=""
                                />
                                <span>{t("Click to Copy")}</span>
                            </div>
                        </TextCopyOverlay>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="mb-3 col-12">
                    <span>{t("Example case for address line 1")}</span>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-4">
                    <div className="h-100 d-flex align-items-center">
                        {t("Full Name")}
                    </div>
                </div>
                <TextCopyOverlay textToBeCopied={fullName}>
                    <div className="col-8">
                        <div className={`h-100 ${styles["form-field-value"]}`}>
                            {fullName}
                        </div>
                    </div>
                </TextCopyOverlay>
            </div>
            <div className="row mb-3">
                <div className="col-4">
                    <div className="h-100 d-flex align-items-center">
                        {t("dashboard_modal_Address")}
                    </div>
                </div>
                <TextCopyOverlay
                    textToBeCopied={
                        country?.toLowerCase() === "us"
                            ? `${address}, C/O ${userPin}`
                            : `${address}, ${address2}`
                    }
                >
                    <div className="col-8">
                        <div
                            className={`h-100 ${styles["double-field"]} ${styles["form-field-value"]}`}
                        >
                            <div>
                                {country?.toLowerCase() === "us" ? (
                                    <>
                                        <span>{address}</span>
                                        <br />
                                        <span
                                            className={`${styles["text-orange"]}`}
                                        >{`C/O ${userPin}`}</span>
                                    </>
                                ) : (
                                    `${address}, ${address2}`
                                )}
                            </div>
                        </div>
                    </div>
                </TextCopyOverlay>
            </div>
            <div className="row mb-3">
                <div className="col-4">
                    <div className="h-100 d-flex align-items-center">
                        {t("City")}
                    </div>
                </div>
                <TextCopyOverlay
                    textToBeCopied={
                        country?.toLowerCase() === "gb"
                            ? `${city} (${userPin})`
                            : city
                    }
                >
                    <div className="col-8">
                        <div className={`h-100 ${styles["form-field-value"]}`}>
                            {country?.toLowerCase() === "gb" ? (
                                <>
                                    <span>{city}</span>&nbsp;
                                    <span
                                        className={`${styles["text-orange"]}`}
                                    >
                                        {`(${userPin})`}
                                    </span>
                                </>
                            ) : (
                                city
                            )}
                        </div>
                    </div>
                </TextCopyOverlay>
            </div>
            <div className="row mb-3">
                <div className="col-4">
                    <div className="h-100 d-flex align-items-center">
                        {t("State / Province")}
                    </div>
                </div>
                <TextCopyOverlay textToBeCopied={state}>
                    <div className="col-8">
                        <div className={`h-100 ${styles["form-field-value"]}`}>
                            {state}
                        </div>
                    </div>
                </TextCopyOverlay>
            </div>
            <div className="row mb-3">
                <div className="col-4">
                    <div className="h-100 d-flex align-items-center">
                        {t("Zip")}
                    </div>
                </div>
                <TextCopyOverlay textToBeCopied={zip}>
                    <div className="col-8">
                        <div className={`h-100 ${styles["form-field-value"]}`}>
                            {zip}
                        </div>
                    </div>
                </TextCopyOverlay>
            </div>
            <div className="row mb-3">
                <div className="col-4">
                    <div className="h-100 d-flex align-items-center">
                        {t("Phone number")}
                    </div>
                </div>
                <TextCopyOverlay textToBeCopied={phoneNumber}>
                    <div className="col-8">
                        <div
                            className={`h-100 ${styles["form-field-value"]}`}
                            dir="ltr"
                        >
                            {phoneNumber}
                        </div>
                    </div>
                </TextCopyOverlay>
            </div>
        </div>
    );
};

const Modal = ({ modalData }) => {
    const { t, i18n } = useTranslation();
    const inversefloater =
        i18n.language === "en" ? "float-left" : "float-right";
    console.log(modalData);
    return (
        <div
            class="modal fade"
            id="us_demo_address"
            tabindex="-1"
            role="dialog"
            aria-labelledby="howToUseMyAddress"
            aria-hidden="true"
        >
            <div
                className={`modal-dialog modal-lg ${styles["modal"]}`}
                role="document"
            >
                <div className="modal-content">
                    <div className={`${styles["modal-body"]} modal-body`}>
                        <div className="d-flex justify-content-between align-items-center m-0 mb-1">
                            <div className="opacity-0"></div>
                            <p
                                className={`modal-title ${styles["modal-title"]}`}
                                id="howToUseMyAddress"
                            >
                                {t("How do I use my address")}
                            </p>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">
                                    <img
                                        className={`${styles["close-icon"]}`}
                                        src={
                                            require("./close-icon.svg").default
                                        }
                                        alt=""
                                    />
                                </span>
                            </button>
                        </div>
                        <p className="m-0 mb-2">
                            {t(
                                "Below you can find two examples-use cases on how you can insert your account number while you check out from any website"
                            )}
                        </p>
                        <div className={`${styles["cards-container"]}`}>
                            <Example1Card {...{ ...modalData, t }} />

                            <Example2Card {...{ ...modalData, t }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
