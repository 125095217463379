import React from "react";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ImageSetContainer from "./imageSetContainer";

function CarouselImageSet({ imagesSrc, banner = false, isMobile=false, isMediumLarge=false, source="home" }) {
	const srcStrings = [...imagesSrc];
	const { t, i18n } = useTranslation();

	let n = banner ? 1 : !isMobile ? isMediumLarge ? 4 : 6 : 3; //tweak this to add more images per container

	const result = new Array(Math.ceil(srcStrings.length / n))
		.fill()
		.map((_) => srcStrings.splice(0, n));

	const carouselItems = result.map((srcSet, index) => (
		<Carousel.Item className={source === "home" ? `item${index}` : `${source}${index}`} key={srcSet[0].path} interval={5000}>
			{banner && srcSet[0].bannerText}
			<ImageSetContainer srcSet={srcSet} banner={banner} isMobile={isMobile}></ImageSetContainer>
		</Carousel.Item>
	));
	// const extraItems = <Carousel.Item className={`new-year-${i18n.language}${isMobile ? "-mobile": ""}`} key={"extra"} interval={5000}/>
		
	return (
		<div className={!banner ? "container-fluid" : ""}>
			<Carousel className={!banner ? null : `carousel-banner ${!isMobile ? "desktop" : "mobile"}`}>{result.length !== 0 ? [( banner ? [] : null), ...carouselItems] : <></>}</Carousel>
		</div>
	);
}

export default CarouselImageSet;
