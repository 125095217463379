import { uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react'
import { getCountries } from '../services/userInfoService';

const useCountries = () => {
	const [countries, setCountries] = useState([]);
	const [locations, setLocations] = useState([]);

	useEffect(() => {
        const fetchedCountries = async () => {
			const data = await getCountries();
			setCountries(data?.data?.sort((a, b) => {
				if (b.name > a.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			}));
			setLocations(uniqBy(data?.data?.map(country => country.OfficeLocation), "id"))
		}
		fetchedCountries()
    }, [])

	return {countries, locations}
}

export default useCountries
