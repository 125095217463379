import React, { useState } from "react";
import {
	Row,
	Col
} from "react-bootstrap";
import RequestWidget from "./requestWidget";
import Banner from "./banner/banner";
import "./style.scss";
import { INCOMING_ORDERS_CONSTANTS } from "../../constants/constants";

export default () => {
	const [reload, setReload] = useState(false)
	const [orders, setOrders] = useState()
	const [parcels, setParcels] = useState()
	let search = window.location.search;
	let params = new URLSearchParams(search);
	let status = params.get("status") || "1";

	return (
		<section className="incoming-orders-container-wrapper">
			<Row>
				<Col>
					<Banner />
				</Col>
			</Row>
			<div className="incoming-orders-container">
				<Row className="row dashboard-row px-4 justify-content-center align-items-center">
					<Col xxl={11} xl={11} sm={12} className="">
						<RequestWidget
							params={params}
							status={status}
							reload={reload}
							setReload={setReload}
							orders={orders}
							setOrders={setOrders}
							parcels={parcels}
							setParcels={setParcels}
						/>
					</Col>
				</Row>
			</div>
		</section>
	)
};