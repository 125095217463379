import React, { useState, useEffect, useRef } from "react";

import {
	Form,
	InputGroup,
	Button,
	Row,
	Col
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import _ from "lodash";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from '../../../contexts/localStorage'

export default (props) => {
	const { t, i18n } = useTranslation();

	const queryParams = qs.parse(window.location.search);
	const [uuid, setUuid] = useState(queryParams.uuid || "");
	const [from, setFrom] = useState(queryParams.from || null);
	const [to, setTo] = useState(queryParams.to || null);
	const fromRef = useRef(null)
	const toRef = useRef(null)
	const uuidRef = useRef(null)

	const onParcelIDChange = (event) => {
		let value = event.target.value;
		setUuid(value);
		props.addQueryParam("uuid", value);
	};

	const search = () => {
		let filteredData = props.parcelData;
		if(props.category === 'all')
			filteredData = props.allData;
		if(props.category === '1')
			filteredData = props.pendingData;
		if(props.category === '2')
			filteredData = props.completedData;
        
		if(uuid && uuid !== "") {
			filteredData = _.filter(filteredData, {
				request_id: uuid
			});
		}
		if(from || to) {
			let toDate = to, fromDate = from;

			if(!toDate || toDate === "") {
				toDate = new Date();
				setTo(toDate);
			}

			toDate = new Date(toDate).getTime();

			if(!fromDate || fromDate === "") {
				filteredData = _.filter(filteredData, (data) => {
					let updatedAt = new Date(data.updated_at).getTime();
					return (updatedAt <= toDate);
				});
			} else {
				fromDate = new Date(fromDate).getTime();
				filteredData = _.filter(filteredData, (data) => {
					let updatedAt = new Date(data.updated_at).getTime();
					return (updatedAt <= toDate) && (updatedAt >= fromDate);
				});
			}
		}
		props.setParcelData([...filteredData]);
		if(!uuid && !from && !to){
			if(props.category === 'all')
				props.setParcelData([...props.allData])
			if(props.category === '1')
				props.setParcelData([...props.pendingData])
			if(props.category === '2')
				props.setParcelData([...props.completedData])
		}
		
	};

	useEffect(() => {
		props.addQueryParam("to", to);
	}, [to]);

	useEffect(() => {
		props.addQueryParam("from", from);
	}, [from]);

	useEffect(() => {
		search();	
	}, [props?.category]);

	useEffect(() => {
		if (!queryParams.uuid || queryParams.uuid === "") {
			setUuid("")
			uuidRef.current.value = ""
		}
		if (!queryParams.from || queryParams.from === null) {
			fromRef.current.value = ""
		}
		if (!queryParams.to || queryParams.to === null) {
			toRef.current.value = ""
		}
	}, [queryParams.uuid, queryParams.from, queryParams.to])

	return (
		<div className="parcel-search-form w-100">
			<div className="search-row">
				<Col md={"4"} sm={"12"} className="search-col">
					<InputGroup>
						<Form.Control defaultValue={uuid} ref={uuidRef} type="text" name="uuid" 
						placeholder={props.placeholder?props.placeholder:t("Type your parcel ID")} onChange={onParcelIDChange} />
						<InputGroup.Append>
							<span className="input-group-text">
								<FaSearch />
							</span>
						</InputGroup.Append>
					</InputGroup>
				</Col>
				<Col md={"3"} sm={"12"} className="block-margin-left search-col">
					<InputGroup>
						<Form.Control
							type="date"
							name="from"
							className="form-control-line-height"
							placeholder="From"
							defaultValue={from}
							ref={fromRef}
							onChange={(e) => setFrom(e.target.value) }
						/>
					</InputGroup>
				</Col>
				<Col md={"3"} sm={"12"} className="block-margin-left search-col">
					<InputGroup>
						<Form.Control
							type="date"
							name="to"
							className="form-control-line-height"
							placeholder="To"
							defaultValue={to}
							ref={toRef}
							onChange={(e) => setTo(e.target.value)}
						/>
					</InputGroup>
				</Col>
				<Col md={"2"} sm={"12"} className="block-margin-left search-col search-request-button">
					<Button type="submit" className="w-100" onClick={search}>
						{ t("Search") }
					</Button>
				</Col>
			</div>
		</div>
	);

};