import React, { useEffect, useState } from "react"
import { Redirect } from "react-router-dom"
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import _, { isUndefined } from "lodash"
import { DEFAULT_RATE, PARCEL_CONSTANTS, TOGGLES } from "../../constants/constants.js"
import * as NAVIGATION_CONSTANTS from "../../constants/navigationConstants"
import { useAuth } from "../../contexts/AuthContext"
import "./style.scss"

import { checkToggleByName, consolidateRequest } from "../../services/parcelApi"
import Services from "./services"
import PaymentSummary from "../parcelPaymentSummary"
import ShippingServiceModal from "./shippingServicesModal"
import AddressModal from "./address"

import { Loader } from "../common"
import { getData, postData } from "../../utils/api.js"
import BeforeConsolidationPaymentSummary from "../beforeConsolidationPaymentSummary"
import useUserAddresses from "../../utils/useUserAddresses.js"
import ResultsModal from "../resultsModal"
import useAnalytics from "../../analytics/useAnalytics.js";
import usePrevious from '../../utils/usePrevious.js'
import { screenEvent } from "../../analytics/constants.js"


export default (props) => {
    const { t, i18n } = useTranslation()
    const { authToken, warehouseId, country, warehousesList } = useAuth()

    const selectedParcels = (props.location && props.location.state && props.location.state.selectedParcels) || []

    const { addressList } = useUserAddresses()

    const [loading, setLoading] = useState(true)
    const [parcels, setParcels] = useState([])
    const [parcelsRates, setParcelsRates] = useState([])
    const [paymentDetails, setPaymentDetails] = useState({})
    const [proceedToPay, setProceedToPay] = useState(false)
    const [totalChargableWeight, setTotalChargableWeight] = useState({})
    const [promoCode, setPromoCode] = useState("")
    const [addOnServices, setAddOnServices] = useState([])
    const [consolidationType, setConsolidationType] = useState("")
    const [shippingAddress, setShippingAddress] = useState({})
    const [membershipServices, setMembershipServices] = useState([])
    const [totalParcelValue, setTotalParcelValue] = useState(0)
    const [optionsPage, setOptionsPage] = useState(true)
    const [payable, setPayable] = useState({})
    const [redirectToDashboard, setRedirectToDashboard] = useState(false)
    const [userAddressesState, setUserAddressesState] = useState(false)
    const [showByPassPaymentModal, setShowByPassPaymentModal] = useState({
        show: paymentDetails?.creditedUser || 0,
        status: 0
    })
    const previousPromoCode = usePrevious(promoCode)
    const warehouse = warehousesList.find(
        (warehouse) => warehouse.id === Number(warehouseId)
    )?.country;
    const analytics = useAnalytics();
    const parcelPaymentAttributes = {
        country: paymentDetails.address?.country,
        city: paymentDetails.address?.city,
        shippingCompany: paymentDetails.service,
        extraServices: (paymentDetails.add_on_services ?? []).map(
            (addonService) => addonService.service
        ),
        promoCode: !!paymentDetails.promo_code_discount?.is_valid
            ? paymentDetails.promo_code_discount.discount_code ??
              undefined
            : undefined,
        parcelIds: (parcels ?? []).map((parcel) => ({
            "Parcel ID": parcel.uuid,
            "Actual Weight": parcel.package_data?.actual_weight,
            "Volumetric Weight": parcel.package_data?.volumetric_weight,
            "Chargeable Weight": parcel.package_data?.chargeable_weight,
        })),
        totalChargeableWeight: totalChargableWeight,
        shippingFees: paymentDetails.shipping_charges,
        discountAmount: !!paymentDetails.promo_code_discount
            ?.is_valid
            ? paymentDetails.promo_code_discount.discounted_amount
            : undefined,
        subtotal: paymentDetails.sub_total,
        totalAmount: paymentDetails.total,
        tax: 0,
        warehouse,
    };

    const onSubmit = async () => {
        if (!paymentDetails?.address?.id) {
            document.querySelector("#change-address").click()
        } else {
            if (paymentDetails.consolidation_type !== "" && consolidationType !== "") {
                const tempPaymentDetails = {
                    parcel_ids: _.map(parcels, "id"),
                    shipping_company_id: paymentDetails.shipping_company_id,
                    service: paymentDetails.service,
                    consolidation_type: consolidationType,
                    promo_code: promoCode,
                    user_address_id: !shippingAddress?.id ? paymentDetails?.address?.id : shippingAddress?.id,
                    add_on_services: addOnServices
                }
                const response = await consolidateRequest(tempPaymentDetails, authToken)
                if (response.status) {
                    const {
                        country,
                        city,
                        shippingCompany,
                        extraServices,
                        promoCode,
                        parcelIds,
                    } = parcelPaymentAttributes;
                    analytics.events.shippingConsolidationRequestCompleted({
                        consolidationType,
                        country,
                        city,
                        shippingCompany,
                        extraServices,
                        promoCode,
                        parcelIds,
                        warehouse
                    });
                }
                if (!payable?.parcel_data) {
                    setRedirectToDashboard(true)
                }
            }

            else if (paymentDetails?.creditedUser) {
                const byPassPayload = {
                    parcel_ids: parcels?.map(parcel => parcel.id),
                    promo_code: promoCode,
                    country_id: paymentDetails?.address?.country,
                    add_on_services: addOnServices,
                    user_address_id: paymentDetails?.address?.id,
                    shipping_service: paymentDetails?.service,
                    shipping_company_id: paymentDetails?.shipping_company_id
                }
                try {
                    const byPassPaymentRes = await postData("paymentService", `/bypass-payment`, byPassPayload, authToken)
                    setShowByPassPaymentModal({
                        show: byPassPaymentRes?.status ? 1 : 0,
                        status: byPassPaymentRes?.status
                    })
                    if (byPassPaymentRes?.status)
                        analytics.events.shippingNoConsolidationCreditedCompleted(
                            parcelPaymentAttributes
                        );
                } catch (error) {
                    console.log(error?.message)
                    setShowByPassPaymentModal({
                        show: 1,
                        status: false
                    })
                }
            }

            else if (((!paymentDetails.consolidation_type || paymentDetails.consolidation_type === "") || payable) && !paymentDetails?.creditedUser) {
                setProceedToPay(true)
                analytics.events.shippingNoConsolidationPayNowButton(parcelPaymentAttributes);
            }
        }
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        })
    }

    const onAddressChange = async (address) => {
        await calculateRatesAndTotal(address)
        // setPaymentDetails({ ...paymentDetails, address })
        // setShippingAddress(address)
    }

    const calculateRatesAndTotal =
        async (address = {}) => {
            setLoading(true)
            // for the address, the priority is for the primary address 
            const request = {
                parcel_ids: selectedParcels.map(parcel => parcel.id),
                // consolidation_type: consolidationType,
                promo_code: promoCode,
                add_on_services: addOnServices,
                country_code: address.country
            }
            let tempCalculations = {}
            tempCalculations =
                await postData(
                    "shippingService", "/parcel/shipping/rates/v4", request, authToken
                )

            if (tempCalculations.payload) {
                let paymentDetailsTemp = tempCalculations.payload.rates?.length > 0 ?
                    _.isEmpty(paymentDetails) || !tempCalculations.payload?.rates?.find(rate => rate.service === paymentDetails.service)
                        ? tempCalculations.payload.rates.find(rate => rate.service === DEFAULT_RATE)
                        : tempCalculations.payload.rates.find(rate => rate.service === paymentDetails.service)
                    : {}

                if (isUndefined(paymentDetailsTemp)) {
                    /*
                    * if fedex is not available, we will show the cheapest as the default shipping service
                    * implemented on: 14th Sept, 2021
                    * implemented by: msamgan
                    * approved by: Saleem and Ismail.
                    *  */
                    paymentDetailsTemp = tempCalculations.payload.rates.reduce((prev, curr) => {
                        return prev.amount < curr.amount ? prev : curr
                    })
                }

                if ((previousPromoCode !== promoCode)&&!!promoCode) {
                    if (paymentDetailsTemp?.promo_code_discount?.is_valid) {
                        analytics.events.shippingApplyPromoCompleted({
                            promoCode:
                            paymentDetailsTemp.promo_code_discount
                                    .discount_code,
                            valid: true,
                        });
                    } else {
                        analytics.events.shippingApplyPromoCompleted({
                            promoCode:
                            paymentDetailsTemp.promo_code_discount
                                    .discount_code,
                            valid: false,
                        });
                    }
                }

                setParcels(tempCalculations.payload.parcel_data)
                setTotalChargableWeight(tempCalculations.payload?.payable && consolidationType !== "" ? tempCalculations.payload?.payable.total_chargeable_weight : tempCalculations.payload.total_chargeable_weight)
                setParcelsRates(tempCalculations.payload.rates)

                setShippingAddress(
                    address
                )

                setPaymentDetails({
                    ...paymentDetailsTemp,
                    address,
                    creditedUser: tempCalculations?.payload?.parcel_user?.is_credit_user || 0
                })

                tempCalculations.payload?.payable ? setPayable({ ...tempCalculations.payload?.payable, address }) : setPayable({})
                setUserAddressesState(tempCalculations.payload?.have_address)
                setTotalParcelValue(tempCalculations.payload?.payable ? tempCalculations.payload?.payable.total_parcel_value : tempCalculations.payload.total_parcel_value)
                setLoading(false)

            } else {
                setLoading(false)
                console.error(tempCalculations.message)
            }
            setOptionsPage(false)
        }


    useEffect(async () => {
        const selectedShippingAddress = _.isEmpty(shippingAddress) ? addressList?.length > 0 ? addressList?.find(address => address.is_primary)
            : { country } : shippingAddress
        if (selectedShippingAddress?.id || selectedShippingAddress?.country) {
            await calculateRatesAndTotal(selectedShippingAddress)
        }
    }, [promoCode, addOnServices.length])

    useEffect(async () => {
        const request =
            await getData(
                "pricingService",
                `/user/discount/services/v1?warehouse_id=${warehouseId}&category=parcel`,
                authToken
            )
        if (request.status && request.payload) {
            const membershipServicesFromApi = request.payload
            setMembershipServices(membershipServicesFromApi)
        }
    }, [warehouseId])

    useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.SHIP_NOW });
    }, []);


    return (
        <>
            {(!selectedParcels.length) ? <Redirect to={NAVIGATION_CONSTANTS.HOMEPAGE} /> : null}
            {proceedToPay &&
                <Redirect
                    to={{
                        pathname: NAVIGATION_CONSTANTS.SHIPPING_PAYMENT,
                        search: "",
                        state: payable && paymentDetails.consolidation_type && paymentDetails.consolidation_type !== "" ?
                            {
                                parcels,
                                payables: payable?.parcel_data,
                                totalChargableWeight: totalChargableWeight,
                                paymentDetails:
                                    shippingAddress?.id && !payable?.address?.id
                                        ? { ...payable?.rates?.find(rate => rate.service === paymentDetails.service), address: shippingAddress }
                                        : { ...payable?.rates?.find(rate => rate.service === paymentDetails.service), address: payable.address },
                                totalParcelValue: totalParcelValue
                            } :
                            {
                                parcels,
                                totalChargableWeight: totalChargableWeight,
                                paymentDetails: !shippingAddress?.id ? (paymentDetails?.address?.id ? paymentDetails : { ...paymentDetails, address: addressList?.find(address => address.is_primary) }) : { ...paymentDetails, address: shippingAddress },
                                addOnServices: addOnServices || [],
                                totalParcelValue: totalParcelValue
                            }
                    }}
                />
            }

            {redirectToDashboard && <Redirect to={NAVIGATION_CONSTANTS.DASHBOARD} />}
            <AddressModal
                address={shippingAddress}
                userAddressesState={userAddressesState}
                setUserAddressesState={setUserAddressesState}
                onAddressChange={onAddressChange}
            />
            <section className="content pb-0 pt-0 shipping-options-container-wrapper">
                <div
                    className="container-fluid container-width padding-vertical-25 payment-option-save-card-height shipping-options-container">
                    {loading && (optionsPage || parcelsRates?.length === 0)
                        ? <Loader />
                        : <>
                            <Row>
                                <Col lg={{ span: parcelsRates?.length > 0 ? 7 : 12 }} md={12} sm={12}>
                                    <Services
                                        parcels={parcels}
                                        rates={parcelsRates}
                                        shippingService={paymentDetails}
                                        setShippingService={setPaymentDetails}
                                        membershipServices={membershipServices}
                                        address={shippingAddress?.id ? shippingAddress : addressList?.find(address => address.is_primary)}
                                        addOnServices={addOnServices}
                                        setConsolidationType={setConsolidationType}
                                        setAddOnServices={setAddOnServices}
                                        userAddressesState={userAddressesState}
                                        addressList={addressList}
                                    />
                                </Col>
                                {parcelsRates?.length > 0 && <Col md={12} sm={12} lg={5} className="mobile-margin-bottom-90 text-start">
                                    {((consolidationType && ([PARCEL_CONSTANTS.ITEM_CONSOLIDATION, PARCEL_CONSTANTS.BOX_CONSOLIDATION]).includes(consolidationType))) ? (
                                        <BeforeConsolidationPaymentSummary
                                            parcels={parcels}
                                            totalChargableWeight={totalChargableWeight}
                                            totalParcelValue={totalParcelValue}
                                            paymentSummary={paymentDetails}
                                            payable={payable}
                                            setPaymentSummary={setPaymentDetails}
                                            promoCode={promoCode}
                                            setPromoCode={setPromoCode}
                                            loading={loading}
                                            submit={onSubmit}
                                            consolidationType={consolidationType}
                                        />
                                    ) : (
                                        <PaymentSummary
                                            parcels={parcels}
                                            totalChargableWeight={totalChargableWeight}
                                            paymentSummary={paymentDetails}
                                            setPaymentSummary={setPaymentDetails}
                                            addOnServices={addOnServices}
                                            setPromoCode={setPromoCode}
                                            promoCode={promoCode}
                                            submit={onSubmit}
                                            loading={loading}
                                            totalParcelValue={totalParcelValue}
                                        />
                                    )}
                                </Col>}
                            </Row>
                            <ShippingServiceModal
                                setShippingService={setPaymentDetails}
                                rates={parcelsRates}
                                separatedParcelsRates={payable?.parcel_data ? payable?.rates : []}
                                shippingService={paymentDetails}
                            />
                            <ResultsModal
                                showModal={showByPassPaymentModal}
                                setShowModal={setShowByPassPaymentModal}
                                redirectLink={NAVIGATION_CONSTANTS.DASHBOARD}
                            />
                        </>
                    }
                </div>
            </section>
        </>
    )
}