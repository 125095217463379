import React, { useEffect, useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from "react-i18next";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import RequestImageView from "../../dashboard/parcel/images";
import { Row, Col } from "react-bootstrap";
import filterFactory from "react-bootstrap-table2-filter";
import { GLOBAL_CONSTANTS } from "../../../constants/constants.js";
import { postData } from "../../../utils/api";
import useAnalytics from "../../../analytics/useAnalytics";


function TableList(props) {

	const { t } = useTranslation();

	const [loading, setLoading] = useState(false)

	const analytics = useAnalytics()

	const options = {

		sizePerPage: 10,

		hideSizePerPage: true,

		hidePageListOnlyOnePage: true,

		showTotal: false,

		prePageText: t('Previous'),

		nextPageText: t('Next'),

	};

	useEffect(() => {

		props?.setCounts({ ...props?.counts, [props?.category]: props?.parcelData?.length })

	}, [props?.category, props?.parcelData?.length]);


	const handleCancelRequest = async (id) => {

		setLoading(true)

		analytics.events.bifmCancelPurchaseRequestButton({requestId:id})
		
		const res = await postData(

			"paymentRequestService",

			"/v1/PurchaseRequestController/cancelPurchaseRequest",

			{ purchase_request_id: id },

			props?.authToken

		)

		if (res.status) {

			props?.setReload(!props?.reload)

		} else {

			console.log("please try again later")

		}

		setLoading(false)

	}


	const expandRow = {

		renderer: row => {

			// var requestDetail = row.ParcelItems[0]

			let statusHistory = {}

			if (row?.PurchaseStatusHistories) {

				statusHistory = row?.PurchaseStatusHistories.find(function (v) {

					return v.status_id === row?.PurchaseStatus?.id
				});

			}

			return (

				<Row>

					{(row?.PurchaseAttachments?.length)

						? <Col md={2} sm={12}>

							<ul id={`gallery-${row.id}`}

								className="list-unstyled lightgallery right-block-img text-center">

								<RequestImageView

									parcel={row}

									hasSearchIcon={true}

									width={"125"}

									height={"125"}

								/>

							</ul>

						</Col>

						: null

					}

					<Col md={row?.PurchaseAttachments?.length ? 10 : 12} sm={12} className={'accordion-row'}>

						{<div className="request-accordion-items">

							<Row className="w-100 px-3 py-2">

								<Col xs={12} sm={6} md={2} lg={2} className="title">

									{t("Product_Name")}

								</Col>

								<Col xs={12} sm={6} md={10} lg={10} className="desc d-flex">

									<div className="w-75">{row?.product_name}</div>

									{row?.PurchaseStatus?.name === "Payment Pending" &&

										<div className="w-25 text-end">

											<div

												role="button"

												className="cancel-request d-inline-block py-2 px-3"

												onClick={() => {

													handleCancelRequest(row.id)

												}}

											>

												{loading ?

													<div className="spinner-border text-dark" role="status"></div>

													:

													<>

														<i class='bx bxs-x-circle mx-2 cross-icon mb-1' ></i>

														<span>{t("Cancel request")}</span>

													</>

												}

											</div>

										</div>

									}

								</Col>

							</Row>

							<Row className="w-100 px-3 py-2">

								<Col xs={12} sm={6} md={2} lg={2} className="title">

									{t("Product_URL")}

								</Col>

								<Col xs={12} sm={6} md={10} lg={10} className="desc">

									<a href={row?.product_url} target="_blank">{row?.product_url}</a>

								</Col>

							</Row>

							<Row className="w-100 px-3 py-2">

								<Col xs={4} sm={6} md={2} lg={2} className="title">

									{t("Size")}

								</Col>

								<Col xs={8} sm={6} md={4} lg={4} className="desc">

									{row?.size}

								</Col>



								<Col xs={4} sm={6} md={3} lg={3} className="title">

									{(row?.PurchaseStatus?.name === 'Confirmation Request') && t("Additional_Service_Fee")}

								</Col>

								<Col xs={8} sm={6} md={3} lg={3} className="desc amount-dir ">

									{(row?.PurchaseStatus?.name === 'Confirmation Request') && GLOBAL_CONSTANTS.CURRENCY_VAL + " " + row?.additional_service_fee?.toFixed(2)}

								</Col>

							</Row>

							<Row className="w-100 px-3 py-2">

								<Col xs={4} sm={6} md={2} lg={2} className="title">

									{t("Quantity")}

								</Col>

								<Col xs={8} sm={6} md={4} lg={4} className="desc">

									{row?.quantity}

								</Col>

								<Col xs={4} sm={6} md={3} lg={3} className="title">

									{(row?.PurchaseStatus?.name === 'Confirmation Request') && t("Total")}

								</Col>

								<Col xs={8} sm={6} md={3} lg={3} className="desc amount-dir ">

									{(row?.PurchaseStatus?.name === 'Confirmation Request') &&

										<strong>{GLOBAL_CONSTANTS.CURRENCY_VAL + " " + row?.total_amount?.toFixed(2)}</strong>}

								</Col>



							</Row>

							<Row className="w-100 px-3 py-2">

								<Col xs={4} sm={6} md={2} lg={2} className="title">

									{t("Color")}

								</Col>

								<Col xs={8} sm={6} md={4} lg={4} className="desc">

									{row?.color}

								</Col>

								<Col xs={4} sm={6} md={3} lg={3} className="title">

									{(row?.PurchaseStatus?.name === 'Confirmation Request') && t("Required")}

								</Col>

								<Col xs={8} sm={6} md={3} lg={3} className="desc ">

									{(row?.PurchaseStatus?.name === 'Confirmation Request') && <span className="crlorange">

										{GLOBAL_CONSTANTS.CURRENCY_VAL + " " + parseFloat(row?.pay_by_wallet_amount).toFixed(2)}</span>

									}

								</Col>

							</Row>

							<Row className="w-100 px-3 py-2">

								<Col xs={4} sm={6} md={2} lg={2} className="title">

									{t("Total_Paid")}

								</Col>

								<Col xs={8} sm={6} md={4} lg={4} className="desc amount-dir">

									<strong>

										{row?.total_paid ? GLOBAL_CONSTANTS.CURRENCY_VAL + " " + parseFloat(row?.total_paid).toFixed(2) : 0}

									</strong>

								</Col>

								<Col xs={4} sm={6} md={3} lg={3} className="title">

									{row?.refunded_amount > 0 && t("Refunded_Amount")}

								</Col>

								<Col xs={8} sm={6} md={4} lg={3} className="desc amount-dir">

									{row?.refunded_amount > 0 && <span className="crlorange">

										{GLOBAL_CONSTANTS.CURRENCY_VAL + " " + row?.refunded_amount?.toFixed(2)}

									</span>

									}

								</Col>

							</Row>

							{row?.comments && row?.comments !== "" &&

								<Row className="w-100 px-3 py-2">

									<Col xs={12} sm={6} md={2} lg={2} className="title">

										{t("Comments")}

									</Col>

									<Col xs={12} sm={6} md={9} lg={9} className="comments desc p-3">

										{row?.comments}

									</Col>

								</Row>

							}

							{(row?.PurchaseStatus?.name === 'Rejected' || row?.PurchaseStatus?.name === 'Confirmation Request') && statusHistory &&

								<>

									<Row className="w-100 px-3">

										<Col xs={12} sm={12} md={12} lg={12}>

											<hr />

										</Col>

									</Row>

									<Row className="w-100 px-3 py-2">

										<Col xs={12} sm={6} md={2} lg={2} className="title">

											{t("Reason")}

										</Col>

										<Col xs={12} sm={6} md={9} lg={9} className="comments desc p-3">

											{statusHistory?.Reason && statusHistory?.Reason?.name !== "" ? t(statusHistory?.Reason?.name) : t("")}

										</Col>

									</Row>

								</>

							}

							{(row?.PurchaseStatus?.name === 'Collected') && statusHistory &&
								<>

									<Row className="w-100 px-3">

										<Col xs={12} sm={12} md={12} lg={12}>

											<hr />

										</Col>

									</Row>

									<Row className="w-100 px-3 py-2">

										<Col xs={12} sm={6} md={2} lg={2} className="title">

											{t("Reason")}

										</Col>

										<Col xs={12} sm={6} md={9} lg={9} className="comments desc p-3">

											{statusHistory?.remarks && statusHistory?.remarks !== "" ? t(statusHistory?.remarks) : t("")}

										</Col>

									</Row>

								</>

							}

						</div>

						}


					</Col>

				</Row>

			)

		},

		showExpandColumn: true,

		expandColumnPosition: 'right',

		showExpandColumn: true,

		expandByColumnOnly: true,

		expandHeaderColumnRenderer: ({ isAnyExpands }) => {

			return "";

		},

		expandColumnRenderer: ({ expanded }) => {

			if (expanded) {

				return (

					<i className="bx bx-chevron-up font-size-20"></i>

				);

			}

			return (

				<i className="bx bx-chevron-down font-size-20"></i>

			);

		}

	};


	return (

		<div className="section-container">

			<ToolkitProvider

				keyField="id"

				data={props?.parcelData || []}

				columns={props?.columns}

				search={{ searchFormatted: true }}

			>

				{

					props => {

						return (

							<div className="table-container my-2">


								<Row className={"table-data"}>

									<Col>

										<BootstrapTable

											{...props?.baseProps}

											pagination={paginationFactory(options)}

											wrapperClasses="table-responsive spacing-table"

											headerClasses="tbl-hd-bg"

											bordered={false}

											noDataIndication={t("No_Record")}

											rowClasses="row-class"

											filter={filterFactory()}

											expandRow={expandRow}

										/>

									</Col>

								</Row>

							</div>

						)

					}

				}

			</ToolkitProvider>

		</div>

	);

}


export default TableList;