import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import leftArrow from "./arrow2left.png"
import rightArrow from "./arrow2right.png"

const Steps = ({ steps }) => {
    const { t, i18n } = useTranslation();
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const reqSteps = require.context("./", true, /step-[0-9](-en|-ar)?.png$/);
    // const reqArSteps = require.context("./", true, /step-[0-9]-ar.png$/);
	const loadStep = imageFile => (reqSteps(`${imageFile}`).default);
	// const loadArStep = imageFile => (reqArSteps(`${imageFile}`).default);
    const stepDescriptions = {
        "step-1": "Log in to your account and click on (Submit Request)",
        "step-2": "Copy the product URL, paste the URL and fill the form with the required product details",
        "step-3": "Proceed with the payment for your order",
        "step-4": "We will receive your order, evaluate and process accordingly",
        "step-5": "Once your product is purchased you can manage and ship through your main dashboard"
    }
    return (
        <div className="position-relative text-center mt-3">
            <span className="color-primary">
                {t("How does Buy it for me service works")}
            </span>
            {reqSteps.keys()?.filter(img => img.includes(i18n?.language) || img === "./step-4.png")?.map((step, index) => {
                const displayDirection = (index%2) === 0 ? "end" : "start"
                return <>
                    <div className={`${i18n.language === "en" ? "step-en-" : "step-ar-"}${displayDirection}`}>
                        { i18n.language === "en" ?
                            (index%2) === 0 ?
                            <>
                                <img className="left-arrow" src={leftArrow}/>
                                <span className="text-white step">{t("Step") + " 0" + (index+1)}</span>
                                <span className="step-desc">{t(
                                    `${step === "./step-4.png" ? stepDescriptions[step?.replace("./", "")?.replace(".png", "")] : stepDescriptions[step?.replace("./", "")?.replace("-en.png","")]}`
                                )}
                                { index === 2 && <i onClick={() => {
                                    document.getElementById("payment-terms")?.click()
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: "smooth",
                                    });}}
                                    className="text-blue tr-cursor bx bx-info-circle mx-1" /> }
                                </span>
                                <img className="step-img" src={loadStep(step)} />
                            </>
                            :
                            <>
                                <img className="step-img" src={loadStep(step)} />
                                <img className="left-arrow" src={rightArrow}/>
                                <span className="text-white step">{t("Step") + " 0" + (index+1)}</span>
                                <span className="step-desc">{t(
                                    `${step === "./step-4.png" ? stepDescriptions[step?.replace("./", "")?.replace(".png", "")] : stepDescriptions[step?.replace("./", "")?.replace("-en.png","")]}`
                                )}</span>
                            </>
                        :
                            (index%2) === 0 ?
                            <>
                                <img className="right-arrow" src={rightArrow}/>
                                <span className="text-white step">{t("Step") + " 0" + (index+1)}</span>
                                <span className="step-desc">{t(
                                    `${step === "./step-4.png" ? stepDescriptions[step?.replace("./", "")?.replace(".png", "")] : stepDescriptions[step?.replace("./", "")?.replace("-ar.png","")]}`
                                )}</span>
                                <img className="step-img" src={loadStep(step)} />
                            </>
                            :
                            <>
                                <img className="step-img" src={loadStep(step)} />
                                <img className="right-arrow" src={leftArrow}/>
                                <span className="text-white step">{t("Step") + " 0" + (index+1)}</span>
                                <span className="step-desc">{t(
                                    `${step === "./step-4.png" ? stepDescriptions[step?.replace("./", "")?.replace(".png", "")] : stepDescriptions[step?.replace("./", "")?.replace("-ar.png","")]}`
                                )}</span>
                            </>
                        }                    
                    </div>
                </>
            })}
        </div>
    );
};

export default Steps;
