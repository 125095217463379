import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from "react-router-dom";
import { PURCHASE_REQUEST } from "../../../../../constants/navigationConstants";
import successIcon from "../../../../../assets/img/icons/success.gif";

export default ({ update=null, modalDataClear }) => {
	const location = useLocation()
	const { t, i18n } = useTranslation();
	return <div className="container text-center">
		<img src={successIcon} className="w-50"/>
		<h4>{t("Request_Payment_successful")}</h4>
		<p>{t("Thank you for your confirmation you can track your buying request on Request tab in the dashboard")}</p>
		<Link to={{
			pathname: PURCHASE_REQUEST,
			search: "",
			state: (location && location.state ? location.state : {})
		}}>
			<button onClick={() => modalDataClear(update)} className="btn btn-primary rounded text-capitalize">
				{t("Return to Purchase request")}
			</button>
		</Link>
	</div>
}