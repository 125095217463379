import React, { useState } from "react"
import { useEffect } from "react"
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap"
import ReactFlagsSelect, { Tt } from "react-flags-select"
import { useTranslation } from "react-i18next"
import PhoneInput from "react-phone-input-2"
import { Link } from "react-router-dom"
import { getMaximumWeight, RATE_CALCULATOR_INPUTS, WEIGHT_TO_DIMENSION } from "../../constants/constants"
import {MEMBERSHIP_PLANS, US_WAREHOUSE } from "../../constants/navigationConstants"
import { useAuth } from "../../contexts/AuthContext"
import { postRateCalculation } from "../../services/userInfoService"
import { postData } from "../../utils/api"
import useCountries from "../../utils/useCountries"
import { TableDisplay } from "../homepage/calculateShippingFeesSection/calculateShippingFeesSection"
import CheckIcon from "./check.svg"
const BeforeTracking = () => {
    const { t, i18n } = useTranslation()
    const { warehousesList, warehouseId, setWarehouseId } = useAuth()
    const { countries } = useCountries()
    const [rateCalculatorData, setRateCalculatorData] = useState({
        country_id: 0,
        weight: "",
        height: "",
        width: "",
        length: "",
        weight_unit: warehouseId === US_WAREHOUSE ? "lb" : "kg",
        dimension_unit: warehouseId === US_WAREHOUSE ? "in" : "cm",
    })
    const [validationInput, setValidationInput] = useState("")
    const [showLoader, setShowLoader] = useState(false)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)
    const [chargesList, setChargesList] = useState([])
    const [floater, setFloater] = useState("");
    const [submitResult, setSubmitResult] = useState({
        message: "",
        severity: "",
    });
    const [contactUserInfo, setContactUserInfo] = useState({
        name: "",
        email: "",
        phone: "",
        content:
            "For more than 70 kg / 150 lb, fill the form to get a call back from our sales team",
        subject: "Maximum weight exceeded",
        country: "AE",
        code: "",
    });

    const alignment = i18n.language === "ar" ? "rtl" : "ltr"
    const classNameForPhoneInput =
        i18n.language === "ar" ? "ltr-direction w-100" : "w-100"

    useEffect(() => {
        setFloater(i18n.language === "ar" ? "float-left" : "float-right");
    }, [i18n.language]);

    const maxWeight = getMaximumWeight(rateCalculatorData.weight_unit)

    const createWarehousesLabels = () => {
        const customLabels = {}
        warehousesList?.forEach(w =>
            customLabels[w.country] = {
                primary: w.name,
                secondary: w.active ? "" : <div className="mx-1 coming-soon orange-bg">{t("Coming soon")}</div>
            }
        )
        return customLabels
    }

    let name, value;
    const handleInputs = (event) => {
        name = event.target.name;
        value = event.target.value;
        setRateCalculatorData({ ...rateCalculatorData, [name]: value })
    }

    const handleWeightChange = (event) => {
        name = event.target.name;
        value = event.target.value;
        event.target.value === WEIGHT_TO_DIMENSION.KG
            ? setRateCalculatorData({
                ...rateCalculatorData,
                dimension_unit: WEIGHT_TO_DIMENSION.CM,
                [name]: value,
            })
            : setRateCalculatorData({
                ...rateCalculatorData,
                dimension_unit: WEIGHT_TO_DIMENSION.INCH,
                [name]: value,
            })
    }

    const features = [
        {
            // boldText: "US & UK address",
            boldText: "Free Addresses",
            regularText: "US, Turkey & Dubai"
        },
        {
            boldText: "One year subscription",
            regularText: "Free Premium"
        },
        {
            boldText: "Multiple",
            regularText: "Shipping options"
        },
        {
            boldText: "Fast Processing",
            regularText: "and Shipping"
        },
        {
            boldText: "Customs clearance",
            regularText: "Expertise in"
        },
        {
            boldText: "Order fulfillment",
            middleText: "and",
            regularText: "Bulk Shipping"
        },
        {
            boldText: "Special Solutions",
            regularText: "for online retailers"
        },
        {
            boldText: "Integrated Platform",
            middleText: "&",
            regularText: "Easy Integration"
        },
    ]

    const openModal = (modalId) => {
        document.querySelector(modalId).click();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (
            rateCalculatorData.weight !== "" &&
            parseInt(rateCalculatorData.weight) >= maxWeight &&
            maxWeight !== 0
        ) {
            openModal("#contactUsModal");
        } else {
            setChargesList([]);
            setShowLoader(true);
            setError(null);
            openModal("#servicesModal");
            try {
                const calculatedList = await postRateCalculation(
                    rateCalculatorData,
                    warehouseId,
                    true
                );
                setChargesList(calculatedList.payload);
                setShowLoader(false);
            } catch (error) {
                setShowLoader(false);
                setError("Currently no service available, please try later.");
            }
        }
    }

    const validateInputs = (e) => {
        setValidationInput("rate-calculator-input-validation")
        handleSubmit(e)
    }
    const handleContactUsFormInputs = (event) => {
        name = event.target.name;
        value = event.target.value;
        setContactUserInfo({ ...contactUserInfo, [name]: value });
    };

    const handleContactUsFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await postData(
            "notificationService",
            "/contact-form/send",
            contactUserInfo
        );
        if (res && res.message) {
            setSubmitResult({
                message:
                    "Thank you for submitting your enquiry, we will get back to you",
                severity: "success",
            });
        } else {
            setSubmitResult({
                message: "Something went wrong !",
                severity: "danger",
            });
        }
        setLoading(false);
        setContactUserInfo({
            name: "",
            email: "",
            phone: "",
            content:
                "For more than 70 kg / 150 lb, fill the form to get a call back from our sales team",
            subject: "Maximum weight exceeded",
            country: "AE",
            code: "",
        });
    };

    return (
        <Container>
            <Row>
                <Col lg={6} md={6} sm={12}>
                    <div className="calculator-box bg-white px-4 py-2 mt-5">
                        <h4 className="my-3">{t("Calculate Shipping Fees")}</h4>
                        <Row>
                            <Col md={12} sm={12}>
                                <Form.Group controlId="ShipFrom">
                                    <Form.Label className={`required ${alignment}`}>
                                        {t("Ship from")}
                                    </Form.Label>
                                    <ReactFlagsSelect
                                        countries={warehousesList?.map(
                                            (warehouse) => warehouse.country
                                        ) || "US"}
                                        customLabels={createWarehousesLabels()}
                                        className={`ltr-direction text-muted`}
                                        selected={
                                            warehousesList?.find(
                                                (w) => w.id === warehouseId
                                            )?.country
                                        }
                                        onSelect={(code) => {
                                            if (warehousesList?.find(w => w.country === code)?.active) {
                                                let warehouse = warehousesList?.find(w => w.country === code)?.id
                                                setWarehouseId(warehouse)
                                                setRateCalculatorData({ ...rateCalculatorData, weight_unit: warehouse === US_WAREHOUSE ? WEIGHT_TO_DIMENSION.LB : WEIGHT_TO_DIMENSION.KG })
                                            } else {
                                                setWarehouseId(1)
                                            }
                                        }}
                                        onFocus={(e) => {
                                            if (e.target.autocomplete) {
                                                e.target.autocomplete =
                                                    "noautocomplete"
                                            }
                                        }}
                                        showSelectedLabel
                                        placeholder={"Select a warehouse"}
                                        alignOptionsToRight
                                        fullWidth
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12} sm={12}>
                                <Form.Group controlId="ShipTo" className="w-100">
                                    <Form.Label className={`required ${alignment}`}>
                                        {t("Ship to")}
                                    </Form.Label>
                                    <select
                                        className={`form-control ${validationInput}`}
                                        name="country_id"
                                        onChange={handleInputs}
                                        value={rateCalculatorData.country}
                                        required
                                    >
                                        <option value="">
                                            {t("Select a country")}
                                        </option>
                                        {countries?.map((country) => (
                                            <option key={country.id} value={country.id}>
                                                {country.name}
                                            </option>
                                        ))}
                                    </select>
                                </Form.Group>
                            </Col>
                            <Col md={6} sm={12}>
                                <Form.Group controlId="parcelWeight" className="w-100">
                                    <Form.Label className={`required ${alignment}`}>
                                        {t("Parcel weight")}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        className={`${validationInput}`}
                                        name="weight"
                                        value={rateCalculatorData.weight}
                                        onChange={handleInputs}
                                        min={RATE_CALCULATOR_INPUTS.WEIGHT_MIN_VALUE}
                                        required
                                        isInvalid={rateCalculatorData.weight >= 50}
                                    />
                                    {rateCalculatorData.weight >= 50 && <Form.Control.Feedback type="invalid" className={i18n?.language === "ar" ? "text-right" : "text-left"}>
                                        {t("Shipping of large packages may take more time to prepare and ship")}
                                    </Form.Control.Feedback>}
                                </Form.Group>
                            </Col>
                            <Col md={6} sm={12}>
                                <Form.Group controlId="wightUnit" className="w-100">
                                    <Form.Label className={`required ${alignment}`}>
                                        {t("Weight unit")}
                                    </Form.Label>
                                    <select
                                        className={`form-control ${validationInput}`}
                                        name="weight_unit"
                                        onChange={handleWeightChange}
                                        value={rateCalculatorData.weight_unit}
                                        required
                                    >
                                        <option value="">
                                            {t("Select weight unit")}
                                        </option>
                                        {Object.values(WEIGHT_TO_DIMENSION)?.slice(0, 2)?.map(unit => <option value={unit?.toLowerCase()}>{t(unit)}</option>)}
                                    </select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <button
                            type="submit"
                            className="btn btn-primary btn-block my-2"
                            onClick={validateInputs}
                        >
                            {t("Calculate shipping cost button")}
                        </button>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                    <div className="calculator-box bg-white px-4 py-2 mt-5">
                        <h4 className="my-3">{t("Subscribe now")}</h4>
                        {features?.map((feature, index) =>
                            <div>
                                <img src={CheckIcon} className="d-inline" />
                                {[0, 1, 2, 4].includes(index) ? <span>{t(feature?.boldText)}</span> : <strong>{t(feature?.boldText)}</strong>}
                                &nbsp;
                                {feature?.middleText ? <span>{t(feature?.middleText)}&nbsp;</span> : null}
                                {[1, 2, 4, 5, 7].includes(index) ? <strong>{t(feature?.regularText)}</strong> : <span className={index === 0 ? "soon" : ""}>{t(feature?.regularText)}</span>}
                            </div>
                        )}
                        <Link
                            to={MEMBERSHIP_PLANS}
                            onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "smooth",
                                });
                            }}
                        >
                            <Button className="color-inverse-default-button rounded py-1 w-100 thick-border my-3">{t("Signup Now")}</Button>
                        </Link>
                    </div>
                </Col>
            </Row>
            <a
                id="servicesModal"
                data-toggle="modal"
                data-target="#services-table-modal"
            />
            <a
                id="contactUsModal"
                data-toggle="modal"
                data-target="#contact-us"
            />
            <div
                className="modal fade"
                id="services-table-modal"
                tabIndex="-1"
                aria-labelledby="new-address-modal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered login-modal-width modal-lg">
                    <div className="modal-content p-3 w-100">
                        <div className="modal-header border-bottom-0 text-start">
                            <h2
                                className="sub-section-title"
                                id="addModalLabel"
                            >
                                {t(
                                    "Estimated shipping Cost with Shopiniworld"
                                )}
                            </h2>
                            <button
                                type="button"
                                className={`close ml-0 mr-0 ${floater}`}
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="bx bx-x" />
                            </button>
                        </div>
                        <div className="modal-body w-100">
                            {/*<p className="text-start description">
                                    {t("Chargeable Weight is")}
                                    {chargeableWeight} {chargeableWeightUnit}
                                </p>*/}
                            <TableDisplay
                                showLoader={showLoader}
                                chargesList={chargesList}
                                rateCalculatorData={rateCalculatorData}
                            />
                            {!!error && (
                                <Alert variant={"danger"}>{error}</Alert>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="contact-us"
                tabIndex="-1"
                aria-labelledby="constact-us"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered login-modal-width">
                    <div className="modal-content p-3 w-100">
                        <div className="modal-header border-bottom-0 pb-0 pt-0">
                            <button
                                type="button"
                                className={`close ${floater}`}
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="bx bx-x" />
                            </button>
                        </div>
                        <div className="modal-body w-100">
                            <h3
                                className={`${alignment} mx-3 mb-4 text-capitalize`}
                            >
                                {t("Contact us now")}
                            </h3>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <Alert variant={"danger"}>
                                    {t("For more than")}
                                    <strong className="mx-1">{`${maxWeight} ${t(
                                        rateCalculatorData.weight_unit
                                    )}`}</strong>
                                    {t(
                                        ", fill the form to get a call back from our sales team"
                                    )}
                                </Alert>
                                <form
                                    className="w-100 p-3"
                                    onSubmit={handleContactUsFormSubmit}
                                >
                                    <div className="form-row mb-1 p-0">
                                        <div
                                            className={`col-12 col-md-6 mb-3 ${alignment}`}
                                        >
                                            <label htmlFor="contactUsNameInput">
                                                {t("Name")}
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="contactUsNameInput"
                                                placeholder={t("Name")}
                                                onChange={
                                                    handleContactUsFormInputs
                                                }
                                                name="name"
                                                value={contactUserInfo.name}
                                                required
                                            />
                                        </div>
                                        <div
                                            className={`col-12 col-md-6 mb-3 ${alignment}`}
                                        >
                                            <label htmlFor="contactUsEmailInput">
                                                {t("Email")}
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="contactUsEmailInput"
                                                placeholder="name@example.com"
                                                onChange={
                                                    handleContactUsFormInputs
                                                }
                                                name="email"
                                                value={
                                                    contactUserInfo.email
                                                }
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row mb-1">
                                        <div
                                            className={`col-12 col-md-6 mb-3 ${alignment}`}
                                        >
                                            <label htmlFor="registerShipto">
                                                {t("Country")}
                                            </label>
                                            <ReactFlagsSelect
                                                countries={countries?.map(
                                                    (country) =>
                                                        country.iso2_code
                                                )}
                                                className={`${i18n.language ===
                                                    "ar" &&
                                                    "ltr-direction"
                                                    } h-120`}
                                                selected={
                                                    contactUserInfo.country
                                                }
                                                onSelect={(code) => {
                                                    setContactUserInfo({
                                                        ...contactUserInfo,
                                                        country: code,
                                                        code: code,
                                                    });
                                                }}
                                                showSelectedLabel
                                                placeholder={
                                                    "Select a country"
                                                }
                                                alignOptionsToRight
                                                fullWidth
                                            />
                                        </div>
                                        <div
                                            className={`col-12 col-md-6 mb-3 ${alignment}`}
                                        >
                                            <label htmlFor="contactUsMobileInput">
                                                {t("Phone")}
                                            </label>
                                            <PhoneInput
                                                country={
                                                    contactUserInfo.country &&
                                                    contactUserInfo.country.toLocaleLowerCase()
                                                }
                                                inputProps={{
                                                    name: "phone",
                                                }}
                                                id="contactUsMobileInput"
                                                onChange={(phoneValue) =>
                                                    setContactUserInfo({
                                                        ...contactUserInfo,
                                                        phone: phoneValue,
                                                    })
                                                }
                                                inputClass="form-control w-100"
                                                value={
                                                    contactUserInfo.phone
                                                }
                                                containerClass={
                                                    classNameForPhoneInput
                                                }
                                            />
                                        </div>
                                    </div>
                                    <button
                                        disabled={loading}
                                        id="contact-us-submit"
                                        className="btn btn-primary btn-block"
                                        type="submit"
                                    >
                                        {loading ? (
                                            <div
                                                class="spinner-border text-light"
                                                role="status"
                                            ></div>
                                        ) : (
                                            t("Submit")
                                        )}
                                    </button>
                                </form>
                                {submitResult.message &&
                                    submitResult.message !== "" && (
                                        <div className="w-100 px-3">
                                            <Alert
                                                variant={
                                                    submitResult.severity
                                                }
                                            >
                                                <i
                                                    class={`${submitResult.severity ===
                                                        "success"
                                                        ? "green-check fas fa-check-circle"
                                                        : "fas fa-times-circle"
                                                        } mx-2`}
                                                ></i>
                                                {t(submitResult.message)}
                                            </Alert>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default BeforeTracking