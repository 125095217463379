import React, { useState, useEffect } from "react";
import {
    Modal
} from "react-bootstrap";

import { useTranslation } from "react-i18next";
import ProductDetail from "./steps/productDetails";
import Checkout from "./steps/checkout";
import { at } from "lodash-es";
import SuccessPurchase from "./steps/successPurchase";
import FailedPurchase from "./steps/FailedPurchase";
import { postData } from "../../../../utils/api";
import { useAuth } from "../../../../contexts/AuthContext";
import { REQUESTS_CONSTANTS } from "../../../../constants/constants";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Resizer from "react-image-file-resizer";
import useAnalytics from "../../../../analytics/useAnalytics";
import { screenEvent } from "../../../../analytics/constants";

export default ({
    show,
    setShow,
    viewModalClose,
    modalRow,
    counts,
    setCounts,
    parcelData,
    setParcelData,
    category,
    reload,
    setReload
}) => {

    const { t, i18n } = useTranslation();
    const [doneSteps, setDoneSteps] = useState([]);
    const [loading, setLoading] = useState(false);
    const { authToken } = useAuth();
    const [productDetails, setProductDetails] = useState(modalRow);
    const [pictureBase64, setPictureBase64] = useState(null);
    const [attachedPicture, setAttachedPicture] = useState({});
    const [attachedPictureName, setAttachedPictureName] = useState(null);
    const [error, setError] = useState(null);
    const [update, setUpdate] = useState(false);
    const stripeTestPromise = loadStripe(process?.env?.REACT_APP_STRIPE_KEY);
    const [apiError, setApiError] = useState('');
    const analytics = useAnalytics()

    const handleBIFMPurchaseRequestCompleted = (requestId, responseData, hasAttachments) => {
        const bIFMPaymentEventAttributes = {
            productUrl: responseData.product_url ?? "",
            productName: responseData.product_name ?? "",
            unitPrice: responseData.unit_price ?? 0,
            quantity: responseData.quantity ?? 0,
            shippingFees: responseData.shipping_fee ?? 0,
            color: responseData.color ?? "",
            size: responseData.size ?? "",
            attachPictures: hasAttachments,
            comments: responseData.comments ?? "",
            totalAmount: responseData.total_amount ?? 0,
            serviceFees: Number(responseData.service_fee) || 0,
        };
        if (!!requestId) {
            bIFMPaymentEventAttributes["requestId"] = requestId;
            analytics.events.bifmPurchaseRequestUpdateCompleted(
                bIFMPaymentEventAttributes
            );
        } else {
            analytics.events.bifmPurchaseRequestCompleted(
                bIFMPaymentEventAttributes
            );
        }
    };

    useEffect(() => {
        setProductDetails(modalRow)
    }, [modalRow]);

    useEffect(() => {
        if (modalRow?.request_id) {
            setDoneSteps(["productDetailsStep", "checkoutStep"])
        } else {
            setDoneSteps(["productDetailsStep"])
        }
    }, [modalRow?.request_id])

    const modalDataClear = (update) => {
        setDoneSteps(["productDetailsStep"])
        setAttachedPictureName("")
        viewModalClose(update)
    }
    const handleFile = (event) => {
        try {
            let files = event.target.files;
            if (files) {
                try {
                  Resizer.imageFileResizer(
                    files[0],
                    '60%',
                    '60%',
                    "JPEG",
                    80,
                    0,
                    (uri) => {
                      setPictureBase64(uri);
                      setAttachedPicture(files[0]);
                      setAttachedPictureName(files[0]?.name)
                    },
                    "base64"
                  );
                } catch (err) {
                  console.log(err);
                }
            }
        } catch (e) {
            setError(e.message);
        }
    };

    const handleCreateOrUpdateRequest = async () => {
        //'/PurchaseRequestController/createOrUpdatePurchase'
        setLoading(true)
        setApiError('');
        if(!productDetails['shipping_fee'])
            productDetails['shipping_fee'] = 0
        const response = await postData(
            "paymentRequestService",
            "/v1/PurchaseRequestController/createOrUpdatePurchase",
            { ...productDetails, service_fee: REQUESTS_CONSTANTS.SERVICE_FEE },
            authToken
        )
        let attachmentsUploadResponse;
        if (response?.status && response?.payload?.data && pictureBase64 && pictureBase64 != "" && attachedPictureName) {
            attachmentsUploadResponse = await postData(
                "paymentRequestService",
                "/v1/RequestOperationsController/requestAttachmentUpload",
                {
                    purchase_request_id: response?.payload?.data?.id,
                    attachment: pictureBase64,
                    is_invoice: 0
                },
                authToken
            )
        } else {
            if(response?.message && response?.message?.errors && response?.message?.errors.length > 0){
                let errorString = response?.message?.errors.map((x) => x.msg).join(', ')
                setApiError(errorString)
            }
        }
        
        let tempParcelData = (!response.status || response.error) ? [] : response.payload.data
        setLoading(false)

        if(response?.status){
            const hasAttachments = attachmentsUploadResponse?.status ? true : false
            handleBIFMPurchaseRequestCompleted(productDetails?.id ? response.payload?.data?.id : undefined, response.payload.data, hasAttachments)
            if (productDetails?.id && productDetails?.id !== "") {
                const attachmentNameParts = at(tempParcelData.PurchaseAttachments[0]?.Attachment, ["domain", "folder_path", "attachment_name"])
                setAttachedPictureName(attachmentNameParts?.join(""))
            }
            setProductDetails(tempParcelData)
            setUpdate(true)
            setDoneSteps(["productDetailsStep", "checkoutStep"])
        }
        
    }

    const handleCancelRequest = async (id) => {
        setLoading(true)
        const res = await postData(
            "paymentRequestService",
            "/v1/PurchaseRequestController/cancelPurchaseRequest",
            { purchase_request_id: id },
            authToken
        )
        if (res.status) {
            setUpdate(true)
        } else {
            console.log("please try again later")
        }
        setLoading(false)
        setShow(false)
    }

    useEffect(() => {
        if (show) {
            if (
                doneSteps.includes("productDetailsStep") &&
                !doneSteps.includes("checkoutStep")
            ) {
                if(doneSteps.includes("productDetailsStep") && doneSteps.length===1 && modalRow.request_id && !productDetails.request_id) return
                analytics.events.screenVisited({
                    screen: productDetails.request_id
                        ? screenEvent.UPDATE_PURCHASE_REQUEST
                        : screenEvent.CREATE_PURCHASE_REQUEST,
                });
            } else if (
                doneSteps.includes("productDetailsStep") &&
                doneSteps.includes("checkoutStep")
            ) {
                analytics.events.screenVisited({
                    screen: screenEvent.BIFM_PAYMENT,
                });
            }
        }
    }, [show, doneSteps]);

    return (
        <Modal show={show}
            onHide={() => viewModalClose(update)}
            size="lg"
            dialogClassName={i18n.language + " purchase-request-modal"}
            aria-labelledby="contained-modal-title-vcenter"
            id="purchase-modal"
            contentClassName={"gray-bg"}
            centered>
            <Modal.Header closeButton onClick={() => modalDataClear(update)}>
            </Modal.Header>
            <Modal.Body className="py-0">
                <div >
                    {(!doneSteps.includes("failedPurchase") && !doneSteps.includes("successPurchase")) &&
                        
                        <div>
                            <h4 className="font-weight-500 mb-3 text-center">{t("Buying_Assistant")}</h4>
                            <p className="font-14 pb-3 text-center">
                                {t("Buying_Assistant_Modal_Description")}
                            </p>
                        </div>
                    }   
                    <div className="card">
                        <div className="row w-100 mb-3 text-center">
                            {(doneSteps.includes("failedPurchase") || doneSteps.includes("successPurchase")) && doneSteps?.length === 1 ?
                                <>
                                    {doneSteps.includes("failedPurchase") &&
                                        <FailedPurchase update={update} modalDataClear={modalDataClear} />}

                                    {doneSteps.includes("successPurchase") &&
                                        <SuccessPurchase update={update} modalDataClear={modalDataClear} />}
                                </>
                                :
                                <>
                                    <div
                                        id="productDetailsStep"
                                        className={`col-md-6 col-sm-12 border-right-gray ${doneSteps.includes("productDetailsStep") ? "text-blue border-bottom-blue" : "text-muted"} py-3`}
                                    >
                                        <i className={`far fa-check-circle mx-2 ${doneSteps.includes("productDetailsStep") ? "text-green" : ""}`}></i>
                                        {t("Product_Details")}
                                    </div>
                                    <div
                                        id="checkoutStep"
                                        className={`col-md-6 col-sm-12 ${doneSteps.includes("checkoutStep") ? "text-blue border-bottom-blue" : "text-muted"} py-3`}
                                    >
                                        <i className={`far fa-check-circle mx-2 ${doneSteps.includes("checkoutStep") ? "text-green" : ""}`}></i>
                                        {t("Request_Checkout")}
                                    </div>
                                </>
                            }
                        </div>
                        {doneSteps.includes("productDetailsStep") && !doneSteps.includes("checkoutStep") &&
                            <ProductDetail
                                setProductDetails={setProductDetails}
                                productDetails={productDetails}
                                setDoneSteps={setDoneSteps}
                                doneSteps={doneSteps}
                                loading={loading}
                                handleCreateOrUpdateRequest={handleCreateOrUpdateRequest}
                                handleFile={handleFile}
                                attachedPictureName={attachedPictureName}
                                apiError={apiError}
                                setApiError={setApiError}
                            />}
                        {doneSteps.includes("productDetailsStep") && doneSteps.includes("checkoutStep") &&
                            <Elements stripe={stripeTestPromise}>
                                <Checkout
                                    setProductDetails={setProductDetails}
                                    productDetails={productDetails}
                                    setDoneSteps={setDoneSteps}
                                    doneSteps={doneSteps}
                                    loading={loading}
                                    setLoading={setLoading}
                                    pictureBase64={pictureBase64}
                                    handleRemoveItemLink={handleCancelRequest}
                                    setReload={setReload}
                                    reload={reload}
                                />
                            </Elements>
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
};

