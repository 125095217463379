import { MenuItem, TextField, withStyles } from "@material-ui/core"
import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { postData } from "../../../utils/api"
import { useAuth } from "../../../contexts/AuthContext"
import axios from "axios"
export default ({
    show,
    handleClose,
    setShowResultsModal,
    orderData
}) => {
    const { t, i18n } = useTranslation()
    const { authToken, warehouseId } = useAuth()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [showMsg, setShowMsg] = useState(false)

    useEffect(() => {
      setData({ ...orderData })
    }, [JSON.stringify(orderData)])
    

    const handleSubmit = async () => {
        try {
            setLoading(true)
            const res = await postData(
                "parcelService",
                `/update-parcel-tracking?warehouse_id=${warehouseId}`,
                { parcel_id: data?.parcelId, purchase_tracking_number: data?.newInbound }, authToken)
            setShowResultsModal({
                show: 1,
                status: res?.status,
            })
            setLoading(false)
        } catch (error) {
            setShowResultsModal({
                show: 1,
                status: 0,
            })
            setLoading(false)
        }
        handleClose()
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName={i18n.language + ""}
            aria-labelledby="contained-modal-title-vcenter"
            id={`update-tracking-no`}
            size={"lg"}
            centered
        >
            <Modal.Header className={`border-bottom-0 justify-content-between border-bottom-none bg-white ${i18n?.language === "ar" ? "close-ar" : ""}`} closeButton>
                <p className={`${i18n?.language === "ar" ? "text-right" : "text-left"} mx-2`}>
                    {<h4>{t("Update Inbound AWB for")} {data?.orderId}</h4>}
                </p>
            </Modal.Header>
            <Modal.Body className={`py-0 text-start`}>
                <div
                    className={"rounded"}
                >
                    <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="newInboundNo" className="required">{t("Inbound AWB")}</label>
                            <input
                                autocomplete="true"
                                type="text"
                                className="form-control"
                                id="newInboundNo"
                                name="newInbound"
                                minLength={0}
                                maxLength={100}
                                value={data?.newInbound}
                                onChange={(e) => {
                                    let value = e.target.value
                                    setData({ ...data, newInbound: value })
                                    setShowMsg(value?.length > 100 || !value || value === "")
                                }}
                                required
                            />
                            { showMsg ? <small className="color-red my-1">
                                    {t("Please enter valid Inbound AWB, max is 100 characters")}
                                </small>
                            : null }
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end mx-3">
                        <button
                            className="text-white text-capitalize border-radius-5 btn-bg-color d-flex flex-row justify-content-center text-decoration-none px-4 py-2 my-2"
                            onClick={() => 
                                handleSubmit()
                            }
                            disabled={showMsg}
                        >
                            {t("Save")}
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}