import styles from "./styles.module.scss";

const Title = () => {
    return (
        <div className={`${styles["seamless-technology"]}`}>
            Seamless Technology with Smart Analytical Tools – Powered by AI.
        </div>
    );
};

export default Title;
