import { useTranslation, Trans } from "react-i18next";

const Introduction = () => {
    const [,i18n] = useTranslation()
    const isAr = i18n.language === 'ar'

    return (
        <>
            <div className="mb-3">
                <p className={`mb-2 fs-26px ${isAr ? "font-weight-bolder":""}`}>
                    <Trans i18nKey="TRUE_WEIGHT_introduction-title">
                        <span className="text-blue-color font-weight-bold" />
                        <span className="color-green font-weight-bold" />
                    </Trans>
                </p>
                <p className="m-0">
                    <Trans i18nKey="TRUE_WEIGHT_introduction-content">
                        <br />
                        <span className="text-blue-color" />
                        <span className="color-green" />
                    </Trans>
                </p>
            </div>
            <div>
                <p className={`mb-2 fs-22px ${isAr ? "font-weight-bolder":"fw-500"}`}>
                    <Trans i18nKey="TRUE_WEIGHT_how-does-it-work-title">
                        <span className="text-blue-color font-weight-bold" />
                        <span className="color-green font-weight-bold" />
                    </Trans>
                </p>
                <p className="m-0">
                    <Trans i18nKey="TRUE_WEIGHT_how-does-it-work-content">
                        <br />
                    </Trans>
                </p>
            </div>
        </>
    );
};

export default Introduction;
