import React from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
import TabBody from "./tabBody";

function Tab9() {
	const { t } = useTranslation();
	
	const questionsAndAnswers = [
		{ containerId: "nineone", collapseId: "collapsenineOne", question: t("Qnineone"), answer: t("Anineone") },
		{ containerId: "ninetwo", collapseId: "collapsenineTwo", question: t("Qninetwo"), answer: t("Aninetwo") },
		{ containerId: "ninethree", collapseId: "collapsenineThree", question: t("Qninethree"), answer: t("Aninethree") },
		{ containerId: "ninefour", collapseId: "collapsenineFour", question: t("Qninefour"), answer: t("Aninefour") },
	]
	return (
		<>
			<div className="accordion" id="accountNavigationQuestions">
				<div className="card align-items-start">
					<p className="sub-page-section-header mb-show-block text-start">{t("Refer a Friend")}</p>
				</div>
				{questionsAndAnswers?.map(questionAndAnswer =>
					<TabBody questionAndAnswer={questionAndAnswer} />
				)}
			</div>
		</>
	);
}

export default Tab9;
