import React, { useState, useEffect, useRef } from "react";

import {
	Col,
	Form,
	InputGroup,
	Row,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import _ from "lodash";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import { INCOMING_ORDERS_CONSTANTS } from "../../../constants/constants";

export default ({ category, addQueryParam, placeholder = null, setReload, reload, removeQueryParam, setOptions, options }) => {
	const { t, i18n } = useTranslation();

	const queryParams = qs.parse(window.location.search);
	const [search, setSearch] = useState(queryParams.search || "");
	const [from, setFrom] = useState(queryParams.from || null);
	const [to, setTo] = useState(queryParams.to || null);
	const fromRef = useRef(null)
	const toRef = useRef(null)
	const parcelsSearchRef = useRef(null)
	const listsSearchRef = useRef(null)

	let isIncomingOrdersTab = queryParams.category === INCOMING_ORDERS_CONSTANTS.INCOMING_ORDERS_VAL
	const onSearchInputChange = (event) => {
		let value = event.target.value;
		setSearch(value);
		let paramToSet = isIncomingOrdersTab ? "listsSearch" : "parcelsSearch" 
		if (value && value !== "") {
			addQueryParam(paramToSet, value);
			setOptions({ ...options, page: {
				...options.page,
				[ isIncomingOrdersTab ? "lists" : "parcels"]: 1 
			}})
		} else {
			removeQueryParam([paramToSet])
			setReload(!reload)
		}
	};

	useEffect(() => {
		addQueryParam("to", to);
	}, [to]);

	useEffect(() => {
		if ((!queryParams.parcelsSearch || queryParams.parcelsSearch === "") && !isIncomingOrdersTab) {
			parcelsSearchRef.current.value = ""
		} else if ((!queryParams.listsSearch || queryParams.listsSearch === "") && isIncomingOrdersTab) {
			listsSearchRef.current.value = ""
		} else if (queryParams?.listsSearch?.length > 0 && isIncomingOrdersTab) {
			listsSearchRef.current.value = queryParams.listsSearch
		} else if (queryParams?.parcelsSearch?.length > 0 && !isIncomingOrdersTab) {
			parcelsSearchRef.current.value = queryParams.parcelsSearch
		}
	}, [(isIncomingOrdersTab ? queryParams.listsSearch : queryParams.parcelsSearch), isIncomingOrdersTab]);

	useEffect(() => {
		addQueryParam("from", from);
	}, [from]);

	return (
		<Row className="w-80 align-items-center justify-content-between">
			<Col md={!isIncomingOrdersTab ? "4" : "8"} sm={ !isIncomingOrdersTab ? "12" : "6"}>
				<InputGroup>
					<Form.Control
						defaultValue={search}
						ref={isIncomingOrdersTab ? listsSearchRef : parcelsSearchRef}
						type="text"
						name="search"
						placeholder={placeholder ? placeholder : t("Type your parcel ID")}
						onChange={onSearchInputChange}
						onKeyPress={e => {
							if (e.charCode === 13) {
								setReload(!reload)
							}
						}}
					/>
					<InputGroup.Append className="tr-cursor" onClick={() => {
						setReload(!reload)
					}}>
						<span className="input-group-text">
							<FaSearch />
						</span>
					</InputGroup.Append>
				</InputGroup>
			</Col>
			{category === INCOMING_ORDERS_CONSTANTS.PARCELS_VAL ?
				<Col lg={"6"} md={"4"} sm={"6"}>
					<Row>
						<Col md={"6"} sm={"6"} className="block-margin-left search-col">
							<InputGroup>
								<Form.Control
									type="date"
									name="from"
									className="form-control-line-height"
									placeholder="From"
									defaultValue={from}
									ref={fromRef}
									onChange={(e) => setFrom(e.target.value)}
								/>
							</InputGroup>
						</Col>
						<Col md={"6"} sm={"6"} className="block-margin-left search-col">
							<InputGroup>
								<Form.Control
									type="date"
									name="to"
									className="form-control-line-height"
									placeholder="To"
									defaultValue={to}
									ref={toRef}
									onChange={(e) => setTo(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
				</Col>
				: null
			}
		</Row >
	);

};