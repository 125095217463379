import Title from "./title";
import Content from "./content";
import styles from "./styles.module.scss";

const InternationalShipping = () => {
    return (
        <div className={`${styles["international-shipping"]} position-relative`}>
            <div className={styles["title"]}>
                <Title />
            </div>
            <div className={styles["contents"]}>
                <Content />
            </div>
            <div
                className={`d-none d-lg-block position-absolute ${styles["header-background-tape"]}`}
            ></div>
        </div>
    );
};

export default InternationalShipping;
