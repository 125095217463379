import React from 'react'
import Banner from './banner'
import Process from './process'

export default () => {
  return (
    <>
        <Banner />
        <Process />
    </>
  )
} 