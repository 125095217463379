import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { postData } from "../../utils/api";
import { useAuth } from "../../contexts/AuthContext";
import * as NAVIGATION_CONSTANTS from "../../constants/navigationConstants";
import _ from "lodash";
import {  PROMOS } from "../../constants/constants";
import { cc_format } from "../../utils/string";

const CreditCard = ({
    setLoading,
    creditCardDetails,
    setCreditCardDetails,
    setLoaderMessage,
    username=null
}) => {
    const { t, i18n } = useTranslation();
    const { authToken } = useAuth();

    const handleInputFocus = (e) => {
        setCreditCardDetails({ ...creditCardDetails, focus: e.target.name });
    };

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        setCreditCardDetails({ ...creditCardDetails, [name]: value });
    };

    useEffect(() => {
      if (username) {
        setCreditCardDetails({ ...creditCardDetails, name: username });
      }
    }, [])
    
    return (
        <Row id="PaymentForm">
            <Col>
                <Row>
                    <Col className="my-2 form-label required">
                        {t("Payment card nick name")}
                    </Col>
                </Row>
                <div className="payment-box">
                    <Row>
                        <Col lg={12} className="my-2">
                            <input
                                type="text"
                                autoComplete="off"
                                className="form-control"
                                minLength={2}
                                maxLength={50}
                                name="name"
                                value={creditCardDetails?.name}
                                placeholder="Enter a nick name"
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                            />
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

export default CreditCard;
