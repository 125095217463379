import React, { useEffect, useState } from "react";
import ContactUsForm from "./contactUsForm";
import Addresses from "./addressesLocations";
import "./style.scss"

function ContactUsPage() {   
	return (
		<div className="global-section-container flex-col separater-bottom-grey">
			<ContactUsForm/>
			<Addresses/>
		</div>
	);
}

export default ContactUsPage;
