import React from "react"
import { useTranslation } from "react-i18next"
import "./style.scss"
import { Link } from "react-router-dom"
import { MEMBERSHIP_PLANS } from "../../constants/navigationConstants"
import { useMediaQuery } from "react-responsive"

const Banner = ({}) => {
  const { t, i18n } = useTranslation()
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  
  return <>
    <div className={`referral-banner-${i18n.language}`}>
        <span className="d-block refer-friend">
            {t("Refer a Friend")}
            {i18n.language !== "ar" && 
              <>
              <br/>
              {t("Win Win")}
              </>
            }
        </span>
        <span className="join-service"> 
            {t("Join our")}&nbsp;
            {isMobile && <br/>}
            <strong>{t("Refer-A-Friend")}</strong>
            <br/>
            {t("program & make a win for both")}
        </span>
        <Link
            id=""
            to={MEMBERSHIP_PLANS}
            className={`referral-signup-btn text-center text-white text-uppercase border-radius-5 btn-bg-color text-decoration-none px-4 py-2`}
	        >
		        {t("Sign up now")}
        </Link>
    </div>
  
  </>
}

export default Banner