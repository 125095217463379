import { head } from "lodash";
import React, { useState, useEffect } from "react";
import {
	Row,
	Col,
	Form,
	Collapse
} from "react-bootstrap";

import { useTranslation } from "react-i18next";

import PaymentCard from "./card";

export default ({name, onNameChange, onCardElementsValueChange, toggleSaveCard, savedCardList, selectedCard, setSelectedCard, grayBg}) => {
	const { t, i18n } = useTranslation();

	const formatCard = (card) => {
		if(card && card.card_no) {
			return `**** **** **** (${card.card_no})`;
		}
		return "";
	};

	const toggleSavedCard = (event, card) => {
		let checked = event.target.checked;
		if(checked) {
			setSelectedCard(card);
		} else {
			setSelectedCard(null);
		}
	};

	const toggleSelectedCardContainer = (card) => {
		setSelectedCard(card);
	};

	useEffect(() => {
		if (savedCardList && savedCardList.length !== 0 && !selectedCard) {
			setSelectedCard(savedCardList.find(savedCard => savedCard.is_default));
		}
	}, [])

	return <div className="card-list">
		<Form.Group>
			{savedCardList.map((card, index) => {
				return <Col sm={12} className={`saved-card-container ${grayBg ? "gray-bg" : ""}`}  onClick={(e) => toggleSelectedCardContainer(card)}>
					<Form.Check
						type="radio"
						label={<div className="saved-card-details">
							<span>{formatCard(card)}</span>
							<span className="name-on-card">{card.name}</span>
						</div>}
						name={card.id}
						id={card.id}
						checked={selectedCard && selectedCard.id === card.id}
						onChange={(e) => toggleSavedCard(e, card)}
					/>
				</Col>;
			})}
			<Col sm={12} className={`saved-card-container ${(!selectedCard ? "new-card-option-selected" : "")}`} onClick={(e) => toggleSelectedCardContainer(null)}>
				<Form.Check
					type="radio"
					label={<div className="saved-card-details">
						<span>{t("Pay with different card")}</span>
					</div>}
					name="new-card"
					id="new-card"
					checked={!selectedCard}
					onChange={(e) => toggleSavedCard(e, null)}
				/>
			</Col>
		</Form.Group>
		<Collapse in={!selectedCard}>
			<Col xs={12} className="new-card-container">
				<PaymentCard
					name={name}
					onNameChange={onNameChange}
					onCardElementsValueChange={onCardElementsValueChange}
					toggleSaveCard={toggleSaveCard}
				/>
			</Col>
		</Collapse>
	</div>;
};
