import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ACCOUNT_PAYMENT_OPTIONS } from "../../constants/navigationConstants";
import { Link } from "react-router-dom";
import BackButtonSvg from "../../assets/img/icons/backButton.svg";
import NewCard from "../stripe/addNewCard";
import { STRIPE_PRIVATE_KEY } from "../../constants/constants.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { MaskedLoader } from "../common";

const stripeTestPromise = loadStripe(STRIPE_PRIVATE_KEY);

const AccountPaymentBackButton = () => {
	const { t, i18n } = useTranslation();
	return (
		<Link to={ACCOUNT_PAYMENT_OPTIONS} className="row align-items-center mb-2">
			{i18n.language === "ar" && (
				<img alt="..." src={BackButtonSvg} className="ml-3 mr-3 rotate-180"/>
			)}
			{i18n.language !== "ar" && (
				<img alt="..." src={BackButtonSvg} className="ml-3 mr-3"/>
			)}
			<p className="mb-0 text-start">{t("Payment")}</p>
		</Link>
	);
};

export default () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [loaderMessage, setLoaderMessage] = useState("");

	useEffect(() => {
		setLoaderMessage(t("Please wait while we load your payment details"));
		setLoading(false);
	}, []);

	return (
		<div className="global-section-container flex-col grey-background pt-5 pb-5 align-items-center">
			<div className="container white-background text-start row pt-3 pb-3">
				{loading ? (
					<MaskedLoader message={loaderMessage} />
				) : null }

				<div className={`col-12 col-sm-12 col-md-12 ${loading? "lower-z-2" : "d-block"}`}>
					<AccountPaymentBackButton />
					<h1 className="sub-page-section-header">
						{t("Add-credit-debit-card")}
					</h1>
					<Elements stripe={stripeTestPromise}>
						<NewCard
							setLoading={setLoading}
							setLoaderMessage={setLoaderMessage}
						/>
					</Elements>
				</div>
				
			</div>
		</div>
	);
};
