import React, { useState, useEffect } from "react";
import {
	Button,
	Row,
	Col,
	OverlayTrigger,
	Tooltip
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
	Link
} from "react-router-dom";

import {
	PARCEL_CONSTANTS
} from "../../../constants/constants.js";
import * as NAVIGATION_CONSTANTS from "../../../constants/navigationConstants";

import ParcelImageView from "./images";
import editIcon from "./editIcon.svg";
import { postData } from "../../../utils/api.js";
import { useAuth } from "../../../contexts/AuthContext.js";
import ManageItemsIcon from "./manageItemsIcon.svg";
import { TextCopyOverlay } from "../../common/index.js";
import useAnalytics from "../../../analytics/useAnalytics.js";

export default ({ parcel, category, setParcels, isMobile, setParcel, incomingOrder=false }) => {
	const { t, i18n } = useTranslation();
	const { authToken, warehousesList, warehouseId } = useAuth()
	const [itemAmount, setItemAmount] = useState(null)
	const [itemId, setItemId] = useState(null);
	const [showInput, setShowInput] = useState(false);
	const analytics = useAnalytics()
	const onUpdateItemAmountClick = async () => {
		if (setParcels) {
			if (itemAmount && itemId) {
				const res = await postData(
					"parcelService",
					`/update/parcel-item/amount?key=${category}`,
					{ id: itemId, amount: itemAmount }, authToken, true)
				if (res.status) {
					setParcels(res.payload?.parcels)
				}
			}
		} 
	}

	const handleShipSelectedEvent = () => {
        const warehouse = warehousesList.find(
            (warehouse) => warehouse.id === Number(warehouseId)
        )?.country;
        if (warehouse) {
            analytics.events.shippingShipSelectedParcelsButton({
                numberOfParcels: 1,
                warehouse,
            });
        }
    };

	return (
		<>
			<div
				className={"accordian-body collapse padding-1rm " + (parcel?.ParcelItems?.length === 1 ? "parcel-accordion-child td-bg" : "")}
				id={`details-${parcel.id}`}>
				<Row>
					{(parcel?.ParcelFiles?.length)
						? <Col md={2} sm={12}>
							<ul id={`gallery-${parcel.id}`}
								className="list-unstyled lightgallery right-block-img text-center">
								<ParcelImageView
									parcel={parcel}
									hasSearchIcon={true}
									width={"125"}
									height={"125"}
								/>
							</ul>
						</Col>
						: null
					}
					<Col md={parcel?.ParcelFiles?.length ? 10 : 12} sm={12} className={`accordion-row ${isMobile ? "px-0" : ""}`}>
						{parcel?.ParcelItems?.map((item, index) => {
							return <div className="parcel-accordion-items" key={item.id}>
								<Col xs={6} md={6} className={`font-13 ${isMobile ? "px-1" : ""}`}>
									{ item?.commodity?.length > 45 ? <OverlayTrigger
										key={"top"}
										placement={"top"}
										overlay={
											<Tooltip id={`tooltip-top`} bsPrefix="tooltip">
												{item?.commodity}
											</Tooltip>
										}
									>
										<span className="pdngspnrght">
											{`${item?.commodity?.substring(0, 45)} ...`}
										</span>
									</OverlayTrigger>
									: <span className="pdngspnrght">
										{item?.commodity}
									</span> }
								</Col>
								{!isMobile &&
									<Col md={3} className={"font-13"}>
										<span className="pdngspnrght">
											{t("Qty X")} {item.quantity}
										</span>
									</Col>
								}
								<Col xs={6} md={3} className={`font-13 text-right ${isMobile ? "px-2" : ""}`}>
									{category && category === PARCEL_CONSTANTS.READY_TO_SHIP && showInput && itemId === item.id ?
										<span className="nowrap-space"><input autoFocus className="style-none w-50" value={itemAmount} onChange={(e) => {
											setItemAmount(e.target.value ? parseFloat(e.target.value) : null)
										}} />
											$ <i
												class={`tr-cursor fas fa-check-circle text-green ${i18n.language === "ar" ? "ml-1" : "mr-1"}`}
												onClick={() => {
													setShowInput(false)
													onUpdateItemAmountClick()
												}}
											></i>
											{t("Save")}
										</span>
										:
										<span>
											{t("Value")} ${!itemAmount || item.id !== itemId || category !== PARCEL_CONSTANTS.READY_TO_SHIP ? item.amount : itemAmount}
											{
												category === PARCEL_CONSTANTS.READY_TO_SHIP &&
												<img
													onClick={() => { setShowInput(true); setItemId(item.id); setItemAmount(null) }}
													className={`tr-cursor ${isMobile ? "mx-1" : "mx-2"}`} src={editIcon} />
											}
										</span>
									}
								</Col>
							</div>;
						})}
						{category && category === PARCEL_CONSTANTS.READY_TO_SHIP
							? <div className="ship-now-btn">
								<Link
									to={{
										pathname: NAVIGATION_CONSTANTS.SHIPMENT_OPTIONS,
										search: "",
										state: {
											selectedParcels: [
												parcel
											]
										}
									}}
								>
									<Button onClick={handleShipSelectedEvent}>
										{t("Ship Now")}
									</Button>
								</Link>
							</div>
							: null
						}
					</Col>
				</Row>
			</div>
		</>
	);
};