import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import qs from "query-string";

import { MEMBERSHIP_CONSTANTS, PARCEL_CONSTANTS, PROMOCODES, REFERRAL_PREFIX } from "../../constants/constants.js";
import { useAuth } from "../../contexts/AuthContext";

import Parcel from "./parcel";
import { useMediaQuery } from "react-responsive";
import Notifications from "./Notifications.js";
import { getData, postData } from "../../utils/api.js";
import { getUserDetails } from "../../services/userInfoService";
import { checkForLifeTimeSubscribtion } from "../../utils/string.js";

const NavItem = ({ isMobile, nav, addQueryParam, setActiveTab, isEnglish, counts }) => {
	return nav.key === PARCEL_CONSTANTS.EXCEPTION && counts[PARCEL_CONSTANTS.EXCEPTION] === 0 ?
		null
		:
		(
			<Nav.Item id={`nav-${nav.key}-parcels-tab`} className={`${isEnglish ? "mr-lg-3 mr-md-2" : "ml-lg-3 ml-md-2"}`}>
				<Nav.Link
					className={`${isMobile && (nav.key === PARCEL_CONSTANTS.ALL || nav.key === PARCEL_CONSTANTS.READY_TO_SHIP) && "w-100"}`}
					eventKey={nav.key}
					onClick={(e) => {
						addQueryParam("category", nav.key);
						setActiveTab(nav.key);
					}}
				>
					{nav.value}{" "}
					<span>
						<span
							className={`roundcrl 
                ${nav.iconClass} ${isMobile &&
									(nav.key === PARCEL_CONSTANTS.ALL || nav.key === PARCEL_CONSTANTS.READY_TO_SHIP) ?
									isEnglish ? "mobile-count-margin-left" : "mobile-count-margin-right"
									:
									""}`}
						>
							{counts[nav.key]}
						</span>
					</span>
				</Nav.Link>
			</Nav.Item>
		)
}


export default (props) => {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const { authToken, membershipPlanID, userPin } = useAuth();
	let search = window.location.search;
	let params = new URLSearchParams(search);
	let category = params.get("category");

	const isEnglish = useMemo(() => i18n.language === "en", [i18n.language]);
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

	const [messageKey, setMessageKey] = useState("")
	const [expirationDate, setExpirationDate] = useState(null)
	const [aramcoUser, setAramcoUser] = useState(false)
	const [friendsCount, setFriendsCount] = useState(0)
	const [loading, setLoading] = useState(true)
	const [counts, setCounts] = useState({
		[PARCEL_CONSTANTS.ALL]: 0,
		[PARCEL_CONSTANTS.READY_TO_SHIP]: 0,
		[PARCEL_CONSTANTS.ACTIVE]: 0,
		[PARCEL_CONSTANTS.DELIVERED]: 0,
		[PARCEL_CONSTANTS.EXCEPTION]: 0,
		[PARCEL_CONSTANTS.READY_PENDING]: 0,
	});
    const [suggestions, setSuggestions] = useState([]);
	const [suppliers, setSuppliers] = useState([]);

	const navTabs = [
		{
			key: PARCEL_CONSTANTS.ALL,
			value: t("All Parcels"),
			iconClass: "gray-icon",
		},
		{
			key: PARCEL_CONSTANTS.READY_TO_SHIP,
			value: t("Ready to ship"),
			iconClass: "hollow blue",
		},
		{
			key: PARCEL_CONSTANTS.READY_PENDING,
			value: t("Pending Payment"),
			iconClass: "hollow red",
		},
		{
			key: PARCEL_CONSTANTS.ACTIVE,
			value: t("My Shipments"),
			iconClass: "hollow orng",
		},
		{
			key: PARCEL_CONSTANTS.DELIVERED,
			value: t("Delivered"),
			iconClass: "hollow grn",
		},
		{
			key: PARCEL_CONSTANTS.EXCEPTION,
			value: t("Exceptions"),
			iconClass: "white-icon",
		},
	];

	const [activeTab, setActiveTab] = useState(category);

	const readyToShipParcel = [];

	const addQueryParam = (key, value) => {
		const queryParam = qs.parse(window.location.search);
		if (value && value !== "") {
			queryParam[key] = value;
		}
		history.push({ search: qs.stringify(queryParam) });
	};

	const removeQueryParam = (key) => {
		const queryParam = qs.parse(window.location.search);
		if (queryParam[key]) {
			delete queryParam[key]
		}
		history.push({ search: qs.stringify(queryParam) });
	};

	useEffect(() => {
		if (!activeTab || activeTab === "") {
			category = "all";
		}
		setActiveTab(category);
		addQueryParam("category", category);
	}, []);

	useEffect(() => {
		removeQueryParam("uuid")
		removeQueryParam("to")
		removeQueryParam("from")
		if (activeTab !== PARCEL_CONSTANTS.ALL) {
			props.setMessage("My Shop & Ship")
		}
	}, [activeTab])

	const itemsAutocomplete = async () => {
		try {
            setLoading(true)

			const [suggestionsResponse,suppliersResponse] = await Promise.all([
				getData(
					"parcelService",
					"/items-details-autocomplete",
					authToken
				),
				getData(
					"parcelService", "/parcels/supplier", authToken
				)
			]);
            if (suggestionsResponse?.status && suppliersResponse?.status) {
                setSuggestions(suggestionsResponse?.payload || []);
				setSuppliers(suppliersResponse?.payload || []);
			}
            setLoading(false)
		} catch (error) {
			console.error('unable to get suggestions/suppliers', error)
            setLoading(false)
		}
    }

    useEffect(async () => {
      await itemsAutocomplete()
    }, [])
    

	useEffect(async () => {
		try {
			const response = await getData("paymentService", "/dashboard-flash", authToken)
			if (response?.payload) {
				setMessageKey(response?.payload?.key)
				setExpirationDate(response?.payload?.expiry_date)
				setAramcoUser(checkForLifeTimeSubscribtion(response?.payload?.expiry_date)
					? true : aramcoUser)
			} else {
				setMessageKey(null)
			}
			//referral-code-count
			const referralCountresponse = await postData(
				"userService",
				"/referral-code-count",
				{ referral_code: `${REFERRAL_PREFIX}${userPin}` },
				authToken)
			if (referralCountresponse?.status) {
				setFriendsCount(referralCountresponse?.payload)
			}
		} catch (error) {
			console.error(error?.message)
		}

		// const res = await getUserDetails(authToken);
		// if (res.status) {
		// 	await setAramcoUser(checkForLifeTimeSubscribtion(res?.plan_data?.expiry_date)
		// 		? true : aramcoUser)
		// }

		setLoading(false)
	}, [])

	const isBusinessPlan = Number(membershipPlanID) === 4;

	return (
		<div className="widget widget_packages">
			{!isBusinessPlan && <Notifications
				membershipPlanID={membershipPlanID}
				loading={loading}
				counts={counts}
				messageKey={messageKey}
				isEnglish={isEnglish}
				activeTab={activeTab}
				isMobile={isMobile}
				expirationDate={expirationDate}
				t={t}
				setShow={props.setShow}
				addQueryParam={addQueryParam}
				setActiveTab={setActiveTab}
				aramcoUser={aramcoUser}
				friendsCount={friendsCount}
				userPin={userPin}
			/>}
			<div className="widget__inner">
				<div className="widget__content" id="mparcels">
					<Nav
						variant="tabs"
						activeKey={activeTab}
						className={`dashboard-nav-items-link ${isMobile && "px-2"}`}
					>
						{navTabs?.map((nav, index) => {
							return (
								<NavItem
									isMobile={isMobile}
									key={nav.key}
									counts={counts}
									isEnglish={isEnglish}
									addQueryParam={addQueryParam}
									setActiveTab={setActiveTab}
									nav={nav}
								/>
							)
						})}
					</Nav>
					<div
						className={`${counts.category === 0
							? "justify-content-center align-items-center d-flex"
							: ""
							} tab-content 
              ${isMobile ? "" : "parcel_minheight"} 
              ${readyToShipParcel.length
								? "padding-all-0"
								: "empty-padding-main-block"
							}
            `}
						id="nav-tabContent"
					>
						{navTabs?.map((nav, index) => {
							return (
								<>
									<div
										className={`tab-pane fade ${activeTab === nav.key ? "show active" : ""
											}`}
										role="tabpanel"
										id={`nav-${nav.key}-parcels-tab-content`}
									>
										<Parcel
											addQueryParam={addQueryParam}
											removeQueryParam={removeQueryParam}
											category={nav.key}
											counts={counts}
											setCounts={setCounts}
											authToken={authToken}
											params={params}
											isMobile={isMobile}
											isEnglish={isEnglish}
											suggestions={suggestions}
											suppliers={suppliers}
										/>
									</div>
								</>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};
