import React, { useState } from "react";
import { IconButton, InputAdornment, OutlinedInput } from "@material-ui/core"
import { Visibility, VisibilityOff } from '@material-ui/icons';

export default ({ password, onChange }) => {
    const [showPassword, setShowPassword] = useState(false)
    return (
        <OutlinedInput
            id="registerPassword"
            name="password"
            autoComplete="new-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => onChange(e)}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                    >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            }
        />
    );
};