import React, { useEffect, useState } from "react";
import {
    Row,
    Col
} from "react-bootstrap";
import RatesList from "./ratesList";
import VasList from "./vasList";

import Banner from "./banner";
import "./style.scss";
import { getData } from "../../utils/api";
import { useAuth } from "../../contexts/AuthContext";
import Loader from "../common/loader";
import NoRecordsIcon from "../incomingOrders/requests/no-records.svg"
import { useTranslation } from "react-i18next";

export default () => {
    const [rates, setRates] = useState()
    const [vas, setVas] = useState()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const { authToken } = useAuth()
    const { t, i18n } = useTranslation();

    const loadShippingRatesAndVas = async () => {
        setLoading(true)
        try {
            const shippingRatesRes = await getData("pricingService", `/customer/rate/discount/v1`, authToken)
            const vasRes = await getData("pricingService", `/customer/services/v1`, authToken)
            if (shippingRatesRes?.status) {
                setRates([...shippingRatesRes?.payload])
            } else {
                setErrors([...(errors.filter(e => ["general", "shippingRates"]?.includes(e?.name)) || []), {
                    name: "shippingRates",
                    msg: "400"
                }])
            }
            if (vasRes?.status) {
                setVas([...vasRes?.payload])
            } else {
                setErrors([...(errors.filter(e => ["general", "vas"]?.includes(e?.name)) || []), {
                    name: "vas",
                    msg: "400"
                }])
            }
            setLoading(false)
        } catch (error) {
            setErrors([...(errors.filter(e => e?.name === "general") || []), {
                name: "general",
                msg: "400"
            }])
            setLoading(false)
        }
    }

    useEffect(async () => {
        await loadShippingRatesAndVas()
    }, [])


    return (
        <section className="myrates-container-wrapper">
            <Row>
                <Col>
                    <Banner />
                </Col>
            </Row>
            <div className="myrates-container">
                {loading ? (
                    <Loader />
                ) :
                    <Row className="row dashboard-row px-4 justify-content-center align-items-center">
                        <Col xxl={11} xl={11} sm={12} className="">
                            <div className="widget widget_packages">
                                <div className="widget__inner rounded">
                                    <div className="widget__content" id="incoming-orders">
                                        {rates?.length === 0 && vas?.length === 0 ?
                                            <div className="text-center">
                                                <img src={NoRecordsIcon} />
                                                <p className="blue-color font-wegiht-500 my-2">{t("No Discounts Added")}</p>
                                            </div>
                                            :
                                            <>
                                                <RatesList
                                                    rates={rates}
                                                // setRates={setRates}
                                                />
                                                <VasList
                                                    vas={vas}
                                                // setVas={setVas}
                                                />
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                }
            </div>
        </section>
    )
};