export const event = {
    USER_LOGGED_IN: "user_logged_in",
    USER_LOGIN: "user_log_in",
    SINGUP_BUTTON: "signup_button",
    SIGNIN_BUTTON: "signin_button",
    MEMBERSHIP_SELECTED: "membership_selected",
    REGISTRATION_COMPLETED: "registration_completed",
    MEMBERSHIP_PROMO_APPLY_COMPLETED: "membership_promo_apply_completed",
    MEMBERSHIP_PROMO_CANCEL_BUTTON: "membership_promo_cancel_button",
    MEMBERSHIP_PROMO_SUBSCRIBE_BUTTON: "membership_promo_subscribe_button",
    MEMBERSHIP_PAYMENT_STARTED: "membership_payment_started",
    MEMBERSHIP_PAYMENT_COMPLETED: "membership_payment_completed",
    MEMBERSHIP_PAYMENT_FAILURE: "membership_payment_failure",
    VERIFY_EMAIL_COMPLETED: "verify_email_completed",
    EMAIL_VERIFICATION_SEND_BUTTON: "email_verification_resend_button",
    FORGOT_PASSWORD_BUTTON: "forgot_password_button",
    SEND_RESET_LINK_BUTTON: "send_reset_link_button",
    UPGRADE_NOW_BUTTON: "upgrade_now_button",
    LOGOUT: "user_logged_out",

    SHIPPING_SHIP_SELECTED_PARCELS_BUTTON:
        "shipping_ship_selected_parcels_button",
    SHIPPING_APPLY_PROMO_COMPLETED: "shipping_apply_promo_completed",
    SHIPPING_CANCEL_PROMO_BUTTON: "shipping_cancel_promo_button",
    SHIPPING_NO_CONSOLIDATION_CREDITED_COMPLETED:
        "shipping_no_consolidation_credited_completed",
    SHIPPING_NO_CONSOLIDATION_PAY_NOW_BUTTON:
        "shipping_no_consolidation_pay_now_button",
    SHIPPING_NO_CONSOLIDATION_PAYMENT_STARTED:
        "shipping_no_consolidation_payment_started",
    SHIPPING_NO_CONSOLIDATION_PAYMENT_COMPLETED:
        "shipping_no_consolidation_payment_completed",
    SHIPPING_NO_CONSOLIDATION_PAYMENT_FAILURE:
        "shipping_no_consolidation_payment_failure",
    SHIPPING_CONSOLIDATION_REQUEST_COMPLETED:
        "shipping_consolidation_request_completed",
    SHIPPING_CONSOLIDATION_CREDITED_COMPLETED:
        "shipping_consolidation_credited_completed",
    SHIPPING_CONSOLIDATION_PAY_BUTTON: "shipping_consolidation_pay_button",
    SHIPPING_CONSOLIDATION_PAY_NOW_BUTTON:
        "shipping_consolidation_pay_now_button",
    SHIPPING_CONSOLIDATION_PAYMENT_STARTED:
        "shipping_consolidation_payment_started",
    SHIPPING_CONSOLIDATION_PAYMENT_COMPLETED:
        "shipping_consolidation_payment_completed",
    SHIPPING_CONSOLIDATION_PAYMENT_FAILURE:
        "shipping_consolidation_payment_failure",
    DASHBOARD_BIFM_SUBMIT_BUTTON: "dashboard_bifm_submit_button",
    BIFM_BUTTON: "bifm_button",
    BIFM_PURCHASE_REQUEST_BUTTON: "bifm_new_purchase_request_button",
    BIFM_CANCEL_PURCHASE_REQUEST_BUTTON: "bifm_cancel_purchase_request_button",
    BIFM_PURCHASE_REQUEST_COMPLETED: "bifm_purchase_request_completed",
    BIFM_PURCHASE_REQUEST_UPDATE_COMPLETED:
        "bifm_purchase_request_update_completed",
    BIFM_PURCHASE_REQUEST_PAY_BUTTON: "bifm_purchase_request_pay_button",
    BIFM_PURCHASE_REQUEST_CHECKOUT_STARTED:
        "bifm_purchase_request_payment_started",
    BIFM_PURCHASE_REQUEST_CHECKOUT_COMPLETED:
        "bifm_purchase_request_payment_completed",
    BIFM_PURCHASE_REQUEST_CHECKOUT_FAILURE:
        "bifm_purchase_request_payment_failure",

    LANGUAGE_BUTTON: "language_button",
    RATE_CALCULATOR_BUTTON: "rate_calculator_button",
    CALCULATE_SHIPPING_COST_BUTTON: "calculate_shipping_cost_button",
    WALLET_BUTTON: "wallet_button",
    HELP_SHOPPING_DIRECTORY_BUTTON: "help_shopping_directory_button",
    HELP_TRUE_WEIGHT_BUTTON: "help_true_weight_button",
    OTHER_SERVICES_BUTTON: "services_other_services_button",
    FACEBOOK_BUTTON: "facebook_button",
    IG_BUTTON: "ig_button",
    LINKED_ID_BUTTON: "linked_in_button",
    TWITTER_BUTTON: "twitter_button",
    HELP_SUPPORT_BUTTON: "help_support_button",
    RATE_US_BUTTON: "rate_us_button",

    DASHBOARD_REFERRAL_COPY_BUTTON: "dashboard_referral_copy_button",
    DASHBOARD_RENEW_BUTTON: "dashboard_renew_button",
    DASHBOARD_ADD_CARD_BUTTON: "dashboard_add_card_button",

    TRACK_SHIPMENT_COMPLETED: "track_shipment_completed",
    HELP_FAQ_BUTTON: "help_faq_button",
    HelpProhibitedMaterialsButton: "help_prohibited_materials_button",

    SERVICE_PACKAGE_CONSOLIDATION_BUTTON:
        "services_package_consolidation_button",
    SERVICE_ITEM_CONSOLIDATION_BUTTON: "services_item_consolidation_button",
    SERVICES_OTHER_SERVICES_BUTTON: "services_other_services_button",
    SERVICES_BIFM_BUTTON: "services_bifm_button",

    CONTACT_US_SUBMIT_COMPLETED: "contact_us_submit_completed",
    E_COMMERCE_BUTTON: "e_commerce_button",
    CONTACT_US: "contact_us",

    APPLE_DOWNLOAD_BUTTON: "apple_download_button",
    GOOGLE_DOWNLOAD_BUTTON: "google_download_button",
    HELP_AND_SUPPORT_BUTTON: "help_and_support_button",
    TRACK_SHIPMENT_BUTTON: "track_shipment_button",
};

export const eventAttribute = {
    PROVIDER: "provider",

    MEMBERSHIP_TYPE: "membership_type",
    PLAN_TYPE: "plan_type",
    AMOUNT: "amount",
    ACTION: "action",

    FIRST_NAME: "first_name",
    LAST_NAME: "last_name",
    PHONE_NUMBER: "phone_number",
    COUNTRY_CODE: "country_code",
    EMAIL: "email",
    REFERRAL_CODE: "referral_code",
    RECEIVE_MARKETING: "receive_marketing",
    RECEIVE_WHATSAPP: "receive_whatsapp",

    PROMO_CODE: "promo_code",
    VALID: "valid",
    SOURCE: "source",

    SUBTOTAL: "subtotal",
    DISCOUNT_AMOUNT: "discount_amount",
    TOTAL_AMOUNT: "total_amount",

    STATUS: "status",

    LANGUAGE: "language",

    SHIP_FORM: "ship_from",
    SHIP_TO: "ship_to",
    PARCEL_WEIGHT: "parcel_weight",
    WEIGHT_UNIT: "weight_unit",

    PRODUCT_URL: "product_url",
    PRODUCT_NAME: "product_name",
    UNIT_PRICE: "unit_price",
    QUANTITY: "quantity",
    SHIPPING_FEES: "shipping_fees",
    COLOR: "color",
    SIZE: "size",
    ATTACH_PICTURES: "attach_pictures",
    COMMENTS: "comments",
    REQUEST_ID: "request_id",
    SERVICE_FEES: "service_fees",

    NO_OF_PARCELS: "no_of_parcels",
    WAREHOUSE: "warehouse",
    COUNTRY: "country",
    CITY: "city",
    SHIPPING_COMPANY: "shipping_company",
    EXTRA_SERVICES: "extra_services",
    PARCEL_IDS: "parcel_ids",
    TOTAL_CHARGEABLE_WEIGHT: "total_chargeable_weight",
    TAX: "tax",

    CONSOLIDATION_TYPE: "consolidation_type",
    CONSOLIDATION_FEES: "consolidation_fees",
    PARCEL_ID: "parcel_id",
    ACTUAL_WEIGHT: "actual_weight",
    VOLUMETRIC_WEIGHT: "volumetric_weight",
    CHARGEABLE_WEIGHT: "chargeable_weight",
    TOTAL_ACTUAL_WEIGHT: "total_actual_weight",

    PAYMENT_METHOD: "payment_method",
    WALLET_BALANCE: "wallet_balance",
    USED_WALLET_AMOUNT: "used_wallet_amount",
    REASON: "reason",

    COMPANY_NAME: "company_name",
    NAME: "name",
    SUBJECT: "subject",
    MESSAGE: "message",

    TRACKING_NUMBER: "tracking_number",
};

export const userAttribute = {
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name",
    PHONE: "phone",
    EMAIL: "email",
    COUNTRY: "country",
    MEMBERSHIP_TYPE: "membership_type",
    PLAN_TYPE: "plan_type",
    LANGUAGE: "language",
    MEMBERSHIP_EXPIRY_DATE: "membership_expiry_date",
    REFERRAL_CODE: "referral_code",
    VERIFICATION_DATE: "verification_date",
    SIGNUP_DATE: "sign_up_date",
};

export const screenEvent = {
    DASHBOARD: {
        name: "dashboard",
        event: "dashboard_page_viewed",
    },
    LOGIN: {
        name: "login",
        event: "login_page_viewed",
    },
    SIGNUP: {
        name: "signup",
        event: "signup_page_viewed",
    },
    VERIFICATION: {
        name: "verification",
        event: "verification_page_viewed",
    },
    FORGOT_PASSWORD: {
        name: "forgot_password",
        event: "forgot_password_page_viewed",
    },
    MEMBERSHIP: {
        name: "membership",
        event: "membership_page_viewed",
    },
    MEMBERSHIP_PROMO_CODE: {
        name: "membership_promo_code",
        event: "membership_promo_code_page_viewed",
    },
    MEMBERSHIP_PAYMENT: {
        name: "membership_payment",
        event: "membership_payment_page_viewed",
    },
    ACCOUNT: {
        name: "account",
        event: "account_page_viewed",
    },
    PROFILE: {
        name: "profile",
        event: "profile_page_viewed",
    },
    MEMBERSHIP_PROFILE: {
        name: "membership_profile",
        event: "membership_profile_page_viewed",
    },
    ADDRESSES: {
        name: "addresses",
        event: "addresses_page_viewed",
    },
    ADD_ADDRESS: {
        name: "add_address",
        event: "add_address_page_viewed",
    },
    EDIT_ADDRESS: {
        name: "edit_address",
        event: "edit_address_page_viewed",
    },
    PAYMENTS: {
        name: "payments",
        event: "payments_page_viewed",
    },
    ADD_PAYMENT: {
        name: "add_payment",
        event: "add_payment_page_viewed",
    },
    PAYMENT_HISTORY: {
        name: "payment_history",
        event: "payment_history_page_viewed",
    },
    WALLET: {
        name: "wallet",
        event: "wallet_page_viewed",
    },
    OUR_SERVICES: {
        name: "our_services",
        event: "our_services_page_viewed",
    },
    SHOPPING_DIRECTORY: {
        name: "shopping_directory",
        event: "shopping_directory_page_viewed",
    },
    WAREHOUSE_ADDRESS_EXAMPLE: {
        name: "warehouse_address_example",
        event: "warehouse_address_example_page_viewed",
    },
    CALCULATOR_RESULT: {
        name: "calculator_result",
        event: "calculator_result_page_viewed",
    },
    CALCULATOR_CONTACT: {
        name: "calculator_contact",
        event: "calculator_contact_page_viewed",
    },
    BIFM: {
        name: "bifm",
        event: "bifm_page_viewed",
    },
    CREATE_PURCHASE_REQUEST: {
        name: "create_purchase_request",
        event: "create_purchase_request_page_viewed",
    },
    UPDATE_PURCHASE_REQUEST: {
        name: "update_purchase_request",
        event: "update_purchase_request_page_viewed",
    },
    BIFM_PAYMENT: {
        name: "bifm_payment",
        event: "bifm_payment_page_viewed",
    },
    SHIP_NOW: {
        name: "ship_now",
        event: "ship_now_page_viewed",
    },
    NO_CONSOLIDATION_PAYMENT: {
        name: "no_consolidation_payment",
        event: "no_consolidation_payment_page_viewed",
    },
    UPDATE_PARCEL_DETAILS: {
        name: "update_parcel_details",
        event: "update_parcel_details_page_viewed",
    },
    SHIPMENT_DETAILS: {
        name: "shipment_details",
        event: "shipment_details_page_viewed",
    },
    CONSOLIDATION_PAYMENT: {
        name: "consolidation_payment",
        event: "consolidation_payment_page_viewed",
    },
};
