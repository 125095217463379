import React from "react";
import { useTranslation } from "react-i18next";
import TabBody from "./tabBody";

function Tab3() {
	const { t } = useTranslation();
	const questionsAndAnswers = [
		{ containerId: "threeone", collapseId: "collapseThreeOne", question: t("Qthreeone"), answer: t("Athreeone") },
		{ containerId: "threetwo", collapseId: "collapseThreeTwo", question: t("Qthreetwo"), answer: t("Athreetwo") },
		{ containerId: "threethree", collapseId: "collapseThreeThree", question: t("Qthreethree"), answer: t("Athreethree") },
		{ containerId: "threefour", collapseId: "collapseThreeFour", question: t("Qthreefour"), answer: t("Athreefour") },
		{ containerId: "threefive", collapseId: "collapseThreeFive", question: t("Qthreefive"), answer: t("Athreefive") },
		{ containerId: "threesix", collapseId: "collapseThreeSix", question: t("Qthreesix"), answer: t("Athreesix") },
	]
	return (
		<>
			<div className="accordion" id="generalQuestionsQuestions">
				<div className="card align-items-start">
					<p className="sub-page-section-header mb-show-block text-start">{t("Payment Processing")}</p>
				</div>
				{questionsAndAnswers?.map(questionAndAnswer =>
					<TabBody questionAndAnswer={questionAndAnswer} />
				)}
			</div>
		</>
	);
}

export default Tab3;
