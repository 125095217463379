import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Row, Col } from "react-bootstrap";
import filterFactory from "react-bootstrap-table2-filter";
import { useAuth } from "../../contexts/AuthContext";

function TableList(props) {
    const { t, i18n } = useTranslation();
	const { rates=false } = props;
	return (
		<div className="section-container">
			<ToolkitProvider
				keyField="uuid"
				data={props?.data || []}
				columns={props?.columns}
				search
			>
				{
					props => {
						return (
							<div className="table-container my-2">
								<Row className={"table-data"}>
									<Col>
										{/* <SearchBar {...props.searchProps} /> */}

										<BootstrapTable
											{...props?.baseProps}
											remote={true}
											keyField={"id"}
											// onTableChange={(type, { page, sizePerPage, totalSize }) => setOptions({ ...options, page, sizePerPage, totalSize })}
											wrapperClasses="table-responsive spacing-table"
											headerClasses={`${i18n?.language === "ar" ? "ar" : ""} ${rates ? "rates" : ""} tbl-hd-bg`}
											bordered={false}
											rowClasses="row-class"
											filter={filterFactory()}
										/>

									</Col>
								</Row>
							</div>
						)
					}
				}
			</ToolkitProvider>
		</div>
	);
}


export default TableList;