import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import * as UserInfo from "../../services/userInfoService"
import {
	postNewUserAddress,
	postUpdateUserAddress
} from "../../services/userInfoService"
import { useAuth } from "../../contexts/AuthContext"
import { head, omit, uniqBy } from "lodash"
import useUserAddresses from "../../utils/useUserAddresses"
import useCountries from "../../utils/useCountries"
import { Alert } from "react-bootstrap"
import { notAllowAR } from "../../utils/string"
// import PlacesAutoComplete from "../../components/common/googlePlacesAutoComplete/placesAutoComplete"

function RegisterAddress(props) {
	const { t, i18n } = useTranslation()
	const classNameForPhoneInput = i18n.language === "ar" ? "ltr-direction w-100" : "w-100"

	//const location = useGeoLocation()
	const { authToken } = useAuth()
	const { addressList } = useUserAddresses()
	// const [newUserAddress, props.setNewUserAddress] = useState({
	// 	fname: props.fname || "",
	// 	lname: props.lname || "",
	// 	country: country || props.country || "AE",
	// 	code: country || props.code || "AE",
	// 	state: props.state || "",
	// 	city_id: "",
	// 	postal_code: postalCode || props.postal_code || "",
	// 	phone: phone || props.phone || "",
	// 	address: props.address || "",
	// 	translated_address: props.translated_address || "",
	// 	address2: props.address2 || null,
	// 	region_id: props.region_id || null,
	// 	is_primary: props.is_primary || 0,
	// })
	const { countries } = useCountries()
	const [states, setStates] = useState([])
	const [errorList, setErrorList] = useState([])

	useEffect(() => {
		async function fillStates() {
			//removal of autofill location
			//const addressDetails = await getAddressLine(props.newUserAddress?.country)
			props.setNewUserAddress({
				...props.newUserAddress,
				code: props.newUserAddress?.country,
				//address: addressDetails?.address ?? "",
			})
			const retrieveStates = await UserInfo.getStates(props.newUserAddress?.country)
			setStates(uniqBy(
				retrieveStates?.data?.filter(
					state => state.state_code && state.state_name
				), "state_code"
			))
		}

		fillStates()
	}, [props.newUserAddress?.country])

	let name, value
	const handleInputs = (event) => {
		name = event.target.name
		value = notAllowAR(event.target.value)
		switch(name) {
			case "country":
				return	props.setNewUserAddress({
						...props.newUserAddress, [name]: value,
						address: "",
						address2: "",
						postal_code: "",
						state: ""
					})

			case "address":
			case "address2":
				props.setNewUserAddress({ ...props.newUserAddress, [name]: value })
				if (props?.newUserAddress?.address || props?.newUserAddress?.address2) {
					if (props?.newUserAddress?.address?.length + props?.newUserAddress?.address2?.length > 40) {
						if (errorList && errorList.length > 0) {
							return setErrorList([...errorList, { param: "address", msg: "your address should not exceed 40 chars", value }])
						}
						else {
							return setErrorList([{ param: "address", msg: "your address should not exceed 40 chars", value }])
						}
					}
					else {
						return setErrorList(null)
					}
				}
			
			default:
				props.setNewUserAddress({ ...props.newUserAddress, [name]: value })
		}

	}

	const handlePhoneInput = (phoneValue) => {
		props.setNewUserAddress({ ...props.newUserAddress, phone: phoneValue })
	}

	const handlePrimary = (event) => {
		if (event.target.checked) {
			props.setNewUserAddress({ ...props.newUserAddress, is_primary: 1 })
		} else {
			props.setNewUserAddress({ ...props.newUserAddress, is_primary: 0 })
		}
	}

	const postAddNewAddressSubmit = (e) => {
		e.preventDefault()
		const registerAddressData = {
			...props.newUserAddress,
			city_id: states?.find(state => state.state_name === props.newUserAddress?.state)?.id,
			is_primary: addressList?.length > 0 ? props.newUserAddress?.is_primary : 1
		}

		async function register(data) {
			if (props.method === "appendNewUser") {
				try {
					const res = await postNewUserAddress(authToken, data)

					if (!res?.status && res?.message && res?.message === "Errors" && res?.payload && res?.payload.length > 0) {
						setErrorList(res?.payload)
						throw new Error("Failed to Update new Address")
					}

					if (res?.status) {
						setErrorList(null)
						props.closeModal()
					}

					if (props.onAddressAdd) {
						return props.onAddressAdd(e)
					}
				} catch (e) {
					console.log(e.message)
				}
			}
			if (props.method === "updateExistingUser") {
				try {
					let diff = Object.keys(props.newUserAddress).reduce((diff, key) => {
						if (props[key] === props.newUserAddress[key]) return diff
						return {
							...diff,
							[key]: props.newUserAddress[key],
						}
					}, {})
					const diffWithoutRegionId = omit(diff, ["region_id", "translated_address"])
					const res = await postUpdateUserAddress(authToken, {
						...diffWithoutRegionId,
						id: props.id,
					})

					if (res?.message && res?.message === "Errors" && res?.payload && res?.payload.length > 0) {
						setErrorList(res?.payload)
						throw new Error("Failed to Update new Address")
					}

					if (res?.status) {
						setErrorList(null)
						props.closeModal()
					}

					if (props.onAddressAdd) {
						return props.onAddressAdd(e)
					}
				} catch (e) {
					console.log(e.message)
				}
			}
		}

		register(registerAddressData)
	}

	function ErrorsList() {
		return (
			<>
				{errorList && uniqBy(errorList, "msg")?.map((error) => (
					<Alert 
						variant={"danger"}
						key={error.param}
					>
						{error?.param}: {error?.msg}
					</Alert>
				))}
			</>
		)
	}

	return <div className="global-section-container flex-col">
		<div className="w-50-mobile-100 white-background w-100 p-0">
			<form
				id="registerNewAddressForm"
				method="POST"
				className="text-start w-100 pr-0 pl-0"
				onSubmit={postAddNewAddressSubmit}
			>
				{/* fname, lname */}
				{/* {addressList?.length > 0 && */}
					<div className="form-group row">
						<div className="form-group col-12 col-sm-12 col-md-6">
							<label htmlFor="registerNewAddressFname"
								className="required">{t("Fname")}</label>
							<input
								autoComplete="off"
								type="text"
								className="form-control"
								id="registerNewAddressFname"
								name="fname"
								value={notAllowAR(props.newUserAddress?.fname)}
								onChange={handleInputs}
								required
							/>
						</div>
						<div className="form-group col-12 col-sm-12 col-md-6">
							<label htmlFor="registerNewAddressLname"
								className="required">{t("Lname")}</label>
							<input
								autoComplete="off"
								type="text"
								className="form-control"
								id="registerNewAddressLname"
								name="lname"
								value={notAllowAR(props.newUserAddress?.lname)}
								onChange={handleInputs}
								required
							/>
						</div>
					</div>
				{/* } */}
				{/* country, state */}
				<div className="form-group row">
					<div className="form-group col-12 col-sm-12 col-md-12">
						<label htmlFor="registerNewAddressCountry"
							className="required">
							{t("Country")}{" "}
						</label>
						<select
							autoComplete="off"
							className="form-control"
							id="registerNewAddressCountry"
							name="country"
							onChange={handleInputs}
							value={props.newUserAddress?.code}
							required
						>
							<option
								value="">{t("Select a country")}</option>
							{countries?.map((country) => (
								<option key={country.id} name="code"
									value={country.iso2_code}>
									{country.name}
								</option>
							))}
						</select>
					</div>
					<div className="col-12 col-sm-12 col-md-12">
						<label className="required" htmlFor="registerNewAddressState">{t("State")}</label>
						<select
							autoComplete="off"
							className="form-control"
							id="registerNewAddressState"
							name="state"
							value={props.newUserAddress?.state}
							onChange={handleInputs}
						>
							<option value="">{t("Select a state")}</option>
							{!!props.state && (
								<option value={props.state} selected>
									{props.state}
								</option>
							)}
							{states?.map((state) => (
								<option key={state.state_code}
									value={state.state_name}>
									{state.state_name}
								</option>
							))}
						</select>
					</div>
				</div>

				<div className="form-group col-12 col-sm-12 pr-0 pl-0">
					<label htmlFor="registerNewAddressAdd1"
						className="required">
						{t("Address-line-1")}{" "}
					</label>
					{/* <PlacesAutoComplete
                            inputId="registerNewAddressAdd1"
                            inputObj={newUserAddress}
                            setValue={props.setNewUserAddress}
                            country={props.newUserAddress?.country}
                            states={states}
                        /> */}
					<input
						autoComplete="off"
						type="text"
						className="form-control"
						id="registerNewAddressAdd1"
						name="address"
						value={props.newUserAddress?.address}
						onChange={handleInputs}
						required
					/>
				</div>
				<div className="form-group col-12 col-sm-12 pr-0 pl-0">
					<label htmlFor="registerNewAddressAdd2">
						{t("Address-line-2")}{" "}
					</label>
					<input
						autoComplete="off"
						type="text"
						className="form-control"
						id="registerNewAddressAdd2"
						name="address2"
						value={props.newUserAddress?.address2}
						onChange={handleInputs}
					/>
				</div>
				{/* city, postal_code */}
				<div className="form-group row">
					<div className="col-12 col-sm-12 col-md-6">
						<label htmlFor="registerNewAddressPostalCode">
							{t("Zip-postal-code")}
						</label>
						<input
							autoComplete="off"
							type="text"
							className="form-control"
							id="registerNewAddressPostalCode"
							name="postal_code"
							value={props.newUserAddress?.postal_code}
							onChange={handleInputs}
						/>
					</div>
					<div className="col-12 col-sm-12 col-md-6">
						<label
							htmlFor="registerNewAddressMobileInput"
							className="required"
						>
							{t("Phone")}{" "}
						</label>
						<PhoneInput
							country={props.newUserAddress?.code.toLowerCase()}
							inputProps={{
								name: "phone",
								required: true,
								autoComplete: "off"
							}}
							id="registerNewAddressMobileInput"
							onChange={handlePhoneInput}
							inputClass="form-control w-100"
							value={props.newUserAddress?.phone}
							disableDropdown
							containerClass={classNameForPhoneInput}
						/>
					</div>
				</div>
				{addressList?.length > 0 && !props?.newUserAddress?.is_source_address &&
					<div className={`form-group row ${i18n.language === "en" ? "px-4" : ""}`}>
						<div
							className="col-12 col-sm-12 col-md-6 d-flex align-items-center">
							<div className="mt-3">
								<input
									type="checkbox"
									className="form-check-input h-auto mt-2"
									id="registerNewAddressIsPrimary"
									onClick={handlePrimary}
									checked={props.newUserAddress?.is_primary}
								/>
								<label
									className="form-check-label mt-1"
									htmlFor="registerNewAddressIsPrimary"
								>
									<span>{t("Make-primary-address")}</span>
								</label>
							</div>
						</div>
					</div>
				}
				{!!errorList && <ErrorsList />}
				<button type="submit"
					disabled={errorList?.length > 0}
					// onClick={(e) => props.onAddressAdd && props.onAddressAdd(e)}
					className="btn btn-primary w-100 mt-3">
					{t("Save")}
				</button>
			</form>
		</div>
	</div>
}

export default RegisterAddress
