import { Link } from "react-router-dom";
import useAnalytics from "../../../../analytics/useAnalytics";
import { CONTACT_US } from "../../../../constants/navigationConstants";
import styles from "./styles.module.scss";

const Contents = () => {
    const analytics = useAnalytics();

    return (
        <>
            <div className={`${styles["shopini-logistics"]}`}>
                E-COMMERCE SOLUTIONS
            </div>
            <div className={`${styles["title"]}`}>
                Dedicated Customer Support
            </div>
            <div className={`${styles["sub-title"]}`}>
                Our expert e-commerce consultants will guide you in optimizing
                your business costs with effective delivery solutions.
            </div>
            <div
                className="d-inline-block"
                onClick={() => {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                }}
            >
                <Link
                    className={`btn btn-primary signup-btn ${styles["btn-overrides"]}`}
                    to={CONTACT_US}
                    onClick={() =>
                        analytics.events.contactUs({ source: "Ecommerce" })
                    }
                >
                    SCHEDULE CALL
                </Link>
            </div>
        </>
    );
};

export default Contents;
