import React, {useEffect, useState} from "react"
import {Col, Row} from "react-bootstrap"
import {useTranslation} from "react-i18next"
import {useAuth} from "../../contexts/AuthContext"
import {
    getUserAddress,
    postMakeAddressPrimary,
} from "../../services/userInfoService"

import RegisterAddress
    from "../../sections/registrationForm/registerNewAddress"

import {Loader} from "../common"
import { isEmpty } from "lodash"
import useUserAddresses from "../../utils/useUserAddresses"

const AddressList = (
    {
        addressList,
        selectedAddress,
        setSelectedAddress,
        setIsAddAddress,
        makePrimary,
        i18n
    }
) => {
    const {t} = useTranslation()

    const onChangeOfAddress = (address) => {
        setSelectedAddress(address)
    }

	useEffect(() => {
		setSelectedAddress(selectedAddress)
	}, [selectedAddress])

    return (
        <>
            <Row
                className={`btn_marg px-3 ${i18n.language === "ar" ? "text-right" : "text-left"}`}>
                {addressList?.map((address, index) => {
                    return (
                        <Col
                            md={12}
                            className={`services-blk ${
                                index > 0 ? "services-blk-brdrtp" : ""
                            }`}
                            data-dismiss="modal"
                            key={address?.id}
							id={selectedAddress?.id}
                            onClick={() => {
                                onChangeOfAddress(address)
                            }}
                        >
                            <div className="d-flex flex-row">
                                <div className="items pding-9">
                                    <input
                                        type="radio"
                                        className="checked-address"
                                        name="user_address_id"
                                        id="user_address_id"
                                        checked={address?.id === selectedAddress?.id}
                                    />
                                </div>
                                <Col md={10} className="pl-2">
									<span
                                        className="font16-gry1 contentdisplay">
										{address?.fname}&nbsp;{address?.lname}
                                        <strong className="d-block font12-gry3 mb-0">{address.address}</strong>
                                        <p className="font12-gry3 mb-0">
											{`${address?.postal_code ? address?.postal_code : "---"} ${
                                                address?.city
                                            }, ${address?.code}`}
										</p>
                                        {/* @php $isocode = countrySelect2($row->country) @endphp
                            {$row->address} <p className="font12-gry3 mb-0">{($row->postal_code)?$row->postal_code:'---'},
                                {$row->city} {$isocode->iso3_code} </p> */}
									</span>
                                </Col>
                                <div className="colwd10 text-right pding-9">
									<span
                                        className="star-icn"
                                        data-id={address?.id}
                                        onClick={() => makePrimary(address)}
                                    >
										<i
                                            className={`bx icstr ${
                                                address?.is_primary ? "bxs-star" : "bx-star"
                                            } cstar${address?.id}`}
                                        />
									</span>
                                </div>
                            </div>
                        </Col>
                    )
                })}
            </Row>
            <Row
                className={`btn_marg ${i18n.language === "ar" ? "text-right" : "text-left"}`}>
                <Col md={12} className="mt-2">
                    <a
                        id="pd_add_new_shipping"
                        onClick={() => setIsAddAddress(true)}
                    >
                        <i className="fas fa-plus"/> {t("Add a new shipping address")}
                    </a>
                </Col>
            </Row>
            <Row>
                <Col md={12}
                     className="mb-4 text-center text-danger no-address-msg"/>
                {/* <Col md={12} className="col-md-12">
					<button
						className="rounded btn-register submit-choose"
						id="pd_change_address_button"
						onClick={onSelectAddress}
					>
						{t("Select")}
					</button>
				</Col> */}
            </Row>
        </>
    )
}

export default ({address, onAddressChange, userAddressesState}) => {
    const {t, i18n} = useTranslation()
    const {authToken, username, country, phone, postalCode} = useAuth()

    const [isAddAddress, setIsAddAddress] = useState(false)
    const [selectedAddress, setSelectedAddress] = useState(address)
	const {addressList, getUserAddressList} = useUserAddresses()
    const [newUserAddress, setNewUserAddress] = useState({
		fname: username && username.split(" ")[0],
		lname: username && username.split(" ").splice(1, username.split(" ").length).join(" "),
		country: country || "AE",
		code: country || "AE",
		state: "",
		city_id: "",
		postal_code: postalCode || "",
		phone: phone || "",
		address: "",
		translated_address: "",
		address2: "",
		region_id: null,
		is_primary: 0,
	})
	const onAddressAdd = async (e) => {
        getUserAddressList()
    }

    const makePrimary = async (address) => {
        await postMakeAddressPrimary(authToken, address)
		getUserAddressList()
    }

	useEffect(async () => {
		if (addressList?.length > 0 && !selectedAddress?.id){
			setSelectedAddress(isEmpty(address) ? addressList?.find(address => address.is_primary) : address)
			setIsAddAddress(false)

		} else {
			setIsAddAddress(true)
        }
	}, [])

    useEffect( () => {
		if (!userAddressesState) {
			setIsAddAddress(true)
		} else {
			setIsAddAddress(false)
		}
    }, [userAddressesState])

    useEffect(() => {
        onAddressChange(selectedAddress)
    }, [selectedAddress])

	const emptyAddressFields = () => {
		setNewUserAddress({
			...newUserAddress,
			country: country,
			code: country,
			state: "",
			city_id: 0,
			postal_code: "",
			phone: phone,
			address: "",
			translated_address: "",
			address2: "",
			region_id: null,
			is_primary: 0,
		});
	}

	useEffect(() => {
		if (isAddAddress) {
			emptyAddressFields()
		}
	}, [isAddAddress])

    return (
        <div
            className="modal fade"
            id="address-modal"
            tabIndex="-1"
            aria-labelledby="address-modal"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-dialog-centered login-modal-width">
                <div className="modal-content p-3 w-100">
                    <div className="modal-header border-bottom-0">
                        <h4
                            className={`modal-title btn_marg font22-gry1 ${i18n.language === "ar" ? "text-right" : "text-left"}`}
                            id="exampleModalLabel"
                        >
                            {isAddAddress ? (
                                <span>
									<a
                                        className="backto"
                                        id="pd_change_shipping"
                                        onClick={() => setIsAddAddress(false)}
                                    >
										<i className="bx bx-chevron-left flip-right"/>
									</a>
									<span
                                        className="pdlft10">{userAddressesState || addressList?.length > 0 ? t("Add new address") : t("Delivery Address")}</span>
								</span>
                            ) : (
                               userAddressesState || addressList?.length > 0 ? t("Change Address") : t("Add Address")
                            )}
                        </h4>
                        <button
                            type="button"
                            className={`close mx-0 ${i18n.language === "ar" ? "float-left" : "float-right"}`}
                            data-dismiss="modal"
                            aria-label="Close"
                            id="dismiss-shipping-address-modal"
                        >
                            <i className="bx bx-x"/>
                        </button>
                    </div>
                    <div className="modal-body w-100">
                            <>
                                {isAddAddress ? (
                                    <RegisterAddress
                                        onAddressAdd={onAddressAdd}
                                        method="appendNewUser"
										newUserAddress={newUserAddress}
										setNewUserAddress={setNewUserAddress}
										addressList={addressList}
                                        closeModal={() => setIsAddAddress(false)}
                                    />
                                ) : (
                                    <AddressList
                                        addressList={addressList}
                                        selectedAddress={!selectedAddress?.id && addressList?.length > 0 ? addressList?.find(address => address.is_primary) : selectedAddress}
                                        setIsAddAddress={setIsAddAddress}
                                        makePrimary={makePrimary}
                                        setSelectedAddress={setSelectedAddress}
                                        i18n={i18n}
                                    />
                                )}
                            </>

                    </div>
                </div>
            </div>
        </div>
    )
}
