import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useAnalytics from "../../../analytics/useAnalytics";
import { REGISTRATION_FORM } from "../../../constants/navigationConstants";
import "./styles.scss";

const Header = () => {
    const { t, i18n } = useTranslation();
    const analytics = useAnalytics()

    return (
        <div className="header-wrapper">
            <div className="aspect-ratio-box">
                <div className="aspect-ratio-box-container">
                    <div className="contents">
                        {/* Do not add any classes to contents div, you may put contents here*/}
                        <p className="shopping-guide text-white">
                            {t("Shopini World Shopping Guide")}
                        </p>
                        <div className="d-inline-block">
                            <Link
                                className="btn btn-primary signup-btn"
                                to={REGISTRATION_FORM}
                                onClick={analytics.events.signupButton}
                            >
                                {t("Directory Sign up")}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
