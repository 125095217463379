import Contents from "./contents";
import CustomerSupportImage from "./image";
import styles from "./styles.module.scss";

const CustomerSupport = () => {
    return (
        <div className={`${styles["schedule-a-call"]}`}>
            <div className="d-flex justify-content-between align-items-start flex-wrap">
                <div className={styles["image"]}>
                    <CustomerSupportImage />
                </div>
                <div className={styles["contents"]}>
                    <Contents />
                </div>
            </div>
        </div>
    );
};

export default CustomerSupport;
