import React from "react"
import { useTranslation } from "react-i18next"
import "./style.scss"
import { Link } from "react-router-dom"
import { MEMBERSHIP_PLANS } from "../../constants/navigationConstants"
import { useMediaQuery } from "react-responsive"

const Banner = ({}) => {
  const { t, i18n } = useTranslation()
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return <>
    <div className={`banner-${i18n.language}`}>
        <span className="d-block need-help">
            {t("Need help to shop")}
            <br/>
            {t("We can do it for you")}
        </span>
        <span className="service-text"> 
            {t("With our")}&nbsp;
            {isMobile && <br/>}
            <strong>{t("Buy it for me service").replace("service :", "").replace(":", "")}</strong>
            {isMobile && <br/>}
            {t("service")}
        </span>
        <Link
            id=""
            to={MEMBERSHIP_PLANS}
            className={`signup-btn text-center text-white text-uppercase border-radius-5 btn-bg-color text-decoration-none px-4 py-2`}
	        >
		        {t("Sign up now")}
        </Link>
    </div>
  
  </>
}

export default Banner