// import { CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from "react-router-dom";
import { getData, postData } from '../../../../../utils/api';
import { useAuth } from '../../../../../contexts/AuthContext';
import { Row, Col, Form, Button } from "react-bootstrap";
import { REQUESTS_CONSTANTS, GLOBAL_CONSTANTS } from '../../../../../constants/constants';
import * as NAVIGATION_CONSTANTS from "../../../../../constants/navigationConstants"
import { at, isEmpty } from 'lodash-es';
import imagePlaceholder from "./imagePlaceholder.png";
import AmazonPay from '../../../../AmazonPay';
import useAnalytics from '../../../../../analytics/useAnalytics';

export default ({
	setDoneSteps,
	doneSteps,
	productDetails,
	setProductDetails,
	loading,
	setLoading,
	pictureBase64,
	handleRemoveItemLink,
	setReload,
	reload,
	location
}) => {
	const { t, i18n } = useTranslation();
	const inverseAlignment = i18n.language === "en" ? "text-left" : "text-right";
	const languageMargin = i18n.language === "en" ? "mr-1" : "ml-1";
	const { authToken, walletBalance, setWalletBalance } = useAuth();
	// const stripe = useStripe();
	// const elements = useElements();

	const [isValid, setIsValid] = useState(false);
	const [name, setName] = useState("");
	const [saveCard, setSaveCard] = useState();
	const [selectedCard, setSelectedCard] = useState(null);
	const [selectWallet, setSelectWallet] = useState(true);
	const [savedCardList, setSavedCardList] = useState([]);
	const [btnId, setBtnId] = useState("")
	const [subTotal, setSubTotal] = useState()
	const [paymentRequestData, setPaymentRequestData] = useState({})
	const [paymentResult, setPaymentResult] = useState("failedPurchase")
	const [isAmazonPay, setIsAmazonPay] = useState(false);
	const [isPaypal, setIsPaypal] = useState(false);
	const [isOthers, setIsOthers] = useState(false);
	const [proceedToNextPage, setProceedToNextPage] = useState(false);
	const [paymentStatus, setPaymentStatus] = useState("in-progress");
	const analytics = useAnalytics()
	const checkoutStartedRef = useRef(false)

	let imageLink = productDetails?.PurchaseAttachments?.length > 0
		? at(productDetails?.PurchaseAttachments[0]?.Attachment, ["domain", "folder_path", "attachment_name"]).join("")
		: pictureBase64

	const bIFMPaymentEventAttributes = {
        productUrl: productDetails.product_url,
        productName: productDetails.product_name,
        unitPrice: productDetails.unit_price,
        quantity: productDetails.quantity,
        shippingFees: productDetails.shipping_fee,
        color: productDetails.color ?? undefined,
        size: `${productDetails.size ?? ""}`,
        attachPictures: !!pictureBase64,
        comments: productDetails.comments ?? undefined,
        totalAmount: subTotal,
        paymentMethod: paymentRequestData?.card_amount
            ? selectedCard?.card_type === "PAYPAL"
                ? "PayPal"
                : selectedCard?.card_type === "AMAZON_PAY"
                ? "Amazon"
                : undefined
            : undefined,
        walletBalance,
        usedWalletAmount: selectWallet
            ? parseFloat(subTotal) > parseFloat(walletBalance)
                ? Number(parseFloat(walletBalance).toFixed(2))||0
                : Number(parseFloat(subTotal).toFixed(2))||0
            : undefined,
        requestId: productDetails.id,
        serviceFees: productDetails.service_fee
            ? Number(productDetails.service_fee)
            : 0,
    };

	async function retrieveWalletDetails() {
		try {
			const response = await getData(
				"paymentRequestService",
				"/v1/PurchaseRequestController/getTransactionsWallet",
				authToken
			)
			let tempWalletData = (!response.status || response.error) ? [] : response.payload.data
			var newBalance = tempWalletData.length > 0 ? (tempWalletData[0].available_balance).toFixed(2) : 0
			setWalletBalance(newBalance ? Number(newBalance) : undefined)
			setLoading(false);
		} catch (e) {
			console.log(e);
		}
	}
	// const onNameChange = (e) => {
	// 	let value = e.target.value;
	// 	setName(value);
	// 	if (value === "") {
	// 		setIsValid(false)
	// 	}
	// };

	// const toggleSaveCard = (e) => {
	// 	setSaveCard(e.target.checked);
	// };
	if (!imageLink)
		imageLink = imagePlaceholder;

	// const onCardElementsValueChange = (event, element) => {
	// 	var displayError = document.getElementById(`card-${element}-error`);
	// 	if (event.error) {
	// 		displayError.textContent = event.error.message;
	// 		setIsValid(false);
	// 	} else {
	// 		displayError.textContent = "";
	// 		setIsValid(true);
	// 	}
	// };

	const onPaymentCompletion = async (response, request = {}) => {
		if (isAmazonPay || isPaypal) {
			if (response?.status) {
				setPaymentStatus("success");
			} else {
				setPaymentStatus("fail");
			}
			setProceedToNextPage(true);
		} else {
			if (response?.status || (request?.is_payby_card === 0 && request?.is_payby_wallet === 1)) {
				setLoading(true)
				await afterPayment(request)
			} else {
				setDoneSteps(["failedPurchase"])
			}
			setLoading(false)
			setProceedToNextPage(false);
		}
		// setWalletBalance(parseFloat(response?.payload?.data?.User?.LastWalletTransaction[0]?.available_balance))
	}

	const afterPayment = async (request = {}) => {
		try {
			let walletPaymentResponse
			if (request?.is_payby_wallet === 1 && request?.wallet_amount > 0) {
				walletPaymentResponse = await postData(
					"paymentRequestService",
					"/v2/PurchaseRequestController/paymentPurchaseRequest",
					{
						purchase_request_id: productDetails.id,
						is_payby_wallet: 1,
						wallet_amount: request?.wallet_amount,
					},
					authToken
				)
				request["wallet_transaction_id"] = walletPaymentResponse?.payload?.item?.id
			}
			if (walletPaymentResponse?.status || request?.is_payby_wallet === 0) {
				const updateStatus = await postData(
					"paymentRequestService",
					"/v2/PurchaseRequestController/createPurchaseRequestPayment",
					{
						...request
					},
					authToken
				)
				if (updateStatus?.status) {
					analytics.events.bifmPurchaseRequestPaymentCompleted(bIFMPaymentEventAttributes)
					setDoneSteps(["successPurchase"])
				}
				if (setReload) {
					setReload(!reload)
				}
			} else {
				const {totalAmount ,requestId, paymentMethod} = bIFMPaymentEventAttributes
				analytics.events.bifmPurchaseRequestPaymentFailure({paymentMethod, totalAmount, requestId, reason: "Failed"})
				setDoneSteps(["failedPurchase"])
			}
		} catch (error) {
			console.log(error)
			setDoneSteps(["failedPurchase"])
		}
	}

	const handlePayment = async () => {
		let request = {
			purchase_request_id: productDetails.id,
		};
		setLoading(true)
		setBtnId("purchase-btn")
		// else {
		// 	if (name && name !== "") {
		// 		if (!isValid) return;
		// 		const { error, paymentMethod } = await stripe.createPaymentMethod({
		// 			type: "card",
		// 			card: elements.getElement(CardNumberElement),
		// 		});
		// 		if (!error) {
		// 			// const { id, card } = paymentMethod;
		// 			request["card_id"] = paymentMethod.id;
		// 			request["is_payby_card"] = 1;
		// 			request["is_new_card"] = 1;
		// 			request["save_card"] = saveCard ? 1 : 0;
		// 		} else {
		// 			console.log(error.message);
		// 			// setPaymentError(t(error.message))
		// 			setLoading(false)
		// 			return;
		// 		}
		// 	} else {
		// 		request["is_payby_card"] = 0;
		// 	}
		// }

		request["is_payby_wallet"] = selectWallet ? 1 : 0;
		request["wallet_amount"] = selectWallet ? (subTotal > parseFloat(walletBalance) ? parseFloat(walletBalance) : subTotal) : 0;
		request["card_amount"] = selectWallet
			? subTotal > parseFloat(walletBalance) ? (subTotal - parseFloat(walletBalance)) : 0
			: subTotal;
		request["card"] = request["card_amount"] > 0 && selectedCard?.id ? {
			...selectedCard,
			digits: selectedCard?.digits,
			name: selectedCard?.name
		} : null
		if (selectedCard && !isEmpty(selectedCard) && request["card_amount"] > 0) {
			request["is_payby_card"] = 1;
			request["card_id"] = selectedCard?.id;
		} else {
			request["is_payby_card"] = 0;
		}
		request["card_reference"] = request["card_amount"] > 0 ? selectedCard?.card_reference : null
		setPaymentRequestData(request)
		try {
			var response
			if (request["is_payby_card"] === 1) {
				if (selectedCard?.card_type === "PAYPAL") {
					response = await postData(
						"paymentService",
						"/paypal/agreement?payment_type=buy-it&capture=true",
						request,
						authToken
					);
				} else if (selectedCard?.card_type === "AMAZON_PAY") {
					response = await postData(
						"paymentService",
						"/amazon/signature?type=buy-it&capture=true",
						request,
						authToken
					);
				}
			}
			request["transaction_id"] = response?.payload?.transaction_id
			onPaymentCompletion(response, request)
		} catch (error) {
			console.log("error - ", error);
			onPaymentCompletion(response, request["is_payby_wallet"])
		}
		setLoading(false);
	}

	// const getSavedCard = async () => {
	// 	const response = await getData("paymentService", "/cards", authToken);
	// 	if (response.status && response.payload && response.payload.length) {
	// 		setSavedCardList(response.payload);
	// 	}
	// 	setLoading(false);
	// 	setBtnId("");
	// };

	useEffect(async () => {
		await retrieveWalletDetails();
		// await getSavedCard();
		setSubTotal(productDetails?.total_amount)
		setSelectWallet(parseFloat(walletBalance) > 0)
	}, [walletBalance]);

	// useEffect(() => {
	// 	if (selectedCard) {
	// 		setName(selectedCard.name);
	// 		setIsValid(true);
	// 	}
	// }, [selectedCard]);

	useEffect(() => {
		if (isAmazonPay || isPaypal) {
			setPaymentRequestData({
				purchase_request_id: productDetails.id,
				is_payby_card: 1,
				card_id: null,
				wallet_amount: selectWallet ? (subTotal > parseFloat(walletBalance) ? parseFloat(walletBalance) : subTotal) : 0,
				card_amount: selectWallet
					? subTotal > parseFloat(walletBalance) ? (subTotal - parseFloat(walletBalance)) : 0
					: subTotal,
				total: subTotal,
				is_payby_wallet: selectWallet ? 1 : 0
			})
		}
	}, [isAmazonPay, selectedCard?.name, isPaypal])


	const onQuantityChange = (changeType) => {
		if (changeType === "inc") {
			setProductDetails({
				...productDetails,
				quantity: productDetails.quantity + 1,
				amount: productDetails?.unit_price * (productDetails.quantity + 1)
			})
			const newSubTotal = subTotal + productDetails?.unit_price
			setSubTotal(newSubTotal)
		} else {
			productDetails?.quantity > 1 ?
				setProductDetails({
					...productDetails,
					quantity: productDetails.quantity - 1,
					amount: productDetails?.unit_price * (productDetails.quantity - 1)
				})
				:
				handleRemoveItemLink(productDetails?.id)
			const newSubTotal = subTotal - productDetails?.unit_price
			setSubTotal(newSubTotal)
		}
	}

	// useEffect(() => {
	// 	if (isAmazonPay || isPaypal || ["AMAZON_PAY", "PAYPAL"]?.includes(selectedCard?.card_type)){
	// 		setSelectWallet(false)
	// 	} else {
	// 		setSelectWallet(true)
	// 	}
	// }, [isAmazonPay, isPaypal, JSON.stringify(selectedCard)])

	useEffect(() => {
        if (
            !checkoutStartedRef.current &&
            (subTotal > parseFloat(walletBalance) || !selectWallet)
        ) {
            checkoutStartedRef.current = true;
            const {
                paymentMethod,
                walletBalance,
                usedWalletAmount,
                ...rest
            } = bIFMPaymentEventAttributes;
            analytics.events.bifmPurchaseRequestPaymentStarted(rest);
        }
    }, [
        JSON.stringify(bIFMPaymentEventAttributes),
        subTotal,
        walletBalance,
        selectWallet,
    ]);

	return <>
		{proceedToNextPage
			? <Redirect
				to={{
					pathname: NAVIGATION_CONSTANTS.PAYMENT_RETURN,
					search: "",
					state: {...(location && location.state && location.state ? location.state : {}), bIFMPaymentEventAttributes}
				}}
			/>
			: null
		}
		<div className="checkout-form w-100 px-2">
			<p className={`mb-3 ${inverseAlignment}`}>{t("Product Details")}</p>
			<div className="card border rounded py-2">
				<div className="row w-100">
					<div className="col-lg-2 col-md-2"><img className="img-fluid" src={imageLink} /></div>
					<div className="col-lg-6 col-md-5 px-0 product-image-detail text-start">
						<h5 className="font-weight-500 mb-2">{productDetails.product_name}</h5>
						{[productDetails.quantity, productDetails.color].map(element =>
							<div className={`${element === productDetails.quantity ? i18n.language === "ar" ? "border-left pl-2" : "border-right pr-2" : "px-4"} d-inline`}>
								<small className={i18n.language === "ar" ? "ml-3" : "mr-3"}>{element === productDetails.quantity ? t("Quantity") : t("Color")}:</small>
								<small className={`${i18n.language === "ar" ? "ml-3" : "mr-3"} white-space-nowrap`}>{element}</small>
							</div>
						)}
					</div>
					{/* <div className="col-lg-2 col-md-3 px-0">
					<div className="w-fit border rounded-top rounded-bottom">
						<a type="button" className="minus bg-gray" onClick={() => onQuantityChange("dec")}
						>-</a>
						<span className="text-blue">{productDetails?.quantity}</span>
						<a type="button" className="plus" onClick={() => onQuantityChange("inc")}>+</a>
					</div>
					<Link onClick={() => handleRemoveItemLink(productDetails?.id)} disabled={loading}>
						{loading && btnId === "" ?
							<div class="spinner-border text-primary" role="status"></div>
							:
							t("Remove")
						}
					</Link>
				</div> */}
					<div className={`col-lg-4 col-md-4 d-flex white-space-nowrap justify-content-end product-quantity ${i18n.language === "ar" ? "pl-4" : "pr-4"}`}>
						$ {productDetails?.unit_price} x {productDetails?.quantity}
					</div>
				</div>
			</div>
			{parseFloat(walletBalance) > 0 && <Form.Group>
				<Col sm={12} className={`saved-card-container border rounded mt-4`}>
					<Form.Check
						type="checkbox"
						size="md"
						label={<div className="saved-card-details">
							<span>{t("Use Wallet")}</span>
							<span className="name-on-card">{t("Balance")}{" "}{parseFloat(walletBalance)?.toFixed(2)} {GLOBAL_CONSTANTS.CURRENCY}</span>
						</div>}
						checked={selectWallet}
						// disabled={isAmazonPay || selectedCard?.card_type === "AMAZON_PAY"}
						onChange={(e) => {
							setSelectWallet(!selectWallet)
							if (subTotal <= parseFloat(walletBalance) && e.target.checked) {
								setIsAmazonPay(false)
								setIsPaypal(false)
								setPaymentRequestData({
									...paymentRequestData,
									is_payby_card: 0
								})
							}
						}}
					/>
				</Col>
			</Form.Group>
			}
			{/* <Row className="px-2 py-1 border">
			<Col col={2}>
				<input className="d-inline" type="checkbox" value={true} />
			</Col>
			<Col col={2}>
				<span>{t("Use Wallet")}</span>
				<small className="text-muted">{"Balance: 10 USD"}</small>
			</Col>
		</Row> */}
			{subTotal > parseFloat(walletBalance) || !selectWallet ?
				<>
					{/* <p className={`mb-2 mt-4 ${inverseAlignment}`}>{t("Request_Payment_Option")}</p> */}
					<AmazonPay
						paymentRequestData={paymentRequestData}
						setShipmentLoading={setLoading}
						onPaymentCompletion={onPaymentCompletion}
						paymentType={"buy-it"}
						shipmentLoading={loading}
						totalPayment={subTotal}
						parcels={[]}
						addOnServices={[]}
						fee={{}}
						setCreditCard={setSelectedCard}
						creditCard={selectedCard}
						setIsAmazonPay={setIsAmazonPay}
						setIsPaypal={setIsPaypal}
						setIsOthers={setIsOthers}
						walletPayment={parseFloat(walletBalance) > subTotal}
						handleSubmit={handlePayment}
						analyticsEventAttributes={bIFMPaymentEventAttributes}
					/>
				</> : null}
			<Row className="w-100 mx-0 mt-3">
				<Col md={4} sm={0} />
				<Col md={8} sm={12} className="border rounded">
					<div className="my-2 d-flex justify-content-between">
						<span className="">{t("Request_Total")}</span>
						<span className="float-end">{GLOBAL_CONSTANTS.CURRENCY_VAL} {(productDetails?.unit_price * productDetails?.quantity).toFixed(2)}</span>
					</div>
					<div className="my-2 d-flex justify-content-between">
						<span className="">{t("Request_Shipping_Fees")}</span>
						<span className="float-end">{GLOBAL_CONSTANTS.CURRENCY_VAL} {productDetails?.shipping_fee}</span>
					</div>
					<div className="my-2 d-flex justify-content-between">
						<span className="">{t("Request_Service_Fees")}</span>
						<span className="float-end">{GLOBAL_CONSTANTS.CURRENCY_VAL} {productDetails?.service_fee}</span>
					</div>
					{/* { selectWallet && 
				<div className="my-2 d-flex justify-content-between">
					<span className="">{t("Wallet Balance")}</span>
					<span className="float-end">-{GLOBAL_CONSTANTS.CURRENCY_VAL} {parseFloat(subTotal) > parseFloat(walletBalance)?parseFloat(walletBalance).toFixed(2):parseFloat(subTotal).toFixed(2)}</span>
				</div>
				} */}
					<hr className="my-3" />
					<div className="my-2 d-flex justify-content-between">
						<h5 className="font-weight-500 d-inline">{t("Request_Subtotal")}</h5>
						<h5 className="font-weight-500 d-inline">
							{GLOBAL_CONSTANTS.CURRENCY_VAL + " " + parseFloat(subTotal).toFixed(2)}
						</h5>
					</div>
				</Col>
			</Row>
			{selectWallet &&
				<Row className="w-100 mx-0 mt-3">
					<Col offset={6} />
					<Col md={6} sm={12} className="border rounded">
						<div className="my-2 d-flex justify-content-between">
							<span className="">{t("Paid from Wallet")}</span>
							<span className="float-end">{GLOBAL_CONSTANTS.CURRENCY_VAL} {parseFloat(subTotal) > parseFloat(walletBalance) ? parseFloat(walletBalance).toFixed(2) : parseFloat(subTotal).toFixed(2)}</span>
						</div>
						<div className="my-2 d-flex justify-content-between">
							<span className="">{t("To be paid")}</span>
							<span className="float-end">{parseFloat(subTotal) <= parseFloat(walletBalance) && GLOBAL_CONSTANTS.CURRENCY_VAL + " " + 0}
								{parseFloat(subTotal) > parseFloat(walletBalance) && GLOBAL_CONSTANTS.CURRENCY_VAL + " " + (parseFloat(subTotal).toFixed(2) - parseFloat(walletBalance).toFixed(2)).toFixed(2)}
							</span>
						</div>
					</Col>
				</Row>
			}
			<Row className="w-100 mx-0 mb-3">
				<Col offset={6} />
				<Col md={6} className="border rounded gray-bg my-3 py-2">
					<Row className="px-1">
						<Col xs={1} className="px-2">
							<i className={`step-color-orange bx bx-info-circle font-18`}></i>
						</Col>
						<Col xs={11} className={`px-2 ${inverseAlignment}`}>
							<span className="font-weight-light font-14 mt-2 px-0">
								<span className={languageMargin}>{t("For payment terms Returns please click")}</span>
							</span>
							<a href={"./buy-it-for-me-policy"} target="_blank">{t("here")}</a>
						</Col>
					</Row>
				</Col>
			</Row>
			<div className="d-flex justify-content-between mt-3 mb-5 button-row">
				<Link
					to={"#"}
					onClick={() => setDoneSteps([...doneSteps.filter(doneStep => doneStep !== "checkoutStep")])}
					className="btn-lg px-5 gray-bg">
					{t("Go Back")}
				</Link>
				{(isAmazonPay || isPaypal || loading && btnId === "purchase-btn" ||
					(!selectWallet || walletBalance < subTotal) && !selectWallet || paymentRequestData?.is_payby_card === 1) ? null : <Button
						disabled={isAmazonPay || isPaypal || loading && btnId === "purchase-btn" ||
							(!selectWallet || walletBalance < subTotal) && !selectWallet || paymentRequestData?.is_payby_card === 1}
						id="purchase-btn"
						onClick={() => handlePayment()}
					>
					{loading ?
						<div className="spinner-border text-light" role="status"></div>
						:
						t("Pay Now")
					}
				</Button>
				}
			</div>
		</div>
	</>
}