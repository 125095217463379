import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAuth } from "../../contexts/AuthContext"
import { ACCOUNT_SETTINGS, ACCOUNT_SHIPPER_ADDRESS_BOOK } from "../../constants/navigationConstants"
import { Link } from "react-router-dom"
import BackButtonSvg from "../../assets/img/icons/backButton.svg"
import {
	getUserAddress,
	postDeleteUserAddress,
	postMakeAddressPrimary,
} from "../../services/userInfoService"
import RegisterAddress from "../../sections/registrationForm/registerNewAddress"
import Account from "../account"
import { Loader } from "../common"
import { CustomSwitch } from "../common/CustomSwitch"
import useUserAddresses from "../../utils/useUserAddresses"
import ReactPaginate from "react-paginate"
import { slice } from "lodash"
import useAnalytics from "../../analytics/useAnalytics";
import { screenEvent } from "../../analytics/constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

const AccountBackButton = () => {
	const { t, i18n } = useTranslation()
	return (
		<Link to={ACCOUNT_SETTINGS} className="row align-items-center mb-2">
			{i18n.language === "ar" && (
				<img
					alt="..."
					src={BackButtonSvg}
					className="ml-3 mr-3 rotate-180"
				/>
			)}
			{i18n.language !== "ar" && (
				<img alt="..." src={BackButtonSvg} className="ml-3 mr-3" />
			)}
			<p className="mb-0 text-start">{t("Account")}</p>
		</Link>
	)
}

const AddNewAddress = (props) => {
	const { t, i18n } = useTranslation()
	const { username, country, phone, postalCode } = useAuth()
	const analytics = useAnalytics()
	const { updateAddresses } = props
	const floater = i18n.language === "ar" ? "float-left" : "float-right"
	const [newUserAddress, setNewUserAddress] = useState({
		fname: username && username.split(" ")[0],
		lname: username && username.split(" ").splice(1, username.split(" ").length).join(" "),
		country: country || "AE",
		code: country || "AE",
		state: "",
		city_id: "",
		postal_code: postalCode || "",
		phone: phone || "",
		address: "",
		translated_address: "",
		address2: "",
		region_id: null,
		is_primary: 0,
		is_source_address: props?.isSourceAddresses ? 1 : 0
		
	})
	const closeModal = () => {
		document.querySelector("#new-address-button").click()
	}

	const emptyAddressFields = () => {
		setNewUserAddress({
			...newUserAddress,
			country: country,
			code: country,
			state: "",
			city_id: 0,
			postal_code: "",
			phone: phone,
			address: "",
			translated_address: "",
			address2: "",
			region_id: null,
			is_primary: 0,
			is_source_address: props?.isSourceAddresses ? 1 : 0
		});
	}

	return (
		<>
			<a
				className="font-18 text-decoration-none"
				id="new-address-button"
				data-toggle="modal"
				data-target="#new-address-modal"
				href="javascript:void(0)"
				onClick={()=>{
					emptyAddressFields();
					analytics.events.screenVisited({ screen: screenEvent.ADD_ADDRESS });
				}}
			>
				<div className="w-100 h-100 d-flex align-items-center justify-content-center border grey-background border-radius-10 p-4">
					<i className="bx bx-plus" />
					{t("Add new address")}
				</div>
			</a>
			<div
				className="modal fade"
				id="new-address-modal"
				tabIndex="-1"
				aria-labelledby="new-address-modal"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered login-modal-width">
					<div className="modal-content p-3 w-100">
						<div className="modal-header border-bottom-0">
							<h5 className="modal-title" id="addModalLabel">
								{t("Add new address")}
							</h5>
							<button
								type="button"
								className={`close ${floater} mr-0 ml-0 mt-0 mb-0`}
								data-dismiss="modal"
								aria-label="Close"
							>
								<i className="bx bx-x" />
							</button>
						</div>
						<div className="modal-body w-100">
							<RegisterAddress
								newUserAddress={newUserAddress}
								setNewUserAddress={setNewUserAddress}
								onAddressAdd={updateAddresses}
								method="appendNewUser"
								closeModal={closeModal}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

const AddressCard = (props) => {
	const { t, i18n } = useTranslation()
	const analytics = useAnalytics()
	const {
		address,
		userAddressesList,
		setUserAddressesList,
		updateAddresses,
		authToken
	} = props

	const updateAddressButton = "update-address-button" + address.id
	const updateAddressModal = "update-address-modal" + address.id
	const floater = i18n.language === "ar" ? "float-left" : "float-right"
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
	const [userAddressDetails, setUserAddressesDetails] = useState({ ...address, is_source_address: props?.isSourceAddresses ? 1 : 0 })
	async function handleDelete(id) {
		setUserAddressesList(
			userAddressesList?.filter(address => address.id !== id)
		)
		try {
			await postDeleteUserAddress(authToken, { id })
		} catch (e) {
			console.log(e)
		}
		setShowDeleteConfirm(false)
	}

	async function handleSetPrimary(e, id) {
		if (e.target.checked) {
			let tempAddresses = [ ...userAddressesList ]
			setUserAddressesList(
				tempAddresses?.map(address => (
					address.id !== id
					? { ...address, is_primary: 0 }
					: { ...address, is_primary: 1 }
					))
					)
		}
		try {
			await postMakeAddressPrimary(authToken, { id })
		} catch (e) {
			console.log(e)
		}
	}

	const closeModal = () => {
		document.querySelector(`#dismiss-address-modal-${address.id}`).click()
	}


	return (
		showDeleteConfirm ?

			<div className="grey-background d-flex justify-content-center align-items-center flex-column w-100 border border-radius-10 p-4 h-100">
				<p>{t("Are you sure you want to delete this address?")}</p>
				<div className="d-flex justify-content-center align-items-center mt-2">
					<a onClick={() => setShowDeleteConfirm(false)} className="btn-lg px-5 mx-2 bg-white">No</a>
					<a onClick={() => handleDelete(address.id)} className="btn-lg px-5 mx-2 btn-primary">Yes</a>
				</div>
			</div>
			:
			<div className="d-flex flex-column w-100 border border-radius-10 p-4 h-100">
				<p>
					{address.fname} {address.lname}
				</p>
				<p>{address.address}</p>
				{!!address.address2 && <p>{address.address2}</p>}
				<p>
					{address.state} {address.postal_code} {address.country}
				</p>
				<p>
					{t("Ph")} <em>{address.phone}</em>
				</p>
				{ props?.isSourceAddresses && <p>
					{t("Address ID")}&nbsp;&nbsp;<em className="text-green">{address.id}</em>
				</p> }
				<div className={`d-flex justify-content-between align-items-center`}>
					<div
						className="text-muted"
					>
						{t("Primary")}
						<CustomSwitch checked={address.is_primary} disabled={address.is_primary} onChange={(e) => handleSetPrimary(e, address.id)} name="PrimaryAddressSwitch" />
					</div> 
					
					<div>
						{!address.is_primary && (
							<a
								className="font-18 text-black mr-1 ml-1"
								onClick={() => setShowDeleteConfirm(true)}
								href="javascript:void(0)"
							>
								<i className="bx icstr bx-trash-alt font-size-22" />
							</a>
						)}

						<a
							className="font-18 text-decoration-none text-black"
							id={updateAddressButton}
							onClick={() => {
								analytics.events.screenVisited({ screen: screenEvent.EDIT_ADDRESS })
								setUserAddressesDetails({
									fname: address.fname,
									lname: address.lname,
									country: address.country,
									code: address.country,
									state: address.state,
									city_id: address.city_id,
									postal_code: address.postal_code,
									phone: address.phone,
									address: address.address,
									translated_address: "",
									address2: address.address2,
									region_id: null,
									is_primary: address.is_primary,
							})}}
							data-toggle="modal"
							data-target={"#" + updateAddressModal}
							href="javascript:void(0)"
						>
							<i className="bx bx-edit-alt font-size-22" />
						</a>
					</div>
				</div>
				<div
					className="modal fade"
					id={updateAddressModal}
					tabIndex="-1"
					aria-labelledby={updateAddressModal}
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered login-modal-width">
						<div className="modal-content p-3 w-100">
							<div className="modal-header border-bottom-0">
								<h5 className="modal-title" id="updateModalLabel">
									{t("Update-address")}
								</h5>
								<button
									id={`dismiss-address-modal-${address.id}`}
									type="button"
									className={`close ${floater} mr-0 ml-0`}
									data-dismiss="modal"
									aria-label="Close"
								>
									<i className="bx bx-x" />
								</button>
							</div>
							<div className="modal-body w-100">
								<RegisterAddress
									id={address.id}
									onAddressAdd={updateAddresses}
									method="updateExistingUser"
									newUserAddress={userAddressDetails}
									setNewUserAddress={setUserAddressesDetails}
									closeModal={closeModal}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
	)
}

export default (props) => {
	const { t } = useTranslation()
	const { authToken, username, userPin } = useAuth()
	const location = useLocation();
    let isSourceAddresses = [ACCOUNT_SHIPPER_ADDRESS_BOOK]?.includes(location?.pathname.replace(".", ""))
	const { addressList, setAddressList, getUserAddressList } = useUserAddresses(isSourceAddresses)
	const [addressesLoading, setAddressesLoading] = useState(true)
	const [pages, setPages] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);
	const [userAddressesList, setUserAddressesList] = useState([]);
	const updateAddresses = async (e) => {
		setAddressesLoading(true)
		await getUserAddressList(isSourceAddresses)
		setAddressesLoading(false)
	}
	const analytics = useAnalytics()

	//for init the addresses
	useEffect(async () => {
		setAddressesLoading(true)
		await getUserAddressList(isSourceAddresses)
		setAddressesLoading(false)
	}, [isSourceAddresses])

	useEffect(async () => {
		if (addressList?.length > 0) {
			setUserAddressesList(addressList)
			setPages(Math.ceil(addressList.length / 6))
		}
	}, [JSON.stringify(addressList)])

	const onPageChange = (params) => {
		setPageNumber(params.selected + 1);
	};

	useEffect(() => {
		if (userAddressesList) {
			let lowerLimit = (pageNumber - 1) * 6;
			let upperLimit = pageNumber * 6;

			let pageData = slice(addressList, lowerLimit, upperLimit)
			setUserAddressesList(pageData);
			if (pageNumber > pages) {
				setPageNumber(1);
			}
		}
	}, [pageNumber, JSON.stringify(addressList)]);

	useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.ADDRESSES });
    }, []);

	return (
		<div className="global-section-container flex-col grey-background pt-5 pb-5 align-items-center">
			<div className="container white-background text-start row pt-3 pb-3">
				<div className="col-12 col-sm-12 col-md-8">
					<AccountBackButton />
					<h1 className="sub-page-section-header">{isSourceAddresses ? t("Shipper-Address-book") : t("Address-book")}</h1>
					<p> {isSourceAddresses ? t("Shipper-Address-book-description") : t("Address-book-description")}</p>

					{addressesLoading ? (
						<Loader />
					) : (
						<div className="row justify-content-between">
							{userAddressesList?.length > 0 ?
								<>
									{userAddressesList?.map((address) => (
										<div
											className="col-12 col-sm-12 col-md-6 w-100 mb-4"
											key={address.id?.toString()}
										>
											<AddressCard
												address={address}
												userAddressesList={userAddressesList}
												setUserAddressesList={setUserAddressesList}
												updateAddresses={updateAddresses}
												authToken={authToken}
												isSourceAddresses={isSourceAddresses}
											/>
										</div>
									))}
									{ addressList?.length > 6 && <div className="col-12 col-sm-12 col-md-12 w-100 mb-3 d-flex justify-content-center">
										<ReactPaginate
											pageCount={pages}
											pageRangeDisplayed={6}
											marginPagesDisplayed={1}
											previousLabel={t("Previous")}
											nextLabel={t("Next")}
											breakClassName={"page-item"}
											breakLinkClassName={"page-link"}
											containerClassName={"parcel-pagination pagination"}
											pageClassName={"page-item"}
											pageLinkClassName={"page-link"}
											activeClassName={"active"}
											previousClassName={"page-item"}
											nextClassName={"page-item"}
											previousLinkClassName={"page-link"}
											nextLinkClassName={"page-link"}
											onPageChange={onPageChange}
										/>
									</div> }
								</> : null}
							<div className="col-12 col-sm-12 col-md-6 w-100 mb-4">
								<AddNewAddress isSourceAddresses={isSourceAddresses} updateAddresses={updateAddresses} />
							</div>
						</div>
					)}
				</div>
				<div className="col-12 col-sm-12 col-md-4 mb-hide">
					<Account
						user={{
							name: username,
							userPin,
						}}
						accountPage={true}
					/>
				</div>
			</div>
		</div>
	)
}
