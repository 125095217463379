import React from "react";
import {useTranslation} from "react-i18next";

function ShippingBenefitsSectionOne() {
    const {t, i18n} = useTranslation();

    return (
        <div className="main-section-container grey-background">
            <div className="container-fluid container-width">
                <h2 className="sub-page-section-header">
                    {t("Our shipping benefits")}
                </h2>
                <p className="mb-5">
                    {t("Our shipping benefits description 2")} <br/>
                    {t("Our shipping benefits description 21")}
                </p>
                <div className="row justify-content-between">
                    <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 post-450-start-text-align">
                        <h2 className="sub-section-title">
                            {t("Shop and save at brands")}
                        </h2>
                        <p className="text-justify">
                            {t("Shop and save at brands description")}
                        </p>
                    </div>
                    <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4  post-450-start-text-align">
                        <h2 className="sub-section-title">
                            {t("Save and don't pay us sales taxes")}
                        </h2>
                        <p className="text-justify">
                            {t("Save and don't pay us sales taxes description")}
                        </p>
                    </div>
                    <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4  post-450-start-text-align">
                        <h2 className="sub-section-title">
                            {t("Package return services")}
                        </h2>
                        <p className="text-justify">
                            {t("Package return services description")}
                        </p>
                    </div>
                    <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4  post-450-start-text-align">
                        <h2 className="sub-section-title">
                            {t("Inspect before shipping")}
                        </h2>
                        <p className="text-justify">
                            {t("Inspect before shipping description")}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShippingBenefitsSectionOne;
