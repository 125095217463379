import React from "react";
import WorldMapSvg from "./world-map-addresses.svg";
import {useTranslation} from "react-i18next";
import AddressCard from "./addressCard";
import Prev from "./prev.svg";
import Next from "./next.svg";
import {useMediaQuery} from "react-responsive";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation, Pagination} from "swiper/core";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import useCountries from "../../utils/useCountries";

SwiperCore.use([Pagination, Navigation]);

function Addresses() {
    const {t, i18n} = useTranslation();

    const isMobile = useMediaQuery({query: "(max-width: 768px)"});
	const { locations } = useCountries();

    return (
        <>
            <div className={`row p-4 ${isMobile ? "grey-background" : "map"}`}>
                <div
                    className="col-12 align-self-center text-center mt-2 ml-1 mr-1">
                    <img alt="..." className="w-100 mb-3" src={WorldMapSvg}/>
                </div>
            </div>
            <div className="row d-none d-md-block">
                <div className="col-12">
                    <div className="row justify-content-center">
                        {locations?.map((location) => (
                            <div
                                className={`${locations?.length <= 3 ? "col-3" : "col-2"} ltr-direction mx-3 align-items-stretch ${
                                    i18n.language === "ar" ? "text-right" : "text-left"
                                }`}
                            >
                                <AddressCard location={location}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div
                className="row d-md-none d-block grey-background ltr-direction">
                <div className={`col-12 ${
                    i18n.language === "ar" ? "text-right" : "text-left"
                }`}>
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1.2}
                        pagination={{
                            "type": "fraction"
                        }}
                        navigation={{
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                            inverse: true
                        }}

                    >
                        {locations?.map((location, index) => (
                            <div className={`card`}>
                                <SwiperSlide>
                                    <AddressCard location={location}
                                                 isMobile={isMobile}/>
                                </SwiperSlide>
                            </div>
                        ))}
                        <img
                            alt="..."
                            src={Next}
                            className={"swiper-button-next"}
                        />
                        <img
                            alt="..."
                            src={Prev}
                            className={"swiper-button-prev"}
                        />
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default Addresses
