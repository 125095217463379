import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import ShopiniFooterLink from "./shopiniFooterLink.json";
import * as constants from "../../constants/constants.js";
import { Link, useLocation } from "react-router-dom";
import * as navigationConstants from "../../constants/navigationConstants";
import { mobileStores } from "../../constants/constants";
import { last } from "lodash";
import US from "./US.svg"
// import UK from "./UK.svg"
//import CN from "./CN.svg"
import UAE from './UAE.svg'
import TRen from "./TR-en.svg"
import TRar from "./TR-ar.svg"
import rates from "./rates.png"
import shipping from "./shipping.png"
import securePayment from "./secure-payment.png"
import VisasSvg from "../copyrightFooter/cards.svg";
import './styles.scss'
import useAnalytics from "../../analytics/useAnalytics";

const LanguagesRow = ({ t, i18n, isMobile, smallNavs }) => {
    return <div className={`${smallNavs ? "text-dark d-flex justify-content-between w-30" : `languages-container-${i18n?.language}`} ${isMobile ? smallNavs ? "border-bottom w-75 pb-3" : "light-border-bottom" : ""}`}>
        <span className="tr-cursor" onClick={
            () => document?.getElementById("en-lng")?.click()
        }>{t("English")}</span>
        <span className="tr-cursor" onClick={
            () => document?.getElementById("ar-lng")?.click()
        }>{t("Arabic")}</span>
        <span className={`${!smallNavs ? "disabled-lang" : "opacity-40"} tr-cursor`} disabled>{t("Français")}</span>
        <span className={`${!smallNavs ? "disabled-lang" : "opacity-40"} tr-cursor`} disabled>{t("Español")}</span>
        <span className={`${!smallNavs ? "disabled-lang" : "opacity-40"} tr-cursor`} disabled>{t("中文")}</span>
    </div>
}

const Copyrights = ({ t, i18n, isMobile, smallNavs }) => {
    return <Row className={`text-white align-items-center justify-content-${isMobile ? "center text-center" : "end"} ${ smallNavs ? "text-muted py-2" : "pt-3"}`}>
        {!isMobile ?
            <>
                <p
                    className={`${!smallNavs ? i18n?.language === "ar" ? "pl-5 ml-5 border-left" : "pr-5 mr-5 border-right" : ""} copy-rights`}
                >
                    {t("Copyright")}
                </p>
                {!smallNavs &&
                    <>
                        <strong
                            className={`${i18n?.language === "ar" ? "ml-5" : "mr-5"} tr-cursor font-12`}
                            onClick={() => {
                                document.getElementById("t&c")?.click()
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "smooth",
                                });
                            }}
                        >
                            {t("Terms and conditions")}
                        </strong>
                        <strong
                            className="tr-cursor font-12"
                            onClick={() => {
                                document.getElementById("p-p")?.click()
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "smooth",
                                });
                            }}
                        >
                            {t("Privacy policy")}
                        </strong>
                    </>
                }
            </>
            :
            <>
                <LanguagesRow t={t} i18n={i18n} isMobile={isMobile} smallNavs={smallNavs} />
                { !smallNavs && <Row className="light-border-bottom pb-3 w-90 justify-content-between align-items-center mb-3">
                    <strong
                        className="tr-cursor"
                        onClick={() => {
                            document.getElementById("t&c")?.click()
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                            });
                        }}
                    >
                        {t("Terms and conditions")}
                    </strong>
                    <strong
                        className="tr-cursor"
                        onClick={() => {
                            document.getElementById("p-p")?.click()
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                            });
                        }}
                    >
                        {t("Privacy policy")}
                    </strong>
                </Row> }
                <p
                    className={`${smallNavs ? "px-3" : ""} copy-rights`}
                >
                    {t("Copyright")}
                </p>
            </>
        }
    </Row>
}

function ShopiniFooter() {
    const { t, i18n } = useTranslation();
    const alignment = i18n.language === "en" ? "ltr" : "rtl";
    const isMobile = useMediaQuery({ query: "(max-width: 991px)" });
    const imgSrc = `/locales/${i18n.language}/shopini.svg`;
    const location = useLocation();
    const handleRedirect = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    const analytics = useAnalytics()
    const warehouses = [
        { map: US, text: "United States", active: 1 },
        // { map: UK, text: "United Kingdom", active: 1 },
        // { map: CN, text: "China", active: 1 },
        { map: UAE, text: "UAE", active: 1 },
        { map: i18n?.language === "ar" ? TRar : TRen, text: "Turkey", active: 0 },
    ]

    const smallNavs = [...navigationConstants?.SMALL_NAV_ROUTES, navigationConstants.THANK_YOU]?.includes(location?.pathname?.replace(".", ""))
    const pagesWithHiddenFooter = [navigationConstants.DIRECTORY, navigationConstants.TRUE_WEIGHT]

    const handleAnalyticsEvent = (link) => {
        switch (link) {
            case navigationConstants.FAQ: {
                analytics.events.helpFaqButton();
                break;
            }
            case navigationConstants.PROHIBITED_MATERIALS: {
                analytics.events.helpProhibitedMaterialsButton();
                break;
            }
            case navigationConstants.DIRECTORY: {
                analytics.events.helpShoppingDirectoryButton();
                break;
            }
            case navigationConstants.TRUE_WEIGHT: {
                analytics.events.helpTrueWeightButton();
                break;
            }
            case navigationConstants.CONTACT: {
                analytics.events.eCommerceButton();
                break;
            }
            case navigationConstants.CONTACT_US: {
                analytics.events.contactUs({source:"Footer"});
                break;
            }
            default:
                return;
        }
    };

    return smallNavs ?
        <div className={isMobile ? "" : "d-flex px-5 justify-content-between align-items-center"}>
            <Copyrights t={t} i18n={i18n} isMobile={isMobile} smallNavs={smallNavs} />
            { !isMobile && <LanguagesRow t={t} i18n={i18n} isMobile={isMobile} smallNavs={smallNavs} /> }
        </div>
        : <>
            {!pagesWithHiddenFooter.includes(location?.pathname?.replace(".", "")) && <Row className="py-4 d-flex justify-content-center align-items-start">
                <Col lg={3} sm={12} className="text-center my-2">
                    <img src={rates} className="img-fluid" />
                    <strong className="d-block my-3">{t("Rates Match Guaranteed")}</strong>
                    <p className="text-width-17 mx-auto my-0">{t("We match your best rates")}</p>
                </Col>
                <Col lg={3} sm={12} className="text-center my-2">
                    <img src={shipping} className="img-fluid" />
                    <strong className="d-block my-3">{t("Multiple Shipping Options")}</strong>
                    <p className="text-width-17 mx-auto my-0">{t("Choose to ship with your preferred shipping company")}</p>
                </Col>
                <Col lg={3} sm={12} className="text-center my-2">
                    <img src={securePayment} className="img-fluid" />
                    <strong className="d-block my-3">{t("Secure Payment Methods")}</strong>
                    <p className="text-width-17 mx-auto my-0">{t("Pay with peace of mind with our Safe Pay systems")}</p>
                </Col>
            </Row>}
            {!navigationConstants.NON_WAREHOUSE_LOCATIONs_ROUTES.includes(location?.pathname?.replace(".", "")) && !navigationConstants?.AUTHED_ROUTES?.includes(location?.pathname?.replace(".", "")) && <>
                <Container id="warehouses-section" fluid className={'services-section-container px-auto'}>
                    <p className="section-title text-center">{t("Our Warehouse Locations")}</p>
                    <Row className="warehouse-container">
                        {warehouses?.map(warehouse =>
                            <Col lg={4} sm={12} className="tr-cursor text-center d-flex justify-content-around align-items-center">
                                <div className="spec-w-h text-center px-3 my-3" onClick={() => {
                                    if (warehouse?.active) {
                                        if (warehouse?.text === "United Kingdom") {
                                            document?.getElementById("uk-warehouse")?.click()
                                        }
                                        if (warehouse?.text === "United States") {
                                            document?.getElementById("us-warehouse")?.click()
                                        }
                                        if (warehouse?.text === "UAE") {
                                            document?.getElementById("uae-warehouse")?.click()
                                        }
                                        window.scrollTo({
                                            top: 0,
                                            left: 0,
                                            behavior: "smooth",
                                        });
                                    }
                                }}>
                                    <img src={warehouse.map} className={`warehouse-map ${isMobile ? "w-100" : "img-fluid"}`} />
                                    {/* {!warehouse.active &&
                                        <img
                                            src={i18n?.language === "ar" ? ComingSoonAr : ComingSoonEn}
                                            className={`${i18n?.language === "ar" ? "coming-soon-ar" : "coming-soon-en"} position-absolute img-fluid`}
                                        />
                                    } */}
                                    <span className={`map-text text-blue ${!warehouse.active && "margin-" + i18n?.language}`}>
                                        {t(warehouse.text)}
                                    </span>
                                </div>
                            </Col>
                        )}
                    </Row>
                </Container>
            </>
            }

            <Container fluid className="footer-links-container">
                <Row className="d-flex justify-content-around">
                    {/*First Column */}
                    <Col className={alignment} xs={12} md={2}>
                        <Link
                            onClick={() => handleRedirect()}
                            to={navigationConstants.HOMEPAGE}
                        >
                            <img
                                className={"shopini-icon " + alignment}
                                src={process?.env?.PUBLIC_URL + imgSrc}
                                // src={shopiniLogo}
                                alt={"..."}
                            />
                        </Link>
                        <p className={`${alignment} text-white`}>
                            <br /> {t("Find it shop it")} <br />{" "}
                            {t("And we will ship it to you")}
                        </p>
                        <Row className={"footer-row " + alignment}>
                            <a
                                className="btn-secondary"
                                href={constants.FACEBOOK_SHOPINI_URL}
                                variant="secondary"
                                target="_blank"
                                onClick={analytics.events.facebookButton}
                            >
                                <i className="bx bxl-facebook" />
                            </a>
                            <a
                                className="btn-secondary"
                                href={constants.TWITTER_SHOPINI_URL}
                                variant="secondary"
                                target="_blank"
                                onClick={analytics.events.twitterButton}
                            >
                                <i className="bx bxl-twitter" />
                            </a>
                            <a
                                className="btn-secondary"
                                href={constants.INSTAGRAM_SHOPINI_URL}
                                variant="secondary"
                                target="_blank"
                                onClick={analytics.events.igButton}
                            >
                                <i className="bx bxl-instagram" />
                            </a>
                            <a
                                className="btn-secondary"
                                href={constants.LINKEDIN_SHOPINI_URL}
                                variant="secondary"
                                target="_blank"
                                onClick={analytics.events.linkedInButton}
                            >
                                <i className="bx bxl-linkedin" />
                            </a>
                        </Row>
                        <Row className={`visa-footer-${i18n.language} px-2 ${isMobile ? "align-items-center justify-content-center pt-5" : ""}`}>
                            <img alt="..." className={isMobile ? "w-50" : ""} src={VisasSvg}></img>
                        </Row>
                    </Col>
                    <Col xs={1} className="custom-col-1 px-0" />
                    <Col
                        xs={12}
                        md={9}
                    >
                        {!isMobile &&
                            <Row className="footer-links-custom-container justify-content-between light-border-bottom position-relative">
                                {ShopiniFooterLink.filter((header) => !["Services"].includes(Object.keys(header)[0])).map((header, index) => (
                                    <>
                                        <Col
                                            className={
                                                `${i18n?.language === "ar" ? "margin-ar" : "margin-en"} px-0`
                                                // `${i18n?.language === "ar" ? index === 0 ? "mr-8" : "margin-ar" : "margin-en"} px-0`
                                                // "margin-en px-0"
                                            }
                                            key={Object.keys(header)[0]}
                                            xs={12}
                                            // md={{ span: 2, offset: index === 0 && i18n.language !== "ar" ? 1 : 0 }}
                                            md={2}
                                        >
                                            <div
                                                className={
                                                    Object.keys(header)[0] === "NOW SHOP IT, SHIP IT ON THE GO" ?
                                                        i18n?.language === "ar" ? "float-left" : "float-right"
                                                        : ""
                                                }>
                                                <p
                                                    className={
                                                        "mb-small font-18 label mx-w-20 " + alignment
                                                    }
                                                >
                                                    {Object.keys(header)[0] === "NOW SHOP IT, SHIP IT ON THE GO"
                                                        ? t(
                                                            Object.keys(header)[0]?.split(",")[0]
                                                        ) +
                                                        "," +
                                                        t(
                                                            Object.keys(header)[0]?.split(",")[1]
                                                        )
                                                        : t(Object.keys(header))}
                                                </p>
                                                {Object.values(header).map((link_obj) =>
                                                    link_obj.map((link) => (
                                                        <div
                                                            className="div-link"
                                                            key={link.linkName}
                                                        >
                                                            <Row className="shopini-links">
                                                                {["ios", "android"].includes(
                                                                    link.linkName
                                                                ) ? (
                                                                    <span className="my-1">
                                                                        <a
                                                                            disabled={link.href === "disabled"}
                                                                            href={link.href}
                                                                            onClick={link.linkName==='ios'?analytics.events.appleDownloadButton:analytics.events.googleDownloadButton}
                                                                            target="_blank"
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    mobileStores[
                                                                                    link
                                                                                        .linkName
                                                                                    ]
                                                                                }
                                                                            />
                                                                        </a>
                                                                    </span>
                                                                ) : (
                                                                    <Link
                                                                        onClick={() =>{
                                                                            link.href !== "disabled" && handleRedirect()
                                                                            handleAnalyticsEvent(link.href)
                                                                        }}
                                                                        disabled={link.href === "disabled"}
                                                                        className={
                                                                            "font-16-400 bg-transparent text-white " +
                                                                            alignment
                                                                        }
                                                                        to={link.href === "disabled" ? "" : link.href}
                                                                    >
                                                                        {link.linkName==='TrueWeigh'
                                                                            ?(<p className="d-inline-block px-1 yellow-badge font-weight-bold"><span className="text-blue-color font-weight-bold">True</span><span className="color-green">Weight</span></p>)
                                                                            :<>{t(link.linkName)}&nbsp;
                                                                                {["Turkey"].includes(link.linkName) && `( ${t("Coming soon")} )`}
                                                                            </>
                                                                        }
                                                                    </Link>
                                                                )}
                                                            </Row>
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                        </Col>
                                    </>
                                ))}
                                <LanguagesRow
                                    t={t}
                                    i18n={i18n}
                                    isMobile={isMobile}
                                    smallNavs={smallNavs}
                                />
                            </Row>
                        }
                        {!isMobile &&
                            <Copyrights
                                t={t}
                                i18n={i18n}
                                isMobile={isMobile}
                                smallNavs={smallNavs}
                            />}
                    </Col>
                </Row>
                {isMobile && (
                    <>
                        <div className="row justify-content-center align-items-center">
                            <p
                                className={`${alignment} shopini-links mt-5 pb-2`}
                            >
                                {t(
                                    Object.keys(
                                        last(ShopiniFooterLink)
                                    )[0].split(",")[0]
                                )}
                                {","}
                                <br />
                                {t(
                                    Object.keys(
                                        last(ShopiniFooterLink)
                                    )[0].split(",")[1]
                                )}
                            </p>
                        </div>
                        <div className="row justify-content-center align-items-center">
                            {Object.values(last(ShopiniFooterLink))[0].map(
                                (store) => (
                                    <div className="col-4 d-flex justify-content-center align-items-center px-1">
                                        <span>
                                            <a
                                                href={store.href}
                                                target="_blank"
                                            >
                                                <img
                                                    src={
                                                        mobileStores[
                                                        store.linkName
                                                        ]
                                                    }
                                                    className="w-100"
                                                />
                                            </a>
                                        </span>
                                    </div>
                                )
                            )}
                        </div>
                        <Copyrights
                            t={t}
                            i18n={i18n}
                            isMobile={isMobile}
                            smallNavs={smallNavs}
                        />
                    </>
                )}
                <Link id="t&c" className="d-none" to={navigationConstants.TERMS_CONDITIONS} />
                <Link id="p-p" className="d-none" to={navigationConstants.PRIVACY_POLICY} />
                <Link id="uk-warehouse" className="d-none" to={navigationConstants.UK_WAREHOUSE} />
                <Link id="us-warehouse" className="d-none" to={navigationConstants.US_WAREHOUSE} />
                <Link id="uae-warehouse" className="d-none" to={navigationConstants.HOMEPAGE} />

            </Container>
        </>
}

export default ShopiniFooter;
