import { findIndex, first, last } from "lodash"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Loader from "../../components/common/loader"
import { getData } from "../../utils/api"
import Banner from "./banner"
import BeforeTracking from "./beforeTracking"
import TrackingList from "./trackingList"
import FromToIcon from "./fromTo.svg"
import { trackingListDateFormat } from "../../utils/moment"
import useAnalytics from "../../analytics/useAnalytics";
export default () => {
  const { t, i18n } = useTranslation()
  const [parcelIdCopy, setParcelIdCopy] = useState(null)
  const [parcelId, setParcelId] = useState(null)
  const [source, setSource] = useState(null)
  const [destination, setDestination] = useState(null)
  const [error, setError] = useState(null)
  const [parcelTracking, setParcelTracking] = useState(null)
  const [shipmentSummary, setShipmentSummary] = useState(null)
  const [loading, setLoading] = useState(false)
  const analytics = useAnalytics()
  const findIndex = (key) => {
    switch (key) {
      case "dimensions":
        return 1
      case "actual_weight":
        return 2
      case "total_shipment_weight":
        return 3
      case "shipping_service":
        return 4
      default:
        return 0
    }
  }
  const handleSubmit = async (e) => {
    e?.preventDefault()
    try {
      setLoading(true)
      const res = await getData("parcelService", `/orders-parcels-shopini/tracking?tracking_number=${parcelId?.trim()}`)
      if (res?.status) {
        setParcelIdCopy(parcelId)
        setParcelTracking([...res?.payload?.updates?.reverse()])
        delete res?.payload?.updates
        let serviceName = res?.payload?.service
        let volWeight = res?.payload?.volumetric_weight?.value
        delete res?.payload?.service
        delete res?.payload?.volumetric_weight
        setSource(res?.payload?.source)
        setDestination(res?.payload?.destination)
        delete res?.payload?.source
        delete res?.payload?.destination
        let tempSummary = { ...res?.payload }
        tempSummary["shipping_service"] = { name: serviceName }
        tempSummary["tracking_number"] = { value: parcelId }
        tempSummary["total_shipment_weight"] = { value: Math.max(tempSummary["actual_weight"]?.value, volWeight), unit: tempSummary["actual_weight"]?.unit }
        let tempShipmentSummary = Object.keys(tempSummary)?.map(key => { 
          return { 
            ...(tempSummary[key]), 
            "key": key, 
            "index": findIndex(key),
            "vol_weight": key === "actual_weight" ? volWeight : undefined
          }
        })
        console.log(tempShipmentSummary?.sort((a,b) => a.index - b.index))
        setShipmentSummary([...tempShipmentSummary?.sort((a,b) => a.index - b.index)])
        analytics.events.trackShipmentCompleted({
          trackingNumber: parcelId?.trim(),
          status: 'Success'
        })
      } else {
        setError("Tracking not found")
        analytics.events.trackShipmentCompleted({
          trackingNumber: parcelId?.trim(),
          status: 'Not found'
        })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError("Tracking not found")
      console.log(error)
      analytics.events.trackShipmentCompleted({
        trackingNumber: parcelId?.trim(),
        status: 'Not found'
      })
    }
  }

  return (
    <div className="gray-bg track-section mb-3">
      <Banner
        handleSubmit={handleSubmit}
        parcelId={parcelId}
        setParcelId={setParcelId}
        error={error}
        setError={setError}
      />
      {loading ? <Loader /> : (parcelTracking?.length > 0 && (parcelIdCopy || parcelIdCopy !== "")) ?
        <>
          <Container className="mt-5">
            <Row>
              <Col md={8} sm={12}>
                <div className="d-flex tracking-list bg-white mb-2 justify-content-between align-items-center px-3 py-3">
                  <div className="text-start">
                    <small className="text-muted d-block">
                      {source?.city} - {source?.country}
                    </small>
                    <span>{source?.ship_date ? source?.ship_date : "-"}</span>
                  </div>
                  <img src={FromToIcon} className="w-50 px-2" />
                  <div>
                    <small className="text-start text-muted d-block">
                      {destination?.city} - {destination?.country}
                    </small>
                    <span>
                      {destination?.arriving_date ? destination?.arriving_date : "-"}
                    </span>
                  </div>
                </div>
                <div className="d-flex tracking-list bg-white mb-2 justify-content-between align-items-center px-3 py-3">
                  <div className="text-start">
                    <small className="text-start text-muted d-block">
                      {t("Status")}
                    </small>
                    <span>
                      <span
                        className={`status-icon`}
                      ></span>
                      &nbsp;&nbsp;{first(parcelTracking)?.title}
                    </span>
                  </div>
                  <div>
                    <small className="text-muted d-block">
                      {t("AWB")}
                    </small>
                    <span>{parcelIdCopy}</span>
                  </div>
                </div>
                <div className="tracking-list bg-white mb-2 px-3 py-3">
                  <p className="text-muted">{t("Shipment Overview")}</p>
                  {shipmentSummary?.map((info, index) =>
                    <div className={`d-flex justify-content-between align-items-center py-2 ${index === shipmentSummary?.length - 1 ? "" : "border-bottom"}`}>
                      <div className="text-start text-w-opacity">
                        <span>{t(info?.key)}</span>
                      </div>
                      <div>
                        <span>
                          {
                            (info?.value ? `${info?.value}${info?.unit ? ` ${info?.unit} ` : ""}${info?.vol_weight ? `/ ${info?.vol_weight} ${info?.unit ? `${info?.unit}` : ""}` : ""}` : "") || 
                            (info?.height && info?.width && info?.length ? `${info?.height} × ${info?.width} × ${info?.length} ${info?.unit}` : "") ||
                            (info?.name ? info?.name : "")
                          }
                        </span>
                      </div> </div>)}
                </div>
              </Col>
              <Col lg={4} sm={12}>
                <TrackingList
                  trackingList={parcelTracking}
                />
              </Col>
            </Row>
          </Container>
        </>
        : <BeforeTracking />}
    </div>
  )
} 