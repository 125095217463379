import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import { getUserDetails } from "../../services/userInfoService";
import { requestTableDateFormat } from "../../utils/moment";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from "react-bootstrap-table2-filter";
import {Loader} from "../common"
import { GLOBAL_CONSTANTS } from "../../constants/constants"

import "./style.scss";
import { getData } from "../../utils/api";
import useAnalytics from "../../analytics/useAnalytics";
import { screenEvent } from "../../analytics/constants";


export default () => {
	const { t, i18n } = useTranslation();
	const [wallet, setWallet] = useState([]);
	const [loading, setLoading] = useState(true)
	const { authToken, walletBalance } = useAuth();
	const styleArabicLeftFloat = i18n.language === "ar" ? "rtl" : "";

	const analytics = useAnalytics();
	
	async function retrieveWalletDetails() {
		try {
			const response = await getData(
				"paymentRequestService",
				"/v1/PurchaseRequestController/getTransactionsWallet",
				authToken
			)
			let tempWalletData = (!response.status || response.error) ? [] : response.payload.data
			setWallet(tempWalletData);
			var newBalance = tempWalletData.length>0?(tempWalletData[0].available_balance).toFixed(2):walletBalance>0?walletBalance.toFixed(2):0
			localStorage.setItem("walletBalance", newBalance)
			setLoading(false);
		} catch (e) {
			console.log(e);
		}
	}

	useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.WALLET });
    }, []);

	useEffect(() => {
		retrieveWalletDetails();
	}, []);
	useEffect(() => {
		setWallet([])
		setLoading(true);
		retrieveWalletDetails();
	}, [i18n.language]);
	const options = {
		sizePerPage: 10,
		hideSizePerPage: true,
		hidePageListOnlyOnePage: true,
		showTotal: false,
		prePageText: t('Previous'),
		nextPageText: t('Next'),
	  };  
	const tableColumns = [
        {
            dataField: "created_at",
            text: t("Request_Sent"),
			classes: "date-format",
            formatter: (cell, row) => {
                return cell?requestTableDateFormat(cell):'-';
            },
        },
		{
            dataField: "PurchasePaymentTransaction.PurchaseRequest.request_id",
            text: t("Request_ID"),
        },
		{
            dataField: "description",
            text: t("Description"),
			formatter: (cell, row) => {
				return (
                    <div>{t(cell)}</div>
                );
			},
        },
		{
            dataField: "transaction_amount",
            text: t("Total"),
			formatter: (cell, row) => {
				var addSign = row.operation === 'CREDIT'?"+":"-";
				if(cell > 0)
					cell = parseFloat(cell)?.toFixed(2)
				else {
					cell *= - 1;
				}
                return (
                    <div className="total-amount">
                       <span className="add-sign">{addSign}</span>
					   <span className="amount-val"> {GLOBAL_CONSTANTS.CURRENCY_VAL} { cell }</span>
                    </div>
                );
            },
        }
	]
	return (
		loading ? 
        <Loader/>
        :
		<div className="global-section-container flex-col grey-background py-3 align-items-center">
			<div className="wallet-container container white-background text-start row pt-3 pb-3 px-4">
				<Row className="w-100 py-4 mx-0" >
					<Col xs={12} sm={6} md={10} lg={10} className="wallet-heading" >{t("Available_Wallet_Balance")}</Col>
					<Col xs={12} sm={6} md={2} lg={2} className="text-end wallet-price" >{wallet.length>0?(wallet[0].available_balance).toFixed(2):walletBalance>0?walletBalance.toFixed(2):0} {t(GLOBAL_CONSTANTS.CURRENCY)}</Col>
				</Row>
				<ToolkitProvider
					keyField="id"
					data={ wallet }
					columns={ tableColumns }
					search={ { searchFormatted: true } }
					>
					{
						props => {
						return (
						<div className="table-container w-100  mx-3">
							
							<Row className={"table-data"}>
							<Col>
								<BootstrapTable
								{ ...props.baseProps }
								pagination={paginationFactory(options)}
								wrapperClasses="table-responsive spacing-table"
								headerClasses="tbl-hd-bg"
								bordered={ false }
								noDataIndication={t("No_Record")}
								rowClasses="row-class"
								filter={filterFactory()}
								/>
							</Col>
							</Row>
						</div>
						) }
					}
					</ToolkitProvider>
			</div>
		</div>
	);
};
